import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Typography,
    TextField,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { RootStoreContext } from '../../global/storeContext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        libraryItem: {
            border: 1,
            borderStyle: 'solid',
            backgroundColor: theme.palette.grey[200],
            borderColor: theme.palette.grey[500],
        },
        libraryButton: {
            padding: '4px',
            minWidth: '0px',
        },
        libraryButtonIcon: {
            border: 1,
            borderStyle: 'solid',
            borderRadius: '100%',
            borderColor: theme.palette.grey[500],
            padding: '4px',
        },
    }),
);
export interface IDescription {
    title: string;
    text: string;
}

export const LibraryComponent: React.FC<{
    name: string;
    singular: string;
    description: string;
    items: IDescription[];
    updateProperty: (value: IDescription[] | []) => void;
    readOnly?: boolean;
}> = observer(({ name, singular, description, items, updateProperty, readOnly }) => {
    const classes = useStyles();

    const [libraryItems, setLibraryItems] = useState<IDescription[]>(items);

    const { uiStore } = useContext(RootStoreContext);

    const itemExists = (item: IDescription) => {
        return (
            item &&
            item.title &&
            item.text &&
            !!libraryItems.find(
                (existingItem) =>
                    item.title === existingItem.title && item.text === existingItem.text,
            )
        );
    };

    const itemTitleExists = (item: IDescription) => {
        return (
            item &&
            item.title &&
            item.text &&
            !!libraryItems.find((existingItem) => item.title === existingItem.title)
        );
    };

    const handleItemDelete = (titleToRemove: string) => {
        if (!titleToRemove) return;

        const updatedItems = libraryItems.filter((item) => titleToRemove !== item.title);
        setLibraryItems(updatedItems);
        updateProperty(updatedItems);
    };

    const handleItemUpdate = (titleToUpdate: string, updatedItem: IDescription) => {
        if (!titleToUpdate || itemExists(updatedItem)) return;

        const updatedItems = libraryItems.map((item) => {
            if (titleToUpdate === item.title) {
                return updatedItem;
            }
            return item;
        });

        setLibraryItems(updatedItems);
        updateProperty(updatedItems);
    };

    const handleItemAdd = (newItem: IDescription) => {
        if (itemTitleExists(newItem)) {
            return uiStore.error(`${singular} already exits`);
        }
        libraryItems.push(newItem);
        setLibraryItems(libraryItems);
        updateProperty(libraryItems);
    };

    return (
        <>
            <Box display='flex' justifyContent='space-between' mb={2}>
                <Typography variant='h6'>{name}</Typography>
                <AddLibraryItem singular={singular} handleItemAdd={handleItemAdd} readOnly={readOnly} />
            </Box>
            <Typography gutterBottom={true}>{description}</Typography>
            <Grid>
                {libraryItems.length === 0 ? (
                    <Grid item>
                        <Box p={3} pl={1} mt={1} className={classes.libraryItem}>
                            <Typography variant='body1'>
                                No {name.toLowerCase()} provided.
                            </Typography>
                        </Box>
                    </Grid>
                ) : (
                    libraryItems.map((item) => {
                        return (
                            <Grid item key={`key-${name}-${item.title}`}>
                                <LibraryItemComponent
                                    singular={singular}
                                    item={item}
                                    handleItemRemove={handleItemDelete}
                                    handleItemUpdate={handleItemUpdate}
                                    readOnly={readOnly}
                                />
                            </Grid>
                        );
                    })
                )}
            </Grid>
        </>
    );
});

export const AddLibraryItem: React.FC<{
    singular: string;
    handleItemAdd: (item: IDescription) => void;
    readOnly?: boolean;
}> = observer(({ singular, handleItemAdd, readOnly }) => {
    const [open, setOpen] = useState(false);

    const [item, setItem] = useState<IDescription>({ title: '', text: '' });

    const { uiStore } = useContext(RootStoreContext);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setItem({ title: '', text: '' });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const id = event.target.id;
        const value = event.target.value;
        if (id) setItem({ ...item, [id]: value });
    };

    const handleAdd = () => {
        if (!item.title || !item.text) return uiStore.error('Fields can not be empty');

        handleItemAdd(item);
        setOpen(false);
        setItem({ title: '', text: '' });
    };

    return (
        <>
            <Button variant='outlined' color='primary' onClick={handleOpen} disabled={readOnly}>
                <AddIcon /> Add a {singular}{' '}
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth={true}>
                <DialogTitle>Add a new {singular}</DialogTitle>
                <DialogContent>
                    <Box mb={1}>
                        <Typography variant='subtitle1' gutterBottom>
                            <b>{singular} name</b>
                        </Typography>
                        <TextField
                            required
                            variant='outlined'
                            size='small'
                            id='title'
                            fullWidth
                            onChange={handleChange}
                        />
                    </Box>
                    <Box mb={1}>
                        <Typography variant='subtitle1' gutterBottom>
                            <b>{singular} Text</b>
                        </Typography>
                        <TextField
                            required
                            variant='outlined'
                            id='text'
                            multiline
                            rows={4}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={readOnly}>Cancel</Button>
                    <Button variant='contained' color='primary' onClick={handleAdd} disabled={readOnly}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export const EditLibraryItem: React.FC<{
    singular: string;
    item: IDescription;
    handleItemRemove: (titleToRemove: string) => void;
    handleItemUpdate: (titleToUpdate: string, newItem: IDescription) => void;
    readOnly?: boolean;
}> = observer(({ singular, item, handleItemRemove, handleItemUpdate, readOnly }) => {
    const [open, setOpen] = React.useState(false);

    const [editedItem, setEditedItem] = useState<IDescription>(item);

    const { uiStore } = useContext(RootStoreContext);

    const classes = useStyles();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        handleItemRemove(item.title);
        setOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = event.target;
        if (id) setEditedItem({ ...editedItem, [id]: value });
    };

    const handleUpdate = () => {
        if (!editedItem || !editedItem.title || !editedItem.text) {
            return uiStore.error('Fields cannot be empty');
        }
        handleItemUpdate(item.title, editedItem);
        setOpen(false);
    };

    return (
        <>
            <Box>
                <Button onClick={handleOpen} className={classes.libraryButton} disabled={readOnly}>
                    <EditOutlinedIcon className={classes.libraryButtonIcon} />
                </Button>
                <Button onClick={handleDelete} className={classes.libraryButton} disabled={readOnly}>
                    <DeleteForeverOutlinedIcon className={classes.libraryButtonIcon} />
                </Button>
            </Box>
            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth={true}>
                <DialogTitle>Add a new {singular}</DialogTitle>
                <DialogContent>
                    <Box mb={2}></Box>
                    <Box mb={1}>
                        <Typography variant='subtitle1' gutterBottom>
                            <b>{singular} name</b>
                        </Typography>
                        <TextField
                            required
                            variant='outlined'
                            size='small'
                            id='title'
                            fullWidth
                            value={editedItem ? editedItem.title : ''}
                            onChange={handleChange}
                        />
                    </Box>
                    <Box mb={1}>
                        <Typography variant='subtitle1' gutterBottom>
                            <b>{singular} Text</b>
                        </Typography>
                        <TextField
                            required
                            variant='outlined'
                            id='text'
                            multiline
                            rows={4}
                            fullWidth
                            value={editedItem ? editedItem.text : ''}
                            onChange={handleChange}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={readOnly}>Cancel</Button>
                    <Button variant='contained' color='primary' onClick={handleUpdate} disabled={readOnly}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export const LibraryItemComponent: React.FC<{
    singular: string;
    item: IDescription;
    readOnly?: boolean;
    handleItemRemove: (titleToRemove: string) => void;
    handleItemUpdate: (titleToUpdate: string, newItem: IDescription) => void;
}> = observer(({ singular, item, handleItemRemove, handleItemUpdate, readOnly }) => {
    const classes = useStyles();

    return (
        <Box p={1} mt={1} className={classes.libraryItem}>
            <Box display='flex' justifyContent='space-between'>
                <Typography variant='body1'>
                    <b>{item.title}</b>
                </Typography>
                {/* icons */}
                <EditLibraryItem
                    singular={singular}
                    item={item}
                    readOnly={readOnly}
                    handleItemRemove={handleItemRemove}
                    handleItemUpdate={handleItemUpdate}
                />
            </Box>

            <Typography>{item.text}</Typography>
        </Box>
    );
});
