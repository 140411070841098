import React from 'react';
import { format } from 'date-fns';
import { Avatar, Grid, Typography, Box, colors } from '@material-ui/core';
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';
import HouseOutlinedIcon from '@material-ui/icons/HouseOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { ICreditReport, ICreditReportAccountSummary } from 'realhaus-sdk';
import StringUtils from '../../utils/string';
import { streetAddress } from '../address/addressCard';
import { IsValidNumber } from '../../global/util';

type RecordProps = {
    icon: React.ReactNode;
    title: string;
    value?: number;
};

type CreditSummaryItemType = {
    label: string;
    value: string | number;
};

type CreditSummaryType = {
    title: string;
    summary: ICreditReportAccountSummary;
};

type RatingType = {
    color: string;
    title: string;
    range: string;
};

type LayoutProps = {
    title: string;
    children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ title, children }) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant='h6' gutterBottom={true}>
                    {title}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Grid container style={{ paddingLeft: 15, paddingRight: 15 }} spacing={3}>
                    {children}
                </Grid>
            </Grid>
        </Grid>
    );
};

export const Summary = ({ label, value }: CreditSummaryItemType) => (
    <Grid
        container
        style={{
            borderBottom: '2px solid #9e9e9e',
            padding: '2px 0',
            marginBottom: 2,
        }}
        xs={12}
    >
        <Grid item md={6}>
            <Typography variant='body1' style={{ textTransform: 'uppercase' }}>
                {label}
            </Typography>
        </Grid>
        <Grid item md={6} alignItems='center'>
            <Typography variant='body1' align='center'>
                {value || ''}
            </Typography>
        </Grid>
    </Grid>
);

export const CreditSummary = ({ title, summary }: CreditSummaryType) => {
    return (
        <Grid item xs={12}>
            <Typography
                gutterBottom
                style={{
                    textAlign: 'center',
                    borderBottom: '2px solid black',
                    backgroundColor: '#9e9e9e',
                    padding: '2px 0',
                    marginBottom: 6,
                }}
            >
                {title}
            </Typography>

            <Summary label='Total Balance' value={StringUtils.asCurrency(summary?.totalBalance)} />
            <Summary label='Max Limit' value={StringUtils.asCurrency(summary?.totalMaxLimit)} />
            <Summary
                label='% of Credit Used'
                value={StringUtils.asPercent(summary?.creditUsePercent ?? 0)}
            />
            <Summary label='Number of Cards' value={summary?.numberOfAccounts ?? 0} />
            <Summary
                label='Past Due Amount'
                value={StringUtils.asCurrency(summary?.totalPastDue)}
            />
            <Summary
                label='Late Payments 30/60/90'
                value={`${summary?.totalLatePayment30 ?? 0}/${summary?.totalLatePayment60 ?? 0}/${
                    summary?.totalLatePayment90 ?? 0
                }`}
            />
            <Summary
                label='MONTHLY PAYMENTS'
                value={StringUtils.asCurrency(summary?.monthlyPayments)}
            />
        </Grid>
    );
};

export const Rating = ({ color, title, range }: RatingType) => (
    <Box my={2} display='flex'>
        <Box width={30} height={20} bgcolor={color} mr={2}></Box>
        <Typography variant='body1'>
            {title}: <b>{range}</b>
        </Typography>
    </Box>
);

export const Record = ({ value, icon, title }: RecordProps) => (
    <Box textAlign='center'>
        <Box style={{ fontSize: 50, marginBottom: 2 }}>{icon}</Box>
        <Typography variant='subtitle1' gutterBottom>
            {title}
        </Typography>
        {!IsValidNumber(value) && (
            <Typography variant='body1' gutterBottom>
                <b>Not Available</b>
            </Typography>
        )}
        {value !== undefined && IsValidNumber(value) && (
            <Typography variant='h6' gutterBottom>
                <span style={{ color: `${value > 0 ? colors.red[800] : colors.green[800]}` }}>
                    {value}
                </span>
            </Typography>
        )}
    </Box>
);

export const CreditRating = ({ creditReport }: { creditReport: ICreditReport }) => {
    const creditColor = (score: number) => {
        if (!IsValidNumber(score)) {
            return { color: colors.grey[900], score: 'N/A' };
        }
        if (score >= 800) return { color: colors.green[900], score: 'Excellent' };
        else if (score <= 799 && score >= 740)
            return { color: colors.green[300], score: 'Very Good' };
        else if (score <= 739 && score >= 670) return { color: colors.yellow[800], score: 'Good' };
        else if (score <= 669 && score >= 580) return { color: colors.orange[800], score: 'Fair' };
        else if (score <= 579) return { color: colors.red[900], score: 'Poor' };
    };

    const countRecords = (
        group: 'public_records' | 'collections' | 'evictions' | 'criminal_history',
    ) => {
        switch (group) {
            case 'criminal_history':
                return 0;
            case 'evictions':
                return 0;
            case 'collections':
                const { collections } = creditReport.publicRecords;
                return collections?.length ?? 0;
            case 'public_records':
                const { bankruptcies, judgement, legalItems } = creditReport.publicRecords;
                return (
                    (bankruptcies?.length ?? 0) +
                    (judgement?.length ?? 0) +
                    (legalItems?.length ?? 0)
                );
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container alignItems='center'>
                    <Grid item md={8}>
                        <Typography variant='subtitle2'>
                            Credit Report as of{' '}
                            {!!creditReport ? format(creditReport?.dateCreated, 'PPP') : ''}
                        </Typography>
                    </Grid>
                    <Grid item md={4}>
                        <Typography>
                            Powered by
                            <Avatar
                                style={{
                                    width: 100,
                                    verticalAlign: 'middle',
                                    marginLeft: 4,
                                    display: 'inline-block',
                                }}
                                src='/img/credit_report/equifax.svg'
                                alt='equifax_logo'
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
                <Grid item xs={12}>
                    <Typography variant='h6' gutterBottom={true}>
                        Credit Rating
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {!!creditReport ? (
                        <Box
                            width={200}
                            height={30}
                            bgcolor={creditColor(creditReport?.creditScore)?.color}
                        >
                            <Typography variant='h6' style={{ color: 'white' }} align='center'>
                                {creditColor(creditReport?.creditScore)?.score}
                            </Typography>
                        </Box>
                    ) : null}

                    <Box>
                        <Rating title='Excellent' range='800 - 900' color={colors.green[900]} />
                        <Rating color={colors.green[300]} title='Very Good' range='740 - 799' />
                        <Rating color={colors.yellow[800]} title='Good' range='670 - 739' />
                        <Rating color={colors.orange[800]} title='Fair' range='580 - 669' />
                        <Rating color={colors.red[900]} title='Poor' range='300 - 579' />
                    </Box>
                </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
                <Grid container spacing={4}>
                    <Grid item md={6}>
                        <Record
                            title='Criminal History'
                            icon={<GavelOutlinedIcon fontSize='inherit' />}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Record title='Evictions' icon={<HouseOutlinedIcon fontSize='inherit' />} />
                    </Grid>
                    <Grid item md={6}>
                        <Record
                            title='Collections'
                            value={countRecords('collections')}
                            icon={<ErrorOutlineOutlinedIcon fontSize='inherit' />}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Record
                            title='Public Records'
                            value={countRecords('public_records')}
                            icon={<AccountBalanceOutlinedIcon fontSize='inherit' />}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export const CreditReportAddresses = ({ creditReport }: { creditReport: ICreditReport }) => {
    return (
        <Layout title='ADDRESSES'>
            {creditReport?.personalInformation.addresses &&
                creditReport.personalInformation.addresses.map((address, k) => (
                    <Grid item md={4} key={k}>
                        <Typography variant='body1' gutterBottom>
                            <b>Address{k + 1}</b>
                        </Typography>
                        <Typography>{streetAddress(address)}</Typography>
                        <Typography>
                            {address.city}, {address.province}, {address.postalCode}
                        </Typography>
                    </Grid>
                ))}
        </Layout>
    );
};

export const CreditReportEmployees = ({ creditReport }: { creditReport: ICreditReport }) => {
    return (
        <Layout title='EMPLOYERS'>
            {creditReport?.personalInformation.employment.length > 0 ? (
                creditReport.personalInformation.employment.map((employer, k) => (
                    <Grid item md={4} key={k}>
                        <Typography component='h3' gutterBottom>
                            <b>Employer</b>
                        </Typography>
                        <Typography>{employer.employer}</Typography>
                        <Typography>{employer.occupation}</Typography>
                    </Grid>
                ))
            ) : (
                <Grid item xs={12}>
                    <Typography variant='body1'>No Record Found</Typography>
                </Grid>
            )}
        </Layout>
    );
};

export const CreditReportCollection = ({ creditReport }: { creditReport: ICreditReport }) => {
    return (
        <Layout title='COLLECTION'>
            {creditReport.publicRecords.collections &&
            creditReport.publicRecords.collections.length > 0 ? (
                creditReport.publicRecords.collections.map((c, k) => (
                    <Grid item xs={12} key={k}>
                        <Typography component='h3' gutterBottom>
                            <b>Agency</b>
                        </Typography>
                        <Grid container>
                            <Grid item md={4}>
                                <Typography>{c.collector}</Typography>
                                <Typography>on behalf of {c.creditor}</Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography>{StringUtils.asCurrency(c.amount)}</Typography>
                                <Typography>AMOUNT</Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography>{StringUtils.asCurrency(c.balance)}</Typography>
                                <Typography>BALANCE</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ))
            ) : (
                <Grid item xs={12}>
                    <Typography variant='body1'>No Record Found</Typography>
                </Grid>
            )}
        </Layout>
    );
};

export const CreditReportPublicRecord = ({ creditReport }: { creditReport: ICreditReport }) => {
    return (
        <Layout title='PUBLIC RECORDS'>
            {!creditReport.publicRecords.judgement &&
            !creditReport.publicRecords.legalItems &&
            !creditReport.publicRecords.bankruptcies ? (
                <Grid item xs={12}>
                    <Typography variant='body1'>No Record Found</Typography>
                </Grid>
            ) : (
                <>
                    {creditReport.publicRecords.judgement &&
                        creditReport.publicRecords.judgement.map((j, k) => (
                            <Grid item xs={12} key={k}>
                                <Typography component='h3' gutterBottom>
                                    <b>Judgement</b>
                                </Typography>
                                <Grid container>
                                    <Grid item md={4}>
                                        <Typography>{j.palintiff}</Typography>
                                        <Typography>
                                            REPORTED ON {format(j.dateFiled, 'PPP')}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Typography>{format(j.dateSatisfied, 'PPP')}</Typography>
                                        <Typography>SETTLED ON</Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Typography>{StringUtils.asCurrency(j.amount)}</Typography>
                                        <Typography>AMOUNT</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}

                    {creditReport.publicRecords.legalItems &&
                        creditReport.publicRecords.legalItems.map((l, k) => (
                            <Grid item xs={12}>
                                <Typography component='h3' gutterBottom>
                                    <b>Legal Item</b>
                                </Typography>
                                <Grid container>
                                    <Grid item md={4}>
                                        <Typography>{l.palintiff}</Typography>
                                        <Typography>
                                            REPORTED ON {format(l.dateFiled, 'PPP')}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Typography>{format(l.dateSatisfied, 'PPP')}</Typography>
                                        <Typography>SETTLED ON</Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Typography>{StringUtils.asCurrency(l.amount)}</Typography>
                                        <Typography>AMOUNT</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}

                    {creditReport.publicRecords.bankruptcies &&
                        creditReport.publicRecords.bankruptcies.map((b, k) => (
                            <Grid item xs={12}>
                                <Typography component='h3' gutterBottom>
                                    <b>Bankruptcies</b>
                                </Typography>
                                <Grid container>
                                    <Grid item md={4}>
                                        <Typography>{b.court}</Typography>
                                        <Typography>
                                            REPORTED ON {format(b.dateFiled, 'PPP')}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Typography>{format(b.dateClosed, 'PPP')}</Typography>
                                        <Typography>CLOSED ON</Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Typography>{b.status}</Typography>
                                        <Typography>STATUS</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                </>
            )}
        </Layout>
    );
};

export const CreditAccountSummary = ({ creditReport }: { creditReport: ICreditReport }) => {
    return (
        <Layout title='CREDIT ACCOUNTS SUMMARY'>
            <Grid item xs={12} md={6}>
                <CreditSummary
                    title='REVOLVING CREDIT'
                    summary={creditReport.accounts.revolvingAccountSummary}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CreditSummary
                    title='MORTGAGE ACCOUNT'
                    summary={creditReport.accounts.mortgageAccountSummary}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CreditSummary
                    title='INSTALLMENT ACCOUNT'
                    summary={creditReport.accounts.installmentAccountSummary}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CreditSummary
                    title='OTHER ACCOUNT'
                    summary={creditReport.accounts.otherAccountsSummary}
                />
            </Grid>
        </Layout>
    );
};
