import { RatingsForLandlord, RatingsForTenant } from 'realhaus-sdk';
import { CalcAverage } from './numbers';

export const overallAverage = (ratings: (RatingsForTenant | RatingsForLandlord)[]) => {
  if (ratings.length > 0) {
    return (
      ratings.reduce((prevAvr, currRating) => {
        const avr = CalcAverage(Object.values(currRating));
        return avr + prevAvr;
      }, 0) / ratings.length
    );
  }
  return 0;
};