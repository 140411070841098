import {
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    Grid,
    IconButton,
    TextField,
    DialogTitle,
    Card,
    Box,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { DropzoneContainer } from '../../../../../createProperty/styles';
import { DatePicker } from '../../../../../datePicker/datepicker';
import { InputFieldMoney } from '../../../../../formComponents/inputfieldmoney';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import { RootStoreContext } from '../../../../../../global/storeContext';
import StringUtils from '../../../../../../utils/string';

interface IRequestMoney {
    service: string;
    setService: (value: string) => void;
    amount: number;
    setAmount: (value: number) => void;
    paymentDate: Date;
    setPaymentDate: (date: Date) => void;
    attachments: File[];
    setAttachments: (file: File[]) => void;
    setRequestNote: (value: string) => void;
    requestNote: string;
    files: (File & {
        preview: string;
    })[];
    setFiles: (
        value: (File & {
            preview: string;
        })[],
    ) => void;
}

export const RequestMoney: React.FC<IRequestMoney> = observer(
    ({
        service,
        setService,
        amount,
        setAmount,
        paymentDate,
        setPaymentDate,
        attachments,
        setAttachments,
        setRequestNote,
        requestNote,
        files,
        setFiles,
    }) => {
        const { uiStore } = React.useContext(RootStoreContext);
        const generatePreview = (files: File[]) => {
            return files.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            );
        };

        const onDrop = (acceptedFiles: File[]) => {
            const validateFiles = acceptedFiles.some((f) => {
                const file = f;
                const fileSize = Math.round(file.size / 1024);
                const maxFileSize = 3072;
                return fileSize > maxFileSize;
            });

            if (validateFiles) {
                // files too large
                const errorMsg = 'Invalid file. The file size must not be more than 3 mb';
                console.error(errorMsg);
                uiStore.error(errorMsg);
                return;
            }

            var newFiles = [...attachments, ...acceptedFiles];
            // Make sure to revoke the data uris to avoid memory leaks

            files?.forEach((file: any) => URL.revokeObjectURL(file.preview));
            setFiles([...generatePreview(newFiles)]);
            setAttachments([...newFiles]);
        };

        const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } =
            useDropzone({
                accept: {
                    imageAndPdf: ['image/*', '.pdf'],
                },
                onDrop,
            });

        const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setService((event.target as HTMLInputElement).value);
        };

        const handleRequestNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setRequestNote(event.target.value);
        };

        const handleAmountInputChange = (value: string | number | null) => {
            const updatedAmount = Number(value);
            if (isNaN(updatedAmount) || updatedAmount < 0) return;
            setAmount(updatedAmount);
            setAmount(updatedAmount);
        };

        const handlePaymentDate = (value: Date | null) => {
            setPaymentDate(value ?? new Date(Date.now()));
        };

        const removePhoto = (file: File) => {
            const photoIndex = files.findIndex((f) => f.name === file.name) as number;
            if (photoIndex >= 0) {
                var tempFile = [...attachments];
                tempFile.splice(photoIndex, 1);
                setFiles([...generatePreview(tempFile)]);
                setAttachments([...tempFile]);
            }
        };

        const imageThumbs = files?.map((file: any) => (
            <Grid item xs={12} md={6}>
                <Card key={file.name} style={{ padding: '5px' }}>
                    <Box display='flex' justifyContent='space-between'>
                        <Typography>{StringUtils.truncateString(file.name)}</Typography>

                        <IconButton
                            aria-label='delete'
                            color='secondary'
                            onClick={() => {
                                removePhoto(file);
                            }}
                        >
                            <DeleteIcon fontSize='small' />
                        </IconButton>
                    </Box>
                </Card>
            </Grid>
        ));
        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DialogTitle style={{ textAlign: 'center' }}>Request Money</DialogTitle>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid>
                            <Typography variant='subtitle1'>
                                <strong>What is this request for?</strong>
                            </Typography>
                        </Grid>
                        <Grid>
                            <FormControl component='fieldset'>
                                <RadioGroup
                                    aria-label='gender'
                                    name='payment'
                                    value={service}
                                    onChange={handleRadioChange}
                                >
                                    <FormControlLabel
                                        value='Damage, missing items and unexpected cleaning'
                                        control={<Radio color='primary' />}
                                        label='Damage, missing items and unexpected cleaning'
                                    />
                                    <FormControlLabel
                                        value='Extra Services'
                                        control={<Radio color='primary' />}
                                        label={
                                            <Typography>
                                                Extra services <br />{' '}
                                                <Typography
                                                    variant='body2'
                                                    style={{ marginTop: '2px', opacity: '0.5' }}
                                                >
                                                    Expenses that were not included in the bills
                                                    already created(e.g amenities not included in
                                                    the listing description,transportation
                                                    costs,e.t.c)
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid>
                            <Typography>
                                <strong>How much will you like to request?</strong>
                            </Typography>
                        </Grid>
                        <Grid>
                            <InputFieldMoney
                                id='money'
                                placeholder='Amount(CAD)'
                                fullWidth
                                value={amount}
                                onChange={handleAmountInputChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid>
                            <Typography>
                                <strong>when is the payment due?</strong>
                            </Typography>
                        </Grid>
                        <Grid>
                            <DatePicker
                                id='movein-picker'
                                selectedDate={paymentDate}
                                onChange={handlePaymentDate}
                                minDate={new Date(Date.now())}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={1}>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Attachments</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>
                                <small>
                                    (Optional)Add any relevant photos,including any receipt or other
                                    documentation.Make sure photos and text are clear,including the
                                    price and name of any items.Accepted formats:PNG,JPG or PDF.
                                </small>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <DropzoneContainer
                                {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
                            >
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p>Drop the files here ...</p>
                                ) : (
                                    <>
                                        <Button variant='outlined' startIcon={<PublishIcon />}>
                                            Upload files
                                        </Button>
                                        <Typography>or drag them in</Typography>
                                    </>
                                )}
                            </DropzoneContainer>
                        </Grid>
                        <Grid item xs={12} container spacing={2}>
                            {imageThumbs}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid>
                            <Typography>
                                <strong>Notes</strong>
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography variant='subtitle1'>
                                <small> Explain why you are requesting the money </small>
                            </Typography>
                        </Grid>
                        <Grid>
                            <TextField
                                value={requestNote}
                                variant='outlined'
                                multiline
                                fullWidth
                                minRows={5}
                                onChange={handleRequestNoteChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    },
);
