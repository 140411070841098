import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, IconButton, SwipeableDrawer } from '@material-ui/core';
import { RootStoreContext } from '../../global/storeContext';
import { useStyles } from './styles';
import { TabComponent, TabSection } from '../uiComponents/tabs';
import { RentApplicationScreeningHeaderComponent } from './header';
import { SummaryComponent } from './summary';
import { FinancialsComponent } from './financials';
import { RentHistoryComponent } from './rentHistory';
import { CreditScreeningComponent } from './creditScreening';
import { paths } from '../../routes';
import { convertIncomeType } from '../../utils/occupation';
import {
    IRatings,
    IdRentApplication,
    IdPropertyListing,
    ICreditReportSummary,
    IncomeType,
    RentApplicationStatus,
    ITenantInfo,
    ILeaseRatingsReview,
    RatingsForLandlord,
    RatingsForTenant,
} from 'realhaus-sdk';
import { TenantReviews } from './tenantReviews';
import { useIsMobible } from '../uiComponents/UIComponents';
import CloseIcon from '@material-ui/icons/Close';
import { PagePlaceholder, TwoColumnPlaceholder } from '../uiComponents/placeholder';

interface RentApplicationScreeningProps {
    rentApplicationId?: string;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
}

type RatingForLandlordOrTenant = RatingsForLandlord | RatingsForTenant;

export const RentApplicationScreeningComponent: React.FC<RentApplicationScreeningProps> = observer(
    ({ rentApplicationId, isOpen, onOpen, onClose }) => {
        const classes = useStyles();
        const { listingStore, uiStore, leaseStore, creditReportStore, ratingStore } =
            useContext(RootStoreContext);
        const [rentApplication, setRentApplication] = useState<IdRentApplication>();
        const [tenant, setTenant] = useState<ITenantInfo>();
        const [tenantId, setTenantId] = useState<string | undefined>();
        const [listing, setListing] = useState<IdPropertyListing>();
        const [tenantIncome, setTenantIncome] = useState<number | undefined>();
        const [screeningReport, setScreeningReport] = useState<ICreditReportSummary | undefined>();
        const [ratingsBreakdown, setRatingsBreakdown] = useState<IRatings>();
        const [tenantReviews, setTenantReviews] = React.useState<
            ({ id: string; leaseAgreementId: string } & ILeaseRatingsReview)[]
        >([]);
        const [ratings, setRatings] = React.useState<RatingForLandlordOrTenant[]>([]);

        const isMobile = useIsMobible();

        useEffect(() => {
            const fetchApplicationDetails = async () => {
                if (!rentApplicationId) {
                    setRentApplication(undefined);
                    return;
                }
                try {
                    uiStore.showLoading();

                    const app = await listingStore.getRentApplication(rentApplicationId);
                    setRentApplication(app);

                    if (!!app) {
                        const tenantId = app.tenantId;
                        setTenantId(tenantId);

                        // get ratings
                        await ratingStore.getTenantReviewsbyId(tenantId).then((data) => {
                            if (!!data) {
                                setTenantReviews(data);

                                // ratingsArray
                                const ratingsArray: (RatingsForLandlord | RatingsForTenant)[] = [];
                                data.forEach((rat) => {
                                    if (!!rat.middleOfTerm) {
                                        ratingsArray.push(rat.middleOfTerm.ratings);
                                    }
                                    if (!!rat.endOfTerm) {
                                        ratingsArray.push(rat.endOfTerm.ratings);
                                    }
                                });
                                setRatings([...ratingsArray]);
                            }
                        });

                        const tenantProfile = await listingStore.getTenantInfoForApplication(
                            tenantId,
                        );
                        setTenant(tenantProfile);

                        if (tenantProfile?.occupation.income) {
                            setTenantIncome(
                                convertIncomeType(
                                    tenantProfile.occupation.income,
                                    tenantProfile?.occupation.incomeType,
                                    IncomeType.MONTHLY,
                                ),
                            );
                        }

                        const listing = await listingStore.getListing(app.listingId);
                        setListing(listing);

                        const creditReportSummary = await creditReportStore.getCreditReportSummary(
                            tenantId,
                        );
                        if (creditReportSummary) {
                            setScreeningReport(creditReportSummary);
                        }
                        const tenantRatings = await ratingStore.calculateTenantRating(tenantId);
                        if (tenantRatings) setRatingsBreakdown(tenantRatings);
                    }

                    uiStore.hideLoading();
                } catch (err) {
                    uiStore.hideLoading();
                }
            };

            fetchApplicationDetails();
        }, [rentApplicationId]);

        const isInitializing = !tenant || !rentApplication || !listing;

        const handleOpen = () => onOpen();
        const handleClose = () => onClose();

        const rejectApplication = async (rejectReason: string) => {
            if (!rentApplicationId || !rentApplication) {
                uiStore.error('We are unable to reject the rent application at this time');
                return;
            }

            try {
                uiStore.showLoading();
                const appId = await listingStore.rejectApplication(
                    rentApplicationId ?? '',
                    rejectReason,
                );
                uiStore.hideLoading();
                if (!!appId) {
                    setRentApplication({
                        ...rentApplication,
                        status: RentApplicationStatus.REJECTED,
                    });
                    uiStore.success('The application has been rejected.');
                    // close application window
                } else {
                    uiStore.error('We are unable to reject the rent application at this time');
                }
            } catch (err) {
                uiStore.hideLoading();
            }
        };

        const acceptApplication = async () => {
            if (!rentApplicationId || !rentApplication) {
                uiStore.error('We are unable to accept the rent application at this time');
                return;
            }

            try {
                uiStore.showLoading();
                const appId = await listingStore.acceptApplication(rentApplicationId ?? '');
                if (!appId) {
                    uiStore.error('We are unable to accept the rent application at this time');
                    uiStore.hideLoading();
                    return;
                }

                const leaseId = await leaseStore.createLeaseAgreementForRentApplication(appId);
                uiStore.hideLoading();
                if (!!leaseId) {
                    uiStore.success('The application has been accepted.');
                    uiStore.goTo(`${paths.landlord.tenants}?leaseId=${leaseId}`);
                    // close application window
                } else {
                    uiStore.error('We are unable to accept the rent application at this time');
                }
            } catch (err) {
                uiStore.hideLoading();
            }
        };

        const handleSubmit = () => {
            // do nothing for now
        };
        const sections: TabSection[] = [
            {
                key: 'Summary',
                title: 'Summary',
                component: (
                    <SummaryComponent
                        moveinDate={rentApplication?.application.moveinDate ?? 0}
                        occupation={tenant?.occupation}
                        pets={rentApplication?.application.hasPets}
                        vehicle={rentApplication?.application.hasVehicle}
                        smoking={rentApplication?.application.smokes}
                        tenantIncome={tenantIncome}
                        tenantId={tenantId}
                        listingId={rentApplication?.listingId}
                        screeningReport={screeningReport}
                        rating={ratingsBreakdown?.rating}
                        grades={ratingsBreakdown?.averageGrades}
                    />
                ),
            },
            {
                key: 'Financials',
                title: 'Financials',
                component: <FinancialsComponent occupation={tenant?.occupation} />,
            },
            {
                key: 'Rent-History',
                title: 'Rent History',
                component: (
                    <RentHistoryComponent
                        tenant={tenant}
                        tenantId={tenantId}
                        grades={ratingsBreakdown?.averageGrades}
                    />
                ),
            },

            {
                key: 'Tenant-Screening',
                title: 'Credit Report',
                component: <CreditScreeningComponent tenantId={tenantId ?? ''} />,
            },
            {
                key: 'Reviews',
                title: 'Reviews',
                component: <TenantReviews tenantReviews={tenantReviews} ratings={ratings} />,
            },
        ];

        return (
            <>
                <SwipeableDrawer
                    className={classes.applicationDrawer}
                    anchor='right'
                    open={isOpen}
                    onOpen={handleOpen}
                    onClose={handleClose}
                >
                    {isMobile && (
                        <>
                            <Box pl={2} pr={2} mt={5} mb={1}>
                                <Grid container justifyContent='flex-end'>
                                    <Grid item>
                                        <IconButton aria-label='close' onClick={handleClose}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}

                    <Box
                        pl={isMobile ? 2 : 10}
                        pr={isMobile ? 2 : 10}
                        mt={isMobile ? 2 : 10}
                        mb={5}
                    >
                        {isInitializing ? (
                            <TwoColumnPlaceholder />
                        ) : (
                            <RentApplicationScreeningHeaderComponent
                                name={`${tenant?.firstname} ${tenant?.lastname}`}
                                address={listing?.address}
                                dateSubmitted={rentApplication?.dateSubmitted ?? 0}
                                onRejectApplication={rejectApplication}
                                onAcceptApplication={acceptApplication}
                                status={rentApplication?.status ?? RentApplicationStatus.WITHDRAWN}
                                ratings={ratings}
                            />
                        )}
                    </Box>

                    <Box mb={10} pl={isMobile ? 2 : 10} pr={isMobile ? 2 : 10}>
                        {isInitializing ? (
                            <PagePlaceholder />
                        ) : (
                            <TabComponent
                                sections={sections}
                                onSubmit={handleSubmit}
                                scrollButtons={true}
                                hideStepperButtons={true}
                            />
                        )}
                    </Box>
                </SwipeableDrawer>
            </>
        );
    },
);
