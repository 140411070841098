import { Box, DialogTitle, Grid, Typography } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { LandlordGrades, RatingsForLandlord, RatingsForTenant, TenantGrades } from 'realhaus-sdk';

export const ReviewExperience: React.FC<{
    isLandlord: boolean;
    expVal: RatingsForTenant | RatingsForLandlord;
    setExpVal: React.Dispatch<React.SetStateAction<RatingsForTenant | RatingsForLandlord>>;
}> = observer(({ isLandlord, expVal, setExpVal }) => {
    const experienceArr = isLandlord
        ? [
              TenantGrades.CLEANLINESS,
              TenantGrades.COMMUNICATION,
              TenantGrades.HOUSE_RULES,
              TenantGrades.RESPECTFULNESS,
          ]
        : [
              LandlordGrades.ACCURACY,
              LandlordGrades.COMMUNICATION,
              LandlordGrades.DIFFICULTY,
              LandlordGrades.RESPECTFULNESS,
          ];

    const isValidKeyForTenantOrLandlord = (val: LandlordGrades | TenantGrades): number => {
        if (
            val === 'Cleanliness' ||
            val === 'Communication' ||
            val === 'House_Rules' ||
            val === 'Respectfulness' ||
            val === 'Accuracy' ||
            val === 'Difficulty'
        ) {
            const key: keyof RatingsForLandlord | keyof RatingsForTenant = val as keyof (
                | RatingsForLandlord
                | RatingsForTenant
            );
            return expVal[key];
        }
        return 0;
    };

    const handleRatingsChange = (
        prop: keyof RatingsForLandlord | keyof RatingsForTenant,
        val: number | null,
    ) => {
        setExpVal({ ...expVal, [prop]: val });
    };
    return (
        <>
            <Grid container spacing={8}>
                <Grid item xs={12}>
                    <Typography align='center'>
                        <DialogTitle>Rate Experience</DialogTitle>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    {experienceArr.map((exp) => {
                        return (
                            <Box display='flex' justifyContent='center' mb={1} key={exp}>
                                <Box display='flex' justifyContent='space-between' width={'50%'}>
                                    <Box>
                                        <Typography variant='subtitle1'>
                                            {exp.replace('_', ' ')}:
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Rating
                                            name={exp}
                                            value={isValidKeyForTenantOrLandlord(exp)}
                                            onChange={(
                                                event: React.ChangeEvent<{}>,
                                                value: number | null,
                                            ) => handleRatingsChange(exp, value)}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        );
                    })}
                </Grid>
            </Grid>
        </>
    );
});
