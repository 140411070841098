import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../global/storeContext';
import { Typography, Grid } from '@material-ui/core';
import { CardListing } from '../uiComponents/card';

export const BookmarkedListingsComponent: React.FC = observer(() => {
    const { listingStore } = useContext(RootStoreContext);
    const [listingIds, setListingIds] = useState<string[]>([]);

    const bookmarkHandler = (listingId: string) => (bookmarkStatus: boolean) => {
        if (bookmarkStatus === false) {
            setListingIds(listingIds.filter((l) => l !== listingId));
        }
    };

    useEffect(() => {
        listingStore.getBookmarkedListings().then((listings) => {
            setListingIds(listings ?? []);
        });
    }, []);

    return (
        <>
            {listingIds.length === 0 ? (
                <Typography variant='subtitle1'>Your bookmark list is empty</Typography>
            ) : (
                listingIds.map((listing) => (
                    <Grid item xs={12} sm={4}>
                        <CardListing
                            listingId={listing}
                            key={`${listing}`}
                            isBookmarked={bookmarkHandler(listing)}
                        />
                    </Grid>
                ))
            )}
        </>
    );
});
