import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';

import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from '@material-ui/core';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ShowingTimeslot } from 'realhaus-sdk';
import { RootStoreContext } from '../../global/storeContext';
import { getRootStyles } from '../styles';
import { ShowingTimeslotCard } from '../showings/showingTimeslotCards';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DeleteIcon from '@material-ui/icons/Delete';

interface PropertyTermsProps {
    listingId: string;
}

export const PropertyShowings: React.FC<PropertyTermsProps> = observer(({ listingId }) => {
    const { listingStore, uiStore } = useContext(RootStoreContext);
    const classes = getRootStyles();
    const [timeslots, setTimeslots] = React.useState<({ id: string } & ShowingTimeslot)[]>([]);
    const [availableTimeslots, setAvailableTimeslots] = React.useState<
        ({ id: string } & ShowingTimeslot)[]
    >([]);
    const [reservedTimeslots, setReservedTimeslots] = React.useState<
        ({ id: string } & ShowingTimeslot)[]
    >([]);
    const [timeslotModalOpen, setTimeslotModalOpen] = React.useState<boolean>(false);

    useEffect(() => {
        uiStore.showLoading();
        if (!!listingId) {
            // get listing
            listingStore
                .getPropertyShowingTimeslots(listingId)
                .then((slots) => {
                    setTimeslots(sortTimeslots(slots));
                })
                .finally(() => {
                    uiStore.hideLoading();
                });
        } else {
            uiStore.hideLoading();
        }
    }, [listingId, listingStore]);

    useEffect(() => {
        setAvailableTimeslots([...timeslots.filter((slot) => !slot.reservedBy)]);
        setReservedTimeslots([...timeslots.filter((slot) => !!slot.reservedBy)]);
    }, [timeslots]);

    const sortTimeslots = (slots: ({ id: string } & ShowingTimeslot)[] | undefined) =>
        slots?.sort((a, b) => a.timestamp - b.timestamp) ?? [];

    const handleTimeslotModalClose = () => {
        setTimeslotModalOpen(false);
    };

    const handleTimeslotModalOpen = () => {
        setTimeslotModalOpen(true);
    };

    const handleDeleteTimeslot = (timeslotId: string) => () => {
        if (!!timeslotId) {
            uiStore.showLoading();
            listingStore
                .deletePropertyShowingTimeslot(listingId, timeslotId)
                .then((tsId) => {
                    if (!!tsId) {
                        setTimeslots([...timeslots.filter((ts) => ts.id !== tsId)]);
                    }
                })
                .finally(() => {
                    uiStore.hideLoading();
                });
        }
    };

    const handleCancelTimeslot = (timeslotId: string) => () => {
        if (!!timeslotId) {
            uiStore.showLoading();
            listingStore
                .cancelPropertyShowingTimeslot(listingId, timeslotId)
                .then((ts) => {
                    if (!!ts) {
                        setTimeslots(
                            sortTimeslots([...timeslots.filter((t) => t.id !== timeslotId), ts]),
                        );
                    }
                })
                .finally(() => {
                    uiStore.hideLoading();
                });
        }
    };

    const TimeslotModal = () => {
        return (
            <>
                <Dialog
                    open={timeslotModalOpen}
                    onClose={handleTimeslotModalClose}
                    maxWidth='sm'
                    fullWidth={true}
                    aria-labelledby='simple-modal-title'
                    aria-describedby='simple-modal-description'
                >
                    <DialogTitle>Create a showing timeslot</DialogTitle>
                    <DialogContent className={classes.newTimeslotModalContent} dividers>
                        <Grid container spacing={3} className={classes.verticalCenter}>
                            <Grid item xs={12}>
                                <ShowingsCounterComponent
                                    handleTimeslotModalClose={handleTimeslotModalClose}
                                    listingId={listingId}
                                    setTimeslots={setTimeslots}
                                    sortTimeslots={sortTimeslots}
                                    timeslots={timeslots}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
        );
    };

    return (
        <>
            <Grid container justifyContent='space-between' className={classes.titleBar}>
                <Grid item>
                    <Typography variant='h5' gutterBottom>
                        Manage Showings
                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant='contained' color='primary' onClick={handleTimeslotModalOpen}>
                        Add Timeslot
                    </Button>
                </Grid>
            </Grid>

            <br />
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <Typography variant='subtitle1' gutterBottom>
                        Reserved Timeslots
                    </Typography>
                    <Grid>
                        <Grid container spacing={1}>
                            {reservedTimeslots.length === 0 ? (
                                <div>There are no scheduled timeslots!</div>
                            ) : null}
                            {reservedTimeslots.map((timeslot) => (
                                <Grid item xs={3} key={timeslot.id}>
                                    <ShowingTimeslotCard
                                        timeslot={timeslot}
                                        handleCancelTimeslot={handleCancelTimeslot(timeslot.id)}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <br />
                    <Divider />
                    <br />
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='subtitle1' gutterBottom>
                        Available Timeslots
                    </Typography>
                    <Grid>
                        <Grid container spacing={1}>
                            {availableTimeslots.length === 0 ? (
                                <div>You have not created any timeslots for showings yet.</div>
                            ) : null}
                            {availableTimeslots.map((timeslot) => (
                                <Grid item xs={3} key={timeslot.id}>
                                    <ShowingTimeslotCard
                                        timeslot={timeslot}
                                        handleDeleteTimeslot={handleDeleteTimeslot(timeslot.id)}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <TimeslotModal />
            </Grid>
        </>
    );
});

export interface ShowingCounterProp {
    id: number;
    selectedTimeslotDatetime: Date | null;
    timeslot: { duration: number; timestamp: number };
}

const ShowingsCounterComponent: React.FC<{
    handleTimeslotModalClose: () => void;
    listingId: string;
    setTimeslots: React.Dispatch<
        React.SetStateAction<
            ({
                id: string;
            } & ShowingTimeslot)[]
        >
    >;
    sortTimeslots: (
        slots:
            | ({
                  id: string;
              } & ShowingTimeslot)[]
            | undefined,
    ) => ({
        id: string;
    } & ShowingTimeslot)[];
    timeslots: ({
        id: string;
    } & ShowingTimeslot)[];
}> = observer(({ handleTimeslotModalClose, listingId, setTimeslots, sortTimeslots, timeslots }) => {
    const { uiStore, listingStore } = useContext(RootStoreContext);
    const [counter, setCounter] = React.useState<number>(0);
    const [timeslotField, setTimeslotField] = React.useState<ShowingCounterProp[]>([
        {
            id: counter,
            selectedTimeslotDatetime: new Date(),
            timeslot: { duration: 30, timestamp: Date.now() },
        },
    ]);

    const handleCreateNewTimeslot = () => {
        uiStore.showLoading();
        listingStore
            .addShowingTimeslot(listingId, timeslotField)
            .then((added) => {
                if (!!added && added.length > 0) {
                    setTimeslots(sortTimeslots([...timeslots, ...added]));
                    uiStore.success('Successfully created timeslot');
                    handleTimeslotModalClose();
                } else {
                    uiStore.error('Failed to create timeslot');
                }
            })
            .finally(() => {
                uiStore.hideLoading();
            });
    };

    const handleCounterIncreament = () => {
        setCounter(counter + 1);
        const lastTimeSlot = timeslotField[timeslotField.length - 1];
        let newTime;
        if (lastTimeSlot.selectedTimeslotDatetime) {
            newTime = new Date(lastTimeSlot.selectedTimeslotDatetime);
            newTime.setMinutes(newTime.getMinutes() + Number(lastTimeSlot.timeslot.duration));
        }

        setTimeslotField([
            ...timeslotField,
            {
                id: counter + 1,
                selectedTimeslotDatetime: newTime ?? lastTimeSlot.selectedTimeslotDatetime,
                timeslot: timeslotField[timeslotField.length - 1].timeslot,
            },
        ] as ShowingCounterProp[]);
    };

    const handleTimeslotFieldChange =
        (id: number, prop1: keyof ShowingCounterProp) =>
        (event: React.ChangeEvent<{ value: unknown }>) => {
            setTimeslotField((prevTimeslot) =>
                prevTimeslot.map((t) =>
                    t.id === id
                        ? { ...t, [prop1]: { ...t.timeslot, duration: event.target.value } }
                        : t,
                ),
            );
        };

    const handleSelectedTimeslotDateTime = (id: number) => (date: MaterialUiPickersDate) => {
        setTimeslotField((prevTimeslot) =>
            prevTimeslot.map((t) => (t.id === id ? { ...t, selectedTimeslotDatetime: date } : t)),
        );
    };

    const handleTimeslotDelete = (id: number) => {
        setTimeslotField((prevTimeslot) => prevTimeslot.filter((t) => t.id !== id));
    };
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container justifyContent='center' alignItems='center' item xs={12}>
                        <Grid item xs={12} md={7}>
                            <Typography variant='subtitle1' gutterBottom>
                                Date and Time
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant='subtitle1' gutterBottom>
                                Duration
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TimeslotFieldComponent
                                timeslotField={timeslotField}
                                handleTimeslotchangeField={handleTimeslotFieldChange}
                                handleSelectedTimeslotDateTime={handleSelectedTimeslotDateTime}
                                handleTimeslotDelete={handleTimeslotDelete}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={12} justifyContent='flex-start'>
                    <Grid item>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleCounterIncreament}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid container item xs={12} justifyContent='flex-end'>
                    <Button onClick={handleTimeslotModalClose} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={handleCreateNewTimeslot} color='primary' variant='contained'>
                        Save
                    </Button>
                </Grid>
            </Grid>
        </>
    );
});

const TimeslotFieldComponent: React.FC<{
    timeslotField: ShowingCounterProp[];
    handleTimeslotchangeField: (
        id: number,
        prop1: keyof ShowingCounterProp,
    ) => (
        event: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleSelectedTimeslotDateTime: (id: number) => (date: MaterialUiPickersDate) => void;
    handleTimeslotDelete: (id: number) => void;
}> = observer(
    ({
        timeslotField,
        handleSelectedTimeslotDateTime,
        handleTimeslotchangeField,
        handleTimeslotDelete,
    }) => {
        const classes = getRootStyles();
        return (
            <>
                <Grid container spacing={0}>
                    {timeslotField.map((timeslot) => (
                        <>
                            <Grid
                                container
                                item
                                xs={12}
                                spacing={2}
                                className={classes.verticalCenter}
                            >
                                <Grid item xs={12} md={7}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container justifyContent='space-around'>
                                            <KeyboardDateTimePicker
                                                disablePast
                                                showTodayButton
                                                inputVariant='outlined'
                                                margin='normal'
                                                format='PPp'
                                                id='availability-picker'
                                                value={timeslot.selectedTimeslotDatetime}
                                                onChange={handleSelectedTimeslotDateTime(
                                                    timeslot.id,
                                                )}
                                                fullWidth
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <TextField
                                        id='duration'
                                        type='number'
                                        margin='normal'
                                        variant='outlined'
                                        fullWidth
                                        value={timeslot.timeslot.duration}
                                        onChange={handleTimeslotchangeField(
                                            timeslot.id,
                                            'timeslot',
                                        )}
                                        inputProps={{ step: 5 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    Minutes
                                                </InputAdornment>
                                            ),
                                        }}
                                        required
                                    />
                                </Grid>
                                <Grid container item xs={1} alignItems='center'>
                                    <Grid item>
                                        <IconButton
                                            aria-label='delete'
                                            style={
                                                timeslot.id === 0
                                                    ? { display: 'none' }
                                                    : { display: 'block' }
                                            }
                                            onClick={() => handleTimeslotDelete(timeslot.id)}
                                        >
                                            <DeleteIcon fontSize='large' />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </Grid>
                        </>
                    ))}
                </Grid>
            </>
        );
    },
);
