import Geohash from 'ngeohash';

import { LeaseStore } from '../stores/leaseStore';
import { AddressViewerAddress } from '../components/address/addressCard';
import { StringIsNullOrWhitespace } from '../global/util';
import { IAddress, GeocoderAddressComponent, GeocoderGeometry, IResidenceInfo, AutocompletePrediction } from 'realhaus-sdk';
import { toQueryString } from './query';


export const AddressMatch = (a: IAddress, b: IAddress) => {
  var isMatch = a.country === b.country && a.province === b.province && a.city === b.city && a.unit === b.unit;
  // check geoloc
  if (!!a.geoloc && !!b.geoloc) {
    isMatch = a.geoloc.lat === b.geoloc.lat && a.geoloc.lng === b.geoloc.lng;
  }
  // check street address
  return isMatch;
}

export const ToIAddress = (addrComponent: GeocoderAddressComponent[], geometry?: GeocoderGeometry) => {
  const address = {} as IAddress;

  for (const component of addrComponent) {

    const componentType = component.types[0];

    switch (componentType) {
      case "street_number": {
        address.streetAddress = !address.streetAddress ? `${component.long_name}` : `${component.long_name} ${address.streetAddress}`;
        break;
      }

      case "route": {
        address.streetAddress = !address.streetAddress ? component.short_name : `${address.streetAddress} ${component.short_name}`;
        break;
      }

      case "postal_code": {
        address.postalCode = `${component.long_name}${address.postalCode ?? ''}`;
        break;
      }

      case "postal_code_suffix": {
        address.postalCode = `${address.postalCode}-${component.long_name}`;
        break;
      }

      case "locality":
        address.city = component.long_name;
        break;

      case "administrative_area_level_1": {
        address.province = component.short_name;
        break;
      }

      case "country":
        address.country = component.long_name;
        break;
    }
  }
  if (geometry) {
    const location = geometry.location;
    const lat = location.lat();
    const lng = location.lng();
    const geohash = Geohash.encode(lat, lng);
    address.geoloc = { lat, lng, geohash };
  }
  return address;
}

export const fetchPreviousAddresses = async (userId: string, leaseStore: LeaseStore, nonRentals: IResidenceInfo[]) => {
  var previousAddresses: AddressViewerAddress[] = [...(nonRentals ?? [])];
  const leases = await leaseStore.getLeaseAgreementByTenantId(userId);
  previousAddresses = [
    ...previousAddresses,
    ...(leases?.map(
      (lease) =>
      ({
        address: lease.listingInfo.address,
        moveinDate: lease.moveinDate,
        moveoutDate: lease.moveoutDate,
        leaseId: lease.id,
      } as AddressViewerAddress),
    ) ?? []),
  ];
  previousAddresses = previousAddresses.sort(
    (a, b) => (b?.moveoutDate ?? 0) - (a?.moveoutDate ?? 0),
  );
  return previousAddresses;
};

export const isValidAddress = (address: IAddress) => {
  const { streetAddress, city, province, postalCode, country } = address;
  return !!address &&
    !StringIsNullOrWhitespace(streetAddress) &&
    !StringIsNullOrWhitespace(city) &&
    !StringIsNullOrWhitespace(province) &&
    !StringIsNullOrWhitespace(postalCode) &&
    !StringIsNullOrWhitespace(country);
}

export const streetAddress = (address: IAddress) => {
  return !address
    ? null
    : address.unit
      ? `${address.unit} - ${address.streetAddress}`
      : address.streetAddress
}

export const formatAddress = (address: IAddress) => {
  return `${streetAddress(address)}, ${address.city}, ${address.province} ${address.postalCode}, ${address.country}`;
}

export const locationQuery = (location: AutocompletePrediction | null) => {
  if (!location || (location?.terms.length ?? 0) <= 1) return '';

  return toQueryString(location.terms, 'value');
};