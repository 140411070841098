import { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';
import { Box, Grid, List, ListItem, Typography } from '@material-ui/core';
import { RootStoreContext } from '../../global/storeContext';
import {
    ILeaseAgreement,
    ILeaseAgreementTemplate,
    ILeaseAgreementTemplateEntry,
    ILeaseAgreementTemplateSection,
    ILeaseAgreementTemplateHeader,
    IUserBio,
} from 'realhaus-sdk';
import { streetAddress } from '../../utils/address';
import { format } from 'date-fns';
import React from 'react';

export const LeaseAgreementReadView = observer(
    React.forwardRef(
        (
            { leaseAgreement }: { leaseAgreement: ILeaseAgreement },
            ref: React.Ref<HTMLDivElement>,
        ) => {
            const { leaseAgreementStore, uiStore, tenantStore } = useContext(RootStoreContext);
            const [tenants, setTenants] = useState<Map<string, IUserBio>>();
            const [owner, setOwner] = useState<IUserBio>();
            const listingAddress = leaseAgreement.listingInfo.address;
            const ownerSignatureDate = leaseAgreement.signatureRequestDate;
            const getTenantSignatures = () => {
                const signatures = [];
                for (const tenantId of leaseAgreement.tenantIds) {
                    const signature = leaseAgreement.signatures
                        .reverse()
                        .find(({ acknowledgedBy }) => tenantId === acknowledgedBy);
                    if (signature) {
                        signatures.push(signature);
                    }
                }
                return signatures;
            };

            const getTenantName = (tenantId: string) => {
                if (!tenants) return '';
                const tenant = tenants.get(tenantId);
                return `${tenant?.firstname} ${tenant?.lastname}`;
            };

            const tenantSignatures = getTenantSignatures();

            const [leaseTemplate, setLeaseTemplate] = useState<ILeaseAgreementTemplate>();
            // Show Lease agreement in read view with option to save and send for signing
            useEffect(() => {
                const fetchLeaseAgreementTemplate = async () => {
                    try {
                        uiStore.showLoading();
                        const template = await leaseAgreementStore.generateLeaseAgreement(
                            leaseAgreement,
                        );
                        const ownerProfile = await tenantStore.getUserBio(leaseAgreement.ownerId);
                        const tenantProfileMap = new Map<string, IUserBio>();
                        for (const tenantId of leaseAgreement.tenantIds) {
                            const profile = await tenantStore.getUserBio(tenantId);
                            tenantProfileMap.set(tenantId, profile);
                        }
                        setLeaseTemplate(template);
                        setOwner(ownerProfile);
                        setTenants(tenantProfileMap);
                    } catch (err) {
                        const errMsg = 'Unable to get the lease agreement for review';
                        console.error(errMsg);
                        console.error(err);

                        uiStore.error(errMsg);
                    } finally {
                        uiStore.hideLoading();
                    }
                };

                fetchLeaseAgreementTemplate();
            }, [leaseAgreement, leaseAgreementStore]);

            const SectionComponent: React.FC<{ section: ILeaseAgreementTemplateSection }> = ({
                section,
            }) => {
                const sectionKey = uuidv4();
                return (
                    <Box mt={1} mb={1}>
                        <Typography variant='h5'>{section.title}</Typography>
                        <List>
                            {section.entries
                                ?.filter(
                                    (e) =>
                                        !e.condition ||
                                        leaseAgreementStore.conditionSatisfied(e.condition),
                                )
                                .map((entry, index) => (
                                    <ListItem key={`${sectionKey}-entry-${index}`}>
                                        <EntryComponent entry={entry} />
                                    </ListItem>
                                ))}
                        </List>
                    </Box>
                );
            };

            const HeaderComponent: React.FC<{ header: ILeaseAgreementTemplateHeader }> = ({
                header,
            }) => {
                const sectionKey = uuidv4();
                return (
                    <Box mt={4} mb={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant='h4' style={{ textAlign: 'left' }}>
                                    {header.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant='h3' style={{ textAlign: 'right' }}>
                                    {header.label}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box mb={3}></Box>
                        <List>
                            {header.entries
                                ?.filter(
                                    (e) =>
                                        !e.condition ||
                                        leaseAgreementStore.conditionSatisfied(e.condition),
                                )
                                .map((entry, index) => (
                                    <ListItem key={`${sectionKey}-entry-${index}`}>
                                        <EntryComponent entry={entry} />
                                    </ListItem>
                                ))}
                        </List>
                    </Box>
                );
            };

            const EntryComponent: React.FC<{ entry: ILeaseAgreementTemplateEntry }> = ({
                entry,
            }) => {
                const entryKey = uuidv4();
                const conditionsSatisfied =
                    !entry.condition || leaseAgreementStore.conditionSatisfied(entry.condition);

                return (
                    <>
                        {conditionsSatisfied && (
                            <Box>
                                <Typography align='justify' variant='subtitle1' component='div'>
                                    {entry.value}
                                </Typography>

                                {entry.entries && entry.entries.length > 0 && (
                                    <>
                                        <List>
                                            {entry.entries.map((subEntry, index) => (
                                                <ListItem key={`${entryKey}-subentry-${index}}`}>
                                                    <EntryComponent entry={subEntry} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </>
                                )}
                            </Box>
                        )}
                    </>
                );
            };

            const RulesClauseComponent: React.FC<{}> = () => {
                return (
                    <Box>
                        <List>
                            {leaseAgreement.clauses?.map((clause, index) => (
                                <ListItem key={`lease-clause-${index}}`}>
                                    <Typography align='justify' variant='subtitle1'>
                                        {clause.title}: {clause.text}
                                    </Typography>
                                </ListItem>
                            ))}
                            {leaseAgreement.rules?.map((rule, index) => (
                                <ListItem key={`lease-rule-${index}}`}>
                                    <Typography align='justify' variant='subtitle1'>
                                        {rule.title}: {rule.text}
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                );
            };

            return (
                <div ref={ref}>
                    <Box p={3}>
                        {/* // include header */}
                        {leaseTemplate?.header && <HeaderComponent header={leaseTemplate.header} />}
                        {/* // include parties */}
                        {leaseTemplate?.sections && tenants && (
                            <Box mt={1} mb={1}>
                                <Typography variant='h5'>PARTIES TO THE AGREEMENT</Typography>
                                <List>
                                    <ListItem>
                                        <Box>
                                            <Typography align='justify' variant='subtitle1'>
                                                This is a Residential Tenancy Agreement between{' '}
                                                {`${owner?.firstname} ${owner?.lastname}`}{' '}
                                                (hereinafter either individually or collectively
                                                referred to as the the "Landlord") and{' '}
                                                {Array.from(tenants.values())
                                                    .map(
                                                        (tenant) =>
                                                            `${tenant?.firstname} ${tenant?.lastname}`,
                                                    )
                                                    .join(', ')}{' '}
                                                (hereinafter either individually or collectively
                                                referred to as the "Tenant").
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                </List>
                            </Box>
                        )}
                        {/* // include occupants */}
                        {leaseTemplate?.sections && leaseAgreement.occupants.length > 0 && (
                            <Box mt={1} mb={1}>
                                <Typography variant='h5'>THE OCCUPANTS</Typography>
                                <List>
                                    <ListItem>
                                        <Box>
                                            <Typography align='justify' variant='subtitle1'>
                                                The Landlord and the Tenant agree that the premises
                                                may be occupied by:{' '}
                                                {leaseAgreement.occupants
                                                    .map(
                                                        (occupant) =>
                                                            `${occupant?.firstName} ${occupant?.lastName}`,
                                                    )
                                                    .join(', ')}{' '}
                                                and their children unless the Landlord consents in
                                                writing to the occupation of the premises by some
                                                other or an additional person or persons.
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                </List>
                            </Box>
                        )}
                        {/* // include premises */}
                        {leaseTemplate?.sections && (
                            <Box mt={1} mb={1}>
                                <Typography variant='h5'>THE PREMISES</Typography>
                                <List>
                                    <ListItem>
                                        <Box>
                                            <Typography align='justify' variant='subtitle1'>
                                                The Landlord will rent to the Tenant and the Tenant
                                                will rent from the Landlord the premises at:
                                            </Typography>
                                            <Typography align='justify' variant='subtitle1'>
                                                {streetAddress(listingAddress)}
                                                <br />
                                                {listingAddress.city}, {listingAddress.province}
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                </List>
                            </Box>
                        )}
                        {/* // include lease terms */}
                        {leaseTemplate?.sections &&
                            leaseTemplate.sections.map((section, index) => (
                                <SectionComponent
                                    section={section}
                                    key={`lease-section-${index}`}
                                />
                            ))}

                        {leaseTemplate?.sections &&
                            (leaseAgreement.clauses.length > 0 ||
                                leaseAgreement.rules.length > 0) && (
                                <>
                                    <Typography variant='h5' gutterBottom>
                                        LANDLORD RULES AND CLAUSES
                                    </Typography>
                                    <RulesClauseComponent />
                                </>
                            )}

                        {/* // include signatures is last page */}
                        {leaseTemplate?.sections && tenantSignatures && ownerSignatureDate && (
                            <Box mt={1} mb={1}>
                                <Typography variant='h5'>SIGNATURES</Typography>
                                <List>
                                    <ListItem>
                                        <Box>
                                            <Typography align='justify' variant='subtitle1'>
                                                By signing this agreement, the Landlord(s) and the
                                                Tenant(s) agree to follow its terms. The Landlord(s)
                                                or Tenant(s) can sign this lease electronically if
                                                they both agree.
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                    <ListItem>
                                        <Box>
                                            <Typography align='justify' variant='subtitle1'>
                                                Unless otherwise agreed in the additional terms, if
                                                there is more than one Tenant, each Tenant is
                                                responsible for all tenant obligations under this
                                                agreement, including the full amount of rent
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                    <ListItem>
                                        <Grid container spacing={4}>
                                            <Grid container item xs={6}>
                                                <Grid
                                                    container
                                                    item
                                                    spacing={0}
                                                    style={{ borderBottom: '1px solid black' }}
                                                >
                                                    <Typography variant='h6'>
                                                        {`${owner?.firstname.toUpperCase()} ${owner?.lastname.toUpperCase()}`}
                                                    </Typography>
                                                </Grid>
                                                <Typography variant='subtitle2'>
                                                    Landlord's Full Name
                                                </Typography>
                                            </Grid>
                                            <Grid container item xs={6}>
                                                <Grid
                                                    container
                                                    item
                                                    spacing={0}
                                                    style={{ borderBottom: '1px solid black' }}
                                                >
                                                    <Typography variant='h6'>
                                                        {format(
                                                            ownerSignatureDate,
                                                            "PPP 'at' h:mm a zzzz",
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Typography variant='subtitle2'>
                                                    Date Signed
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    {tenantSignatures.map((signature, index) => (
                                        <ListItem key={`tenant-signature-${index}`}>
                                            <Grid container spacing={4}>
                                                <Grid container item xs={6}>
                                                    <Grid
                                                        container
                                                        item
                                                        spacing={0}
                                                        style={{ borderBottom: '1px solid black' }}
                                                    >
                                                        <Typography variant='h6'>
                                                            {getTenantName(
                                                                signature.acknowledgedBy,
                                                            ).toUpperCase()}
                                                        </Typography>
                                                    </Grid>
                                                    <Typography variant='subtitle2'>
                                                        Tenants's Full Name
                                                    </Typography>
                                                </Grid>
                                                <Grid container item xs={6}>
                                                    <Grid
                                                        container
                                                        item
                                                        spacing={0}
                                                        style={{ borderBottom: '1px solid black' }}
                                                    >
                                                        <Typography variant='h6'>
                                                            {format(
                                                                signature.timestamp,
                                                                "PPP 'at' h:mm a zzzz",
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                    <Typography variant='subtitle2'>
                                                        Date Signed
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        )}
                    </Box>
                </div>
            );
        },
    ),
);
