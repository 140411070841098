import React from 'react';
import { observer } from 'mobx-react-lite';
import { AdminPageLayout, PageTitle } from '../../../components/uiComponents/UIComponents';
import { BookmarkedListingsComponent } from '../../../components/bookmarks/bookmark';

export const LandlordBookmarkPage: React.FC = observer(() => {
    return (
        <AdminPageLayout
            header={<PageTitle>Bookmarked Listings</PageTitle>}
            content={<BookmarkedListingsComponent />}
        />
    );
});
