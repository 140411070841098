import React, { useEffect, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    IAddress,
    LeaseAgreementStatus,
    LeaseAgreementSource,
    ILeaseAgreement,
    ITenantLeaseInvite,
    IdLeaseAgreement,
} from 'realhaus-sdk';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { RightDrawer } from '../uiComponents/rightDrawer';
import { RootStoreContext } from '../../global/storeContext';
import { streetAddress } from '../../utils/address';
import { TabComponent, TabSection } from '../uiComponents/tabs';
import { LeaseTermsComponent } from './leaseTerms';
import { LeaseRentDepositFeesComponent } from './rentDepositFees';
import { LeasePSIPolicyComponent } from './petsSmokingInsurance';
import { LeaseClausesComponent } from './clauses';
import { LeaseRulesComponent } from './rules';
import { GridContent } from '../uiComponents/UIComponents';
import { LeaseAgreementReadView } from './leaseAgreementReadView';
import { RequestTenantLeaseSignatureDialog } from './dialog';
import { LeaseTenantInformationComponent } from './tenantInformation';
import { LeaseDocumentsComponent } from './documents';
import { RequestedChanges } from './requestedChanges';
import { ConfirmDialog } from '../uiComponents/confirmationDialog';

export interface LeaseAgreementProps {
    leaseAgreementId?: string;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
}

export enum ViewMode {
    MODIFY,
    PREVIEW,
}
type IDTenantLeaseInvite = { id: string } & ITenantLeaseInvite;
export const LeaseAgreementLandlordComponent: React.FC<LeaseAgreementProps> = observer(
    ({ leaseAgreementId, isOpen, onOpen, onClose }) => {
        const { leaseStore, uiStore } = useContext(RootStoreContext);
        const [leaseAgreement, setLeaseAgreement] = useState<ILeaseAgreement>();
        const [leaseModified, setLeaseModified] = useState<boolean>();
        const [tenant, setTenant] = useState<{ firstname: string; lastname: string }>();
        const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.MODIFY);
        const [tenantLeaseInvite, setTenantLeaseInvite] = React.useState<
            { id: string } & ITenantLeaseInvite
        >({} as IDTenantLeaseInvite);
        const [isReadOnly, setIsReadOnly] = React.useState(true);

        const handleClose = () => {
            setViewMode(ViewMode.MODIFY);
            onClose();
        };

        const init = () => {
            // get lease agreement
            if (leaseAgreementId) {
                leaseStore.getLeaseAgreementById(leaseAgreementId).then(async (l) => {
                    if (
                        !l ||
                        (l.source !== LeaseAgreementSource.NON_REALHAUS &&
                            (!l.tenantIds || l.tenantIds.length === 0))
                    ) {
                        closeView();
                        return;
                    }
                    setLeaseAgreement(l);

                    // get tenant
                    if (l.tenantIds.length > 0) {
                        const tenantId = l.tenantIds[0];

                        const tInfo = await leaseStore.getTenantInfo(tenantId);
                        if (!tInfo) {
                            throw new Error(
                                `Could not retrieve tenant Info for tenantId: ${tenantId}`,
                            );
                        }
                        setTenant({ firstname: tInfo.firstname, lastname: tInfo.lastname });
                    } else if (l.source === LeaseAgreementSource.NON_REALHAUS) {
                        // get lease invite info
                        const tInfo = await leaseStore.getTenantInvitationInfo(l.id);
                        if (tInfo) {
                            setTenant({ firstname: tInfo.firstname, lastname: tInfo.lastname });
                            setTenantLeaseInvite(tInfo);
                        } else {
                            console.warn(
                                `Could not retrieve tenant Invitation Info for Lease: ${l.id}`,
                            );
                        }
                    }

                    // Lease should be editable only if agreement is still in draft
                    setIsReadOnly(l.status !== LeaseAgreementStatus.DRAFT);
                });
            } else {
                console.log('no lisiting');
                closeView();
                return;
            }
        };

        useEffect(() => {
            init();
        }, [leaseAgreementId]);

        const closeView = () => {
            setLeaseAgreement(undefined);
            setTenant(undefined);
            setTenantLeaseInvite({} as IDTenantLeaseInvite);
            handleClose();
        };

        const handleClosePreview = () => {
            setViewMode(ViewMode.MODIFY);
        };

        const updateLeaseAgreementProperty = (field: keyof ILeaseAgreement) => (value: unknown) => {
            if (!leaseAgreement || isReadOnly) return;

            setLeaseAgreement({
                ...leaseAgreement,
                [field]: value,
            });

            setLeaseModified(true);
        };

        const updateTenantLeaseInvite =
            (prop: keyof ITenantLeaseInvite) => (event: React.ChangeEvent<{ value: unknown }>) => {
                setTenantLeaseInvite((tenantLeaseInvite) => ({
                    ...tenantLeaseInvite,
                    [prop]: event.target.value,
                }));

                setLeaseModified(true);
            };

        const handleSavePreviewLeaseAgreement = async () => {
            if (!!leaseAgreementId && !!leaseAgreement) {
                try {
                    if (!isReadOnly && leaseModified) {
                        uiStore.showLoading();
                        //ToDo: check that tenant has not signed lease before saving modified lease agreement
                        await leaseStore.updateLeaseAgreement(leaseAgreementId, leaseAgreement);
                        // Send Lease Invitation to tenant
                        if (
                            leaseAgreement.source === LeaseAgreementSource.NON_REALHAUS &&
                            (!leaseAgreement.tenantIds.length ||
                                leaseAgreement.tenantIds.length === 0) &&
                            !tenantLeaseInvite.id
                        ) {
                            // send lease invitation to tenant
                            await leaseStore.createTenantLeaseInvite(
                                tenantLeaseInvite,
                                leaseAgreementId,
                            );
                        }

                        setLeaseModified(false);

                        uiStore.success('Lease Agreement updated');
                    }
                    // show lease preview
                    setViewMode(ViewMode.PREVIEW);
                } catch (err) {
                    uiStore.error('Failed to update lease agreement');
                } finally {
                    uiStore.hideLoading();
                }
            }
        };

        const handleTenantSignatureRequest = async () => {
            if (
                !leaseAgreementId ||
                !leaseAgreement ||
                (leaseAgreement.source !== LeaseAgreementSource.NON_REALHAUS &&
                    (!leaseAgreement?.tenantIds || leaseAgreement?.tenantIds.length === 0))
            ) {
                const err =
                    'Tenant signature request failed. Unable to find the tenant for this Lease Agreement!';
                uiStore.error(err);
                return;
            }

            uiStore.showLoading();
            try {
                const updatedLeaseAgreement = {
                    ...leaseAgreement,
                    signatureRequestDate: Date.now(),
                    status: LeaseAgreementStatus.PENDING_TENANT_SIGNATURE,
                };
                setLeaseAgreement(updatedLeaseAgreement);
                await leaseStore.updateLeaseAgreement(leaseAgreementId, updatedLeaseAgreement);
                uiStore.success('Lease agreement sent to tenant');
            } catch (err) {
                uiStore.error('Failed to send lease agreement to tenant');
            } finally {
                uiStore.hideLoading();
                handleClose();
            }
        };

        return (
            <>
                <RightDrawer isOpen={isOpen} onOpen={onOpen} onClose={handleClose}>
                    <Grid container direction='column' spacing={3}>
                        <Grid item>
                            {!!leaseAgreement && !!leaseAgreementId && (
                                <>
                                    <Box mb={2}>
                                        <LeaseAgreementHeader
                                            tenant={tenant}
                                            listingAddress={leaseAgreement.listingInfo.address}
                                            lease={{
                                                id: leaseAgreementId,
                                                ...leaseAgreement,
                                            }}
                                            refetchData={init}
                                        />
                                    </Box>
                                    <>
                                        {viewMode === ViewMode.MODIFY ? (
                                            <LeaseModifyComponent
                                                isReadOnly={isReadOnly}
                                                leaseAgreement={leaseAgreement}
                                                updateLeaseAgreementProperty={
                                                    updateLeaseAgreementProperty
                                                }
                                                handleReview={handleSavePreviewLeaseAgreement}
                                                updateTenantLeaseInvite={updateTenantLeaseInvite}
                                                tenantLeaseInvite={tenantLeaseInvite}
                                                leaseId={leaseAgreementId}
                                            />
                                        ) : (
                                            <LeasePreviewComponent
                                                leaseAgreement={leaseAgreement}
                                                handleBack={handleClosePreview}
                                                handleRequestSignature={
                                                    handleTenantSignatureRequest
                                                }
                                            />
                                        )}
                                    </>
                                </>
                            )}
                        </Grid>
                        <Grid item></Grid>
                    </Grid>
                </RightDrawer>
            </>
        );
    },
);

const LeaseAgreementHeader: React.FC<{
    tenant: { firstname: string; lastname: string } | undefined;
    listingAddress: IAddress;
    lease: IdLeaseAgreement;
    refetchData: () => void;
}> = observer(({ tenant, listingAddress, lease, refetchData }) => {
    const { uiStore, leaseStore } = useContext(RootStoreContext);
    const [openConfirmCancel, setOpenConfirmCancel] = React.useState<boolean>(false);
    const isLandlord = uiStore.isLandlordMode();

    const handleOpenCancelDialog = () => {
        setOpenConfirmCancel(true);
    };

    const handleCloseCancelDialog = () => {
        setOpenConfirmCancel(false);
    };

    const handleCancelLease = async () => {
        try {
            uiStore.showLoading();
            await leaseStore.endLeaseAgreement(lease).then(() => {
                uiStore.success('Lease agreement cancelled');
                refetchData();
            });
        } catch (err) {
            uiStore.error('Could not cancel agreement at this moment.Please try again later');
        } finally {
            uiStore.hideLoading();
            handleCloseCancelDialog();
        }
    };

    return (
        <>
            <Grid container>
                <Grid item xs={6}>
                    {!!tenant ? (
                        <>
                            <Typography variant='body1'>Lease agreement for</Typography>
                            <Typography variant='h5'>
                                {tenant.firstname} {tenant.lastname}
                            </Typography>
                        </>
                    ) : (
                        <Typography variant='body1'>Non Realhaus Lease</Typography>
                    )}
                </Grid>
                <Grid
                    container
                    direction='column'
                    item
                    xs={6}
                    justifyContent='center'
                    alignItems='flex-end'
                    spacing={3}
                >
                    <Box marginBottom={2}>
                        <Typography variant='h6' align='right'>
                            {streetAddress(listingAddress)}
                            <br />
                            {listingAddress.city}, {listingAddress.province}
                        </Typography>
                    </Box>

                    {isLandlord && lease.status === LeaseAgreementStatus.DRAFT && (
                        <Box marginBottom={2}>
                            <Button
                                variant='outlined'
                                color='secondary'
                                onClick={handleOpenCancelDialog}
                            >
                                Cancel
                            </Button>
                        </Box>
                    )}
                    {lease.status === LeaseAgreementStatus.CANCELLED && (
                        <Box marginBottom={2}>
                            <Typography variant='subtitle1' color={'secondary'} gutterBottom>
                                This lease agreement is cancelled
                            </Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>

            <ConfirmDialog
                title={'Cancel Lease Agreement'}
                content={
                    <>
                        <Typography>
                            Are you sure you want to cancel this agreement? This action cannot be
                            undone.
                        </Typography>
                    </>
                }
                openDialog={openConfirmCancel}
                closeDialog={(confirm: boolean) => () => {
                    if (confirm) {
                        handleCancelLease();
                    }
                    handleCloseCancelDialog();
                }}
            />
        </>
    );
});

interface ILeaseModifyComponentProps {
    isReadOnly: boolean;
    leaseAgreement: ILeaseAgreement;
    updateLeaseAgreementProperty: (field: keyof ILeaseAgreement) => (value: unknown) => void;
    handleReview: () => void;
    tenantLeaseInvite: ITenantLeaseInvite;
    leaseId: string;
    updateTenantLeaseInvite: (
        prop: keyof ITenantLeaseInvite,
    ) => (event: React.ChangeEvent<{ value: unknown }>) => void;
}

export const LeaseModifyComponent: React.FC<ILeaseModifyComponentProps> = observer(
    ({
        isReadOnly,
        leaseAgreement,
        updateLeaseAgreementProperty,
        handleReview,
        tenantLeaseInvite,
        updateTenantLeaseInvite,
        leaseId,
    }) => {
        // TODO: ensure sections do no allow edit for agreements not in draft status
        const isNonRealhausLease = leaseAgreement.source === LeaseAgreementSource.NON_REALHAUS;
        const isRealhausLease = !isNonRealhausLease;
        const sections: TabSection[] = [
            {
                key: 'tenant-information',
                title: 'Tenant Information',
                subTitle: "Verify the Tenant's Information",
                hide:
                    isRealhausLease ||
                    !(
                        isNonRealhausLease &&
                        (!leaseAgreement.tenantIds || leaseAgreement.tenantIds.length === 0)
                    ),
                component: !!leaseAgreement ? (
                    <LeaseTenantInformationComponent
                        updateTenantLeaseInvite={updateTenantLeaseInvite}
                        tenantLeaseInvite={tenantLeaseInvite}
                        readOnly={isReadOnly}
                    />
                ) : (
                    <></>
                ),
            },
            {
                key: 'lease-terms-specifics',
                title: 'Lease Term & Specifics',
                subTitle: 'Verify the address and lease terms',
                component: !!leaseAgreement ? (
                    <LeaseTermsComponent
                        leaseAgreement={leaseAgreement}
                        setLeaseAgreement={updateLeaseAgreementProperty}
                        readOnly={isReadOnly}
                    />
                ) : (
                    <></>
                ),
            },
            {
                key: 'rent-deposit-fees',
                title: 'Rent Deposit & Fees',
                subTitle: 'Confirm rent amount, security deposits and any fees',
                component: !!leaseAgreement ? (
                    <LeaseRentDepositFeesComponent
                        leaseAgreement={leaseAgreement}
                        setLeaseAgreement={updateLeaseAgreementProperty}
                        readOnly={isReadOnly}
                    />
                ) : (
                    <></>
                ),
            },
            {
                key: 'pets-smoking-insurance',
                title: 'Pets, Smoking & Insurance',
                subTitle:
                    'Specify policies for pets, smoking, insurance & auto-rent payment requirements',
                component: !!leaseAgreement ? (
                    <LeasePSIPolicyComponent
                        leaseAgreement={leaseAgreement}
                        setLeaseAgreement={updateLeaseAgreementProperty}
                        readOnly={isReadOnly}
                    />
                ) : (
                    <></>
                ),
            },
            {
                key: 'lease-clauses',
                title: 'Clauses',
                subTitle: 'Specify clauses for the rent term',
                hide: isNonRealhausLease,
                component: !!leaseAgreement ? (
                    <LeaseClausesComponent
                        leaseAgreement={leaseAgreement}
                        updateProperty={updateLeaseAgreementProperty}
                        readOnly={isReadOnly}
                    />
                ) : (
                    <></>
                ),
            },
            {
                key: 'lease-rules',
                title: 'Rules',
                subTitle: 'Specify rules for the rent term',
                hide: isNonRealhausLease,
                component: !!leaseAgreement ? (
                    <LeaseRulesComponent
                        leaseAgreement={leaseAgreement}
                        updateProperty={updateLeaseAgreementProperty}
                        readOnly={isReadOnly}
                    />
                ) : (
                    <></>
                ),
            },
            {
                key: 'lease-documents',
                title: 'Lease Documents',
                subTitle: 'Upload other supporting documents',
                component: !!leaseAgreement ? (
                    <LeaseDocumentsComponent leaseId={leaseId} readOnly={isReadOnly} />
                ) : (
                    <></>
                ),
            },
            {
                key: 'Requested-changes-on-lease-documents',
                title: 'Requested Changes',
                subTitle: 'See changes requested on lease documents',
                component: !!leaseAgreement ? (
                    <RequestedChanges leaseAgreement={leaseAgreement} />
                ) : (
                    <></>
                ),
            },
        ];

        return (
            <TabComponent
                sections={sections}
                direction='vertical'
                onSubmit={handleReview}
                saveLabel={'REVIEW...'}
            />
        );
    },
);

interface ILeasePreviewComponentProps {
    leaseAgreement: ILeaseAgreement;
    handleBack: () => void;
    handleRequestSignature: () => void;
}
export const LeasePreviewComponent: React.FC<ILeasePreviewComponentProps> = observer(
    ({ leaseAgreement, handleBack, handleRequestSignature }) => {
        const [signatureRequestDialogOpen, setSignatureRequestDialogOpen] = React.useState(false);
        const isNonRealhausLease = leaseAgreement.source === LeaseAgreementSource.NON_REALHAUS;

        const openSignatureRequestDialog = () => {
            setSignatureRequestDialogOpen(true);
        };

        const closeSignatureRequestDialog = () => {
            setSignatureRequestDialogOpen(false);
        };

        const agreementStatusMessage = () => {
            switch (leaseAgreement.status) {
                case LeaseAgreementStatus.DRAFT: {
                    return 'This lease agreement is in progress.';
                }
                case LeaseAgreementStatus.PENDING_TENANT_SIGNATURE: {
                    return 'Waiting for tenant to sign.';
                }
                case LeaseAgreementStatus.SIGNED: {
                    return 'This lease agreement has been signed.';
                }
                default: {
                    return '';
                }
            }
        };

        return (
            <>
                <GridContent spacing={2}>
                    <Grid
                        item
                        justifyContent='space-between'
                        style={{ width: '100%', display: 'flex' }}
                    >
                        <Button onClick={handleBack} style={{ marginRight: '5px' }}>
                            <KeyboardArrowLeft />
                            BACK
                        </Button>
                        {leaseAgreement.status === LeaseAgreementStatus.DRAFT ? (
                            <Button
                                variant='contained'
                                color='primary'
                                style={{ borderRadius: '0' }}
                                onClick={openSignatureRequestDialog}
                            >
                                Request Signature
                            </Button>
                        ) : (
                            <Typography variant='subtitle1' color='primary'>
                                {agreementStatusMessage()}
                            </Typography>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                            height: '75vh',
                            width: '100%',
                            overflow: 'auto',
                            border: '1px solid #e8e8e8',
                        }}
                    >
                        <LeaseAgreementReadView leaseAgreement={leaseAgreement} />
                    </Grid>
                </GridContent>

                {/* Confirm send request for tenant signature */}
                <RequestTenantLeaseSignatureDialog
                    dialogOpen={signatureRequestDialogOpen}
                    closeDialog={closeSignatureRequestDialog}
                    onAccept={() => {
                        closeSignatureRequestDialog();
                        handleRequestSignature();
                    }}
                />
            </>
        );
    },
);
