import { Grid, Typography, DialogTitle } from '@material-ui/core';
import { LeaseTerm } from 'realhaus-sdk';

export const ReviewInfo: React.FC<{
    firstName?: string;
    term: string;
    landlordOrTenant: string;
    oppLandlordOrTenant: string;
}> = ({ firstName, term, landlordOrTenant, oppLandlordOrTenant }) => {
    return (
        <>
            <Grid container spacing={8}>
                <Grid item xs={12}>
                    <Typography align='center'>
                        <DialogTitle>{`${
                            term === LeaseTerm.midterm ? 'Middle of Lease' : 'End of Lease'
                        } Review for ${firstName}`}</DialogTitle>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography>
                        Let us know about your experience with {firstName}. Your reviews are
                        extremely useful as it encourages {landlordOrTenant} to act thoughtfully and
                        inform other {oppLandlordOrTenant} about potential encounters.
                        <br />
                        <br />
                        These feebacks will be publicly visible.
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};
