import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router';
import { RootStoreContext } from '../../../global/storeContext';

export const CompleteAccountSetupDialog: React.FC<{
    openCompleteProfileDialog: boolean;
    handleCloseDialog: () => void;
}> = observer(({ openCompleteProfileDialog, handleCloseDialog }) => {
    const navigate = useNavigate();
    const { uiStore } = React.useContext(RootStoreContext);

    const isLandlordMode = uiStore.isLandlordMode();
    return (
        <>
            <Dialog
                open={openCompleteProfileDialog}
                onClose={handleCloseDialog}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    Complete Profile &amp; ID Verification
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        You have not completed your account setup. To ensure you have the best
                        experience and access to Realhaus features, please finalize your account
                        setup.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color='primary'>
                        Cancel
                    </Button>
                    <Button
                        onClick={() =>
                            isLandlordMode ? navigate('/m/dashboard') : navigate('/t/dashboard')
                        }
                        color='primary'
                        autoFocus
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});
