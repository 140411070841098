import React from 'react';
import { FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';

type ValuesType = {
    label: string;
    val: any;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

export const SelectField: React.FC<{
    id: string;
    value?: string;
    values: ValuesType[];
    onChange: (value: string | number | null) => void;
    variant?: 'standard' | 'outlined' | 'filled';
    label?: string;
    fullWidth?: boolean;
}> = observer(({ id, value, onChange, values, variant, label, fullWidth }) => {
    const classes = useStyles();
    return (
        <FormControl variant={variant || 'standard'} className={fullWidth ? '' : classes.formControl} fullWidth={fullWidth}>
            {label && <InputLabel id={`${id}-select-field-input-label`}>{label}</InputLabel>}
            <Select
                id={id}
                value={value ?? ''}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    onChange(event.target.value as string);
                }}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ 'aria-label': `${label ? 'With' : 'Without'} label` }}
                fullWidth={fullWidth}
            >
                {values.map((value, i) => (
                    <MenuItem key={i} value={value.val}>
                        {value.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
});
