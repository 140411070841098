import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';


export const LeaseCardDetail = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  minHeight: '170px'
});

export const useStyles = makeStyles({
  cardParent: {
    marginBottom: '16px',
    padding: '15px 8px 15px 8px',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    "&:hover": {
      cursor: 'pointer',
    }
  },
  cardRoot: {
    // height: '100%',
    padding: '0px',
    paddingRight: '0px !important',
    backgroundColor: 'transparent'
  },
  cardContent: {
    textAlign: 'center',
  },
  cardContentAlign: {
    textAlign: 'center',
  },
  noPadding: {
    padding: '0px !important',
  },
  successColor: {
    color: '#4caf50',
  },
  errorColor: {
    color: '#f44336',
  },
  warningColor: {
    color: '#ffeb3b',
  },
  capitalization: {
    textTransform: 'capitalize'
  },
  modalContent: {
    minHeight: '200px',
    padding: '40px !important'
  }
});