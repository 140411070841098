import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    DropzoneContainer,
    ImageContent,
    ImageThumb,
    ImageThumbAction,
    ImageThumbInner,
    ImageThumbsContainer,
    ImageThumbTools,
    Title,
} from './styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { RootStoreContext } from '../../global/storeContext';

interface PropertyPhotosProps {
    propertyPhotos: File[];
    setPropertyPhotos: (files: File[]) => void;
    savedPhotos: {
        id: string;
        url: string;
    }[];
    setPhotoToDelete: (photoId: string) => void;
}

export const PropertyPhotos: React.FC<PropertyPhotosProps> = observer(
    ({ propertyPhotos, setPropertyPhotos, savedPhotos, setPhotoToDelete }) => {
        const { uiStore } = React.useContext(RootStoreContext);

        const generatePreview = (files: File[]) => {
            return files.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            );
        };

        const [files, setFiles] = React.useState<
            (File & {
                preview: string;
            })[]
        >([...generatePreview(propertyPhotos)]);

        const [photos, setPhotos] = React.useState(savedPhotos ?? []);

        const onDrop = (acceptedFiles: File[]) => {
            const checkedFiles = acceptedFiles
                .map((acceptedFile) => {
                    const fileSize = acceptedFile.size / 1024;
                    if (fileSize > 3072) {
                        uiStore.error('File too large. Picture size must not be over 3mb');
                        return null;
                    }
                    return acceptedFile;
                })
                .filter((file) => file !== null) as File[];

            var newFiles = [...propertyPhotos, ...checkedFiles];
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach((file: any) => URL.revokeObjectURL(file.preview));
            setFiles([...generatePreview(newFiles)]);
            setPropertyPhotos([...newFiles]);
        };

        const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } =
            useDropzone({
                accept: {
                    'image/*': [],
                },
                onDrop,
            });

        const removePhoto = (file: any) => {
            const index = propertyPhotos.findIndex((f) => f.name === file.name);
            if (index >= 0) {
                var temp = [...propertyPhotos];
                temp.splice(index, 1);

                setPropertyPhotos([...temp]);
                setFiles([...generatePreview(temp)]);
            }
        };

        const deletePhoto = (image: { url: string; id: string }) => {
            if (!!image) {
                setPhotos(photos.filter((x) => x.id !== image.id));
                setPhotoToDelete(image.id);
            }
        };

        const imageThumbs = files.map((file: any) => (
            <ImageThumb key={file.name}>
                <ImageThumbInner>
                    <ImageContent src={file.preview} />
                </ImageThumbInner>
                <ImageThumbTools>
                    <ImageThumbAction>
                        <IconButton
                            aria-label='delete'
                            color='secondary'
                            onClick={() => {
                                removePhoto(file);
                            }}
                        >
                            <DeleteIcon fontSize='small' />
                        </IconButton>
                    </ImageThumbAction>
                </ImageThumbTools>
            </ImageThumb>
        ));

        const imagePreview = photos.map((image: any) => (
            <ImageThumb key={image.id}>
                <ImageThumbInner>
                    <ImageContent src={image.url} />
                </ImageThumbInner>
                <ImageThumbTools>
                    <ImageThumbAction>
                        <IconButton
                            aria-label='delete'
                            color='secondary'
                            onClick={() => {
                                //   removePhoto(file);
                                deletePhoto(image);
                            }}
                        >
                            <DeleteIcon fontSize='small' />
                        </IconButton>
                    </ImageThumbAction>
                </ImageThumbTools>
            </ImageThumb>
        ));

        return (
            <>
                <Title>
                    <Typography variant='h5' gutterBottom>
                        Add photos of your property.
                    </Typography>
                </Title>

                <form noValidate autoComplete='off'>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DropzoneContainer
                                {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
                            >
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p>Drop the files here ...</p>
                                ) : (
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                )}
                            </DropzoneContainer>
                            <ImageThumbsContainer>{imageThumbs}</ImageThumbsContainer>
                            {photos ? (
                                <ImageThumbsContainer>{imagePreview}</ImageThumbsContainer>
                            ) : null}
                        </Grid>
                    </Grid>
                </form>
            </>
        );
    },
);
