const MAX_DELAY = 200;
const MAX_RETRIES = 5;

/**
 * Safely retry the given function a number of times until successful completion
 * @param fn The function to call
 * @param onRetriesCompleted The callback function to call if we completed all retries without success
 * @param retriesLeft The number of times to call the given function (defaults to 5)
 * @param delay The number of milliseconds to wait before (defaults to 200ms)
 * @return Returns the results of provided function if immediately successful
 */
export const retry = (fn: Function, onRetriesCompleted = () => { }, retriesLeft: number = MAX_RETRIES, delay = MAX_DELAY): any => {
  try {
    return fn();
  } catch (error) {
    if (retriesLeft) {
      setTimeout(() => retry(fn, onRetriesCompleted, retriesLeft - 1, delay), delay);
    } else {
      onRetriesCompleted();
    }
  }
};