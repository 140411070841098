import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, IconButton, SwipeableDrawer, Theme, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

export interface RightDrawerProps {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    children?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        applicationDrawer: {
            '& .MuiDrawer-paper': {
                [theme.breakpoints.up('md')]: {
                    width: '65%',
                },
                [theme.breakpoints.down('md')]: {
                    width: '95%',
                },
            },
        },
    }),
);

export const RightDrawer: React.FC<RightDrawerProps> = observer(
    ({ children, isOpen, onOpen, onClose }) => {
        const classes = useStyles();
        const handleOpen = () => onOpen();
        const handleClose = () => onClose();
        const isMobile = useMediaQuery('(max-width:959px)');

        return (
            <>
                <SwipeableDrawer
                    className={classes.applicationDrawer}
                    anchor='right'
                    open={isOpen}
                    onOpen={handleOpen}
                    onClose={handleClose}
                >
                    {isMobile ? (
                        <Box pl={2} pr={2} mt={5} mb={10}>
                            <Box mb={3}>
                                <Grid container justifyContent='flex-end'>
                                    <Grid item>
                                        <IconButton aria-label='close' onClick={handleClose}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Box>
                            {children}
                        </Box>
                    ) : (
                        <Box pl={10} pr={10} mt={10} mb={10}>
                            {children}
                        </Box>
                    )}
                </SwipeableDrawer>
            </>
        );
    },
);
