import React from 'react';
import { observer } from 'mobx-react-lite';
import { Title } from './styles';
import { IProperty } from 'realhaus-sdk';
import { Grid, TextField, Typography } from '@material-ui/core';

interface PropertyDescriptionProps {
    property: IProperty;
    updateProperty: (
        prop: keyof IProperty,
    ) => (event: React.ChangeEvent<{ value: unknown }>) => void;
}

export const PropertyDescription: React.FC<PropertyDescriptionProps> = observer(
    ({ property, updateProperty }) => {
        const handleChange =
            (prop: keyof IProperty) => (event: React.ChangeEvent<{ value: unknown }>) => {
                updateProperty(prop)(event);
            };

        return (
            <>
                <Title>
                    <Typography variant='h5' gutterBottom>
                        Describe your place to guests
                    </Typography>
                </Title>
                <Typography variant='subtitle1' gutterBottom>
                    Mention the best features of your property, any special amenities, and what you
                    love about the neighbourhood.
                </Typography>

                <form noValidate autoComplete='off'>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id='property-description'
                                multiline
                                minRows={10}
                                fullWidth
                                defaultValue={property.description}
                                margin='normal'
                                variant='outlined'
                                onChange={handleChange('description')}
                            />
                        </Grid>
                        <Grid item xs={12}></Grid>
                    </Grid>
                </form>
            </>
        );
    },
);
