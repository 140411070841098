import { makeAutoObservable } from 'mobx';
import FirebaseStore from './firebaseStore';
import { Collections } from './dbStore';
import { BankAccountPurpose, IBankConnection, IExchangePlaidLinkTokenRequest } from 'realhaus-sdk';
import { UserStore } from './userStore';
import { exchangePlaidPublicToken, getBankAccounts, getBankAccountsByIdHandler, getPlaidLinkToken, removeBankAccountConnection } from '../utils/apiClient';

export class BankAccountStore {
  constructor(private fireStore: FirebaseStore, private userStore: UserStore) {
    makeAutoObservable(this);
  }

  getUserBankAccounts = async (bankAccountPurpose: BankAccountPurpose): Promise<(any) | undefined> => {
    const uid = this.userStore.userId;
    if (!uid) throw new Error('Not Authenticated');
    const token = await this.fireStore.authService.currentUser?.getIdToken() || '';
    const request = { token, body: { bankAccountPurpose } }
    const res = await getBankAccounts(request)
    if (res) {
      return res.json()
    }
  }

  getBankAccountsById = async (id: string): Promise<(any) | undefined> => {
    try {
      const uid = this.userStore.userId;
      if (!uid) throw new Error('Not Authenticated');
      const token = await this.fireStore.authService.currentUser?.getIdToken() || '';
      const request = { token, body: { id } }
      const res = await getBankAccountsByIdHandler(request)
      if (res) {
        return res.json()
      }
    } catch (err) {
      console.log('>>Error getting bank accounts by Id', err)
    }
  }

  connectBankAccount = async (bankAccountPurpose: BankAccountPurpose, payload: IBankConnection): Promise<void> => {
    const uid = this.userStore.userId;
    if (!uid) throw new Error('Not Authenticated');

    switch (bankAccountPurpose) {
      case BankAccountPurpose.PAYMENTS:
        await this.fireStore.updateDocument(Collections.bankAccounts, uid, { payments: [payload] })
        break;
      case BankAccountPurpose.PAYOUTS:
        await this.fireStore.updateDocument(Collections.bankAccounts, uid, { payouts: [payload] })
        break;
    }
  }

  removeBankAccountConnection = async (bankAccountPurpose: BankAccountPurpose): Promise<boolean | void> => {
    const uid = this.userStore.userId;
    if (!uid) throw new Error('Not Authenticated');
    const token = await this.fireStore.authService.currentUser?.getIdToken() || '';
    const request = { token, body: { bankAccountPurpose } }
    const res = await removeBankAccountConnection(request)
    if (res) {
      return true
    }
  }

  getLinkToken = async (): Promise<any> => {
    const uid = this.userStore.userId;
    if (!uid) throw new Error('Not Authenticated');
    const token = await this.fireStore.authService.currentUser?.getIdToken() || '';
    const res = await getPlaidLinkToken(token)
    if (res) {
      return res.json()
    }
  }

  exchangePublicToken = async (payload: IExchangePlaidLinkTokenRequest): Promise<any> => {
    const uid = this.userStore.userId;
    if (!uid) throw new Error('Not Authenticated');
    const token = await this.fireStore.authService.currentUser?.getIdToken() || '';
    const request = { token, body: { ...payload } }
    const res = await exchangePlaidPublicToken(request)
    if (res) {
      return res.json()
    }
  }
}
