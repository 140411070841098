import { format } from 'date-fns';

export const parseMessage = (msg: string): string => {
  let parsed = msg;
  parsed = parseTimestamps(parsed);
  return parsed;
}

const parseTimestamps = (msg: string): string => {
  var parsed = msg;
  const timestampRegex = '{{timestamp:[0-9]*}}';
  const timestampValueRegex = '[0-9]+'
  const matches = msg.match(timestampRegex);
  if (matches) {
    matches.forEach((matchStr) => {
      const regexMatch = matchStr.match(timestampValueRegex);
      if (regexMatch && parsed.indexOf(matchStr) >= 0) {
        const ts = Number(regexMatch[0])
        parsed = parsed.replaceAll(matchStr, format(ts, 'PPPP \'at\' p'));
      }
    });
  }

  return parsed;
}