import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Divider, Grid, Typography } from '@material-ui/core';
import {
    IdLeaseAgreement,
    ILeaseRatingsReview,
    LeaseAgreementViewer,
    RatingsForLandlord,
    RatingsForTenant,
} from 'realhaus-sdk';
import { RootStoreContext } from '../../../global/storeContext';
import { AggRatingsCard, ReviewCard, ReviewersName } from '../../uiComponents/ratings';

interface LeaseReviewsProps {
    leaseAgreement: IdLeaseAgreement;
    viewer: LeaseAgreementViewer;
}

export const LeaseReviewsComponent: React.FC<LeaseReviewsProps> = observer(
    ({ leaseAgreement, viewer }) => {
        const { uiStore, ratingStore } = useContext(RootStoreContext);

        const [ratingsReview, setRatingsReview] = React.useState<ILeaseRatingsReview>();
        const [ratingsScoreArr, setRatings] = React.useState<
            (RatingsForLandlord | RatingsForTenant)[]
        >([]);
        const isLandLord = uiStore.isLandlordMode();
        const userId = isLandLord ? leaseAgreement.tenantIds[0] : leaseAgreement.ownerId;

        useEffect(() => {
            const init = async () => {
                await ratingStore
                    .getLeaseAgreementRatingsReview(leaseAgreement, userId)
                    .then((data) => {
                        if (!!data) {
                            setRatingsReview(data);

                            const ratings = [];
                            if (!!data?.middleOfTerm) {
                                ratings.push(data.middleOfTerm.ratings);
                            }

                            if (!!data?.endOfTerm) {
                                ratings.push(data.endOfTerm.ratings);
                            }

                            setRatings([...ratings]);
                        }
                    });
            };
            init();
        }, [leaseAgreement]);

        return (
            <>
                <Grid container spacing={5}>
                    {!!ratingsReview ? (
                        <>
                            <Grid item xs={12}>
                                <AggRatingsCard ratings={ratingsScoreArr} />
                            </Grid>

                            <Grid item xs={12}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12}>
                                <ReviewersName userId={userId} />
                            </Grid>

                            <Grid item xs={12}>
                                <ReviewCard
                                    title='Middle Of Term'
                                    leaseReview={ratingsReview.middleOfTerm}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <ReviewCard
                                    title='End Of Term'
                                    leaseReview={ratingsReview.endOfTerm}
                                />
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} spacing={5}>
                            <Typography variant='h5' align='center' style={{ marginTop: '20px' }}>
                                No reviews available
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </>
        );
    },
);
