import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@material-ui/core';

type DialogProps = {
    dialogOpen: boolean;
    onAccept: (data?: string | any) => void;
    onReject?: () => void;
    closeDialog: () => void;
};

export const RequestTenantLeaseSignatureDialog: React.FC<DialogProps> = observer(
    ({ dialogOpen, onAccept, closeDialog }) => {
        return (
            <>
                <Dialog
                    open={dialogOpen}
                    onClose={closeDialog}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                    fullWidth={true}
                >
                    <>
                        <DialogTitle id='alert-dialog-title'>
                            Request Tenant's Signature
                        </DialogTitle>
                        <DialogContent>
                            <Typography variant='body1' gutterBottom>
                                You will not be able to make changes after the lease agreement has
                                been sent to the tenant.
                                <br />
                                <br />
                                Will you like to send this lease agreeent for signing now?
                            </Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={closeDialog} color='primary'>
                                Cancel
                            </Button>
                            <Button onClick={onAccept} color='primary' variant='contained'>
                                Send Request
                            </Button>
                        </DialogActions>
                    </>
                </Dialog>
            </>
        );
    },
);

export const TenantSignLeaseAgreementDialog: React.FC<DialogProps> = observer(
    ({ dialogOpen, onAccept, closeDialog }) => {
        return (
            <>
                <Dialog
                    open={dialogOpen}
                    onClose={closeDialog}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                    fullWidth={true}
                >
                    <>
                        <DialogTitle id='alert-dialog-title'>Sign Lease Agreement</DialogTitle>
                        <DialogContent>
                            <Typography variant='subtitle1' gutterBottom>
                                By clicking on the "Sign" button, you agree to be bound and abide by
                                the rules and regulations set out in this lease agreement for the
                                duration of the lease term.
                            </Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={closeDialog} color='primary'>
                                Cancel
                            </Button>
                            <Button onClick={onAccept} color='primary' variant='contained'>
                                Sign
                            </Button>
                        </DialogActions>
                    </>
                </Dialog>
            </>
        );
    },
);

export const TenantRequestLeaseChangesDialog: React.FC<DialogProps> = observer(
    ({ dialogOpen, onAccept, closeDialog }) => {
        const [changes, setChanges] = React.useState('');

        const handleRequestChanges = () => {
            if (changes.length) {
                onAccept(changes);
            }
        };
        return (
            <>
                <Dialog
                    open={dialogOpen}
                    onClose={closeDialog}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                    fullWidth={true}
                >
                    <>
                        <DialogTitle id='alert-dialog-title'>
                            Request changes to Lease Agreement
                        </DialogTitle>
                        <DialogContent>
                            <Typography variant='subtitle1' gutterBottom>
                                Provide the details of a change to make to the{' '}
                                <b>Lease Agreement</b>.
                            </Typography>
                            <Typography variant='subtitle1' gutterBottom>
                                The Lease will be put back in the <b>DRAFT</b> status until the
                                changes have been addressed.
                            </Typography>

                            <TextField
                                autoFocus
                                margin='dense'
                                id='changeRequest'
                                type='text'
                                fullWidth={true}
                                placeholder='Provide the details of the change request.'
                                variant='outlined'
                                multiline
                                minRows={4}
                                maxRows={4}
                                onChange={(e) => setChanges(e.target.value)}
                            />
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={closeDialog} color='primary'>
                                Cancel
                            </Button>
                            <Button
                                onClick={handleRequestChanges}
                                color='primary'
                                variant='contained'
                            >
                                Request Change
                            </Button>
                        </DialogActions>
                    </>
                </Dialog>
            </>
        );
    },
);
