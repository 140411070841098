import { createStyles, makeStyles, styled, Theme } from '@material-ui/core/styles';

export const PageContent = styled('div')({
  marginTop: '40px'
});

export const PageHead = styled('section')({
  marginBottom: '40px',
  marginTop: '40px',
  display: 'flex'
})

export const FullWidth = styled('span')({
  width: '100%',
})

export const FullHeight = styled('span')({
  height: '90vh',
})

export const FullContainer = styled('div')({
  width: '100%',
  height: '100vh',
})

export const FixedFullContainer = styled('div')({
  width: '100%',
  height: '100vh',
  position: 'fixed'
})

export const Spacing = styled('div')({
  flex: '1 1 auto',
})

export const Overlay = styled('div')({
  position: 'absolute',
  width: '100%',
  display: 'flex',
  flex: '1 1 auto',
  top: 0,
  backgroundColor: 'black',
  opacity: 0.4,
  color: 'white'
})

export const OverlayContent = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  flex: '1 1 auto',
  padding: 10
})

export const SearchPageContent = styled('div')({
  marginTop: '0px'
});

export const SearchListingContainer = styled('div')({
  width: '100%',
  height: 'calc(100vh - 180px)',
})

export interface StyleProps {
  tab: "list" | "map";
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%'
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 1 auto',
    },
    cityIcon: {
      width: theme.spacing(9),
      height: theme.spacing(9)
    },
    filterContainer: {
      width: '100%',
      margin: '10px',
    },
    inputField: {
      width: '100%',
      minWidth: '120px',
    },
    formButton: {
      height: '100%',
      width: '100%',
    },
    fullWidth: {
      width: '100%',
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    searchBarRoot: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
    },
    spacedContainer: {
      marginTop: '30vh',
    },
    searchBar: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    searchBarIconButton: {
      padding: 10,
    },
    searchDivider: {
      height: 28,
      margin: 4,
    },
    searchDividerButton: {
      padding: 10,
    },
    noWrap: {
      whiteSpace: 'nowrap'
    },
    filterFormContainer: {
      padding: '25px',
    },
    listingCard: {
      height: '100%'
    },
    listingCardMedia: {
      height: 160,
    },
    listingCardDetails: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      minHeight: '170px'
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '100%',
      overflow: 'hidden',
    },
    toggleButton: {
      padding: 2,
      fontSize: 11
    }
  }),
);