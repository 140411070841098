import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 250px)',
    },
  },
  grid: {
    height: '100%',
    width: '100%',
  },
  borderLeftRight: {
    borderLeft: 'groove',
    borderRight: 'groove',
  },
  boxBorderRight: {
    borderRight: '1px solid #D3D3D3',
  },
  boxBorderBottom: {
    borderBottom: '1px solid #D3D3D3',
  },
  contentPadding: {
    padding: '16px',
    borderBottom: '1px solid #D3D3D3',

  },
  messageWrapper: {
    width: '100%'
  },
  box: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: '100%',
      overflow: "auto"
    },
  },
  messagesBox: {
    width: '100%',
    overflow: "auto",
    height: 'calc(100vh - 380px)',

  },
  date: {
    textAlign: "right",
    padding: "0 16px",
  },
  cursorPointer: {
    '&:hover': {
      cursor: "pointer",
    },
  },
  inputBorderRadius: {
    borderRadius: "50px",
    border: 'none',
    '&:focus': {
      outline: "none",
    },
  },
  textBold: {
    fontWeight: "bold"
  }
}));