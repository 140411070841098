import { makeAutoObservable } from 'mobx';
import FirebaseStore from './firebaseStore';
import { UIStore } from './uiStore';
import { UserStore } from './userStore';
import { Collections, LimitQueryOperation, WhereQueryOperation } from './dbStore';
import { Appointment, AppointmentType, IdShowingTimeslot, ShowingTimeslot, IDAppointment } from 'realhaus-sdk';

export class ShowingStore {

  constructor(private fireStore: FirebaseStore, private uiStore: UIStore, private userStore: UserStore) {
    makeAutoObservable(this);
  }

  reserveTimeslot = async (listingId: string, propertyId: string, timeslotId: string) => {
    const uid = this.userStore.userId;
    if (!uid) {
      this.uiStore.error("Log in or create an account to reserve a showing");
      return;
    }

    // get listing
    // const propertyId = ((await this.fireStore.getDocument(Collections.listings, listingId)).data() as IListing).propertyId;

    const query = await this.fireStore.findDocuments(Collections.appointments, [
      new WhereQueryOperation('timeslotId', '==', timeslotId),
      new WhereQueryOperation('listingId', '==', listingId)
    ]);

    if (query.size > 0) {
      // timeslot already reserved
      this.uiStore.error("Could not reserve showing. The timeslot is already reserved");
      return;
    }

    // all is well, add appointment
    const appt: Appointment = { type: AppointmentType.SHOWING, guestId: uid, listingId, propertyId, timeslotId };
    await this.fireStore.addDocument(Collections.appointments, appt);
    return appt;
  }

  reservedTimeslotByListing = async (listingId: string): Promise<IdShowingTimeslot | undefined> => {
    const uid = this.userStore.userId;
    if (!uid) return;

    const appts = await this.fireStore.findDocuments(Collections.appointments, [
      new WhereQueryOperation('listingId', '==', listingId),
      new WhereQueryOperation('guestId', '==', this.userStore.userId)
    ]);

    if (!appts.empty) {
      const timeslots: IdShowingTimeslot[] = [];
      for (const doc of appts.docs) {
        const timeNow = Date.now();
        const appt = doc.data() as Appointment;
        const ts = await this.getShowingTimeslotByProperty(appt.propertyId, appt.timeslotId);

        if (!!ts && ts.timestamp >= timeNow) {
          timeslots.push(ts);
        }
      };

      if (timeslots.length > 0) {
        return timeslots.sort((a, b) => a.timestamp - b.timestamp)[timeslots.length - 1];
      }
    }
  }

  getShowingTimeslotByProperty = async (propertyId: string, timeslotId: string): Promise<IdShowingTimeslot | undefined> => {

    const collectionId = `${Collections.properties}/${propertyId}/${Collections.showings}`;

    const ref = await this.fireStore.getDocument(collectionId, timeslotId);
    if (ref.exists()) {
      return { id: ref.id, ...(ref.data() as ShowingTimeslot) };
    }

    return undefined;
  }

  cancelReservedShowingByListing = async (listingId: string, timeslotId: string) => {
    const query = await this.fireStore.findDocuments(Collections.appointments, [
      new WhereQueryOperation('timeslotId', '==', timeslotId),
      new WhereQueryOperation('guestId', '==', this.userStore.userId),
      new WhereQueryOperation('listingId', '==', listingId),
      new LimitQueryOperation(1)
    ]);

    if (!query.empty) {
      const doc = query.docs[0];
      await this.fireStore.deleteDocument(Collections.appointments, doc.id);
    }
  }

  getAppointments = async (): Promise<IDAppointment[]> => {
    const uid = this.userStore.userId;
    if (!uid) return [];

    const query = await this.fireStore.findDocuments(Collections.appointments, [
      new WhereQueryOperation('guestId', '==', uid),
    ]);

    const appts: IDAppointment[] = [];
    const asyncFuncs: Promise<void>[] = [];
    query.docs.forEach((appt) => {
      const func = async () => {
        if (!appt.exists()) return;
        const apptData = (appt.data() as Appointment);
        const showing = (await this.fireStore.getDocument(`${Collections.properties}/${apptData.propertyId}/${Collections.showings}`, apptData.timeslotId)).data() as ShowingTimeslot
        const oneWeekAgo = new Date().setDate(new Date().getDate() - 7)
        if (showing && showing.timestamp > oneWeekAgo) { appts.push({ id: appt.id, ...apptData } as IDAppointment); }
      };
      asyncFuncs.push(func());
    });

    await Promise.all(asyncFuncs);
    const filtData = appts.filter((data) => data !== undefined);

    return filtData as IDAppointment[];
  }
}