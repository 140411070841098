import { createStyles, makeStyles, styled, Theme } from '@material-ui/core/styles';

export const PageContent = styled('div')({
  marginTop: '40px'
});

export const PageHead = styled('section')({
  marginBottom: '40px',
  marginTop: '40px'
})

export const CardContentLink = styled('a')({
  display: 'block',
  width: '100%',
  textDecoration: 'none',
  color: 'inherit',
  margin: '-16px',
  padding: '24px'
})

export const CardContentLinkItem = styled('div')({
  display: 'flex',
  height: '100%',
})

export const CardLinkIcon = styled('div')({
  marginBottom: '16px'
})

export const CardLinkTitle = styled('div')({
  marginBottom: '8px',
  height: 'auto',
})

export const CardLinkFooter = styled('div')({
})

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%'
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 1 auto',
    },
    cover: {
      width: 151,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    container: {
      width: '100%',
      border: '1px solid rgba(0,0,0,0.5)'
    },
    divider: {
      backgroundColor: 'rgba(0,0,0,0.5)'
    },
    profileAvatar: {
      width: '128px',
      height: '128px',
    },
  }),
);