import { Button, Menu, MenuItem } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';

export interface ConnectAccountButtonProps {
    variant?: 'outlined' | 'text' | 'contained' | undefined;
    color?: 'primary' | 'inherit' | 'secondary' | 'default' | undefined;
    size?: 'small' | 'medium' | 'large' | undefined;
    text?: string;
    ready: boolean;
    open: Function;
}

export const ConnectAccountButton: React.FC<ConnectAccountButtonProps> = observer(
    ({
        text = 'Connect account',
        variant = 'outlined',
        color = 'primary',
        size = 'small',
        ready,
        open,
    }) => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

        const handleOpenBankAccount = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handleCloseBankAccount = () => {
            setAnchorEl(null);
        };

        return (
            <>
                <Button
                    variant={variant}
                    color={color}
                    size={size}
                    aria-haspopup='true'
                    onClick={handleOpenBankAccount}
                >
                    {text}
                </Button>
                <Menu
                    id='fund-wallet-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseBankAccount}
                >
                    <MenuItem
                        disabled={!ready}
                        onClick={() => {
                            open();
                            handleCloseBankAccount();
                        }}
                    >
                        Bank
                    </MenuItem>
                    <MenuItem>
                        <span id='loginPaypalButton'></span>
                    </MenuItem>
                </Menu>
            </>
        );
    },
);
