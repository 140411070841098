import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Dialog, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { AdminPageLayout, PageTitle } from '../../../components/uiComponents/UIComponents';
import { RootStoreContext } from '../../../global/storeContext';
import { RentApplicationSnapshot } from '../../../components/prospectiveTenants/rentApplicantSnapshot';
import { RentApplicationScreeningComponent } from '../../../components/applicationScreening/rentApplicationScreening';
import StringUtils from '../../../utils/string';
import {
    IdPropertyListing,
    IdRentApplication,
    IdLeaseTerm,
    ICreditReportSummary,
    ITenantInfo,
} from 'realhaus-sdk';
import { ApplicantInviteForm } from '../../../components/prospectiveTenants/applicantInviteForm';
import { ProspectiveTabs } from './prospectiveTabs';

export const ProspectiveTenants: React.FC = observer(() => {
    const { listingStore, ratingStore, creditReportStore, uiStore } = useContext(RootStoreContext);
    const [listings, setListings] = useState<Map<string, IdPropertyListing>>(
        new Map<string, IdPropertyListing>(),
    );
    const [tenants, setTenants] = useState<Map<string, ITenantInfo>>(
        new Map<string, ITenantInfo>(),
    );
    const [rentApplications, setRentApplications] = useState<IdRentApplication[]>([]);
    const [leaseTerms, setLeaseTerms] = useState<Map<string, IdLeaseTerm>>(
        new Map<string, IdLeaseTerm>(),
    );
    const [rentApplicationScreeningModalOpen, setRentApplicationScreeningModalOpen] =
        React.useState<boolean>(false);
    const [selectedRentAppId, setSelectedRentAppId] = useState<string | undefined>();
    const [tenantRatings, setTenantRatings] = useState<Map<string, number>>(
        new Map<string, number>(),
    );
    const [tenantCreditScore, setTenantCreditScore] = useState<Map<string, ICreditReportSummary>>(
        new Map<string, ICreditReportSummary>(),
    );

    const tenantName = (tenantId: string) => {
        const tenant = tenants.get(tenantId);
        return !tenant ? null : `${tenant?.firstname} ${tenant?.lastname}`;
    };

    const tenantsIncome = (tenantId: string) => {
        const tenant = tenants.get(tenantId);
        return StringUtils.normalizeIncome(
            tenant?.occupation.income,
            tenant?.occupation.incomeType,
        );
    };

    const tenantStatus = (tenantId: string) => {
        const tenant = tenants.get(tenantId);
        return tenant?.occupation.status;
    };

    const listingAddress = (listingId: string) => {
        const property = listings.get(listingId);
        if (!property) {
            console.log('Property is not available.');
            return null;
        }

        // return property.title;
        return property.address;
    };

    const listingRentPrice = (listingId: string) => {
        const term = leaseTerms.get(listingId);
        if (!term) {
            console.log('There is no lease term available for this listing.');
            return 0;
        }

        return term.rentAmount;
    };

    useEffect(() => {
        const init = async () => {
            try {
                uiStore.showLoading();
                const lsArr = (await listingStore.getUserListings()) ?? [];
                const ls = new Map<string, IdPropertyListing>();
                const lsTerms = new Map<string, IdLeaseTerm>();
                await Promise.all(
                    lsArr?.map(async (l) => {
                        if (ls.has(l.listingId)) return;

                        ls.set(l.listingId, l);
                        // get leaseterm
                        const term = await listingStore.getListingTerm(l.listingId); //.then((term) => {
                        if (!!term) {
                            lsTerms.set(l.listingId, term);
                        }
                    }),
                );
                setLeaseTerms(lsTerms);
                setListings(ls);

                const rentApps = await listingStore.getApplicationsForListings(
                    lsArr.map((ls) => ls.listingId),
                );
                setRentApplications(rentApps);

                const tenants = await Promise.all(
                    rentApps.map(async (ra) => {
                        const tenant = await listingStore.getTenantInfoForApplication(ra.tenantId);
                        return Promise.resolve({ id: ra.tenantId, tenant: tenant });
                    }),
                );

                const tMap = new Map<string, ITenantInfo>();
                tenants.forEach((tenant) => {
                    if (!tMap.has(tenant.id) && !!tenant.tenant) {
                        tMap.set(tenant.id, tenant.tenant);
                    }
                });
                setTenants(tMap);

                const rMap = new Map<string, number>();
                const creditScoreMap = new Map<string, ICreditReportSummary>();
                await Promise.all(
                    tenants.map(async (tenant) => {
                        const calculatedRatings = await ratingStore.calculateTenantRating(
                            tenant.id,
                        );
                        if (calculatedRatings) {
                            rMap.set(tenant.id, calculatedRatings.rating);
                        }

                        const creditReport = await creditReportStore.getCreditReportSummary(
                            tenant.id,
                        );
                        if (creditReport) {
                            creditScoreMap.set(tenant.id, creditReport);
                        }
                    }),
                );
                setTenantRatings(rMap);
                setTenantCreditScore(creditScoreMap);
                uiStore.hideLoading();
            } catch (error) {
                uiStore.hideLoading();
            }
        };

        init();
    }, []);

    const handleRentSnapshotSelected = (rentAppId: string) => () => {
        // store selected rentAppid
        setSelectedRentAppId(rentAppId);
        setRentApplicationScreeningModalOpen(true);
    };
    const handleScreeningViewModalOpen = () => {
        if (!selectedRentAppId) return;
        setRentApplicationScreeningModalOpen(true);
    };

    const handleScreeningViewModalClose = () => {
        setRentApplicationScreeningModalOpen(false);
    };


    if (!uiStore.loading && rentApplications.length === 0) {
        return (
            <>
                <Box>
                    <Typography>You currently have no applications</Typography>
                </Box>
            </>
        );
    } else {
        return (
            <>
                {rentApplications.map((app) => (
                    <RentApplicationSnapshot
                        key={app.id}
                        name={tenantName(app.tenantId) ?? ''}
                        address={listingAddress(app.listingId)}
                        dateSubmitted={app.dateSubmitted}
                        moveinDate={app.application.moveinDate}
                        employmentStatus={tenantStatus(app.tenantId)}
                        income={tenantsIncome(app.tenantId)}
                        rentPrice={listingRentPrice(app.listingId)}
                        onSelected={handleRentSnapshotSelected(app.id)}
                        isActive={selectedRentAppId === app.id}
                        rating={tenantRatings.get(app.tenantId)}
                        credit={tenantCreditScore.get(app.tenantId)}
                    />
                ))}

                <RentApplicationScreeningComponent
                    rentApplicationId={selectedRentAppId}
                    isOpen={rentApplicationScreeningModalOpen}
                    onOpen={handleScreeningViewModalOpen}
                    onClose={handleScreeningViewModalClose}
                />
            </>
        );
    }
});

export const ProspectiveTenantsPage: React.FC = observer(() => {
    const [openInvite, setOpenInvite] = React.useState(false);

    const handleOpenInvite = () => {
        setOpenInvite(true);
    };

    const handleCloseInvite = () => {
        setOpenInvite(false);
    };

    return (
        <>
            <AdminPageLayout
                header={
                    <Grid container justifyContent='space-between'>
                        <PageTitle>Prospective Tenants</PageTitle>
                        <Button variant='outlined' color='primary' onClick={handleOpenInvite}>
                            <AddIcon />
                            Invite Applicant
                        </Button>
                    </Grid>
                }
                content={<ProspectiveTabs />}
            />
            <Dialog
                open={openInvite}
                onClose={handleCloseInvite}
                aria-labelledby='invite-modal'
                aria-describedby='invite-modal'
            >
                <ApplicantInviteForm handleCloseInvite={handleCloseInvite} />
            </Dialog>
        </>
    );
});
