import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Container,
    Grid,
    IconButton,
    Link,
    Typography,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import SearchIcon from '@material-ui/icons/Search';
import { HeroSection, HomeSection } from '../../components/uiComponents/UIComponents';
import { MainHeader } from '../../layouts/main/header';
import { MainFooter } from '../../layouts/main/footer';
import { SearchLocationAutocomplete } from '../../components/search/autocomplete';
import { useNavigate } from 'react-router-dom';
import { AutocompletePrediction } from 'realhaus-sdk';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { locationQuery } from '../../utils/address';
import { NegativeBanner } from '../../App';

const SearchContent: React.FC = observer(() => {
    const [place, setPlace] = useState<AutocompletePrediction | null>(null);
    const [selectedMoveinDate, setMoveinDateChange] = React.useState<Date>(new Date(Date.now()));
    const navigate = useNavigate();

    const locationSelected = (location: AutocompletePrediction | null) => {
        setPlace(location);
        console.log(location);
    };

    const searchProperties = () => {
        if (!place || !selectedMoveinDate) return;
        const query = `q=${locationQuery(place)}&moveIn=${selectedMoveinDate?.getTime()}`;
        console.log(`Searching for properties in ${query}`);
        navigate(`/search?${query}`);
    };

    const handleMoveinDateChange = (date: MaterialUiPickersDate) => {
        if (date) {
            setMoveinDateChange(date);
        }
    };

    return (
        <Box bgcolor='white' pt={3} pb={3} pl={5} pr={5} mb={2} mt={3} borderRadius='8px'>
            <form noValidate autoComplete='off'>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={7}>
                        <SearchLocationAutocomplete
                            label='Location'
                            onLocationSelected={locationSelected}
                        />
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent='space-around'>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant='inline'
                                    label='Move-In'
                                    autoOk
                                    format='MMMM dd, yyyy'
                                    id='movein-picker'
                                    value={selectedMoveinDate}
                                    minDate={new Date(Date.now())}
                                    onChange={handleMoveinDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'add date',
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <IconButton
                            type='submit'
                            aria-label='search'
                            color='primary'
                            href='#'
                            onClick={searchProperties}
                        >
                            <SearchIcon fontSize='large' />
                        </IconButton>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
});

export const HomePage: React.FC = observer(() => {
    const cities = [
        {
            img: 'img/featured_cities/vancouver.jpg',
            name: 'Vancouver',
            link: '/search?q=vancouver--bc',
        },
        {
            img: 'img/featured_cities/calgary.jpeg',
            name: 'Calgary',
            link: '/search?q=calgary--ab',
        },
        {
            img: 'img/featured_cities/edmonton.jpeg',
            name: 'Edmonton',
            link: '/search?q=edmonton--ab',
        },
        {
            img: 'img/featured_cities/reddeer.jpeg',
            name: 'Red Deer',
            link: '/search?q=red-deer--ab',
        },
        {
            img: 'img/featured_cities/toronto.jpeg',
            name: 'Toronto',
            link: '/search?q=toronto--on',
        },
        {
            img: 'img/featured_cities/mississauga.jpeg',
            name: 'Mississauga',
            link: '/search?q=mississauga--on',
        },
        {
            img: 'img/featured_cities/markham.jpeg',
            name: 'Markham',
            link: '/search?q=markham--on',
        },
        {
            img: 'img/featured_cities/hamilton.jpeg',
            name: 'Hamilton',
            link: '/search?q=hamilton--on',
        },
    ];

    return (
        <>
            <MainHeader colorWhite={false} elevateOnScroll={false} />
            <NegativeBanner />
            <HeroSection>
                <Container maxWidth='md'>
                    <Typography
                        variant='h2'
                        gutterBottom
                        align='center'
                        style={{ fontWeight: 'bold' }}
                    >
                        Find your New Home
                    </Typography>
                    {/* <Typography variant='h4' gutterBottom align='center'>
                        Search &middot; Apply &middot; Move In
                    </Typography> */}
                    <SearchContent />
                </Container>
            </HeroSection>
            <HomeSection>
                <Container maxWidth='md'>
                    <Grid container direction='column' spacing={2}>
                        <Grid item>
                            <Typography variant='h5' gutterBottom style={{ fontWeight: 'bold' }}>
                                Explore Rentals around You
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2}>
                                {cities.map((city) => (
                                    <Grid item xs={6} md={3} key={city.name}>
                                        <Link href={city.link} underline='none' color='inherit'>
                                            <Card>
                                                <CardMedia
                                                    component='img'
                                                    alt='green iguana'
                                                    height='140'
                                                    image={city.img}
                                                />
                                                <CardContent>
                                                    <Typography
                                                        align='center'
                                                        gutterBottom
                                                        variant='h5'
                                                        component='div'
                                                    >
                                                        {city.name}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Link>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </HomeSection>
            <MainFooter />
        </>
    );
});
