import React from 'react';
import { Icon } from '@material-ui/core';
const interacIcon = '../../../img/vendor_icons/InteracLogo.svg';
const stripeIcon = '../../../img/vendor_icons/Stripe_Logo.svg';
const paypalIcon = '../../../img/vendor_icons/PayPal.svg';

const SmallIconImage: React.FC<{ src: string }> = ({ src }) => {
    return (
        <Icon>
            <img src={src} alt='Icon' height={30} width={30} style={{ verticalAlign: 'middle' }} />
        </Icon>
    );
};

const LargeIconImage: React.FC<{ src: string }> = ({ src }) => {
    return (
        <Icon>
            <img src={src} alt='Icon' height={60} width={60} style={{ verticalAlign: 'middle' }} />
        </Icon>
    );
};

export const LargeInteracIcon: React.FC = () => {
    return <LargeIconImage src={interacIcon} />;
};

export const LargeStripeIcon: React.FC = () => {
    return <LargeIconImage src={stripeIcon} />;
};

export const LargePaypalIcon: React.FC = () => {
    return <LargeIconImage src={paypalIcon} />;
};

export const SmallInteracIcon: React.FC = () => {
    return <SmallIconImage src={interacIcon} />;
};

export const SmallStripeIcon: React.FC = () => {
    return <SmallIconImage src={stripeIcon} />;
};

export const SmallPayPalIcon: React.FC = () => {
    return <SmallIconImage src={paypalIcon} />;
};
