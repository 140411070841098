import React from 'react';
import { observer } from 'mobx-react-lite';
import { Title } from './styles';
import { Grid, InputLabel, MenuItem, TextField, Typography } from '@material-ui/core';
import { IAddress, provinces, ProvinceInfo, AutocompletePrediction } from 'realhaus-sdk';
import { SearchLocationAutocomplete } from '../search/autocomplete';
import { RootStoreContext } from '../../global/storeContext';
import { ToIAddress } from '../../utils/address';

interface PropertyAddressProps {
    address: IAddress;
    setAddress: (address: IAddress) => void;
}

export const PropertyAddress: React.FC<PropertyAddressProps> = observer(
    ({ address, setAddress }) => {
        const { googleMapStore } = React.useContext(RootStoreContext);

        const handleChange =
            (prop: keyof IAddress) => (event: React.ChangeEvent<{ value: unknown }>) => {
                setAddress({ ...address, [prop]: event.target.value });
            };

        const handleLocationChange = async (location: AutocompletePrediction | null) => {
            if (location && !!location.place_id) {
                await googleMapStore.apiService?.geocode(
                    {
                        placeId: location?.place_id,
                    },
                    (results) => {
                        if (results && results?.length > 0) {
                            const addr = ToIAddress(
                                results[0].address_components,
                                results[0].geometry,
                            );
                            addr.unit = address.unit;

                            setAddress(addr);
                        }
                    },
                );

                document.getElementById('unit')?.focus();
            }
        };

        return (
            <>
                <Title>
                    <Typography variant='h4' gutterBottom>
                        Let’s fill out some information about your property.
                    </Typography>
                </Title>
                <Typography variant='h5' gutterBottom>
                    Where is this property located?
                </Typography>
                <form noValidate autoComplete='off'>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel id='country-label'>Country</InputLabel>
                            <TextField
                                select
                                margin='normal'
                                id='country'
                                fullWidth
                                required
                                variant='outlined'
                                value={address.country ?? ''}
                                onChange={handleChange('country')}
                            >
                                <MenuItem value={'canada'}>Canada</MenuItem>
                            </TextField>
                        </Grid>
                        {/* Street */}
                        <Grid item xs={12}>
                            <InputLabel htmlFor='street'>Street</InputLabel>
                            <SearchLocationAutocomplete
                                onLocationSelected={handleLocationChange}
                                placeholder='e.g. 11108 108th Avenue'
                                outlined
                            />
                        </Grid>
                        {/* Unit */}
                        <Grid item xs={12}>
                            <InputLabel htmlFor='unit'>Apt, Suite (optional)</InputLabel>
                            <TextField
                                id='unit'
                                placeholder='Apt or Suite number i.e. 2401 or 20A'
                                fullWidth
                                margin='normal'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText='e.g. Suite #7'
                                variant='outlined'
                                value={address.unit}
                                onChange={handleChange('unit')}
                                required
                            />
                        </Grid>
                        {/* City */}
                        <Grid item xs={6}>
                            <InputLabel htmlFor='city'>City</InputLabel>
                            <TextField
                                id='city'
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                value={address.city}
                                onChange={handleChange('city')}
                                margin='normal'
                                required
                            />
                        </Grid>
                        {/* State */}
                        <Grid item xs={6}>
                            <InputLabel htmlFor='province'>Province</InputLabel>
                            <TextField
                                select
                                margin='normal'
                                id='province'
                                fullWidth
                                required
                                variant='outlined'
                                value={address.province ?? ''}
                                onChange={handleChange('province')}
                            >
                                {provinces.map((province: ProvinceInfo) => (
                                    <MenuItem key={province.name} value={province.abbreviation}>
                                        {province.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {/* Postal Code */}
                        <Grid item xs={6}>
                            <InputLabel htmlFor='post-code'>Postal Code</InputLabel>
                            <TextField
                                id='post-code'
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin='normal'
                                variant='outlined'
                                helperText='e.g. M2J 5N7'
                                value={address.postalCode}
                                onChange={handleChange('postalCode')}
                                required
                            />
                        </Grid>
                    </Grid>
                </form>
            </>
        );
    },
);
