import { createStyles, makeStyles, styled, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullHeight: {
      height: "100%"
    },
    content: {
      padding: "48px!important",
      maxHeight: "100vh!important",
      overflowY: "auto"
    }
  }));

export const Logo = styled('div')({
  top: '50px',
  left: '50px',
  position: 'relative'
})

export const ImgContainer = styled('div')((props: any) => ({
  height: '100vh',
  width: '100%',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundImage: `url(${props.backgroundUrl})`
}))

