import { Card, CardContent, Typography, CardActions, Button } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { BillStatus, BillType, IdBill } from 'realhaus-sdk';
import { RootStoreContext } from '../../../../global/storeContext';
import { paths } from '../../../../routes';
import { useStyles } from './style';

export const DueRentCardComponent: React.FC = observer(() => {
    const { leaseStore, uiStore } = useContext(RootStoreContext);
    const [overDueRent, setOverDueRent] = useState<IdBill[]>([]);
    const classes = useStyles();

    const fetchPastDueBills = async () => {
        const leaseAgreements = await leaseStore.getOwnerLeaseAgreements();
        if (!leaseAgreements) {
            return console.log('>> Landlord has no leaseAgreement');
        }
        const odBills = [];
        for (const leaseAgreement of leaseAgreements) {
            const leaseBills = await leaseStore.getLeaseBills(leaseAgreement.id);
            odBills.push(
                ...leaseBills.filter(
                    (bill) =>
                        bill.type === BillType.RENT &&
                        bill.status === BillStatus.PENDING &&
                        bill.dueDate < Date.now(),
                ),
            );
        }
        setOverDueRent(odBills);
        uiStore.hideLoading();
    };

    useEffect(() => {
        uiStore.showLoading();
        fetchPastDueBills();
    }, []);

    const viewLeaseDetails = () => {
        uiStore.goTo(paths.landlord.tenants);
    };
    return (
        <>
            <Card variant='elevation' className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <Typography variant='subtitle1' color='textSecondary'>
                        Late Payments
                    </Typography>
                    <Typography variant='h2' component='h2'>
                        {overDueRent.length}
                    </Typography>
                </CardContent>
                <CardActions className={classes.cardActions}>
                    <Button
                        size='small'
                        color='primary'
                        variant='outlined'
                        disabled={overDueRent.length === 0}
                        onClick={viewLeaseDetails}
                    >
                        Collect Rent
                    </Button>
                </CardActions>
            </Card>
        </>
    );
});
