import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, Typography } from '@material-ui/core';
import { RootStoreContext } from '../../../global/storeContext';
import { PropertyShowingTimeslotCard } from '../../../components/showings/showingTimeslotCards';
import { paths } from '../../../routes';
import { IDAppointment, IdShowingTimeslot } from 'realhaus-sdk';
import { AppointmentType } from 'realhaus-sdk/dist/enums/appointment';

export const TenantAppointments: React.FC = observer(() => {
    const { showingStore } = useContext(RootStoreContext);
    const [appointments, setAppointments] = useState<IDAppointment[]>([]);
    useEffect(() => {
        // get user appointments
        showingStore.getAppointments().then((appts) => {
            setAppointments(appts);
        });
    }, [showingStore]);

    const AppointmentCard = (props: { appointment: IDAppointment; onCancel: () => void }) => {
        const { appointment } = props;

        switch (appointment.type) {
            case AppointmentType.SHOWING:
                return <AppointmentShowingCard appointment={appointment} />;
            default:
                return null;
        }
    };

    const handleAppointmentCanceled = (id: string) => () => {
        setAppointments(appointments.filter((appt) => appt.id !== id));
    };

    return (
        <>
            <>
                <Typography variant='h6' gutterBottom>
                    Upcoming Appointments
                </Typography>
                <Grid container spacing={1}>
                    {appointments &&
                        appointments.map((appt: IDAppointment) => (
                            <Grid item xs={3} key={appt.id}>
                                <AppointmentCard
                                    appointment={appt}
                                    onCancel={handleAppointmentCanceled(appt.id)}
                                />
                            </Grid>
                        ))}
                    {!appointments || appointments.length === 0 ? (
                        <Grid item xs={12}>
                            <Typography variant='body1'>
                                You have no upcoming appointments!
                            </Typography>
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Grid>
            </>
        </>
    );
});

const AppointmentShowingCard = (props: { appointment: IDAppointment }) => {
    const { appointment } = props;
    const { showingStore, listingStore, uiStore } = useContext(RootStoreContext);
    const [showing, setShowing] = useState<IdShowingTimeslot | undefined>();
    const [propertyAddress, setPropertyAddress] = useState<string>('');

    useEffect(() => {
        showingStore
            .getShowingTimeslotByProperty(appointment.propertyId, appointment.timeslotId)
            .then((ts) => setShowing(ts));
        listingStore.getProperty(appointment.propertyId).then((property) => {
            const address = `${property.address.streetAddress}`;
            setPropertyAddress(address);
        });
    }, [appointment, showingStore, listingStore]);

    const cancelShowing = () => {
        showingStore
            .cancelReservedShowingByListing(appointment.listingId, appointment.timeslotId)
            .then(() => {
                uiStore.success('Your reservation has been cancelled');
            });
    };

    const viewListing = () => {
        const propertyUrl = paths.listings.detail.replace(':listingId', appointment.listingId);
        uiStore.goTo(propertyUrl);
    };

    return (
        <>
            {!!showing ? (
                <PropertyShowingTimeslotCard
                    timeslot={showing}
                    address={propertyAddress}
                    handleViewProperty={viewListing}
                    handleCancelTimeslot={cancelShowing}
                />
            ) : null}
        </>
    );
};
