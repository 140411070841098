import { createStyles, makeStyles, styled, Theme } from '@material-ui/core/styles';

export const PageContent = styled('div')({
  marginTop: '40px'
});


export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 600,
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    mobileView: {
      display: 'block',
      [theme.breakpoints.down(960)]: {
        display: 'none',
      },
    }
  }),
);