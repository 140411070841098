import { createStyles, makeStyles, styled, Theme } from '@material-ui/core/styles';

export const Header = styled('header')({
  width: '100% !important',
  height: '60px',
  position: 'fixed',
  left: '0px',
  zIndex: 100,
});

export const Footer = styled('footer')({
  width: '100% !important',
  backgroundColor: '#F7F7F7!important',
  borderTop: '1px solid #DDDDDD!important',
  margin: '0 auto !important',
  marginTop: '24px!important'
});

export const FooterCopyright = styled('div')({
  width: '100% !important',
  borderTop: '1px solid #DDDDDD!important',
  marginTop: '24px!important',
  padding: '16px 0px',
})

export const FooterContent = styled('section')({
  // paddingLeft: '24px',
  paddingRight: '24px'
});

export const FooterDescriptionContent = styled('div')({
  width: '100%',
  paddingBottom: '16px',
});

export const FooterLogo = styled('div')({
  paddingBottom: '16px'
});

export const HeaderContent = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  position: 'relative',
  width: '100%',
  zIndex: 1,
  maxWidth: '1440px',
  paddingLeft: '24px',
  paddingRight: '24px',
  margin: '0px auto',
})

export const HeaderNav = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '70px',
});

export const Spacing = styled('div')({
  flex: '1 1 auto',
  minWidth: '0px',
  padding: '0px 24px',
  textAlign: 'center',
})

export const Logo = styled('a')({
  outline: 'none',
  alignItems: 'center',
  display: 'inline-flex ',
  width: '168px',
  height: '40px',
  position: 'relative',
  verticalAlign: 'middle',
  zIndex: 1,
  textDecoration: 'none',
});

export const MainContent = styled('div')({
  flex: '1 0 100%',
  marginTop: '90px'
});

export const SearchBar = styled('div')({

});

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      background: 'transparent',
      color: theme.palette.common.black,
      // borderBottom: `1px solid ${theme.palette.grey[400]}`,
      // position: 'relative',
      display: 'flex',
      zIndex: 1000,
    },
    toolBar: {
      minHeight: 60,
      '& > div': {
        flexGrow: 1,
      },
      '& > div:nth-child(even)': {
        // width: '100%'
      }
    },
    colorWhite: {
      color: theme.palette.common.white,
    },
    colorBlack: {
      color: theme.palette.common.black,
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up(938)]: {
        display: 'none',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up(938)]: {
        display: 'flex',
      },
    },
  }),
);
