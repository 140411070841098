export const GooglePlacesApiKey = process.env.GOOGLE_PLACES_API_KEY || process.env.REACT_APP_GOOGLE_PLACES_API_KEY;
export const FlinksConnectUrl = process.env.FLINKS_CONNECT_URL || process.env.REACT_APP_FLINKS_CONNECT_URL;
export const PersonaTemplateId = process.env.PERSONA_TEMPLATE_ID || process.env.REACT_APP_PERSONA_TEMPLATE_ID;
export const PersonaEnvironment = (process.env.PERSONA_ENVIRONMENT || process.env.REACT_APP_PERSONA_ENVIRONMENT) === 'production' ? 'production' : 'sandbox';
export const StripePaymentLink = process.env.STRIPE_PAYMENT_LINK || process.env.REACT_APP_STRIPE_PAYMENT_LINK;
export const PaypalClientId = process.env.PAYPAL_CLIENT_ID || process.env.REACT_APP_PAYPAL_CLIENT_ID;
export const PaypalClientSecrete = process.env.PAYPAL_CLIENT_SECRETE || process.env.REACT_APP_PAYPAL_CLIENT_SECRETE;
export const PaypalTokenEndpoint = process.env.PAYPAL_TOKEN_ENDPOINT || process.env.REACT_APP_PAYPAL_TOKEN_ENDPOINT;
export const PaypalUserProfileEndpoint = process.env.PAYPAL_USERPROFILE_ENDPOINT || process.env.REACT_APP_PAYPAL_USERPROFILE_ENDPOINT;
export const LaunchDarklySDKClientID = process.env.LAUNCHDARKLY_SDK_CLIENTID || process.env.REACT_APP_LAUNCHDARKLY_SDK_CLIENTID;
export const UseFirebaseEmulator = (process.env.USE_FB_EMULATOR || process.env.REACT_APP_USE_FB_EMULATOR) === 'true';
export const FirebaseAuthPort = Number(process.env.FB_EMU_AUTH_PORT || process.env.REACT_APP_FB_EMU_AUTH_PORT || 9099);
export const FirebaseStoragePort = Number(process.env.FB_EMU_STORAGE_PORT || process.env.REACT_APP_FB_EMU_STORAGE_PORT || 9199);
export const FirebaseDBPort = Number(process.env.FB_EMU_DB_PORT || process.env.REACT_APP_FB_EMU_DB_PORT || 8081);
export const FirebaseFunctionsPort = Number(process.env.FB_EMU_FUNCTIONS_PORT || process.env.REACT_APP_FB_EMU_FUNCTIONS_PORT || 5001);

