import {
    Container,
    Grid,
    styled,
    Typography,
    Collapse as MuiCollapse,
    GridSpacing,
    useMediaQuery,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import {
    HeroContainer,
    HeroContainerContent,
    HeroContainerOverlay,
    HomeSectionContainer,
} from '../styles';
import { makeStyles } from '@material-ui/core/styles';
import { MainLayout } from '../../layouts/main/main';

const AdminPageContentStyle = styled('div')({
    marginTop: '0px',
});

const PageHeadStyle = styled('section')({
    marginBottom: '40px',
    display: 'flex',
});
export const AdminPageLayout: React.FC<{
    header?: React.ReactNode;
    content: React.ReactNode;
    maxWidth?: false | 'lg' | 'md' | 'sm' | 'xl' | 'xs' | undefined;
}> = observer(({ header, content, maxWidth }) => {
    return (
        <MainLayout>
            <AdminPageContent maxWidth={maxWidth}>
                <PageHead>{header}</PageHead>
                <GridContent>
                    <GridMainContent>{content}</GridMainContent>
                </GridContent>
            </AdminPageContent>
        </MainLayout>
    );
});
export const AdminPageContent: React.FC<{
    children?: React.ReactNode;
    maxWidth?: false | 'lg' | 'md' | 'sm' | 'xl' | 'xs' | undefined;
}> = observer(({ children, maxWidth = 'lg' }) => {
    return (
        <Container maxWidth={maxWidth}>
            <AdminPageContentStyle>{children}</AdminPageContentStyle>
        </Container>
    );
});

export const PageHead: React.FC<{ children?: React.ReactNode }> = observer(({ children }) => {
    return <PageHeadStyle>{children}</PageHeadStyle>;
});

export const PageTitle: React.FC<{ children?: React.ReactNode }> = observer(({ children }) => {
    return (
        <>
            <Typography variant='h5' gutterBottom>
                {children}
            </Typography>
        </>
    );
});

export const GridContent: React.FC<{ spacing?: GridSpacing; children?: React.ReactNode }> =
    observer(({ spacing = 1, children }) => {
        return (
            <>
                <Grid container spacing={spacing}>
                    {children}
                </Grid>
            </>
        );
    });

export const GridMainContent: React.FC<{
    children?: React.ReactNode;
    withSidebar?: boolean;
    spacing?: GridSpacing;
}> = observer(({ children, withSidebar, spacing = 1 }) => {
    return (
        <>
            <Grid container item xs={withSidebar ? 8 : 12} spacing={spacing}>
                {children}
            </Grid>
        </>
    );
});

export const GridSideContent: React.FC<{ children?: React.ReactNode }> = observer(
    ({ children }) => {
        return (
            <>
                <Grid container item xs={12} md={4}>
                    {children}
                </Grid>
            </>
        );
    },
);

export const HeroSection: React.FC<{
    children?: React.ReactNode;
}> = observer(({ children }) => {
    return (
        <HeroContainer>
            <HeroContainerOverlay>
                <HeroContainerContent>{children}</HeroContainerContent>
            </HeroContainerOverlay>
        </HeroContainer>
    );
});

export const HomeSection: React.FC<{
    children?: React.ReactNode;
}> = observer(({ children }) => {
    return <HomeSectionContainer>{children}</HomeSectionContainer>;
});
const useCollapseStyles = makeStyles({
    hidden: {
        padding: '0 !important',
    },
});

const GridItemXs12: React.FC = observer((props: { children?: React.ReactNode }) => (
    <Grid item xs={12} {...props} />
));

/**
 * This is a UI Component for a Collapsible Grid container.
 *  It combines the Components `Collapse` and `Grid` for reusability.
 */
export const GridCollapse: React.FC<{ children?: React.ReactNode }> = observer((props) => {
    const classes = useCollapseStyles();
    return (
        <MuiCollapse component={GridItemXs12} classes={{ hidden: classes.hidden }} {...props}>
            {/* This spacing has to match with the one in the container
            outside the collapse */}
            <Grid container spacing={0}>
                {props.children}
            </Grid>
        </MuiCollapse>
    );
});

export function useIsMobible() {
    const isMobile = useMediaQuery('(max-width:959px)');
    return isMobile;
}
