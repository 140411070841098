import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      marginBottom: theme.spacing(2),
    },
    divider: {
      margin: theme.spacing(2, 0)
    },
    applicationDrawer: {
      "& .MuiDrawer-paper": {
        [theme.breakpoints.up('md')]: {
          width: '60%',
        },
        [theme.breakpoints.down('md')]: {
          width: '90%',
        },
      }
    }
  }),
);