import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Link,
    TextField,
    Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { RootStoreContext } from '../../../global/storeContext';
import { ContentBody, ContentFooter, ContentHeader, RootContent } from '../styles';
import { AuthErrors } from '../../../constants/authErrors';
import { User } from 'firebase/auth';

interface ICredential {
    email: string;
    password: string;
}

export const Login: React.FC = observer(() => {
    const { userStore, uiStore } = useContext(RootStoreContext);
    const [credential, setCredential] = React.useState<ICredential>({
        email: '',
        password: '',
    });
    const [signinError, setSigninError] = React.useState<any>(undefined);
    const [signinMsg, setSigninMsg] = React.useState<string | undefined>(undefined);
    const [showSendVerificationLink, setShowSendVerificationLink] = React.useState(false);
    const [unverifiedUser, setUnverifiedUser] = React.useState<User>();
    const [openRecoverModal, setOpenRecoverModal] = React.useState<boolean>(false);

    const clearMessages = () => {
        setSigninError(undefined);
        setSigninMsg(undefined);
        setUnverifiedUser(undefined);
        setShowSendVerificationLink(false);
    };

    const handleOpenRecoverModal = () => {
        setOpenRecoverModal(true);
    };

    const handleCloseRecoverModal = () => {
        setOpenRecoverModal(false);
    };
    const handleChange =
        (prop: keyof ICredential) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setCredential({ ...credential, [prop]: event.target.value });
        };

    const handleLogin = (e: any) => {
        clearMessages();

        if (!credential.email || !credential.password) {
            setSigninError('Enter a valid email and password.');
            return;
        }

        uiStore.showLoading();
        userStore
            .login(credential.email, credential.password)
            .then(() => {
                uiStore.hideLoading();
            })
            .catch((err) => {
                uiStore.hideLoading();
                if (!!err?.code) {
                    const authErr = AuthErrors.find((au) => au.code === err.code);
                    if (!!authErr) {
                        setSigninError(authErr.message);
                    } else {
                        setSigninError(
                            'Uh-Oh! We were unable to sign into your account. Try again later',
                        );
                    }

                    if (authErr?.code === 'email-not-verified' && !!err.user) {
                        setUnverifiedUser(err.user);
                        // show resend Verification email button
                        setShowSendVerificationLink(true);
                    }
                }
            });
        e.preventDefault();
    };

    const handleSendVerificationLink = () => {
        if (!unverifiedUser) return;
        clearMessages();
        userStore.sendVerificationLink(unverifiedUser).then(() => {
            setSigninMsg(
                'A verification link has been sent to your email! Click the link sent to your email, then try login again',
            );
        });
    };

    return (
        <>
            <RootContent>
                <ContentHeader>
                    <Typography variant='h5' gutterBottom>
                        <b>Welcome back</b>
                    </Typography>
                    <Typography color='textSecondary' variant='body1' align='center'>
                        Please enter your credentials below
                    </Typography>
                </ContentHeader>
                <ContentBody>
                    <form noValidate autoComplete='off' onSubmit={handleLogin}>
                        <Card elevation={0}>
                            <CardContent>
                                <Grid container>
                                    {!!signinError ? (
                                        <Grid item xs={12}>
                                            <Box
                                                display='flex'
                                                alignItems='center'
                                                // padding='5px 0px'
                                            >
                                                <ErrorIcon fontSize='large' color='error' />
                                                &nbsp;&nbsp;
                                                <Typography color='textSecondary' variant='body1'>
                                                    {signinError}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    ) : null}
                                    {showSendVerificationLink ? (
                                        <Grid item xs={12}>
                                            <Box
                                                display='flex'
                                                alignItems='center'
                                                // padding='5px 0px'
                                            >
                                                <Typography color='textSecondary' variant='body1'>
                                                    Didn't receive a verification link?{' '}
                                                    <Button onClick={handleSendVerificationLink}>
                                                        Resend Verification Link
                                                    </Button>
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    ) : null}
                                    {!!signinMsg ? (
                                        <Grid item xs={12}>
                                            <Box display='flex' alignItems='center'>
                                                <Typography color='primary' variant='body1'>
                                                    {signinMsg}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    ) : null}
                                </Grid>
                                <Grid container spacing={2}>
                                    {/* Email */}
                                    <Grid item xs={12}>
                                        <TextField
                                            id='email'
                                            label='Email'
                                            placeholder='your@email.com'
                                            type='email'
                                            fullWidth
                                            margin='dense'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={credential.email}
                                            onChange={handleChange('email')}
                                            required
                                        />
                                    </Grid>

                                    {/* Password */}
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id='password'
                                            label='Password'
                                            placeholder='Password'
                                            type='password'
                                            margin='dense'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={credential.password}
                                            onChange={handleChange('password')}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align='right'>
                                            <Link
                                                variant='body2'
                                                style={{ cursor: 'pointer' }}
                                                onClick={handleOpenRecoverModal}
                                            >
                                                Forgot password?
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>

                            <div>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={handleLogin}
                                    disabled={!credential.email || !credential.password}
                                    type='submit'
                                    fullWidth
                                >
                                    Login
                                </Button>
                            </div>
                        </Card>
                    </form>
                </ContentBody>
                <Divider variant='middle' />
                <ContentFooter>
                    <Typography variant='subtitle1' gutterBottom>
                        Don't have an account?
                        <Button href='/signup' color='primary'>
                            Sign up!
                        </Button>
                    </Typography>
                    <Typography variant='body2' gutterBottom>
                        Your privacy is very important to us!
                        <br />
                        By logging in, you agree to abide by Realhaus's Terms of Use and acknowledge
                        our Privacy Policy.
                    </Typography>
                </ContentFooter>
                <RecoverPassword open={openRecoverModal} handleClose={handleCloseRecoverModal} />
            </RootContent>
        </>
    );
});

// forgot password modal
const RecoverPassword: React.FC<{ open: boolean; handleClose: () => void }> = observer(
    ({ open, handleClose }) => {
        const [email, setEmail] = React.useState<string>();
        const { userStore, uiStore } = React.useContext(RootStoreContext);
        const [recoverErr, setRecoverErr] = React.useState<string>();
        const [passwordResetSuccess, setPasswordResetSuccess] = React.useState<string | undefined>(
            undefined,
        );
        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value);
        };

        const clearMessages = () => {
            setPasswordResetSuccess(undefined);
            setRecoverErr(undefined);
        };

        const handleRecoverPassword = () => {
            if (!email) {
                setRecoverErr('Enter a valid email');
                return;
            }
            if (!!email) {
                uiStore.showLoading();
                userStore
                    .passwordReset(email)
                    .then(() => {
                        uiStore.hideLoading();
                        setPasswordResetSuccess(
                            'We have sent you an email with instructions to reset your password.',
                        );
                        setTimeout(() => {
                            handleClose();
                            clearMessages();
                        }, 3000);
                    })
                    .catch((err) => {
                        uiStore.hideLoading();
                        if (err.code === 'auth/user-not-found') {
                            setRecoverErr('A user with that email does not exist.');
                        }
                    });

                clearMessages();
            }
            return;
        };
        return (
            <>
                <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
                    <DialogTitle>Recover Password</DialogTitle>
                    <DialogContent>
                        <Grid container>
                            {!!passwordResetSuccess ? (
                                <Grid item xs={12}>
                                    <Box
                                        display='flex'
                                        alignItems='center'
                                        // padding='5px 0px'
                                    >
                                        <ErrorIcon fontSize='large' color='primary' />
                                        &nbsp;&nbsp;
                                        <Typography color='primary' variant='body1'>
                                            {passwordResetSuccess}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ) : null}

                            {!!recoverErr ? (
                                <Grid item xs={12}>
                                    <Box display='flex' alignItems='center'>
                                        <ErrorIcon fontSize='large' color='error' />
                                        &nbsp;&nbsp;
                                        <Typography color='error' variant='body1'>
                                            {recoverErr}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ) : null}
                            <Grid item xs={12}>
                                <form
                                    noValidate
                                    autoComplete='off'
                                    onSubmit={handleRecoverPassword}
                                >
                                    <TextField
                                        id='recoverPassword'
                                        label='Email'
                                        placeholder='your@email.com'
                                        type='email'
                                        fullWidth
                                        margin='dense'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={email}
                                        onChange={handleChange}
                                        required
                                    />
                                </form>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleRecoverPassword}
                            disabled={!email}
                            type='submit'
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    },
);
