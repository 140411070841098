import { BankAccountPurpose, IBillRequest, IExchangePlaidLinkTokenRequest } from 'realhaus-sdk';
import { firebaseConfig } from '../firebase.config';

interface IApiRequestParameters<T> {
  token: string;
  body: T;
};

interface IAddTenantLeasePayload {
  leaseId: string;
}

interface IBankAccountPurposeRequest {
  bankAccountPurpose: BankAccountPurpose;
}

interface IGetBankAccountsByIdRequest {
  id: string;
}

interface IWithdrawFundsRequest {
  amount: number;
}

export const requestPayBill = async (request: IApiRequestParameters<IBillRequest>) => {
  const { token, body } = request;
  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/wallet/paybill`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify({ ...body })
      }
    );
    return response.status === 200;
  } catch (ex) {
    console.error('An error occurred while processing bill payment!');
  }
  return false;
};

export const requestWithdrawFunds = async (request: IApiRequestParameters<IWithdrawFundsRequest>) => {
  const { token, body } = request;

  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/wallet/withdrawFunds`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify({ ...body })
      }
    );
    return response.status === 200;
  } catch (err) {
    console.error(err);
    throw new Error('Failed to initiate fund withdrawal process');
  }

}

export const requestAddTenantFromInviteToLease = async (request: IApiRequestParameters<IAddTenantLeasePayload>) => {
  const { token, body } = request;
  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/lease/addTenantToLease`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify({ ...body })
      }
    );
    return response.status === 200;
  } catch (ex) {
    console.error('An error occurred while adding tenant to lease agreement!');
  }
  return false;
};

interface IFundWalletInteracResponse {
  paymentUrl: string;
}
export const requestFundWalletViaInterac = async (req: IApiRequestParameters<{ amount: number }>): Promise<IFundWalletInteracResponse | undefined> => {

  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/interac/fundWallet`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req.token}`,
      },
      method: 'POST',
      body: JSON.stringify({ ...req.body })
    });

    if (response.ok) {
      return (await response.json()) as IFundWalletInteracResponse;
    } else {
      console.error(`FundWallet Response ${response.status}`, { responseStatus: response.status, responseText: response.statusText });
      console.error(response.json());
    }
  } catch (err) {
    console.error(err);
    throw new Error("Failed to initiate fund wallet process via Interac");
  }

};
export const getPlaidLinkToken = async (token: string) => {
  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/plaid/create-token`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'GET',
      }
    );
    if (response.status === 200) {
      return response
    }
  } catch (ex) {
  }
  return false;
};

export const exchangePlaidPublicToken = async (request: IApiRequestParameters<IExchangePlaidLinkTokenRequest>) => {
  const { token, body } = request;
  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/plaid/exchange-token`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify({ ...body })
      }
    );
    if (response.status === 200) {
      return response
    }
  } catch (ex) {
  }
  return false;
};

export const removeBankAccountConnection = async (request: IApiRequestParameters<IBankAccountPurposeRequest>) => {
  const { token, body } = request;
  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/plaid/remove-bank-connection`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify({ ...body })
      }
    );
    if (response.status === 200) {
      return response
    }
  } catch (ex) {
  }
  return false;
};

export const getBankAccounts = async (request: IApiRequestParameters<IBankAccountPurposeRequest>) => {
  const { token, body } = request;
  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/plaid/get-bank-account-connection`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify({ ...body })
      }
    );
    if (response.status === 200) {
      return response
    }
  } catch (ex) {
  }
  return false;
};

export const getBankAccountsByIdHandler = async (request: IApiRequestParameters<IGetBankAccountsByIdRequest>) => {
  const { token, body } = request;
  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/plaid/get-bank-account-by-id`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        body: JSON.stringify({ ...body })
      }
    );
    if (response.status === 200) {
      return response
    }
  } catch (ex) {
  }
  return false;
};


interface ISendMoney {
  listingId: string;
  tenantId: string;
  amount: number;
  notes: string;
}

export const sendMoneyToTenantRequest = async (request: IApiRequestParameters<ISendMoney>) => {
  const { token, body } = request;
  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/lease/sendMoney`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify({ ...body }),
    })
    return response.status === 200
  }
  catch (err) {
    console.log(err);
    console.error('An error occured while trying to send money to tenent');
  }
}

interface IRequestPayment {
  listingId: string;
  leaseId: string;
  tenantId: string;
  dueDate: number;
  amount: number;
  reason: string;
  notes: string;
  attachments?: string[]
}

export const requestPaymentFromTenant = async (req: IApiRequestParameters<IRequestPayment>) => {
  const { token, body } = req;

  try {
    const response = await fetch(`${firebaseConfig.cloudFunctionApiURL}/lease/requestPayment`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify({ ...body })
    })

    return response.status === 200
  }
  catch (err) {
    console.error(err);
    console.error('An error occured while trying to request for payment')
  }

}
