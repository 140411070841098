import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { UpsertProperty } from '../../../components/createProperty/upsertProperty';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { MainLayout } from '../../../layouts/main/main';
import { useStyles } from './styles';
import { Spacing } from '../../styles';
import {
    AdminPageContent,
    GridContent,
    GridMainContent,
    GridSideContent,
    PageHead,
    PageTitle,
} from '../../../components/uiComponents/UIComponents';
import { RootStoreContext } from '../../../global/storeContext';
import { paths } from '../../../routes';
import { PropertyTerms } from '../../../components/manageProperty/terms';
import { PropertyFeatures } from '../../../components/manageProperty/features';
import { PropertyShowings } from '../../../components/manageProperty/showings';
import { IdPropertyListing } from 'realhaus-sdk';

export const ManageListingPage: React.FC = observer(() => {
    const classes = useStyles();
    const { uiStore, listingStore } = useContext(RootStoreContext);

    const { listingId, pageId } = useParams<{ listingId: string; pageId: string }>();
    const [listing, setListing] = React.useState<IdPropertyListing>();

    useEffect(() => {
        if (!!listingId) {
            // get listing
            listingStore.getListing(listingId).then((l) => {
                setListing(l);
            });
        }
    }, [listingId, listingStore]);

    const pageComponent = (pageId: string) => {
        if (!listingId) return;
        switch (pageId) {
            case 'details': {
                // get listing
                return <UpsertProperty listingId={listingId} />;
            }
            case 'features': {
                return <PropertyFeatures listingId={listingId} />;
            }
            case 'terms': {
                return <PropertyTerms listingId={listingId} />;
            }
            case 'showings':
            default: {
                return !listing?.propertyId ? null : (
                    <PropertyShowings listingId={listing?.propertyId} />
                );
            }
        }
    };

    const sidebarTitle = (text: string) => {
        return (
            <React.Fragment>
                <Typography
                    component='span'
                    variant='subtitle1'
                    color='textPrimary'
                    className={classes.padLeft}
                >
                    {text}
                </Typography>
            </React.Fragment>
        );
    };

    const pageTo = (page: string) => () => {
        if (!listingId) return;
        uiStore.goTo(
            paths.landlord.editListing.replace(':listingId', listingId).replace(':pageId', page),
        );
    };

    return (
        <>
            <MainLayout>
                <AdminPageContent>
                    <PageHead>
                        <div>
                            <PageTitle>{listing?.title}</PageTitle>
                        </div>
                        <Spacing />
                        <div></div>
                    </PageHead>
                    <GridContent>
                        <GridSideContent>
                            <div className={classes.sidebar}>
                                <List component='nav' aria-labelledby='nested-list-subheader'>
                                    <ListItem
                                        selected={pageId === 'terms'}
                                        button
                                        onClick={pageTo('terms')}
                                    >
                                        <ListItemText primary={sidebarTitle('Terms')} />
                                    </ListItem>
                                    <ListItem
                                        button
                                        selected={pageId === 'details'}
                                        onClick={pageTo('details')}
                                    >
                                        <ListItemText primary={sidebarTitle('Property Info')} />
                                    </ListItem>
                                    <ListItem
                                        button
                                        selected={pageId === 'features'}
                                        onClick={pageTo('features')}
                                    >
                                        <ListItemText primary={sidebarTitle('Features')} />
                                    </ListItem>
                                    <ListItem
                                        button
                                        selected={pageId === 'showings'}
                                        onClick={pageTo('showings')}
                                    >
                                        <ListItemText primary={sidebarTitle('Showings')} />
                                    </ListItem>
                                </List>
                            </div>
                        </GridSideContent>
                        <GridMainContent withSidebar={true}>
                            {pageId && pageComponent(pageId)}
                        </GridMainContent>
                    </GridContent>
                </AdminPageContent>
            </MainLayout>
        </>
    );
});
