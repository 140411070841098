import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Grid,
    Typography,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';
import PinDropIcon from '@material-ui/icons/PinDrop';
import { format } from 'date-fns';
import { ShowingTimeslot, IdShowingTimeslot } from 'realhaus-sdk';
import { getRootStyles } from './styles';
import clsx from 'clsx';
import { RootStoreContext } from '../../global/storeContext';

interface ShowingTimeslotProps {
    timeslot: IdShowingTimeslot;
}
interface ShowingTimeslotCardProps extends ShowingTimeslotProps {
    handleDeleteTimeslot?: () => void;
    handleCancelTimeslot?: () => void;
}

interface SelectableShowingTimeslotCardProps extends ShowingTimeslotProps {
    selected: boolean;
    onClicked: (timeslot: IdShowingTimeslot) => () => void;
}
interface PropertyShowingTimeslotCardPropos extends ShowingTimeslotProps {
    address: string;
    handleViewProperty?: () => void;
    handleCancelTimeslot?: () => void;
}
export const ShowingTimeslotCard: React.FC<ShowingTimeslotCardProps> = observer(
    ({ timeslot, handleDeleteTimeslot, handleCancelTimeslot }) => {
        const classes = getRootStyles();
        const { userStore } = useContext(RootStoreContext);

        return (
            <Card
                variant='outlined'
                className={classes.timeslotCard}
                style={{ padding: '16px 5px' }}
            >
                <TimeslotContent
                    timeslot={timeslot}
                    showIcon={!!handleCancelTimeslot}
                    showReservedBy={
                        !!timeslot.reservedBy &&
                        !!userStore.userId &&
                        userStore.userId !== timeslot.reservedBy.id
                    }
                />

                <CardActions hidden={!!handleDeleteTimeslot && !!handleCancelTimeslot}>
                    {!!handleDeleteTimeslot ? (
                        <Button size='small' onClick={handleDeleteTimeslot}>
                            Delete
                        </Button>
                    ) : null}
                    {!!handleCancelTimeslot ? (
                        <Button size='small' color='secondary' onClick={handleCancelTimeslot}>
                            Cancel
                        </Button>
                    ) : null}
                </CardActions>
            </Card>
        );
    },
);

export const PropertyShowingTimeslotCard: React.FC<PropertyShowingTimeslotCardPropos> = observer(
    ({ timeslot, address, handleViewProperty, handleCancelTimeslot }) => {
        const classes = getRootStyles();
        const { userStore } = useContext(RootStoreContext);

        return (
            <Card
                variant='outlined'
                className={classes.timeslotCard}
                style={{ padding: '16px 5px' }}
            >
                <TimeslotContent
                    timeslot={timeslot}
                    showIcon={!!handleCancelTimeslot}
                    showReservedBy={
                        !!timeslot.reservedBy &&
                        !!userStore.userId &&
                        userStore.userId !== timeslot.reservedBy.id
                    }
                    location={address}
                />

                <CardActions hidden={!!handleViewProperty && !!handleCancelTimeslot}>
                    {!!handleViewProperty ? (
                        <Button size='small' onClick={handleViewProperty}>
                            View
                        </Button>
                    ) : null}
                    {!!handleCancelTimeslot ? (
                        <Button size='small' color='secondary' onClick={handleCancelTimeslot}>
                            Cancel
                        </Button>
                    ) : null}
                </CardActions>
            </Card>
        );
    },
);
export const UpcomingShowingTimeslotCard: React.FC<PropertyShowingTimeslotCardPropos> = observer(
    ({ timeslot, address, handleViewProperty, handleCancelTimeslot }) => {
        const classes = getRootStyles();
        const { userStore } = useContext(RootStoreContext);

        return (
            <Card
                variant='outlined'
                className={classes.timeslotCard}
                style={{ padding: '16px 5px' }}
            >
                <UpcomingTimeslotContent
                    timeslot={timeslot}
                    showIcon={!!handleCancelTimeslot}
                    showReservedBy={
                        !!timeslot.reservedBy &&
                        !!userStore.userId &&
                        userStore.userId !== timeslot.reservedBy.id
                    }
                    location={address}
                />

                <CardActions hidden={!!handleViewProperty && !!handleCancelTimeslot}>
                    {!!handleViewProperty ? (
                        <Button size='small' onClick={handleViewProperty}>
                            View
                        </Button>
                    ) : null}
                    {!!handleCancelTimeslot ? (
                        <Button size='small' color='secondary' onClick={handleCancelTimeslot}>
                            Cancel
                        </Button>
                    ) : null}
                </CardActions>
            </Card>
        );
    },
);

export const SelectableShowingTimeslotCard: React.FC<SelectableShowingTimeslotCardProps> = observer(
    ({ timeslot, selected, onClicked }) => {
        const classes = getRootStyles();

        return (
            <Card
                variant='outlined'
                className={clsx(classes.timeslotCard, {
                    [classes.timeslotSelected]: selected,
                })}
                onClick={onClicked(timeslot)}
                style={{ padding: '16px 5px' }}
            >
                <CardActionArea>
                    <TimeslotContent timeslot={timeslot} showIcon={true} />
                </CardActionArea>
            </Card>
        );
    },
);

const TimeslotContent = (props: {
    timeslot: { id: string } & ShowingTimeslot;
    showIcon?: boolean;
    showReservedBy?: boolean;
    location?: string;
}) => {
    const classes = getRootStyles();
    const { timeslot, showIcon, location, showReservedBy } = props;
    return (
        <CardContent className={classes.alignCenter}>
            {!!showIcon ? (
                <Typography gutterBottom>
                    <EventIcon />
                </Typography>
            ) : null}
            <Typography variant='body1'>{format(timeslot.timestamp, 'PP')}</Typography>
            <Typography variant='body1' color='textSecondary'>
                {format(timeslot.timestamp, 'p')}
            </Typography>
            <Typography variant='body1' color='textSecondary'>
                {timeslot.duration} minutes
            </Typography>
            {!!showReservedBy && !!timeslot.reservedBy ? (
                <Grid container alignItems='center' justifyContent='center' spacing={1}>
                    <Grid item>
                        <PersonIcon color='primary' fontSize='small' />
                    </Grid>
                    <Grid item>
                        <Typography variant='button' color='primary' display='block'>
                            {timeslot.reservedBy?.name}
                        </Typography>
                    </Grid>
                </Grid>
            ) : null}
            {!!location ? (
                <Grid container alignItems='center' justifyContent='center' spacing={1}>
                    <Grid item>
                        <PinDropIcon color='primary' fontSize='small' />
                    </Grid>
                    <Grid item>
                        <Typography variant='button' color='primary' display='block'>
                            {location}
                        </Typography>
                    </Grid>
                </Grid>
            ) : null}
        </CardContent>
    );
};
const UpcomingTimeslotContent = (props: {
    timeslot: { id: string } & ShowingTimeslot;
    showIcon?: boolean;
    showReservedBy?: boolean;
    location?: string;
}) => {
    const classes = getRootStyles();
    const { timeslot, showIcon, location, showReservedBy } = props;
    return (
        <CardContent className={classes.alignCenter}>
            {!!showIcon ? (
                <Typography gutterBottom>
                    <EventIcon />
                </Typography>
            ) : null}
            <Typography variant='body1'>{format(timeslot.timestamp, 'PP')}</Typography>
            <Typography variant='body1' color='textSecondary'>
                {format(timeslot.timestamp, 'p')}
            </Typography>
            <Typography variant='body1' color='textSecondary'>
                {timeslot.duration} minutes
            </Typography>
            {!!location ? (
                <Grid container alignItems='center' justifyContent='center' spacing={1}>
                    <Grid item>
                        <PinDropIcon color='primary' fontSize='small' />
                    </Grid>
                    <Grid item>
                        <Typography variant='button' color='primary' display='block' gutterBottom>
                            {location}
                        </Typography>
                    </Grid>
                </Grid>
            ) : null}
            <br />
            {!!showReservedBy && !!timeslot.reservedBy ? (
                <Grid container alignItems='center' justifyContent='center' spacing={1}>
                    <Grid item>
                        <Typography variant='button' color='textSecondary' display='block'>
                            {timeslot.reservedBy?.name}
                        </Typography>
                    </Grid>
                </Grid>
            ) : null}
        </CardContent>
    );
};

// export const ReservedTimeslotCard = (props: {
//     timeslot: { id: string } & ShowingTimeslot;
//     handleCancelTimeslot: (timeslotId: string) => () => void;
// }) => {
//     const classes = getRootStyles();
//     const { timeslot, handleCancelTimeslot } = props;

//     return (
//         <>
//             <Grid item xs={4}>
//                 <Card variant='outlined' className={classes.timeslotCard}>
//                     <CardContent className={classes.alignCenter}>
//                         <Typography gutterBottom>
//                             <EventIcon fontSize='large' />
//                         </Typography>
//                         <Typography variant='body1'>{format(timeslot.timestamp, 'PP')}</Typography>
//                         <Typography variant='body1' color='textSecondary'>
//                             {format(timeslot.timestamp, 'p')}
//                         </Typography>
//                         <Typography variant='body1' color='textSecondary'>
//                             {timeslot.duration} minutes
//                         </Typography>
//                         <Grid container alignItems='center' justifyContent='center' spacing={1}>
//                             <Grid item>
//                                 <PersonIcon color='primary' fontSize='small' />
//                             </Grid>
//                             <Grid item>
//                                 <Typography variant='button' color='primary' display='block'>
//                                     {timeslot.reservedBy?.name}
//                                 </Typography>
//                             </Grid>
//                         </Grid>
//                     </CardContent>
//                     <CardActions>
//                         <Button
//                             size='small'
//                             color='secondary'
//                             onClick={handleCancelTimeslot(timeslot.id)}
//                         >
//                             Cancel
//                         </Button>
//                     </CardActions>
//                 </Card>
//             </Grid>
//         </>
//     );
// };
