import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { IEmergencyContact } from 'realhaus-sdk';
import { InputField } from '../formComponents/inputfield';
import { InputFieldPhone } from '../formComponents/inputfieldPhone';
import { InputVariant } from '../../enums/InputVariant';

interface emergencyContactProps {
    profile: IEmergencyContact;
    updateProfile: (prop: keyof IEmergencyContact) => (value: any) => void;
}

export const TenantEmergencyContact: React.FC<emergencyContactProps> = observer(
    ({ profile, updateProfile }) => {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant='subtitle2' gutterBottom>
                            Name
                        </Typography>
                        <InputField
                            id='name'
                            placeholder='name'
                            fullWidth
                            variant={InputVariant.STANDARD}
                            margin='dense'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={profile?.name ?? ''}
                            onChange={updateProfile('name')}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle2' gutterBottom>
                            Email
                        </Typography>
                        <InputField
                            id='email'
                            placeholder='email'
                            fullWidth
                            variant={InputVariant.STANDARD}
                            margin='dense'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={profile?.email ?? ''}
                            onChange={updateProfile('email')}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='subtitle2' gutterBottom>
                            Phone Number
                        </Typography>
                        <InputFieldPhone
                            id='phoneNumber'
                            fullWidth
                            variant={InputVariant.STANDARD}
                            value={profile?.phone ?? ''}
                            onChange={updateProfile('phone')}
                        />
                    </Grid>
                </Grid>
            </>
        );
    },
);
