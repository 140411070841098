import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    // height: '100%',
    padding: '15px 0px',
  },
  title: {
    fontSize: 14,
  },
  cardContent: {
    textAlign: 'center',
  },
  cardActions: {
    justifyContent: 'center',
  },
});