import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { RootStoreContext } from '../../../global/storeContext';

export const PaypalButton: React.FC<{ amount: string; onComplete: () => void }> = observer(
    ({ amount, onComplete }) => {
        const [{ isPending }] = usePayPalScriptReducer();
        const { uiStore, userStore } = React.useContext(RootStoreContext);
        return (
            <>
                {isPending ? uiStore.loading : null}
                <PayPalButtons
                    createOrder={(_data, actions) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: 'CAD',
                                        value: amount,
                                        breakdown: {
                                            item_total: { currency_code: 'CAD', value: amount },
                                        },
                                    },
                                    custom_id: userStore.userInfo?.uid, // retain user info here
                                },
                            ],
                            intent: 'CAPTURE',
                        });
                    }}
                    onApprove={(_data, actions) => {
                        if (!actions.order) return Promise.resolve();
                        return actions.order.capture().then((details: any) => {
                            const amountFunded = `${details.purchase_units[0].amount.value} ${details.purchase_units[0].amount.currency_code}`;
                            uiStore.success(`Your Wallet has been funded by ${amountFunded}`);
                            onComplete();
                        });
                    }}
                />
            </>
        );
    },
);
