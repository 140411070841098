import { Popper, Card, CardContent, Grid, Typography, CardMedia } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { IdPricedListingProperty, IRatings } from 'realhaus-sdk';
import StringUtils from '../../utils/string';
import { useStyles } from './styles';
import ClearIcon from '@material-ui/icons/Clear';
import StarIcon from '@material-ui/icons/Star';

export const MapDropDetails: React.FC<{
    popperId: string | undefined;
    open: boolean;
    anchorE1: HTMLElement | null;
    listing: IdPricedListingProperty;
    rating: IRatings | undefined;
    maxWidth: string;
    maxHeight?: string;
    handleClose: () => void;
}> = observer(({ popperId, open, anchorE1, listing, handleClose, rating, maxWidth, maxHeight }) => {
    const classes = useStyles();

    return (
        <>
            <Popper id={popperId} open={open} anchorEl={anchorE1}>
                <Card
                    className={classes.popupPaper}
                    style={{ maxWidth: `${maxWidth}`, maxHeight: `${maxHeight}` }}
                >
                    <CardContent>
                        <Grid container>
                            <Grid item xs={11}>
                                <Typography variant='h6' className={classes.ellipsis}>
                                    {listing.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <ClearIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                            </Grid>
                        </Grid>

                        <Typography variant='subtitle1'>
                            {`${listing?.address.unit ? `${listing?.address.unit} ` : ''}${
                                listing?.address.streetAddress
                            }, ${listing?.address.city}, ${listing?.address.province}`}
                        </Typography>
                    </CardContent>

                    <CardMedia
                        className={classes.listingImage}
                        image={listing.photos[0].url}
                    ></CardMedia>
                    <CardContent>
                        <Typography variant='h6'>
                            {StringUtils.asCurrency(listing.rentAmount ?? 0)}
                        </Typography>
                        <Typography variant='subtitle1' color='textSecondary'>
                            Available on {new Date(listing.dateAvailable).toDateString()}
                        </Typography>
                        <Typography variant='subtitle1' color='textSecondary'>
                            {`${listing?.bedrooms} bed`} . {`${listing?.bathrooms} bath`} .{' '}
                            {`${listing?.size} sq.ft.`}
                        </Typography>
                        <Typography variant='subtitle1' color='textSecondary'>
                            {rating?.rating ? (
                                <>
                                    <StarIcon
                                        fontSize='small'
                                        style={{ marginBottom: '-2px', color: 'red' }}
                                    />
                                    {rating.rating.toFixed(2)}
                                </>
                            ) : (
                                'No Ratings'
                            )}{' '}
                            ( {rating?.reviews.length || 'No'} Reviews)
                        </Typography>
                    </CardContent>
                </Card>
            </Popper>
        </>
    );
});
