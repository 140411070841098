import React from 'react';
import { observer } from 'mobx-react-lite';
import { Typography } from '@material-ui/core';

export const TermsOfServiceComponent: React.FC = observer(() => {
    return (
        <>
            <div className='ordered-bullets' style={{ paddingBottom: '50px' }}>
                <Typography variant='body1' align='justify' gutterBottom>
                    Thank you for using Realhaus.
                </Typography>
                <Typography variant='body1' align='justify' gutterBottom display='block'>
                    These Terms and Services ("<b>Terms</b>") govern your access to and use of the
                    Realhaus Platform ("<b>Platform</b>"), owned and operated by Realhaus
                    Technologies Inc. ("<b>Company</b>", "<b>we</b>", "<b>us</b>", or "<b>our</b>").
                    Realhaus's mission is to provide landlords and tenants with a rental property
                    listing and management platform that improves efficiency, communication, and
                    cost savings while elevating the tenant-landlord relationship experience.
                </Typography>
                <Typography variant='body1' align='justify' gutterBottom display='block'>
                    The Realhaus Platform offers an online venue that enables users (“Members”) to
                    publish, offer, search for, manage, and book services. Members who publish and
                    offer services are <b>“Landlords</b>,” Members who search for, reserve, lease,
                    or use services are <b>“Tenants</b>.” You must register an account to access and
                    use many features of the Realhaus Platform, and must keep your account
                    information accurate.
                </Typography>
                <Typography variant='body1' align='justify' gutterBottom display='block'>
                    By accessing or using the Platform, you ("<b>User</b>", "<b>you</b>", or "
                    <b>your</b>") agree to be bound by these Terms, which establishes a contractual
                    relationship between you and the Company. If you do not agree to these Terms,
                    you may not access or use the Platform.
                </Typography>
                <Typography variant='body1' align='justify' gutterBottom display='block'>
                    Our collection and use of personal information in connection with your access to
                    and use of the Realhaus Platform is described in our Privacy Policy.
                </Typography>
                <ol>
                    <li>
                        <Typography variant='h6' gutterBottom display='inline'>
                            Definitions
                        </Typography>
                        <ol>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    "Tenant" refers to a user who intends to lease or is leasing a
                                    property through the Platform.
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    "Landlord" refers to a user who owns or manages a property that
                                    can be leased through the Platform.
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    "Property" refers to real estate listed and managed on the
                                    Platform.
                                </Typography>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant='h6' gutterBottom display='inline'>
                            Eligibility
                        </Typography>
                        <Typography variant='body1' align='justify' gutterBottom display='block'>
                            To access or use the Platform, you must be at least 18 years of age and
                            have the authority to enter into these Terms. By using the Platform, you
                            represent and warrant that you meet these requirements.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='h6' gutterBottom display='inline'>
                            Registration and Account
                        </Typography>
                        <ol>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    To use most features of the Platform, you may be required to
                                    register for an account ("Account"). You agree to provide
                                    accurate, current, and complete information during the
                                    registration process and to update such information to keep it
                                    accurate, current, and complete.
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    You are responsible for maintaining the confidentiality of your
                                    Account login information and are fully responsible for all
                                    activities that occur under your Account. You agree to
                                    immediately notify the Company of any unauthorized use, or
                                    suspected unauthorized use, of your Account or any other breach
                                    of security.
                                </Typography>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant='h6' gutterBottom display='inline'>
                            Platform Services
                        </Typography>
                        <ol>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    The Platform enables Tenants to find, view, and lease Properties
                                    and enables Landlords to list, manage, and lease their
                                    Properties. The Company does not own, sell, rent, or lease any
                                    Properties and does not act as a real estate agent, broker, or
                                    insurer.
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    Realhaus offers an end-to-end property listing, management,
                                    review, and rent payment platform in Canada, aiming to make
                                    long-term renting easy, enjoyable, and safe.
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    Landlords can access Tenant's past rental history to make
                                    informed decisions, while Tenants can view Landlord ratings
                                    before submitting rental applications.
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    While the Company endeavors to provide accurate and up-to-date
                                    information on the Platform, it does not guarantee the accuracy,
                                    completeness, or suitability of any Property listings or other
                                    information provided.
                                </Typography>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant='h6' gutterBottom display='inline'>
                            Tenant and Landlord Responsibilities
                        </Typography>
                        <ol>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    Tenants are responsible for conducting their own due diligence,
                                    including inspecting Properties and reviewing applicable lease
                                    terms before entering into a lease agreement.
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    Landlords are responsible for ensuring that their Properties
                                    comply with all applicable laws, regulations, and codes, and for
                                    providing accurate and up-to-date information about their
                                    Properties on the Platform.
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    Both Tenants and Landlords agree to comply with all applicable
                                    laws and regulations in their use of the Platform and to resolve
                                    any disputes between themselves directly, without involving the
                                    Company.
                                </Typography>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant='h6' gutterBottom display='inline'>
                            Fees
                        </Typography>
                        <ol>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    Access to and use of the Platform may be subject to fees, as
                                    determined by the Company. The Company reserves the right to
                                    modify the fees at any time, and any such changes will be posted
                                    on the Platform and become effective upon posting.
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    Realhaus is not a financial company and as such, financial
                                    transactions in and out of the platorm are subject to the
                                    payment vendor transaction fees which is the responsibility of
                                    the user.
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    Tenants and Landlords are responsible for paying any applicable
                                    fees for their use of the Platform. Failure to pay such fees may
                                    result in the suspension or termination of your access to the
                                    Platform.
                                </Typography>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant='h6' gutterBottom display='inline'>
                            Content and Intellectual Property
                        </Typography>
                        <ol>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    The Platform and its content, including but not limited to text,
                                    images, graphics, logos, software, and code, are protected by
                                    copyright, trademark, and other intellectual property laws. The
                                    Company grants you a limited, non-exclusive, non-transferable,
                                    and revocable license to access and use the Platform for your
                                    personal and non-commercial use, subject to these Terms.
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    You may not use, copy, reproduce, distribute, modify, create
                                    derivative works from, publicly display, publicly perform, or
                                    otherwise exploit any content, code, or materials on the
                                    Platform without the express prior written permission of the
                                    Company or the respective copyright owner.
                                </Typography>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant='h6' gutterBottom display='inline'>
                            User-Generated Content
                        </Typography>
                        <ol>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    Users may submit content to the Platform, including text,
                                    images, and other materials ("User-Generated Content"). By
                                    submitting User-Generated Content, you grant the Company a
                                    non-exclusive, royalty-free, perpetual, irrevocable, and
                                    sublicensable right to use, reproduce, modify, adapt, publish,
                                    translate, create derivative works from, distribute, and display
                                    such content throughout the world in any media.
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    You represent and warrant that you have all necessary rights to
                                    submit User-Generated Content to the Platform and that such
                                    content does not infringe any intellectual property rights,
                                    violate any privacy or publicity rights, or otherwise harm or
                                    wrongfully exploit any person or entity.
                                </Typography>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant='h6' gutterBottom display='inline'>
                            Termination
                        </Typography>
                        <ol>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    The Company may terminate your access to and use of the
                                    Platform, at its sole discretion, at any time and without notice
                                    to you. You may also cancel your Account at any time by
                                    contacting the Company.
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    Upon termination, all rights granted to you under these Terms
                                    will cease, and you must cease all use of the Platform and
                                    delete any downloaded materials in your possession.
                                </Typography>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant='h6' gutterBottom display='inline'>
                            Disclaimers
                        </Typography>
                        <ol>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    The Platform is provided on an "as is" and "as available" basis,
                                    without any warranties of any kind, either express or implied,
                                    including, but not limited to, warranties of merchantability,
                                    fitness for a particular purpose, and non-infringement.
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    The Company does not warrant that the Platform will be
                                    uninterrupted, error-free, secure, or free of viruses or other
                                    harmful components. The Company does not guarantee the accuracy,
                                    reliability, or completeness of any information provided on the
                                    Platform.
                                </Typography>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant='h6' gutterBottom display='inline'>
                            Limitation of Liability
                        </Typography>
                        <ol>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    In no event shall the Company, its affiliates, or their
                                    respective officers, directors, employees, agents, or licensors
                                    be liable for any incidental, special, exemplary, or
                                    consequential damages, including lost profits, loss of data or
                                    loss of goodwill, service interruption, computer damage or
                                    system failure or the cost of substitute products or services,
                                    or for any damages for personal or bodily injury or emotional
                                    distress arising out of or in connection with the use of the
                                    Platform, even if the Company has been advised of the
                                    possibility of such damages.
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='inline'
                                >
                                    The Company's total liability to you for all claims arising from
                                    the use of the Platform shall not exceed the greater of (a) the
                                    amount of fees paid by you to the Company during the twelve (12)
                                    months prior to the date on which the claim arose, or (b) one
                                    hundred Canadian dollars (CAD 100).
                                </Typography>

                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    These limitations of liability and damages are fundamental
                                    elements of the agreement between you and Realhaus.
                                </Typography>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant='h6' gutterBottom display='inline'>
                            Indemnification
                        </Typography>
                        <Typography variant='body1' align='justify' gutterBottom display='block'>
                            You agree to indemnify, defend, and hold harmless the Company, its
                            affiliates, and their respective officers, directors, employees, agents,
                            and licensors from and against any and all claims, liabilities, damages,
                            losses, costs, expenses, and fees (including reasonable attorneys' fees)
                            arising out of or in connection with your use of the Platform, your
                            violation of these Terms, or your violation of any rights of a third
                            party, including but not limited to, intellectual property rights,
                            privacy rights, or any other applicable laws or regulations.
                        </Typography>
                    </li>
                </ol>
            </div>
        </>
    );
});
