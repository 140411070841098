import React from 'react';
import { observer } from 'mobx-react-lite';
import { Title } from './styles';
import { IProperty, PropertyTypeEnum } from 'realhaus-sdk';
import { Grid, InputLabel, MenuItem, TextField, Typography } from '@material-ui/core';
import StringUtils from '../../utils/string';

interface PropertyTypeProps {
    property: IProperty;
    updateProperty: (
        prop: keyof IProperty,
    ) => (event: React.ChangeEvent<{ value: unknown }>) => void;
}

export const PropertyType: React.FC<PropertyTypeProps> = observer(
    ({ property, updateProperty }) => {
        const handleChange =
            (prop: keyof IProperty) => (event: React.ChangeEvent<{ value: unknown }>) => {
                console.log(event.target.value);
                updateProperty(prop)(event);
            };

        const year = new Date().getFullYear();

        return (
            <>
                <Title>
                    <Typography variant='h5' gutterBottom>
                        What kind of property are you listing?
                    </Typography>
                </Title>

                <form noValidate autoComplete='off'>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <InputLabel htmlFor='year-built'>Year Built</InputLabel>
                            <TextField
                                id='year-built'
                                type='number'
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                    inputMode: 'numeric',
                                }}
                                variant='outlined'
                                margin='normal'
                                value={property.yearBuilt}
                                onChange={handleChange('yearBuilt')}
                                required
                                InputProps={{ inputProps: { min: 1900, max: year + 10 } }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel htmlFor='size'>Size (sq ft)</InputLabel>
                            <TextField
                                id='size'
                                type='number'
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                    inputMode: 'numeric',
                                }}
                                variant='outlined'
                                margin='normal'
                                value={property.size}
                                onChange={handleChange('size')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor='property-type'>Property type</InputLabel>
                            <TextField
                                select
                                margin='normal'
                                id='property-type'
                                fullWidth
                                required
                                variant='outlined'
                                value={property.propertyType}
                                onChange={handleChange('propertyType')}
                            >
                                {Object.entries(PropertyTypeEnum).map((k) => (
                                    <MenuItem key={k[0]} value={k[1].toString()}>
                                        {StringUtils.enumToDisplayString(k[0])}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel htmlFor='bedrooms'>Bedrooms</InputLabel>
                            <TextField
                                id='bedrooms'
                                type='number'
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                    inputMode: 'numeric',
                                }}
                                InputProps={{ inputProps: { min: 1 } }}
                                variant='outlined'
                                margin='normal'
                                value={property.bedrooms}
                                onChange={handleChange('bedrooms')}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel htmlFor='bathrooms'>Bathrooms</InputLabel>
                            <TextField
                                id='bathrooms'
                                type='number'
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                    inputMode: 'numeric',
                                }}
                                InputProps={{ inputProps: { min: 0 } }}
                                variant='outlined'
                                margin='normal'
                                value={property.bathrooms}
                                onChange={handleChange('bathrooms')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}></Grid>
                    </Grid>
                </form>
            </>
        );
    },
);
