import { useContext, useEffect, useRef, useState } from 'react';
import {
    Typography,
    Grid,
    makeStyles,
    createStyles,
    Theme,
    ButtonBase,
    Button,
    Box,
    IconButton,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import {
    ILeaseAgreementDocument,
    IdLeaseAgreement,
    IdLeaseAgreementDocument,
    LeaseAgreementStatus,
} from 'realhaus-sdk';
import { RootStoreContext } from '../../../global/storeContext';
import DescriptionIcon from '@material-ui/icons/Description';
import CancelIcon from '@material-ui/icons/Cancel';
import { LeaseAgreementReadView } from '../leaseAgreementReadView';
import { RightDrawer } from '../../uiComponents/rightDrawer';
import { Print } from '@material-ui/icons';
import ReactToPrint from 'react-to-print';
import { DocumentUploadButton } from '../documentUploadButton';
import React from 'react';
import Delete from '@material-ui/icons/Delete';
import { ConfirmDialog } from '../../uiComponents/confirmationDialog';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        documentsContainer: {
            border: 1,
            borderStyle: 'solid',
            backgroundColor: theme.palette.grey[100],
            borderColor: theme.palette.grey[200],
            padding: '1rem',
            display: 'flex',
            justifyContent: 'center',
        },
        documentItemButton: {
            padding: '1rem',
        },
    }),
);

export const LeaseDocumentsComponent: React.FC<{ leaseAgreement: IdLeaseAgreement }> = observer(
    ({ leaseAgreement }) => {
        const classes = useStyles();
        const { leaseStore, uiStore } = useContext(RootStoreContext);
        const [documents, setDocuments] = useState<IdLeaseAgreementDocument[]>([]);
        const [docToDelete, setDocToDelete] = React.useState<IdLeaseAgreementDocument>();
        const [openConfirmDelete, setOpenConfirmDelete] = React.useState<boolean>(false);
        const [leaseViewOpen, setLeaseViewOpen] = useState(false);
        const leaseItem = { title: 'Lease Agreement' };
        const isLandlordMode = uiStore.isLandlordMode();

        const isTerminated = leaseAgreement.status === LeaseAgreementStatus.TERMINATED;

        const handleLeaseOpen = () => {
            setLeaseViewOpen(true);
        };

        const handleLeaseClose = () => {
            setLeaseViewOpen(false);
        };

        const handleDocumentDownload = (document: IdLeaseAgreementDocument) => {
            if (!document.url) {
                uiStore.error('Unable to download the document.');
                return;
            }

            const link = window.document.createElement('a');
            link.target = '_blank';
            link.rel = 'noopener noreferrer';
            link.href = document.url || '';
            link.download = `${document.title}` || '';
            link.click();
        };

        const handleDeleteDocument = async (doc: IdLeaseAgreementDocument) => {
            try {
                uiStore.showLoading();
                await leaseStore.deleteLeaseAgreementDocument(leaseAgreement.id, doc.id, doc);
                setDocuments(documents.filter((d) => d.id !== doc.id));
            } catch (err) {
            } finally {
                uiStore.hideLoading();
            }
        };
        const getDocuments = async () => {
            const docs = await leaseStore.getLeaseAgreementDocuments(leaseAgreement.id);
            if (docs && docs.length > 0) {
                setDocuments(docs);
            }
        };
        useEffect(() => {
            const init = async () => {
                if (documents.length > 0) {
                    return;
                }
                uiStore.showLoading();
                try {
                    getDocuments();
                } finally {
                    uiStore.hideLoading();
                }
            };
            init();
        }, []);

        return (
            <>
                <Grid container spacing={4}>
                    <Grid xs={12} item container className={classes.documentsContainer}>
                        <Grid item>
                            <DocumentItemComponent
                                documentTitle={leaseItem.title}
                                handleItemClick={handleLeaseOpen}
                            />
                        </Grid>
                        {documents
                            .filter((doc) => !doc.tag)
                            .map((item) => (
                                <Grid item key={`key-document-${item.id}`}>
                                    <DocumentItemComponent
                                        documentTitle={item.title}
                                        handleItemClick={() => handleDocumentDownload(item)}
                                    />
                                </Grid>
                            ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='space-between' mb={2}>
                            <Typography variant='h6'>Additional Documents</Typography>
                            {isLandlordMode && (
                                <DocumentUploadButton
                                    leaseId={leaseAgreement.id}
                                    updateDocument={getDocuments}
                                    additionalDocument={true}
                                    isTerminated={isTerminated}
                                />
                            )}
                        </Box>
                        <Grid item container spacing={2}>
                            {documents &&
                                documents
                                    .filter((doc) => doc.tag)
                                    .map((item) => (
                                        <Grid item xs={6} md={4}>
                                            <Box
                                                display='flex'
                                                justifyContent='space-between'
                                                mb={2}
                                            >
                                                <DocumentItemComponent
                                                    documentTitle={item.title}
                                                    handleItemClick={() =>
                                                        handleDocumentDownload(item)
                                                    }
                                                />
                                                {isLandlordMode && (
                                                    <IconButton
                                                        edge='end'
                                                        aria-label='delete'
                                                        onClick={() => {
                                                            setDocToDelete(item);
                                                            setOpenConfirmDelete(true);
                                                        }}
                                                        disabled={isTerminated}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                )}
                                            </Box>
                                        </Grid>
                                    ))}
                        </Grid>
                    </Grid>
                </Grid>
                <ConfirmDialog
                    title='Confirm Delete Document'
                    content={
                        <Typography>
                            Are you sure you want to delete "{docToDelete?.title}"? This action
                            cannot be undone.
                        </Typography>
                    }
                    openDialog={openConfirmDelete}
                    closeDialog={(confirm: boolean) => () => {
                        if (confirm && docToDelete) {
                            handleDeleteDocument(docToDelete);
                        }

                        setOpenConfirmDelete(false);
                    }}
                />
                <LeaseAgreementView
                    leaseAgreement={leaseAgreement}
                    viewOpen={leaseViewOpen}
                    closeView={handleLeaseClose}
                />
            </>
        );
    },
);

const DocumentItemComponent: React.FC<{
    documentTitle: string;
    handleItemClick: () => void;
}> = observer(({ documentTitle, handleItemClick }) => {
    const classes = useStyles();

    const handleDocumentOpen = () => {
        handleItemClick();
    };

    return (
        <Grid container item>
            <ButtonBase onClick={handleDocumentOpen} className={classes.documentItemButton}>
                <DescriptionIcon />
                <Typography variant='body1'>{documentTitle}</Typography>
            </ButtonBase>
        </Grid>
    );
});

export const LeaseAgreementView: React.FC<{
    viewOpen: boolean;
    closeView: () => void;
    leaseAgreement: IdLeaseAgreement;
}> = observer(({ leaseAgreement, viewOpen, closeView }) => {
    const printRef = useRef(null);

    return (
        <>
            <RightDrawer isOpen={viewOpen} onOpen={() => {}} onClose={closeView}>
                <Grid container direction='column' spacing={0}>
                    <Grid
                        container
                        item
                        spacing={0}
                        justifyContent='space-between'
                        style={{ width: '100%', display: 'flex' }}
                    >
                        <Grid item>
                            <ReactToPrint
                                pageStyle={`@media print{
                              @page{
                                        margin:20mm 10mm 20mm 10mm;
                              }
                            }`}
                                trigger={() => (
                                    <Button>
                                        <Print /> Print/Download
                                    </Button>
                                )}
                                content={() => printRef.current}
                                documentTitle='Realhaus Lease Agreement'
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                style={{ borderRadius: '0', color: 'black' }}
                                onClick={closeView}
                            >
                                <CancelIcon />
                            </Button>
                        </Grid>
                    </Grid>
                    <LeaseAgreementReadView ref={printRef} leaseAgreement={leaseAgreement} />
                    <Grid item></Grid>
                </Grid>
            </RightDrawer>
        </>
    );
});
