import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles({
  cardParent: {
    marginBottom: '16px',
    paddingTop: '0px',
    padding: '5px',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    "&:hover": {
      cursor: 'pointer',
    }
  },
  cardRoot: {
    // height: '100%',
    padding: '0px',
    paddingRight: '0px !important',
    backgroundColor: 'transparent'
  },
  noPadding: {
    padding: '0px !important',
  },
  title: {
    fontSize: 14,
  },
  cardContent: {
    textAlign: 'center',
    margin: 'auto',
  },
  cardActions: {
    justifyContent: 'center',
  },
  successColor: {
    color: '#4caf50',
  },
  errorColor: {
    color: '#f44336',
  },
  warningColor: {
    color: '#ffeb3b',
  },
  listCard: {
    width: 'fit-content',
    border: `1px solid "#424242"`,
    borderRadius: '3px',
    backgroundColor: '#fff',
    color: '#1976d2',
  },
});
