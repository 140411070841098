
import { styled } from '@material-ui/core/styles';


export const PageContent = styled('div')({
  marginTop: '40px'
});

export const PageHead = styled('section')({
  marginBottom: '40px',
  marginTop: '40px',
  display: 'flex'
})

export const FullWidth = styled('span')({
  width: '100%'
})

export const Spacing = styled('div')({
  flex: '1 1 auto',
})

export const StepperButtons = styled('div')({
  display: 'flex',
  marginTop: '30px'
})

export const Title = styled('div')({
  marginBottom: '20px',
  marginTop: '20px'
});

export const ImageThumbsContainer = styled('aside')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
});

export const ImageThumb = styled('div')({
  display: 'inline-flex',
  flexDirection: 'column',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 234,
  height: 165,
  padding: 4,
  boxSizing: 'border-box',
  position: 'relative'
});

export const ImageThumbInner = styled('div')({
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
});

export const ImageContent = styled('img')({
  display: 'block',
  width: '234px',
  height: '156px'
});

export const ImageThumbTools = styled('div')({
  position: 'absolute',
  top: '8px',
  right: '0px',
  zIndex: 1,
  boxSizing: 'border-box',
  display: 'flex',
})

export const ImageThumbAction = styled('div')({
  margin: '0 8px 0 0',
  backgroundColor: 'whitesmoke',
  borderRadius: '24px'
})

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
}

export const DropzoneContainer = styled('div')({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: '2px',
  borderRadius: '2px',
  borderColor: (props) => getColor(props),
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease -in -out'
});

