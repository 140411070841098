import React from 'react';
import { observer } from 'mobx-react-lite';
import DateFnsUtils from '@date-io/date-fns';
import {
    KeyboardDatePicker,
    KeyboardDateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Grid } from '@material-ui/core';

export const DatePicker: React.FC<{
    id: string;
    selectedDate: Date | null;
    minDate?: Date;
    maxDate?: Date;
    disable?: boolean;
    onChange: (date: Date | null) => void;
}> = observer(({ id, selectedDate, minDate, maxDate, disable, onChange }) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent='space-around'>
                <KeyboardDatePicker
                    autoOk
                    variant='inline'
                    inputVariant='outlined'
                    margin='normal'
                    format='MMMM dd, yyyy'
                    id={id}
                    value={selectedDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={onChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    fullWidth
                    disabled={disable}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
});

export const DateTimePicker: React.FC<{
    id: string;
    selectedDate: Date;
    minDate?: Date;
    onChange: (date: Date | null) => void;
}> = observer(({ id, selectedDate, minDate, onChange }) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent='space-around'>
                <KeyboardDateTimePicker
                    disablePast
                    showTodayButton
                    inputVariant='outlined'
                    margin='normal'
                    format='PPp'
                    minDate={minDate}
                    id={id}
                    value={selectedDate}
                    onChange={onChange}
                    fullWidth
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
});
