import React from 'react';
import { observer } from 'mobx-react-lite';
import { AuthLayout } from '../../layouts/auth/auth';
import { Login } from '../../components/auth/login/login';

export const LoginPage: React.FC = observer(() => {
    return (
        <>
            <AuthLayout>
                <Login />
            </AuthLayout>
        </>
    );
});
