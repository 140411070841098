import React from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { streetAddress } from '../../utils/address';
import { useStyles } from './styles';
import { IAddress, ICreditReportSummary } from 'realhaus-sdk';
import StringUtils from '../../utils/string';

interface RentApplicationSnapshotProps {
    name: string;
    address: IAddress | null;
    dateSubmitted: number;
    moveinDate: number;
    employmentStatus?: string;
    income?: number;
    rentPrice: number;
    isActive?: boolean;
    onSelected: () => void;
    rating?: number;
    credit?: ICreditReportSummary;
}

export const RentApplicationSnapshot: React.FC<RentApplicationSnapshotProps> = observer(
    ({
        name,
        address,
        dateSubmitted,
        moveinDate,
        employmentStatus = 'unemployed',
        income = 0,
        rentPrice,
        onSelected,
        isActive,
        rating,
        credit,
    }) => {
        const classes = useStyles();
        const employmentDisplayStatus = () => {
            switch (employmentStatus) {
                case 'employed': {
                    return 'Employed';
                }
                case 'unemployed': {
                    return 'Unemployed';
                }
                case 'retired': {
                    return 'Retired';
                }
                case 'self-employed': {
                    return 'Self-employed';
                }
                case 'student': {
                    return 'Student';
                }
            }
        };

        const incomeColorCode = () => {
            const halfOfIncome = Number(income) * 0.5;
            if (income <= rentPrice) {
                return classes.errorColor;
            } else if (rentPrice > halfOfIncome) {
                return classes.warningColor;
            } else {
                return classes.successColor;
            }
        };

        return (
            <>
                <Grid
                    container
                    direction='row'
                    className={classes.cardParent}
                    onClick={onSelected}
                    style={{
                        backgroundColor: `${isActive ? '#f5f5f5' : 'transparent'}`,
                        width: '100%',
                    }}
                    spacing={2}
                >
                    <Grid item xs={12} md={3} className={classes.cardContent}>
                        <Typography variant='body1'>
                            <b>{name}</b>
                        </Typography>
                        <Typography variant='subtitle1' color='textSecondary'>
                            {address ? `${streetAddress(address)} ${address.city}` : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={2} className={classes.cardContent}>
                        <Typography variant='body1'>
                            <b>{format(dateSubmitted, 'PP')}</b>
                        </Typography>
                        <Typography variant='subtitle1' color='textSecondary'>
                            Submission Date
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={2} className={classes.cardContent}>
                        <Typography variant='body1'>
                            <b>{format(moveinDate, 'PP')}</b>
                        </Typography>
                        <Typography variant='subtitle1' color='textSecondary'>
                            Move-in Date
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Grid container direction='row' spacing={2}>
                            <Grid item xs={6} md={3} className={classes.cardContent}>
                                <Typography
                                    variant='body1'
                                    style={{
                                        color: credit?.creditScoreRange.color ?? '#f44336',
                                    }}
                                >
                                    <b>{credit?.creditScore ?? 'N/A'}</b>
                                </Typography>
                                <Typography variant='subtitle1' color='textSecondary'>
                                    credit
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={3} className={classes.cardContent}>
                                <Typography variant='body1' className={incomeColorCode()}>
                                    <b>{StringUtils.asCurrency(income)}</b>
                                </Typography>
                                <Typography variant='subtitle1' color='textSecondary'>
                                    Income / mo
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={3} className={classes.cardContent}>
                                <Typography
                                    variant='body1'
                                    className={
                                        employmentStatus === 'employed' ||
                                        employmentStatus === 'self-employed'
                                            ? classes.successColor
                                            : employmentStatus === 'student' ||
                                              employmentStatus === 'retired'
                                            ? classes.warningColor
                                            : classes.errorColor
                                    }
                                >
                                    <b> {`${employmentDisplayStatus()}`}</b>
                                </Typography>
                                <Typography variant='subtitle1' color='textSecondary'>
                                    Employment
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={3} className={classes.cardContent}>
                                <Typography variant='body1' color='error'>
                                    <b>{rating?.toFixed(1) ?? 'N/A'}</b>
                                </Typography>
                                <Typography variant='subtitle1' color='textSecondary'>
                                    Rating
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    },
);
