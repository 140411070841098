import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { IdPropertyListing, IProspectiveTenant } from 'realhaus-sdk';
import { RootStoreContext } from '../../global/storeContext';

interface InviteFormProps {
    handleCloseInvite: () => void;
}

export const ApplicantInviteForm: React.FC<InviteFormProps> = observer(({ handleCloseInvite }) => {
    const [applicantDetails, setApplicantDetails] = React.useState({} as IProspectiveTenant);
    const [listings, setListings] = React.useState<IdPropertyListing[]>();
    const { listingStore, tenantStore, uiStore } = useContext(RootStoreContext);
    const [selectListing, setSelectListing] = React.useState<string>();
    const [changesMade, setChangesMade] = React.useState(false);

    const handleApplicantDetails =
        (prop: keyof IProspectiveTenant) => (e: React.ChangeEvent<{ value: unknown }>) => {
            e.preventDefault();
            setApplicantDetails({ ...applicantDetails, [prop]: e.target.value });
            setChangesMade(true);
        };

    const handleChangeListings = async (e: React.ChangeEvent<{ value: unknown }>) => {
        if (!!e.target.value) {
            const selectedTenantListing = listings?.find((l) => {
                return l.title === e.target.value;
            });

            if (!!selectedTenantListing?.listingId) {
                await listingStore
                    .getListingMailInfo(selectedTenantListing.listingId)
                    .then((mailInfo) => {
                        setApplicantDetails({
                            ...applicantDetails,
                            ['listingInfo' as keyof IProspectiveTenant]: { ...mailInfo },
                        });
                    });
            }

            setSelectListing(e.target.value as string);
            setChangesMade(true);
        }
    };

    const handleSubmit = async () => {
        if (!!applicantDetails) {
            await tenantStore
                .createProspectiveTenantInvite(applicantDetails)
                .then(() => {
                    uiStore.success('Invite sent');
                    handleCloseInvite();
                })
                .catch(() => {
                    uiStore.error('Invite failed');
                });
        }
    };

    useEffect(() => {
        listingStore.getUserListings().then((l) => {
            setListings(l);
        });
    }, []);

    return (
        <>
            <DialogTitle>Enter Applicant Details</DialogTitle>
            <DialogContent>
                <form autoComplete='off'>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='subtitle2'>First Name</Typography>
                            <TextField
                                variant='outlined'
                                type='text'
                                required
                                fullWidth
                                size='small'
                                value={applicantDetails.firstname ?? ''}
                                onChange={handleApplicantDetails('firstname')}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='subtitle2'>Last Name</Typography>
                            <TextField
                                variant='outlined'
                                type='text'
                                required
                                fullWidth
                                size='small'
                                value={applicantDetails.lastname ?? ''}
                                onChange={handleApplicantDetails('lastname')}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='subtitle2'>Email</Typography>
                            <TextField
                                variant='outlined'
                                type='email'
                                required
                                fullWidth
                                size='small'
                                value={applicantDetails.email ?? ''}
                                onChange={handleApplicantDetails('email')}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='subtitle2'>Listings</Typography>
                            <Select
                                id='listings'
                                fullWidth
                                required
                                variant='outlined'
                                value={selectListing ?? ''}
                                onChange={handleChangeListings}
                            >
                                {listings?.map((l) => (
                                    <MenuItem value={l.title} key={l.listingId}>
                                        {l.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseInvite} color='primary'>
                    Cancel
                </Button>
                <Button
                    color='primary'
                    variant='contained'
                    onClick={handleSubmit}
                    disabled={!changesMade || uiStore.loading}
                >
                    Send
                </Button>
            </DialogActions>
        </>
    );
});
