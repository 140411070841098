import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import {
    Button,
    Divider,
    FormControlLabel,
    Grid,
    InputLabel,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core';
import { RootStoreContext } from '../../global/storeContext';
import { useStyles } from './style';
import {
    IPolicy,
    Utilities,
    Amenities,
    IAmenitiesInfo,
    Pets,
    IEntity,
    IListingTerm,
    IListing,
    Children,
} from 'realhaus-sdk';
interface PropertyFeaturesProps {
    listingId: string;
}

const utilityPolicies: IPolicy[] = Utilities.map(
    (u) => ({ text: u, suffix: 'Included', value: false } as IPolicy),
);

const amenities: IAmenitiesInfo[] = Amenities.map((u) => ({
    ...u,
}));

const housePolicies: IPolicy[] = [
    ...Pets.map((p) => ({ text: p, suffix: 'Allowed', value: false })),
    ...Children.map((c) => ({ text: c, value: false })),
    { text: 'Smoking', suffix: 'Allowed', value: false },
    { text: 'Events', suffix: 'Allowed', value: false },
];

export const PropertyFeatures: React.FC<PropertyFeaturesProps> = observer(({ listingId }) => {
    const classes = useStyles();
    const { listingStore, uiStore } = useContext(RootStoreContext);
    const [terms, setTerms] = React.useState<IEntity & IListingTerm>({} as IEntity & IListingTerm);
    const [utilityRules, setUtilityRules] = React.useState<IPolicy[]>([]);
    const [amenityRules, setAmenityRules] = React.useState<IAmenitiesInfo[]>([]);
    const [additionalUtility, setAdditionalUtility] = React.useState<string>('');
    const [houseRules, setHouseRules] = React.useState<IPolicy[]>([]);
    const [additionalHouseRules, setAdditionalHouseRules] = React.useState<string>('');
    const [listing, setListing] = React.useState<IListing>();

    useEffect(() => {
        uiStore.showLoading();
        if (!!listingId) {
            // get listing
            listingStore
                .getListingTerm(listingId)
                .then((t) => {
                    if (t) {
                        setUtilityRules([...(t.features?.utilities ?? [])]);
                        setAdditionalUtility(t.features?.additionalUtilityRules);
                        setHouseRules([...(t.features?.houseRules ?? [])]);
                        setAdditionalHouseRules(t.features?.additionalHouseRules);
                        setTerms(t);
                    }

                    listingStore.getListing(listingId).then((t) => {
                        if (t) {
                            setListing(t);
                            setAmenityRules([...(t.amenities ?? [])]);
                        }
                    });
                })
                .finally(() => {
                    uiStore.hideLoading();
                });
        } else {
            uiStore.hideLoading();
        }
    }, [listingId]);

    // const handleTermsChange =
    //     (prop: keyof IListingTerm) => (event: React.ChangeEvent<{ value: unknown }>) => {
    //         setTerms({ ...terms, [prop]: event.target.value as string });
    //     };

    const updateAdditionalUtility = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdditionalUtility(event.target.value);
    };

    const updateAdditionalHouseRules = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdditionalHouseRules(event.target.value);
    };

    const handleUtilityChange =
        (policy: IPolicy) => (event: React.ChangeEvent<HTMLInputElement>) => {
            const existsIndex = utilityRules.findIndex((x) => x.text === policy.text);
            if (existsIndex >= 0 && !event.target.checked) {
                // item exists. remove
                const utils = [...utilityRules];
                utils.splice(existsIndex, 1);
                setUtilityRules(utils);
            } else if (existsIndex < 0 && event.target.checked) {
                // add new
                const utils = [
                    ...utilityRules,
                    { text: policy.text, suffix: policy.suffix } as IPolicy,
                ];
                setUtilityRules(utils);
            }
        };

    const handleAmenityChange =
        (amenity: IAmenitiesInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
            const existsIndex = amenityRules.findIndex((x) => x.key === amenity.key);
            if (existsIndex >= 0 && !event.target.checked) {
                // item exists. remove
                const allAmenities = [...amenityRules];
                allAmenities.splice(existsIndex, 1);
                setAmenityRules(allAmenities);
            } else if (existsIndex < 0 && event.target.checked) {
                // add new
                const allAmenities = [...amenityRules, { ...amenity }];
                setAmenityRules(allAmenities);
            }
        };

    const handleHouseRulesChange =
        (policy: IPolicy) => (event: React.ChangeEvent<HTMLInputElement>) => {
            const existsIndex = houseRules.findIndex((x) => x.text === policy.text);
            if (existsIndex >= 0 && !event.target.checked) {
                // item exists. remove
                const rules = [...houseRules];
                rules.splice(existsIndex, 1);
                setHouseRules(rules);
            } else if (existsIndex < 0 && event.target.checked) {
                // add new
                const rules = [
                    ...houseRules,
                    { text: policy.text, suffix: policy.suffix } as IPolicy,
                ];
                setHouseRules(rules);
            }
        };

    const handleSave = () => {
        uiStore.showLoading();
        const { id, ...leaseTerms } = terms;
        listingStore
            .upsertListingTerm(listingId, {
                ...leaseTerms,
                features: {
                    utilities: [...utilityRules],
                    houseRules: [...houseRules],
                    additionalHouseRules: additionalHouseRules ?? null,
                    additionalUtilityRules: additionalUtility ?? null,
                },
            })
            .then((_) => {
                listingStore
                    .updateListing(listingId, {
                        ...listing,
                        amenities: [...amenityRules],
                    })
                    .then((_) => {
                        uiStore.success('Property Rules and Policies updated');
                    });
            })
            .finally(() => {
                uiStore.hideLoading();
            });
    };

    const utilityPolicyChecked = (policy: IPolicy) =>
        utilityRules.findIndex((p) => p.text === policy.text) >= 0;

    const amenityChecked = (amenity: IAmenitiesInfo) =>
        amenityRules.findIndex((a) => a.key === amenity.key) >= 0;

    const housePolicyChecked = (policy: IPolicy) =>
        houseRules.findIndex((p) => p.text === policy.text) >= 0;

    return (
        <>
            <Typography variant='h5' gutterBottom>
                Features and Policies
            </Typography>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <Typography variant='h6' gutterBottom>
                        Amenities
                    </Typography>
                    <Grid container spacing={2} className={classes.root}>
                        {amenities.map((amenity) => {
                            return (
                                <Grid item xs={6} key={amenity.value}>
                                    <FormControlLabel
                                        label={
                                            <Typography variant='subtitle1' gutterBottom>
                                                {amenity.value}
                                            </Typography>
                                        }
                                        control={
                                            <Switch
                                                checked={amenityChecked(amenity)}
                                                onChange={handleAmenityChange(amenity)}
                                                name={amenity.key}
                                                color='primary'
                                            />
                                        }
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <br />
                    <Divider />
                    <br />
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='h6' gutterBottom>
                        Utilities
                    </Typography>
                    <Grid container spacing={2}>
                        {utilityPolicies.map((policy) => {
                            return (
                                <Grid item xs={6} key={policy.text}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={utilityPolicyChecked(policy)}
                                                onChange={handleUtilityChange(policy)}
                                                name={policy.text}
                                                color='primary'
                                            />
                                        }
                                        label={
                                            <Typography variant='subtitle1' gutterBottom>
                                                {policy.text} {policy.suffix}
                                            </Typography>
                                        }
                                    />
                                </Grid>
                            );
                        })}

                        <Grid item xs={12}>
                            <InputLabel htmlFor='additionalUtilityRules'>
                                Additional Utility Policies
                            </InputLabel>
                            <TextField
                                id='additionalUtilityRules'
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                margin='normal'
                                multiline
                                rows={5}
                                value={additionalUtility}
                                onChange={updateAdditionalUtility}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <br />
                    <Divider />
                    <br />
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='h6' gutterBottom>
                        House Rules
                    </Typography>
                    <Grid container spacing={2}>
                        {housePolicies.map((policy) => {
                            return (
                                <Grid item xs={6} key={policy.text}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={housePolicyChecked(policy)}
                                                onChange={handleHouseRulesChange(policy)}
                                                name={policy.text}
                                                color='primary'
                                            />
                                        }
                                        label={
                                            <Typography variant='subtitle1' gutterBottom>
                                                {policy.text} {policy.suffix}
                                            </Typography>
                                        }
                                    />
                                </Grid>
                            );
                        })}

                        <Grid item xs={12}>
                            <InputLabel htmlFor='additionalHouseRules'>
                                Additional House Rules
                            </InputLabel>
                            <TextField
                                id='additionalHouseRules'
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                margin='normal'
                                multiline
                                rows={5}
                                value={additionalHouseRules}
                                onChange={updateAdditionalHouseRules}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <br />
                    <Divider />
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <Button variant='contained' color='primary' onClick={handleSave}>
                        Save
                    </Button>
                </Grid>
            </Grid>
        </>
    );
});
