import { makeAutoObservable } from 'mobx';
import { BillStatus, BillType, IBill, IDTransaction, ITransaction, IWallet } from 'realhaus-sdk';
import { requestFundWalletViaInterac, requestPayBill, requestWithdrawFunds } from '../utils/apiClient';
import { Collections, LimitQueryOperation, QueryOperation, StartAfterQueryOperation, WhereQueryOperation } from './dbStore';
import FirebaseStore from './firebaseStore';
import { UserStore } from './userStore';
import { FundSourceTypes } from '../enums/FundSource';
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';


export const DEFAULT_TRANSACTIONS_LIMIT = 25;


/**
* Calculate the net amount inclusive of the fee to deduct from the user for transferring a desired amount
* calculating from the point of view that the vendor will charge Realhaus based on the net amount provided.
* As a result this amount is charged to the user and is inclusive of that the fee the vendor will charge to Realhaus.
* e.g. (amount + $0.65) + (1 - 2.9%)
*
* @param desiredAmount the desired amount to transfer
* @param percentageFee the percentage fee the vendor will charge
* @param fixedFee the fixed fee the vendor will charge
* @returns amount to charge the user that will be inclusive of desired amount and vendor fee
*/
const calculateNetAmountInclusively = (desiredAmount: number, percentageFee: number, fixedFee: number) => {
  return desiredAmount === 0 ? 0 : (desiredAmount + fixedFee) / (1 - percentageFee);
}


export class WalletStore {
  constructor(private fireStore: FirebaseStore, private userStore: UserStore) {
    makeAutoObservable(this);
  }

  private defaultWallet = (uid: string): IWallet => {
    return {
      amount: 0,
      owner: uid
    }
  }

  getWallet = async () => {

    const uid = this.userStore.userId;
    if (!uid) {
      return;
    }

    const wallet = await this.fireStore.getDocument(Collections.wallets, uid);
    if (!wallet || !wallet.exists()) return this.defaultWallet(uid);

    return wallet.data() as IWallet;
  }

  getWalletTransactions = async (startAfter: QueryDocumentSnapshot<DocumentData> | null): Promise<{ transactions: IDTransaction[], lastDoc: QueryDocumentSnapshot<DocumentData> } | undefined> => {
    const uid = this.userStore.userId;
    if (!uid) {
      return;
    }

    // queries array
    const queries: QueryOperation[] = [new LimitQueryOperation(DEFAULT_TRANSACTIONS_LIMIT)];

    if (startAfter?.exists()) {
      queries.push(new StartAfterQueryOperation(startAfter))
    }

    const transactionSnapshot = await this.fireStore.findDocuments(`${Collections.wallets}/${uid}/transactions`, queries);
    const transactions = transactionSnapshot.docs.map((d) => ({
      id: d.id,
      ...(d.data() as ITransaction)
    } as IDTransaction));

    return { transactions, lastDoc: transactionSnapshot.docs[transactionSnapshot.docs.length - 1] }
  }

  payBill = async (
    leaseId: string,
    billId: string,
    senderId: string,
    amount: number,
    note: string
  ) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) {
      throw Error('User is not logged-in!');
    }
    if (uid !== senderId) {
      throw Error('Unauthorized payment request!');
    }
    const token = await this.fireStore.authService.currentUser?.getIdToken() || '';
    const billRequest = { token, body: { leaseId, billId, senderId, amount, note } };
    return await requestPayBill(billRequest);
  }

  updateBillStatusAsPaid = async (leaseAgreementId: string, billId: string) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) {
      throw Error('User is not logged-in');
    }
    const path = `${Collections.leaseAgreements}/${leaseAgreementId}/${Collections.bills}`
    const billDoc = await this.fireStore.getDocument(path, billId);
    if (!billDoc.exists()) {
      throw Error('Bill not found');
    }

    if ((billDoc.data() as IBill).status !== BillStatus.PAID) {
      await this.fireStore.updateDocument(path, billId, { status: BillStatus.PAID });
      console.log("Bill Status Updated");
    }
  }

  getFundWalletFeeBreakdown = (amount: number) => {
    // TODO: confirm this is correct. Obtained from Online Checkout price of 3.49% + 49¢
    const payPalAmount = calculateNetAmountInclusively(amount, 0.0349, 0.49);
    const stripeFeeAmount = calculateNetAmountInclusively(amount, (0.029 + 0.006), 0.3); // +0.6% for international cards
    const interacFeeAmount = calculateNetAmountInclusively(amount, 0.0065, 1.25);

    return {
      [FundSourceTypes.PAYPAL]: {
        fee: Number((payPalAmount - amount).toFixed(2)),
        netAmount: Number(payPalAmount.toFixed(2)),
      },
      [FundSourceTypes.STRIPE]: {
        fee: Number((stripeFeeAmount - amount).toFixed(2)),
        netAmount: Number(stripeFeeAmount.toFixed(2)),
      },
      [FundSourceTypes.INTERAC]: {
        fee: Number((interacFeeAmount - amount).toFixed(2)),
        netAmount: Number(interacFeeAmount.toFixed(2)),
      }
    }
  }

  fundWalletViaInterac = async (amount: number) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) {
      throw Error('User is not logged-in!');
    }
    const token = await this.fireStore.authService.currentUser?.getIdToken() || '';

    return requestFundWalletViaInterac({ token, body: { amount } })
  }

  withdrawFunds = async (amount: number) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) {
      throw new Error('User is not logged in');
    }

    const token = await this.fireStore.authService.currentUser?.getIdToken() || '';
    return requestWithdrawFunds({ token, body: { amount } });
  }

  getLast30DaysTransactions = async (userId: string) => {
    if (!userId) return;

    // current day
    const today = new Date();

    // thirty days ago
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);

    const transactionDoc = await this.fireStore.findDocuments(`${Collections.wallets}/${userId}/transactions`, [new WhereQueryOperation('timestamp', '>=', thirtyDaysAgo.getTime())]);

    return transactionDoc.docs.map((doc) => ({
      id: doc.id,
      ...(doc.data() as ITransaction)
    } as IDTransaction))

  }

  isPositiveTransactionInLast30Days = async () => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) throw new Error('User is not logged in');

    const thirtyDaysTransactions = await this.getLast30DaysTransactions(uid);

    if (!!thirtyDaysTransactions && thirtyDaysTransactions.length > 0) {
      for (let tranx of thirtyDaysTransactions) {
        if (tranx.amount > 0) return true;
      }
      return false;
    }
    return false;
  }
}