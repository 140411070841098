import { Box, Button, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { IdPropertyListing, IdProspectiveTenant } from 'realhaus-sdk';
import { useStyles } from './styles';
import { RootStoreContext } from '../../global/storeContext';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
export const TenantInvites: React.FC = observer(() => {
    const [invites, setInvites] = React.useState<IdProspectiveTenant[]>();
    const [listings, setListings] = React.useState<IdPropertyListing[]>();
    const { tenantStore, listingStore, uiStore } = React.useContext(RootStoreContext);

    useEffect(() => {
        const init = async () => {
            try {
                uiStore.showLoading();
                await fetchInvites();
                await listingStore.getUserListings().then((l) => {
                    setListings(l);
                });
                uiStore.hideLoading();
            } catch (error) {
                uiStore.hideLoading();
            }
        };
        init();
    }, []);

    const fetchInvites = async () => {
        await tenantStore.getProspectiveTenantsInvites().then((i) => {
            setInvites(i);
        });
    };
    const getListingName = (listingId: string) => {
        const listingName = listings?.find((l) => {
            return l.listingId === listingId;
        });
        return listingName?.title;
    };

    const handleDeleteInvite = (inviteId: string) => () => {
        tenantStore.deleteProspectiveTenantInvite(inviteId).then(async () => {
            await fetchInvites();
        });
    };

    const classes = useStyles();

    if (uiStore.loading) return null;
    if (!uiStore.loading && invites?.length === 0) {
        return (
            <>
                <Box>
                    <Typography>You've sent no invite</Typography>
                </Box>
            </>
        );
    } else {
        return (
            <>
                {invites?.map((invite) => (
                    <Grid
                        spacing={2}
                        container
                        direction='row'
                        className={classes.cardParent}
                        key={invite.id}
                    >
                        <Grid item xs={12} md={4}>
                            <Grid container direction='row' style={{ height: '100%' }}>
                                <Grid item xs={6} className={classes.cardContent}>
                                    <Typography variant='body1'>
                                        <b>{invite.firstname}</b>
                                    </Typography>
                                    <Typography variant='subtitle1' color='textSecondary'>
                                        First name
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} className={classes.cardContent}>
                                    <Typography variant='body1'>
                                        <b>{invite.lastname}</b>
                                    </Typography>
                                    <Typography variant='subtitle1' color='textSecondary'>
                                        Last name
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={7}>
                            <Grid container direction='row' style={{ height: '100%' }}>
                                <Grid item xs={12} className={classes.cardContent}>
                                    <Typography variant='body1'>
                                        <b>{getListingName(invite.listingInfo?.listingId)}</b>
                                    </Typography>
                                    <Typography variant='subtitle1' color='textSecondary'>
                                        Property Name
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1}>
                            <Grid container direction='row' style={{ height: '100%' }}>
                                <Grid item xs={12} className={classes.cardContent}>
                                    <Typography variant='body1'>
                                        <Button
                                            variant='text'
                                            color='secondary'
                                            size='small'
                                            onClick={handleDeleteInvite(invite.id)}
                                        >
                                            <DeleteIcon />
                                        </Button>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </>
        );
    }
});
