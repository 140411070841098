import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { RootStoreContext } from '../../global/storeContext';
import { Button, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { DatePicker } from '../datePicker/datepicker';
import { IEntity, IListingTerm } from 'realhaus-sdk';

interface PropertyTermsProps {
    listingId: string;
}

export const PropertyTerms: React.FC<PropertyTermsProps> = observer(({ listingId }) => {
    const { listingStore, uiStore } = useContext(RootStoreContext);
    const [terms, setTerms] = React.useState<IEntity & IListingTerm>({} as IEntity & IListingTerm);
    const [selectedAvailabilityDate, setSelectedAvailabilityDate] = React.useState<Date | null>(
        null,
    );
    useEffect(() => {
        uiStore.showLoading();
        if (!!listingId) {
            // get listing
            listingStore
                .getListingTerm(listingId)
                .then((t) => {
                    if (t) {
                        setSelectedAvailabilityDate(
                            t?.dateAvailable ? new Date(t.dateAvailable) : null,
                        );
                        setTerms(t);
                    }
                })
                .finally(() => {
                    uiStore.hideLoading();
                });
        } else {
            uiStore.hideLoading();
        }
    }, [listingId]);

    const handleTermsChange =
        (prop: keyof IListingTerm) => (event: React.ChangeEvent<{ value: unknown }>) => {
            setTerms({ ...terms, [prop]: event.target.value as string });
        };

    const handleAvailabilityChange = (date: Date | null) => {
        setSelectedAvailabilityDate(date);
        setTerms({ ...terms, dateAvailable: date?.getTime() ?? 0 });
    };

    const handleSave = () => {
        uiStore.showLoading();
        const { id, ...leaseTerms } = terms;
        listingStore
            .upsertListingTerm(listingId, leaseTerms)
            .then((tId) => {
                uiStore.success('Terms updated');
            })
            .finally(() => {
                uiStore.hideLoading();
            });
    };
    return (
        <>
            <Typography variant='h5' gutterBottom>
                Rent Terms
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant='subtitle1' gutterBottom>
                        Property available on
                    </Typography>
                    <DatePicker
                        id='movein-picker'
                        selectedDate={selectedAvailabilityDate}
                        onChange={handleAvailabilityChange}
                        minDate={new Date(Date.now())}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='subtitle1' gutterBottom>
                        Duration of rent (in months)
                    </Typography>
                    <TextField
                        id='duration'
                        type='number'
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        value={terms?.duration}
                        onChange={handleTermsChange('duration')}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>Month</InputAdornment>,
                        }}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='subtitle1' gutterBottom>
                        Cost of Rent (Monthly)
                    </Typography>
                    <TextField
                        id='cost'
                        type='number'
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        value={terms?.rentAmount}
                        onChange={handleTermsChange('rentAmount')}
                        InputProps={{
                            startAdornment: <InputAdornment position='start'>$ CAD</InputAdornment>,
                        }}
                        required
                    />
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='subtitle1' gutterBottom>
                        Security Deposit
                    </Typography>
                    <TextField
                        id='deposit'
                        type='number'
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        value={terms?.depositAmount}
                        onChange={handleTermsChange('depositAmount')}
                        InputProps={{
                            startAdornment: <InputAdornment position='start'>$ CAD</InputAdornment>,
                        }}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant='contained' color='primary' onClick={handleSave}>
                        Save
                    </Button>
                </Grid>
            </Grid>
        </>
    );
});
