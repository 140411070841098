import React, { useEffect, useState, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import WorkIcon from '@material-ui/icons/Work';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PetsIcon from '@material-ui/icons/Pets';
import SmokingRoomsIcon from '@material-ui/icons/SmokingRooms';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import { format } from 'date-fns';
import { useStyles } from './styles';
import { RootStoreContext } from '../../global/storeContext';
import { CreditScoreRange } from '../../stores/creditReportStore';
import { convertIncomeType } from '../../utils/occupation';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';
import HouseOutlinedIcon from '@material-ui/icons/HouseOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { IOccupation, ICreditReportSummary, IncomeType, Grades, GradeType } from 'realhaus-sdk';

enum RatingRange {
    EXCELLENT = 4.0,
    GOOD = 3.5,
    FAIR = 3.0,
    POOR = 2.0,
}

enum RecommendedAction {
    ACCEPT = 'ACCEPT',
    CAUTION = 'CAUTION',
    HIGHRISK = 'HIGHRISK',
    UNKNOWN = 'UNKNOWN',
}

interface SummaryProps {
    moveinDate: number;
    occupation?: IOccupation;
    pets?: string;
    smoking?: string;
    vehicle?: string;
    listingId?: string;
    tenantIncome?: number;
    tenantId?: string | undefined;
    screeningReport?: ICreditReportSummary;
    rating?: number;
    grades?: Grades;
}

interface AttributeRatingProps {
    rating: number;
    totalPossibleRating: number;
    title: string;
}
export const AttributeRating: React.FC<AttributeRatingProps> = ({
    rating,
    totalPossibleRating,
    title,
}) => {
    const classes = useStyles();
    return (
        <>
            <Box className={classes.ratingContainer}>
                <Box position='relative' display='inline-flex'>
                    <CircularProgress
                        variant='determinate'
                        value={(rating / totalPossibleRating) * 100}
                        className={classes.progressBarColor}
                    />
                    <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position='absolute'
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                    >
                        <Typography
                            variant='body1'
                            component='div'
                            className={classes.progressBarColor}
                        >
                            <b>{rating}</b>
                        </Typography>
                    </Box>
                </Box>
                <Typography variant='subtitle2'>
                    <b>{title}</b>
                </Typography>
            </Box>
        </>
    );
};

export const SummaryComponent: React.FC<SummaryProps> = observer(
    ({
        moveinDate,
        occupation,
        pets,
        smoking,
        vehicle,
        listingId,
        tenantIncome,
        tenantId,
        screeningReport,
        rating,
        grades,
    }) => {
        const { listingStore, creditReportStore, ratingStore } = useContext(RootStoreContext);
        const [rent, setRent] = useState<number>();
        const [creditScore, setCreditScore] = useState<number>();

        const classes = useStyles();

        const applicationInfoElement = (
            icon: JSX.Element,
            title: string,
            text: string,
            subtitle?: string,
        ) => (
            <>
                <Box className={classes.flexContent}>
                    {icon}
                    <div>
                        <Typography
                            display={!!subtitle ? 'inline' : 'initial'}
                            style={{ textTransform: 'uppercase' }}
                        >
                            {title}{' '}
                        </Typography>
                        {!!subtitle ? (
                            <Typography display='inline' style={{ textTransform: 'none' }}>
                                ({subtitle})
                            </Typography>
                        ) : null}
                        <Typography variant='subtitle1'>
                            <b>{text}</b>
                        </Typography>
                    </div>
                </Box>
            </>
        );

        const recommendAction = () => {
            if (!tenantIncome || !rent) {
                return RecommendedAction.UNKNOWN;
            }

            const halfSalary = tenantIncome * 0.5;
            const point6Salary = tenantIncome * 0.6;

            if (
                rent <= halfSalary &&
                ((!creditScore && !rating) ||
                    (creditScore && creditScore >= CreditScoreRange.GOOD) ||
                    (rating && rating >= RatingRange.GOOD))
            ) {
                return RecommendedAction.ACCEPT;
            } else if (
                rent <= point6Salary &&
                ((!creditScore && !rating) ||
                    (creditScore && creditScore >= CreditScoreRange.FAIR) ||
                    (rating && rating >= RatingRange.FAIR))
            ) {
                return RecommendedAction.CAUTION;
            } else {
                return RecommendedAction.HIGHRISK;
            }
        };

        const ratingMessage = () => {
            if (!rating) {
                return;
            }

            if (rating >= RatingRange.GOOD) {
                return 'high rating';
            } else if (rating >= RatingRange.FAIR) {
                return 'decent rating';
            } else {
                return 'poor rating';
            }
        };

        const incomeMessage = () => {
            if (!tenantIncome || !rent) {
                return;
            }

            const halfSalary = tenantIncome * 0.5;
            const point6Salary = tenantIncome * 0.6;

            if (rent <= halfSalary) {
                return 'great income';
            } else if (rent <= point6Salary) {
                return 'decent income';
            } else {
                return 'low income';
            }
        };

        const creditScoreMessage = () => {
            if (!creditScore) {
                return;
            }

            if (creditScore >= CreditScoreRange.GOOD) {
                return 'great credit score';
            } else if (creditScore >= CreditScoreRange.FAIR) {
                return 'decent credit score';
            } else {
                return 'poor credit score';
            }
        };

        const checkRecommendation = (): {
            color: string;
            recommendation: RecommendedAction;
            summary: string;
        } => {
            if (!rent || !tenantIncome) {
                return {
                    color: classes.greyTextColor,
                    recommendation: RecommendedAction.UNKNOWN,
                    summary:
                        "We are unable to recommend an action. The rent of the property and the tenant's monthly income is required to make an accurate recommendation",
                };
            } else {
                const action = recommendAction();
                const incomeMsg = incomeMessage();
                const ratingMsg = ratingMessage();
                const creditScoreMsg = creditScoreMessage();

                const result = {
                    color:
                        action === RecommendedAction.ACCEPT
                            ? classes.successColor
                            : action === RecommendedAction.CAUTION
                            ? classes.warningColor
                            : classes.errorColor,
                    recommendation: action,
                    summary:
                        action === RecommendedAction.HIGHRISK
                            ? 'High risk tenant. Do consider other options'
                            : `This tenant has a ${incomeMsg}${ratingMsg ? `, ${ratingMsg}` : ''}${
                                  creditScoreMsg ? `, ${creditScoreMsg}` : ''
                              }.`,
                };

                switch (action) {
                    case RecommendedAction.ACCEPT: {
                        result.summary +=
                            'Our analysis indicates that this tenant meets all requirements';
                        break;
                    }
                    case RecommendedAction.CAUTION: {
                        result.summary +=
                            'Our analysis indicates that this tenant meets some of the requirements';
                        break;
                    }
                    default: {
                        break;
                    }
                }

                return result;
            }
        };

        useEffect(() => {
            const init = async () => {
                if (listingId) {
                    const term = await listingStore.getListingTerm(listingId);
                    setRent(term?.rentAmount);
                }

                if (tenantId) {
                    const creditReport = await creditReportStore.getCreditReport(tenantId);
                    if (creditReport?.creditScore) {
                        setCreditScore(creditReport.creditScore);
                    }
                }
            };
            init();
        }, [creditReportStore, listingId, listingStore, ratingStore, tenantId]);

        const getRecomendation = () => {
            const recommendation = checkRecommendation();
            return recommendation?.recommendation;
        };

        const getRecomendationSummary = () => {
            const recommendation = checkRecommendation();
            return recommendation?.summary;
        };

        const getRecomendationColor = () => {
            const recommendation = checkRecommendation();
            return recommendation?.color;
        };
        const screeningReportElement = (
            icon: JSX.Element,
            title: string,
            value: number,
            color: string,
            subtitle: string,
        ) => (
            <>
                <Grid item xs={6} sm={2}>
                    <Grid container direction='column' style={{ textAlign: 'center' }}>
                        <Grid item xs={12}>
                            {icon}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1' style={{ whiteSpace: 'nowrap' }}>
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5' style={{ color: `${color}` }}>
                                {value}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant='overline'
                                style={{ textTransform: 'uppercase', color: 'grey' }}
                            >
                                {subtitle}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );

        return (
            <>
                <Grid container direction='column' spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6' gutterBottom={true}>
                            Recommendation:{' '}
                            <b className={getRecomendationColor()}>{getRecomendation()}</b>
                            <Typography variant='body1' display='inline'></Typography>
                        </Typography>
                        <Typography variant='body1' gutterBottom={true}>
                            {getRecomendationSummary()}
                        </Typography>
                        <Typography variant='subtitle1' gutterBottom={true}>
                            <b>Please note:</b>
                        </Typography>
                        <Typography variant='body1' gutterBottom={true}>
                            Some information such as criminal history and evictions have not been
                            factored into this recommendation
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    {/* Occupation  */}
                                    <Grid item xs={6} md={4}>
                                        {applicationInfoElement(
                                            <WorkIcon
                                                color='primary'
                                                fontSize='large'
                                                style={{ verticalAlign: 'middle' }}
                                            />,
                                            'Occupation',
                                            occupation?.title ?? '',
                                        )}
                                    </Grid>
                                    {/* Income */}
                                    <Grid item xs={6} md={4}>
                                        {applicationInfoElement(
                                            <AttachMoneyIcon
                                                color='primary'
                                                fontSize='large'
                                                style={{ verticalAlign: 'middle' }}
                                            />,
                                            'Income',
                                            `${convertIncomeType(
                                                occupation?.income ?? 0,
                                                occupation?.incomeType ?? IncomeType.YEARLY,
                                                IncomeType.YEARLY,
                                            )}`,
                                            IncomeType.YEARLY,
                                        )}
                                    </Grid>
                                    {/* Movein */}
                                    <Grid item xs={6} md={4}>
                                        {applicationInfoElement(
                                            <CalendarTodayIcon
                                                color='primary'
                                                fontSize='large'
                                                style={{ verticalAlign: 'middle' }}
                                            />,
                                            'Move-in',
                                            format(moveinDate, 'PP'),
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={7}>
                                <Grid container spacing={2}>
                                    {Object.keys(grades || {}).map((gradeType, i) => (
                                        <Grid item xs={6} sm={4} key={`rating-grade-${i}`}>
                                            <AttributeRating
                                                totalPossibleRating={5}
                                                rating={
                                                    !!grades ? grades[gradeType as GradeType] : 0
                                                }
                                                title={gradeType}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        {/* additional information */}
                        <Grid item xs={6} md={4}>
                            {applicationInfoElement(
                                <PetsIcon
                                    color='primary'
                                    style={{ verticalAlign: 'middle' }}
                                    fontSize='large'
                                />,
                                'Pets',
                                pets ?? '',
                            )}
                        </Grid>
                        <Grid item xs={6} md={4}>
                            {applicationInfoElement(
                                <SmokingRoomsIcon
                                    color='primary'
                                    style={{ verticalAlign: 'middle' }}
                                    fontSize='large'
                                />,
                                'Smoking',
                                smoking ?? '',
                            )}
                        </Grid>

                        <Grid item xs={6} md={4}>
                            {applicationInfoElement(
                                <DriveEtaIcon
                                    color='primary'
                                    style={{ verticalAlign: 'middle' }}
                                    fontSize='large'
                                />,
                                'Vehicles',
                                vehicle ?? '',
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {/* Screening Report Summary */}
                        <Box mt={4} mb={3}>
                            <Typography
                                variant='h5'
                                style={{ textTransform: 'uppercase' }}
                                display='inline'
                            >
                                Screening Report
                            </Typography>
                            <Typography variant='h5' display='inline'>
                                {' '}
                                (TransUnion)
                            </Typography>
                        </Box>
                        <Grid container direction='row'>
                            {screeningReportElement(
                                <CreditCardOutlinedIcon fontSize='large' />,
                                'Credit Score',
                                screeningReport?.creditScore || 0,
                                screeningReport?.creditScoreRange.color || 'rgb(255, 98, 101)',
                                screeningReport?.creditScoreRange
                                    ? `range ${screeningReport?.creditScoreRange.lower}-${screeningReport?.creditScoreRange.upper}`
                                    : 'N/A',
                            )}

                            {screeningReportElement(
                                <GavelOutlinedIcon fontSize='large' />,
                                'Criminal History',
                                screeningReport?.criminalHistory || 0,
                                screeningReport?.criminalHistory &&
                                    screeningReport?.criminalHistory > 0
                                    ? 'rgb(255, 98, 101)'
                                    : 'rgb(67, 224, 193)',
                                `Record${
                                    screeningReport?.criminalHistory &&
                                    screeningReport?.criminalHistory === 1
                                        ? ''
                                        : 's'
                                } found`,
                            )}

                            {screeningReportElement(
                                <HouseOutlinedIcon fontSize='large' />,
                                'Evictions',
                                screeningReport?.evictions || 0,
                                screeningReport?.evictions && screeningReport?.evictions > 0
                                    ? 'rgb(255, 98, 101)'
                                    : 'rgb(67, 224, 193)',
                                `Record${
                                    screeningReport?.evictions && screeningReport?.evictions === 1
                                        ? ''
                                        : 's'
                                } found`,
                            )}

                            {screeningReportElement(
                                <WorkOutlineOutlinedIcon fontSize='large' />,
                                'Employers',
                                screeningReport?.employers || 0,
                                screeningReport?.employers && screeningReport?.employers > 0
                                    ? 'rgb(255, 98, 101)'
                                    : 'rgb(67, 224, 193)',
                                `Employer${
                                    screeningReport?.employers && screeningReport?.employers === 1
                                        ? ''
                                        : 's'
                                } on file`,
                            )}

                            {screeningReportElement(
                                <ErrorOutlineOutlinedIcon fontSize='large' />,
                                'Collections',
                                screeningReport?.collections || 0,
                                screeningReport?.collections && screeningReport?.collections > 0
                                    ? 'rgb(255, 98, 101)'
                                    : 'rgb(67, 224, 193)',
                                `Collection account${
                                    screeningReport?.collections &&
                                    screeningReport?.collections === 1
                                        ? ''
                                        : 's'
                                }`,
                            )}

                            {screeningReportElement(
                                <AccountBalanceOutlinedIcon fontSize='large' />,
                                'Public Records',
                                screeningReport?.publicRecords || 0,
                                screeningReport?.publicRecords && screeningReport?.publicRecords > 0
                                    ? 'rgb(255, 98, 101)'
                                    : 'rgb(67, 224, 193)',
                                `Record${
                                    screeningReport?.publicRecords &&
                                    screeningReport?.publicRecords === 1
                                        ? ''
                                        : 's'
                                } found`,
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    },
);
