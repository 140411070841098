import { AuthErrorCodes } from 'firebase/auth';

export interface AuthError {
  code: string;
  message: string;
}

export const AuthErrorCodesEx = {
  ...AuthErrorCodes,
  EMAIL_NOT_VERIFIED: 'email-not-verified',
  USER_NOT_AUTHORIZED: 'user-not-authorized'
}

export const AuthErrors: AuthError[] = [
  {
    code: AuthErrorCodesEx.INVALID_EMAIL,
    message: 'The email you entered is invalid. Try again.'
  },
  {
    code: AuthErrorCodes.USER_DISABLED,
    message: 'This account has been disabled.'
  },
  {
    code: AuthErrorCodesEx.USER_DELETED,
    message: 'You do not have an account with us. Create one.'
  },
  {
    code: AuthErrorCodesEx.INVALID_PASSWORD,
    message: 'The password you entered is incorrect. Try again.'
  },
  {
    code: AuthErrorCodesEx.TOO_MANY_ATTEMPTS_TRY_LATER,
    message: 'You have tried to login one too many times. Try again later.'
  },
  {
    code: AuthErrorCodesEx.EMAIL_EXISTS,
    message: 'It appears you already have an account with us. Try sign in.'
  },
  {
    code: AuthErrorCodesEx.OPERATION_NOT_ALLOWED,  // Thrown if email/password accounts are not enabled. Enable email/password accounts in the Firebase Console, under the Auth tab.
    message: 'This account has been disabled.'
  },
  {
    code: AuthErrorCodesEx.WEAK_PASSWORD,
    message: 'The password is too week. Consider a password longer than 8 characters and contains Letters, Numbers and Symbol.'
  },
  {
    code: AuthErrorCodesEx.EMAIL_NOT_VERIFIED,
    message: 'Your account is not active. Verify your email with the link emailed to you'
  },
  {
    code: AuthErrorCodesEx.USER_NOT_AUTHORIZED,
    message: 'You are not authorized to carry out this action'
  }
];