import {
    AppBar,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Grid,
    IconButton,
    Slide,
    Toolbar,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import {
    ChevronRight as ChevronRightIcon,
    ChevronLeft as ChevronLeftIcon,
    Close as CloseIcon,
} from '@material-ui/icons';
import { useStyles } from './styles';

const Transition: React.FC = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});
export const PhotoGalleryModal: React.FC<{
    title: string;
    isOpen: boolean;
    handleClose: () => void;
    listingPhotos: { id: string; url: string }[];
    clickedImageIndex: number;
}> = observer(({ title, isOpen, handleClose, listingPhotos, clickedImageIndex }) => {
    const [currentImage, setCurrentImage] = useState<string>(listingPhotos[clickedImageIndex].url);
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(clickedImageIndex);

    const classes = useStyles();

    const totalLength = listingPhotos.length;
    const handleNext = () => {
        if (currentImageIndex + 1 >= totalLength) {
            setCurrentImageIndex(0);
            const firstImage = listingPhotos[0].url;
            setCurrentImage(firstImage);
            return;
        }
        const nextIndex = currentImageIndex + 1;
        setCurrentImage(listingPhotos[nextIndex].url);
        setCurrentImageIndex(nextIndex);
    };

    const handlePrevious = () => {
        if (currentImageIndex === 0) {
            setCurrentImageIndex(totalLength - 1);
            const lastImage = listingPhotos[totalLength - 1].url;
            setCurrentImage(lastImage);
            return;
        }
        const prevIndex = currentImageIndex - 1;
        setCurrentImage(listingPhotos[prevIndex].url);
        setCurrentImageIndex(prevIndex);
    };

    return (
        <>
            <Dialog
                fullScreen={true}
                open={isOpen}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <DialogContent style={{ minHeight: '200px' }}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography variant='h6' className={classes.title}>
                                {title}
                            </Typography>
                            <IconButton
                                color='inherit'
                                onClick={handleClose}
                                aria-label='close'
                                style={{ float: 'right' }}
                            >
                                <CloseIcon />
                            </IconButton>
                            {/* <Button onClick={handleClose}>save</Button> */}
                        </Toolbar>
                    </AppBar>
                    <Divider style={{ width: '100%', marginBottom: '1rem' }} />
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={1}
                            justifyContent='flex-start'
                            alignItems='center'
                            alignContent='center'
                            style={{ height: '85vh', display: 'flex' }}
                        >
                            <IconButton onClick={handlePrevious}>
                                <ChevronLeftIcon fontSize='large' />
                            </IconButton>
                        </Grid>
                        <Grid item xs={10}>
                            <img
                                src={currentImage}
                                alt='Enlarged listing'
                                style={{ width: '100%', height: '85vh', objectFit: 'contain' }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={1}
                            justifyContent='flex-end'
                            alignItems='center'
                            alignContent='center'
                            style={{ height: '85vh', display: 'flex' }}
                        >
                            <IconButton onClick={handleNext}>
                                <ChevronRightIcon fontSize='large' />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Typography align='center'>
                        {currentImageIndex + 1}/{totalLength}
                    </Typography>
                </DialogActions>
            </Dialog>
        </>
    );
});
