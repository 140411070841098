import { Button } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FundWalletCheckout } from './fundWalletCheckout';

export interface FundWalletButtonProps {
    variant?: 'outlined' | 'text' | 'contained' | undefined;
    color?: 'primary' | 'inherit' | 'secondary' | 'default' | undefined;
    size?: 'small' | 'medium' | 'large' | undefined;
    text?: string;
}

export const FundWalletButton: React.FC<FundWalletButtonProps> = observer(
    ({ text = 'Fund Wallet', variant = 'outlined', color = 'primary', size = 'small' }) => {
        const [openCheckout, setOpenCheckout] = React.useState(false);

        const handleOpenCheckout = () => {
            setOpenCheckout(true);
        };

        const handleCloseCheckout = () => {
            setOpenCheckout(false);
        };

        const handleCheckoutComplete = () => {
            console.log('Fund Wallet Checkout complete');
        };

        const handleCheckoutFailure = () => {
            console.log('Fund Wallet Checkout Failure');
        };

        return (
            <>
                <Button
                    variant={variant}
                    color={color}
                    size={size}
                    aria-haspopup='true'
                    onClick={handleOpenCheckout}
                >
                    {text}
                </Button>
                <FundWalletCheckout
                    open={openCheckout}
                    handleClose={handleCloseCheckout}
                    handleComplete={handleCheckoutComplete}
                    handleFailure={handleCheckoutFailure}
                />
            </>
        );
    },
);
