import { observer } from 'mobx-react-lite';
import { Breadcrumbs, Typography, Link } from '@material-ui/core';
import React from 'react';
import { AdminPageLayout } from '../../components/uiComponents/UIComponents';
import { PrivacyPolicyComponent } from '../../components/help/privacyPolicy';

export const PrivacyPolicyPage: React.FC = observer(() => {
    return (
        <AdminPageLayout
            header={
                <div>
                    <Breadcrumbs separator='›' aria-label='breadcrumb'>
                        <Link color='inherit' href='/'>
                            Home
                        </Link>
                        <Typography color='textPrimary'>Privacy Policy</Typography>
                    </Breadcrumbs>
                    <Typography variant='h4' gutterBottom>
                        Privacy policy
                    </Typography>
                </div>
            }
            content={<PrivacyPolicyComponent />}
            maxWidth='md'
        />
    );
});
