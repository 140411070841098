
export const StringIsNullOrWhitespace = (s: string | null | undefined) => !s || s.trim().length === 0;
export const IsValidBoolean = (b: boolean) => /true/i.test(`${b}`) || /false/i.test(`${b}`);
// export const IsValidNumber = (val: any) => !!(val || val === 0) && !isNaN(Number(val.toString()));
export const IsValidNumber = (val: any): boolean =>
  val !== null && val !== undefined && !isNaN(Number(val)) && isFinite(Number(val));
export const LoadWindowScript = (src: string, position: HTMLElement | null, id: string) => {
  if (!document) {
    throw new Error('No document/ window present');
  }
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('defer', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
};