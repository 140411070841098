import React from 'react';
import { observer } from 'mobx-react-lite';
import { GridContent, GridMainContent } from '../uiComponents/UIComponents';
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import { IRentApplicationForm } from 'realhaus-sdk';

interface AdditionalInfoProps {
    rentApplication: IRentApplicationForm;
    updateInfo: (prop: keyof IRentApplicationForm) => (value: string) => void;
}
export const AdditionalInfoComponent: React.FC<AdditionalInfoProps> = observer(
    ({ rentApplication, updateInfo }) => {
        const handleAdditionalInfoChange =
            (prop: keyof IRentApplicationForm) => (ev: React.ChangeEvent<HTMLInputElement>) => {
                updateInfo(prop)(ev.target.value);
            };
        return (
            <>
                <GridContent spacing={5}>
                    <GridMainContent>
                        <Typography variant='h5' gutterBottom>
                            Pets, Vehicles, Smoking?
                        </Typography>
                    </GridMainContent>
                    <GridMainContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormControl component='fieldset'>
                                    <FormLabel component='label'>
                                        Will you have pets with you on this property?
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-label='pets'
                                        name='pets'
                                        value={rentApplication.hasPets ?? ''}
                                        onChange={handleAdditionalInfoChange('hasPets')}
                                    >
                                        <FormControlLabel
                                            value='yes'
                                            control={<Radio />}
                                            label='Yes'
                                        />
                                        <FormControlLabel
                                            value='no'
                                            control={<Radio />}
                                            label='No'
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl component='fieldset'>
                                    <FormLabel component='label'>
                                        Will you have a vehicle on the property?
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-label='vehicle'
                                        name='vehicle'
                                        value={rentApplication.hasVehicle ?? ''}
                                        onChange={handleAdditionalInfoChange('hasVehicle')}
                                    >
                                        <FormControlLabel
                                            value='yes'
                                            control={<Radio />}
                                            label='Yes'
                                        />
                                        <FormControlLabel
                                            value='no'
                                            control={<Radio />}
                                            label='No'
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl component='fieldset'>
                                    <FormLabel component='label'>Do you smoke?</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-label='smokes'
                                        name='smokes'
                                        value={rentApplication.smokes ?? ''}
                                        onChange={handleAdditionalInfoChange('smokes')}
                                    >
                                        <FormControlLabel
                                            value='yes'
                                            control={<Radio />}
                                            label='Yes'
                                        />
                                        <FormControlLabel
                                            value='yes-outdoors'
                                            control={<Radio />}
                                            label='Yes but Outdoors only'
                                        />
                                        <FormControlLabel
                                            value='yes-occasionally'
                                            control={<Radio />}
                                            label='Yes but Occasionally'
                                        />
                                        <FormControlLabel
                                            value='no'
                                            control={<Radio />}
                                            label='No'
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </GridMainContent>
                </GridContent>
            </>
        );
    },
);
