import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Grid,
    IconButton,
    Typography,
} from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { RootStoreContext } from '../../global/storeContext';
import { IdPropertyListing, IListingTerm } from 'realhaus-sdk';
import { Overlay, OverlayContent, Spacing, useStyles } from '../../pages/styles';
import { paths } from '../../routes';
import StringUtils from '../../utils/string';

interface prop {
    listingId: string;
    isBookmarked: (bookmarkedStatus: boolean) => void;
}

export const CardListing: React.FC<prop> = observer(({ listingId, isBookmarked }) => {
    const { listingStore, uiStore } = useContext(RootStoreContext);
    const [listing, setListing] = useState<IdPropertyListing | undefined>();
    const [leaseTerm, setLeaseTerm] = React.useState<IListingTerm | undefined>();
    const [isSaved, setIsSaved] = useState<boolean>(false);

    const classes = useStyles();

    const viewListingPath = (listingId: string) =>
        paths.listings.detail.replace(':listingId', listingId);

    const toggleBookmark = (listingId: string) => {
        if (isSaved) {
            listingStore.removeBookmarkedListing(listingId).then(() => {
                setIsSaved(false);
                uiStore.success('Bookmark updated');
                isBookmarked(false);
            });
        } else {
            listingStore.createBookmark(listingId).then(() => {
                setIsSaved(true);
                uiStore.success('Bookmark updated');
                isBookmarked(true);
            });
        }
    };
    const BookmarkIconComponent = () => {
        return (
            <IconButton
                color='primary'
                onClick={(e) => {
                    e.preventDefault();
                    toggleBookmark(listingId);
                }}
            >
                {isSaved ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </IconButton>
        );
    };

    useEffect(() => {
        listingStore.getListing(listingId).then((listing) => {
            setListing(listing);
        });

        listingStore.getListingTerm(listingId).then((term) => {
            setLeaseTerm(term);
        });

        // check if listing is bokmarked
        listingStore.getBookmarkedListings().then((listingIds) => {
            setIsSaved(listingIds.some((x) => x === listingId));
        });
    }, [listingId]);

    const moveInStatus = (leaseTerm?.dateAvailable ?? 0) >= Date.now() ? '' : 'Move in now';

    return (
        <Card className={classes.listingCard}>
            <CardActionArea href={viewListingPath(listingId)}>
                {listing?.photos && listing.photos.length > 0 ? (
                    <CardMedia className={classes.listingCardMedia} image={listing.photos[0].url} />
                ) : null}
                <Overlay>
                    <OverlayContent>
                        <Grid container>
                            <Grid item xs={10}>
                                <Typography
                                    variant='button'
                                    display='block'
                                    style={{ marginTop: '12px' }}
                                >
                                    {moveInStatus}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <BookmarkIconComponent />
                            </Grid>
                        </Grid>
                    </OverlayContent>
                </Overlay>
                <div className={classes.listingCardDetails}>
                    <CardContent>
                        <Typography variant='h6' className={classes.ellipsis}>
                            {listing?.title}
                        </Typography>
                        <Typography variant='subtitle1'>
                            <Box fontWeight='fontWeightBold'>
                                {`From ${StringUtils.asCurrency(leaseTerm?.rentAmount ?? 0)}`}
                            </Box>
                        </Typography>
                        <Spacing />
                        <Typography variant='subtitle1' color='textSecondary'>
                            {`${listing?.address.unit ? `${listing?.address.unit} ` : ''}${
                                listing?.address.streetAddress
                            }, ${listing?.address.city}, ${listing?.address.province}`}
                        </Typography>
                        <Typography variant='subtitle1'>
                            {`${listing?.bedrooms} bed`} | {`${listing?.bathrooms} bath`} |{' '}
                            {`${listing?.size} sq.ft.`}
                        </Typography>
                    </CardContent>
                </div>
            </CardActionArea>
        </Card>
    );
});
