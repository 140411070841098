import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../global/storeContext';
import { IdMessage } from 'realhaus-sdk';
import {
    Avatar,
    Box,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Button,
    IconButton,
} from '@material-ui/core';
import { useStyles } from './style';
import { format } from 'date-fns';
import MessageIcon from '@material-ui/icons/Message';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import { InputField } from '../../components/formComponents/inputfield';
import { parseMessage } from '../../utils/message';
import { ArrowBack } from '@material-ui/icons';
import { Stack } from '@mui/material';
interface InboxConversationDetailsProps {
    conversationId: string;
    isMobile?: boolean;
    messageBack?: () => void;
}
export const InboxConversationDetails: React.FC<InboxConversationDetailsProps> = observer(
    ({ conversationId, isMobile = false, messageBack }) => {
        const classes = useStyles();
        const messagesEndRef = React.createRef<HTMLDivElement>();
        const { messageStore, userStore, uiStore } = useContext(RootStoreContext);

        const [members, setMembers] = useState<Map<string, { name: string; avatar: string }>>(
            new Map<string, { name: string; avatar: string }>(),
        );

        const [messages, setMessages] = useState<IdMessage[]>([]);
        const [sendScrollToBottom, setSendScrollToBottom] = useState<boolean>(false);

        const addNewMessage = (msg: IdMessage) => {
            setMessages([...messages, msg]);
        };

        const removeOldMessage = (msg: IdMessage) => {
            setMessages((messages) => messages.filter((message) => message.id !== msg.id));
        };

        const scrollToBottom = () => {
            if (messagesEndRef.current) {
                messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
            }
        };

        useEffect(() => {
            // get members
            const init = async () => {
                const convo = await messageStore.getConversation(conversationId);

                const memberProfiles = await Promise.all(
                    convo.members.map(async (member) => {
                        const profile = await userStore.getUserProfile(member);
                        return Promise.resolve({
                            id: member,
                            name: profile?.firstname,
                            avatar: '',
                        });
                    }),
                );

                // get member profiles
                const m = new Map<string, { name: string; avatar: string }>();
                memberProfiles.forEach((p) => {
                    m.set(p.id, { name: p.name ?? '', avatar: p.avatar });
                });

                setMembers(m);
                // await fetchMessages();
                messageStore.subscribeMessages(conversationId, addNewMessage, removeOldMessage);
            };

            init();
        }, [conversationId]);

        const senderProfile = (userId: string): { name: string; avatar: string } | undefined =>
            members.get(userId);

        const isSameDay = (timestamp: number, timestamp2: number) => {
            const d1 = new Date(timestamp);
            const d2 = new Date(timestamp2);

            return (
                d1.getFullYear() === d2.getFullYear() &&
                d1.getMonth() === d2.getMonth() &&
                d1.getDate() === d2.getDate()
            );
        };

        const memberNames = () => {
            const names: string[] = [];

            members.forEach((val, key) => {
                if (key !== userStore.userId) {
                    names.push(val.name);
                }
            });

            return names.join(', ');
        };

        const [message, setMessage] = useState<string | null>('');
        const handleMessageChange = (msg: string | null) => {
            if (msg) {
                setMessage(msg);
            } else {
                setMessage('');
            }
        };
        const sendMessage = async () => {
            if (message) {
                uiStore.showLoading();
                try {
                    await messageStore.sendMessage(conversationId, message);
                    setSendScrollToBottom(true);
                    setMessage('');
                } catch (error) {
                    console.log('Error trying to send message', error);
                } finally {
                    uiStore.hideLoading();
                }
            }
        };

        useEffect(() => {
            scrollToBottom();
        }, [messageStore.messages]);

        useEffect(() => {
            if (sendScrollToBottom) {
                scrollToBottom();
                setSendScrollToBottom(false);
            }
        }, [messagesEndRef]);

        return (
            <>
                <Grid container className={classes.grid}>
                    <Grid item xs={12}>
                        <Box className={classes.contentPadding} display='flex'>
                            <Stack direction='row' alignItems='center' spacing={2}>
                                {isMobile && (
                                    <IconButton
                                        onClick={() => {
                                            !!messageBack && isMobile && messageBack();
                                        }}
                                    >
                                        <ArrowBack />
                                    </IconButton>
                                )}
                                <Typography component='h6' variant='h6'>
                                    {memberNames()}
                                </Typography>
                            </Stack>
                        </Box>
                    </Grid>
                    <Box className={`${classes.grid}`}>
                        <Grid item md={12}>
                            <div className={classes.messagesBox} ref={messagesEndRef}>
                                <List className={classes.messageWrapper}>
                                    {messageStore.messages.map((msg, index) => {
                                        const sender = senderProfile(msg.sender);
                                        return (
                                            <Box key={index}>
                                                {(index === 0 ||
                                                    !isSameDay(
                                                        msg.timestamp,
                                                        messageStore.messages[index - 1].timestamp,
                                                    )) && (
                                                    <ListItem alignItems='center'>
                                                        <ListItemText
                                                            secondary={
                                                                <>
                                                                    <Typography
                                                                        variant='body2'
                                                                        color='textSecondary'
                                                                        align='center'
                                                                        component='span'
                                                                        style={{ display: 'block' }}
                                                                    >
                                                                        {format(
                                                                            msg.timestamp,
                                                                            'PP',
                                                                        )}
                                                                    </Typography>
                                                                </>
                                                            }
                                                        />
                                                    </ListItem>
                                                )}
                                                {sender && msg.type === 'message' && (
                                                    <Box
                                                        display='flex'
                                                        justifyContent='space-between'
                                                        alignItems='center'
                                                        m={2}
                                                    >
                                                        <ListItemAvatar>
                                                            <Avatar
                                                                alt={sender.name}
                                                                src={sender.avatar}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={
                                                                <>
                                                                    <Typography
                                                                        component='span'
                                                                        variant='subtitle1'
                                                                        className={classes.textBold}
                                                                    >
                                                                        {`${sender.name} `}
                                                                    </Typography>
                                                                    <Typography
                                                                        component='span'
                                                                        variant='caption'
                                                                        color='textSecondary'
                                                                    >
                                                                        {format(msg.timestamp, 'p')}
                                                                    </Typography>
                                                                </>
                                                            }
                                                            secondary={
                                                                <>
                                                                    <Typography
                                                                        variant='body1'
                                                                        color='textSecondary'
                                                                        component='span'
                                                                    >
                                                                        {msg.message}
                                                                    </Typography>
                                                                </>
                                                            }
                                                        />
                                                    </Box>
                                                )}

                                                {msg.type === 'event' && (
                                                    <Box
                                                        m={2}
                                                        py={1}
                                                        px={2}
                                                        display='flex'
                                                        alignItems='center'
                                                        bgcolor='secondary.light'
                                                        borderRadius='20px'
                                                        fontSize={12}
                                                        justifyContent='space-between'
                                                    >
                                                        <MessageIcon fontSize='inherit' />
                                                        <Box ml={1} component='span'>
                                                            {parseMessage(msg.message)}
                                                        </Box>
                                                        <Box ml={1} component='span'>
                                                            {format(msg.timestamp, 'p')}
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Box>
                                        );
                                    })}
                                </List>
                            </div>
                        </Grid>
                        <Box display='flex' justifyContent='center' alignItems='center'>
                            <AddPhotoAlternateIcon />
                            <Box mx={1} width='50%'>
                                <InputField
                                    onChange={handleMessageChange}
                                    id='message'
                                    placeholder='Type a message'
                                    fullWidth={true}
                                    size='small'
                                    value={message ?? ''}
                                    multiline
                                    maxRows={2}
                                    InputProps={{
                                        className: classes.inputBorderRadius,
                                    }}
                                />
                            </Box>
                            <Button
                                color='primary'
                                variant='contained'
                                size='small'
                                onClick={sendMessage}
                            >
                                Send
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </>
        );
    },
);
