import { styled } from '@material-ui/core/styles';

export const RootContent = styled('div')({
  width: '100%',
  maxWidth: '500px'
});

export const ContentHeader = styled('div')({
  textAlign: 'center',
  marginTop: '15px',
  marginBottom: '15px',
  padding: '16px',
});

export const ContentBody = styled('div')({
  padding: '16px'
});

export const ContentFooter = styled(ContentHeader)({});