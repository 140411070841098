import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Divider, Grid, Link, MenuItem, TextField, Typography } from '@material-ui/core';
import { IEmergencyContact, ITenantProfile, IUserBio } from 'realhaus-sdk';
import { InputField } from '../formComponents/inputfield';
import { GridContent, GridMainContent, GridSideContent } from '../uiComponents/UIComponents';
import { DatePicker } from '../datePicker/datepicker';
import { useStyles } from './styles';
import { InputFieldPhone } from '../formComponents/inputfieldPhone';
import { RootStoreContext } from '../../global/storeContext';
import { paths } from '../../routes';

interface BioInfoProps {
    profile: ITenantProfile;
    userBio: IUserBio;
    updateProfile: (
        prop: keyof ITenantProfile,
    ) => (value: string | number | IEmergencyContact | null) => void;
    updateUserBio: (prop: keyof IUserBio) => (value: string | number | null) => void;
}

export const BioInfoComponent: React.FC<BioInfoProps> = observer(
    ({ profile, userBio, updateProfile, updateUserBio }) => {
        const classes = useStyles();
        const { uiStore } = useContext(RootStoreContext);

        const handleDOBChange = (date: Date | null) => {
            updateUserBio('dateOfBirth')(!!date ? date.getTime() : 0);
        };

        const handleChange =
            (prop: keyof IUserBio) => (event: React.ChangeEvent<{ value: unknown }>) => {
                updateUserBio(prop)(event.target.value as string);
            };

        const handleEmergencyContactChange =
            (prop: keyof IEmergencyContact) => (value: string | number | null) => {
                updateProfile('emergencyContact')({
                    ...profile.emergencyContact,
                    [prop]: value?.toString(),
                });
            };

        return (
            <>
                <GridContent spacing={5}>
                    <GridMainContent>
                        <Typography variant='h5' gutterBottom>
                            Tell the landlord about yourself
                        </Typography>
                    </GridMainContent>
                    <GridMainContent withSidebar>
                        <Grid container spacing={2} className={classes.grid}>
                            {/* First Name */}
                            <Grid item xs={6}>
                                <Typography variant='subtitle2' gutterBottom>
                                    FIRST NAME
                                </Typography>
                                <InputField
                                    id='firstname'
                                    fullWidth
                                    placeholder='First Name'
                                    value={userBio?.firstname ?? ''}
                                    onChange={updateUserBio('firstname')}
                                    disable={true}
                                />
                            </Grid>
                            {/* Last Name */}
                            <Grid item xs={6}>
                                <Typography variant='subtitle2' gutterBottom>
                                    LAST NAME
                                </Typography>
                                <InputField
                                    id='lastname'
                                    fullWidth
                                    placeholder='Last Name'
                                    value={userBio?.lastname ?? ''}
                                    onChange={updateUserBio('lastname')}
                                    disable={true}
                                />
                            </Grid>
                            {/* Date of Birth */}
                            <Grid item xs={6}>
                                <Typography variant='subtitle2' gutterBottom>
                                    DATE OF BIRTH
                                </Typography>
                                <DatePicker
                                    id='dob-picker'
                                    selectedDate={
                                        userBio.dateOfBirth ? new Date(userBio.dateOfBirth) : null
                                    }
                                    onChange={handleDOBChange}
                                    disable={true}
                                />
                            </Grid>
                            {/* Gender */}
                            <Grid item xs={6}>
                                <Typography variant='subtitle2' gutterBottom>
                                    Gender
                                </Typography>
                                <TextField
                                    select
                                    margin='normal'
                                    id='gender'
                                    variant='outlined'
                                    value={userBio.gender ?? ''}
                                    onChange={handleChange('gender')}
                                    fullWidth
                                    disabled
                                >
                                    <MenuItem value='male'>Male</MenuItem>
                                    <MenuItem value='female'>Female</MenuItem>
                                    <MenuItem value='other'>Other</MenuItem>
                                </TextField>
                            </Grid>
                            <Divider variant='middle' className={classes.divider} />
                            <Typography variant='h6' gutterBottom>
                                Emergency Contact
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle2' gutterBottom>
                                        Full Name
                                    </Typography>
                                    <InputField
                                        id='fullname'
                                        fullWidth
                                        placeholder='Full Name'
                                        value={profile?.emergencyContact?.name ?? ''}
                                        onChange={handleEmergencyContactChange('name')}
                                        disable={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='subtitle2' gutterBottom>
                                        Email
                                    </Typography>
                                    <InputField
                                        id='email'
                                        fullWidth
                                        placeholder="Contact's Email"
                                        value={profile?.emergencyContact?.email ?? ''}
                                        onChange={handleEmergencyContactChange('email')}
                                        disable={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='subtitle2' gutterBottom>
                                        Phone Number
                                    </Typography>
                                    <InputFieldPhone
                                        id='emergencyPhone'
                                        fullWidth
                                        value={profile?.emergencyContact?.phone ?? ''}
                                        onChange={handleEmergencyContactChange('phone')}
                                        disable={true}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </GridMainContent>
                    <GridSideContent>
                        <Typography variant='body1' align='justify'>
                            Make sure all the fields in the application are filled as accurate as
                            possible so the landlord has all the information they need to make a
                            decision.
                            <br />
                            <br />
                            Your personal information can be updated through the{' '}
                            <Link color='primary' href={paths.account.personalInfo}>
                                personal info
                            </Link>{' '}
                            page.
                            <br />
                            <br />
                            Your emergency contact information can be updated through the{' '}
                            <Link color='primary' href={paths.tenant.profile}>
                                profile page
                            </Link>{' '}
                            page.
                        </Typography>
                        <Typography variant='body1' align='justify'></Typography>
                    </GridSideContent>
                </GridContent>
            </>
        );
    },
);
