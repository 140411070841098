import { observer } from 'mobx-react-lite';
import React from 'react';
import { Divider, Grid } from '@mui/material';
import { AggRatingsCard, ReviewCard, ReviewersName } from '../uiComponents/ratings';
import { Typography } from '@material-ui/core';
import { ILeaseRatingsReview, RatingsForLandlord, RatingsForTenant } from 'realhaus-sdk';

type RatingForLandlordOrTenant = RatingsForLandlord | RatingsForTenant;

export const TenantReviews: React.FC<{
    tenantReviews: ({
        id: string;
        leaseAgreementId: string;
    } & ILeaseRatingsReview)[];
    ratings: RatingForLandlordOrTenant[];
}> = observer(({ tenantReviews, ratings }) => {
    return (
        <>
            {!!tenantReviews || tenantReviews > 0 ? (
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <AggRatingsCard ratings={ratings} />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {tenantReviews.map((tenantRev, index) => (
                        <>
                            <Grid container item xs={12} spacing={2} key={index}>
                                <Grid item xs={12}>
                                    <ReviewersName
                                        userId={tenantRev.id}
                                        leaseAgreementId={tenantRev.leaseAgreementId}
                                    />
                                </Grid>
                                <Grid item container xs={12} spacing={4}>
                                    {!!tenantRev.middleOfTerm && (
                                        <Grid item xs={12} md={6}>
                                            <ReviewCard leaseReview={tenantRev.middleOfTerm} />
                                        </Grid>
                                    )}
                                    {!!tenantRev.endOfTerm && (
                                        <Grid item xs={12} md={6}>
                                            <ReviewCard leaseReview={tenantRev.endOfTerm} />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </>
                    ))}
                </Grid>
            ) : (
                <Grid item xs={12} spacing={5}>
                    <Typography variant='h5' align='center' style={{ marginTop: '20px' }}>
                        No reviews available
                    </Typography>
                </Grid>
            )}
        </>
    );
});
