import React, { useEffect, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { RightDrawer } from '../../uiComponents/rightDrawer';
import { RootStoreContext } from '../../../global/storeContext';
import {
    IdLeaseAgreement,
    LeaseAgreementStatus,
    IAddress,
    ITenantInfo,
    LeaseAgreementViewer,
    IRequestDocumentType,
} from 'realhaus-sdk';
import { streetAddress } from '../../../utils/address';
import { TabComponent, TabSection } from '../../uiComponents/tabs';
import { LeaseDocumentsComponent } from './documents';
import { LeaseOverviewComponent } from './overview';
import { LeaseReviewsComponent } from './reviews';
import { LeaseBillsComponent } from './bills';
import { ConfirmDialog } from '../../uiComponents/confirmationDialog';

interface LeaseDetailProps {
    leaseAgreementId?: string;
    isOpen: boolean;
    viewer: LeaseAgreementViewer;
    onOpen: () => void;
    onClose: () => void;
    setFilteredLeases: React.Dispatch<React.SetStateAction<IdLeaseAgreement[] | undefined>>;
    setSelectedLeaseId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const LeaseAgreementDetailComponent: React.FC<LeaseDetailProps> = observer(
    ({
        leaseAgreementId,
        isOpen,
        viewer,
        onOpen,
        onClose,
        setFilteredLeases,
        setSelectedLeaseId,
    }) => {
        const { leaseStore } = useContext(RootStoreContext);
        const [leaseAgreement, setLeaseAgreement] = useState<IdLeaseAgreement>();
        const [tenant, setTenant] = useState<ITenantInfo>();
        const [pendingRequests, setPendingRequests] = React.useState<
            ({ id: string } & IRequestDocumentType)[]
        >([]);

        const handleClose = () => {
            onClose();
        };

        const fetchPendingRequests = async () => {
            if (!leaseAgreementId) return;
            await leaseStore.getPendingRequestDocuments(leaseAgreementId).then((pendingRequest) => {
                if (!!pendingRequest && pendingRequest.length > 0) {
                    setPendingRequests(pendingRequest);
                }
            });
        };

        const init = async () => {
            if (!leaseAgreementId) {
                setLeaseAgreement(undefined);
                handleClose();
                return;
            }

            // get lease agreement
            leaseStore.getLeaseAgreementById(leaseAgreementId).then(async (l) => {
                setLeaseAgreement(l);

                if (!l || !l.tenantIds || l.tenantIds.length === 0) return;
                // get tenant
                const tenantId = l.tenantIds[0];

                const tenantInfo = await leaseStore.getTenantInfo(tenantId);
                setTenant(tenantInfo);
            });

            fetchPendingRequests();
        };

        useEffect(() => {
            init();
        }, [leaseAgreementId]);

        const sections: TabSection[] = [
            {
                key: 'lease-detail-overview',
                title: 'Overview',
                subTitle: 'View the overview of the lease agreement',
                component: !!leaseAgreement ? (
                    <LeaseOverviewComponent
                        leaseAgreement={leaseAgreement}
                        pendingRequests={pendingRequests}
                        reFetchPendingRequests={fetchPendingRequests}
                        setPendingRequests={setPendingRequests}
                    />
                ) : (
                    <></>
                ),
            },
            {
                key: 'lease-detail-documents',
                title: 'Documents',
                subTitle: 'View and download lease agreement documents',
                component: !!leaseAgreement ? (
                    <LeaseDocumentsComponent leaseAgreement={leaseAgreement} />
                ) : (
                    <></>
                ),
            },
            {
                key: 'lease-bills',
                title: 'Bills',
                subTitle: 'View tenant bills',
                component: !!leaseAgreement ? (
                    <LeaseBillsComponent leaseAgreement={leaseAgreement} tenant={tenant} />
                ) : (
                    <></>
                ),
            },
            {
                key: 'lease-detail-reviews',
                title: 'Reviews',
                subTitle: 'Rate the property',
                component: !!leaseAgreement ? (
                    <LeaseReviewsComponent leaseAgreement={leaseAgreement} viewer={viewer} />
                ) : (
                    <></>
                ),
            },
        ];
        return (
            <>
                <RightDrawer isOpen={isOpen} onOpen={onOpen} onClose={handleClose}>
                    <Grid container direction='column' spacing={3}>
                        <Grid item>
                            {!!tenant && !!leaseAgreement && (
                                <>
                                    <Box mb={2}>
                                        <LeaseDetailHeader
                                            lease={leaseAgreement}
                                            tenant={{
                                                firstname: tenant.firstname,
                                                lastname: tenant.lastname,
                                            }}
                                            listingAddress={leaseAgreement.listingInfo.address}
                                            status={leaseAgreement.status}
                                            handleRefetchData={init}
                                            setFilteredLeases={setFilteredLeases}
                                            handleCloseLeaseDrawer={handleClose}
                                            setSelectedLeaseId={setSelectedLeaseId}
                                        />
                                    </Box>
                                    <TabComponent
                                        sections={sections}
                                        direction='horizontal'
                                        onSubmit={() => {}}
                                        disableSave
                                        hideStepperButtons
                                    />
                                </>
                            )}
                        </Grid>
                        <Grid item></Grid>
                    </Grid>
                </RightDrawer>
            </>
        );
    },
);

const LeaseDetailHeader: React.FC<{
    tenant: { firstname: string; lastname: string };
    listingAddress: IAddress;
    status: LeaseAgreementStatus;
    lease: IdLeaseAgreement;
    handleRefetchData: () => void;
    handleCloseLeaseDrawer: () => void;
    setFilteredLeases: React.Dispatch<React.SetStateAction<IdLeaseAgreement[] | undefined>>;
    setSelectedLeaseId: React.Dispatch<React.SetStateAction<string | undefined>>;
}> = observer(
    ({
        lease,
        tenant,
        listingAddress,
        status,
        handleRefetchData,
        setFilteredLeases,
        handleCloseLeaseDrawer,
        setSelectedLeaseId,
    }) => {
        const [openConfirmTerminate, setOpenConfirmTerminate] = React.useState<boolean>(false);
        const { uiStore, leaseStore } = React.useContext(RootStoreContext);
        const [openConfirmRenew, setOpenConfirmRenew] = React.useState<boolean>(false);

        const handleOpenTerminateDialog = () => {
            setOpenConfirmTerminate(true);
        };

        const handleOpenRenewDialog = () => {
            setOpenConfirmRenew(true);
        };

        const handleCloseRenewDialog = () => {
            setOpenConfirmRenew(false);
        };

        const handleCloseTermninateDialog = () => {
            setOpenConfirmTerminate(false);
        };

        const handleTerminateLease = async () => {
            try {
                uiStore.showLoading();
                await leaseStore.endLeaseAgreement(lease).then(() => {
                    uiStore.success('Agreement terminated');
                    handleRefetchData();
                });
            } catch (err) {
                uiStore.error(
                    'Could not terminate agreement at this moment.Please try again later',
                );
            } finally {
                uiStore.hideLoading();
                handleCloseTermninateDialog();
            }
        };

        const handleRenewLease = async () => {
            try {
                uiStore.showLoading();
                await leaseStore.renewLeaseAgreement(lease.id).then((response) => {
                    if (!!response) {
                        uiStore.success('Lease agreement renewed successfully');
                        setFilteredLeases((prev) => {
                            if (!!prev) {
                                return [...prev, response as any as IdLeaseAgreement];
                            }
                        });
                        handleCloseLeaseDrawer();
                        setSelectedLeaseId(response.id);
                    }
                });
            } catch (err) {
                uiStore.error('Could not renew lease agreement at this time. Please try again');
            } finally {
                uiStore.hideLoading();
                handleCloseRenewDialog();
            }
        };

        const agreementStatusMessage = () => {
            switch (status) {
                case LeaseAgreementStatus.DRAFT: {
                    return 'This lease agreement is in progress.';
                }
                case LeaseAgreementStatus.PENDING_TENANT_SIGNATURE: {
                    return 'Waiting for tenant to sign.';
                }
                case LeaseAgreementStatus.SIGNED: {
                    return 'This lease is active.';
                }
                case LeaseAgreementStatus.TERMINATED: {
                    return 'This lease is terminated';
                }
                case LeaseAgreementStatus.CANCELLED: {
                    return 'This lease is cancelled';
                }
                default: {
                    return '';
                }
            }
        };

        return (
            <>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant='body1'>Lease Detail for Property at</Typography>
                        <Typography variant='h5'>
                            {streetAddress(listingAddress)}
                            <br />
                            {listingAddress.city}, {listingAddress.province}
                        </Typography>
                    </Grid>

                    <Grid container item xs={6} alignItems='flex-end' direction='column'>
                        <Grid item>
                            <Typography
                                variant='subtitle1'
                                color={
                                    lease.status === LeaseAgreementStatus.TERMINATED
                                        ? 'secondary'
                                        : 'primary'
                                }
                                gutterBottom
                            >
                                {agreementStatusMessage()}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {status === LeaseAgreementStatus.SIGNED && uiStore.isLandlordMode() ? (
                                <Grid container xs={11} spacing={0} justifyContent='flex-end'>
                                    <Grid item xs={6}>
                                        <Button
                                            color='primary'
                                            variant='outlined'
                                            onClick={handleOpenRenewDialog}
                                        >
                                            Renew
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            color='secondary'
                                            variant='outlined'
                                            onClick={handleOpenTerminateDialog}
                                        >
                                            Terminate
                                        </Button>
                                    </Grid>
                                </Grid>
                            ) : status === LeaseAgreementStatus.TERMINATED &&
                              uiStore.isLandlordMode() ? (
                                <>
                                    <Button
                                        color='primary'
                                        variant='outlined'
                                        onClick={handleOpenRenewDialog}
                                    >
                                        Renew
                                    </Button>
                                </>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>

                <ConfirmDialog
                    title={'Terminate Lease Agreement'}
                    content={
                        <>
                            <Typography>
                                Are you sure you want to terminate this agreement? This action
                                cannot be undone.
                            </Typography>
                        </>
                    }
                    openDialog={openConfirmTerminate}
                    closeDialog={(confirm: boolean) => () => {
                        if (confirm) {
                            handleTerminateLease();
                        }

                        handleCloseTermninateDialog();
                    }}
                />
                <ConfirmDialog
                    title={'Renew Lease Agreement'}
                    content={
                        <>
                            <Typography>Are you sure you want to renew this agreement?</Typography>
                        </>
                    }
                    openDialog={openConfirmRenew}
                    closeDialog={(confirm: boolean) => () => {
                        if (confirm) {
                            handleRenewLease();
                        }

                        handleCloseRenewDialog();
                    }}
                />
            </>
        );
    },
);
