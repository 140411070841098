import { Skeleton, Stack } from '@mui/material';
import React from 'react';

export const PagePlaceholder: React.FC = () => {
    return (
        <Stack spacing={2}>
            <Skeleton variant='text' />
            <Skeleton variant='rectangular' height={200} />
            <Skeleton variant='rectangular' height={300} />
            <Skeleton variant='rectangular' height={200} />
        </Stack>
    );
};

export const TwoColumnPlaceholder: React.FC = () => {
    return (
        <Stack direction='row' spacing={5} justifyContent='space-between'>
            <Skeleton variant='rectangular' width='50%' height={80} />
            <Skeleton variant='rectangular' width='50%' height={80} />
        </Stack>
    );
};
