import React, { useContext, useEffect, useState } from 'react';
import {
    Typography,
    Grid,
    Button,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    DialogActions,
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
} from '@material-ui/core';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { IdBill, IdLeaseAgreement, BillStatus, ITenantInfo } from 'realhaus-sdk';
import { RootStoreContext } from '../../../global/storeContext';
import PaymentIcon from '@material-ui/icons/Payment';
import { useStyles } from './billStyles';
import { WalletSummaryText } from '../../wallet/walletWidget';
import { SendOrRequestPaymentModal } from './request/payment/send/sendOrRequestModal';
import { NumericFormat } from 'react-number-format';
import { IsValidNumber } from '../../../global/util';
import { useIsMobible } from '../../uiComponents/UIComponents';

const BillStatuses = [
    { key: 'overdue', Text: 'Overdue' },
    { key: 'pending', Text: 'Pending' },
    { key: 'paid', Text: 'Paid' },
    { key: 'canceled', Text: 'Canceled' },
];

const resolveBillStatus = (bill: IdBill) => {
    switch (bill.status) {
        case BillStatus.CANCELED:
            return 'canceled';
        case BillStatus.PENDING:
            return bill.dueDate < Date.now() ? 'overdue' : 'pending';
        case BillStatus.PAID:
            return 'paid';
        default:
            return 'pending';
    }
};

export const LeaseBillsComponent: React.FC<{
    leaseAgreement: IdLeaseAgreement;
    tenant: ITenantInfo | undefined;
}> = observer(({ leaseAgreement, tenant }) => {
    const { leaseStore, uiStore } = useContext(RootStoreContext);
    const [statusFilter, setStatusFilter] = React.useState<string[]>(
        BillStatuses.map((s) => s.key),
    );
    const [bills, setBills] = useState<IdBill[]>([]);
    const [filteredBills, setFilteredBills] = useState<IdBill[]>([]);
    const [openPaymentModal, setPaymentModal] = React.useState(false);

    const handlePaymentModalClose = () => {
        setPaymentModal(false);
    };

    const handlePaymentModalOpen = () => {
        setPaymentModal(true);
    };

    const isLandlordMode = uiStore.isLandlordMode();

    useEffect(() => {
        // fetch lease bills;
        fetchBills();
    }, []);

    const fetchBills = async () => {
        const leaseBills = await leaseStore.getLeaseBills(leaseAgreement.id);
        setBills(leaseBills);
        setFilteredBills(leaseBills);
    };

    const filterBills = (filteredStatuses: string[]) => {
        let filtered = [...(bills ?? [])];
        if (filteredStatuses.length > 0) {
            filtered = filtered.filter((bill) =>
                filteredStatuses.find((f) => f === resolveBillStatus(bill)),
            );
        }
        setFilteredBills(filtered);
    };

    const handleStatusFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let filteredValues = event.target.value as string[];
        filteredValues =
            filteredValues.length === 0 ? BillStatuses.map((s) => s.key) : filteredValues;
        setStatusFilter(filteredValues);
        filterBills(filteredValues);
    };

    const handlePaymentRequested = async () => {
        await fetchBills();
        filterBills(statusFilter);
        console.log('onPaymentRequested Called');
    };
    const isMobile = useIsMobible();

    return (
        <>
            <Grid container spacing={2} direction='column'>
                <Grid item container justifyContent='space-between' spacing={1}>
                    <Grid item xs={12} md={4}>
                        {isLandlordMode && (
                            <Button
                                variant='outlined'
                                color='primary'
                                onClick={handlePaymentModalOpen}
                            >
                                send/Request payment
                            </Button>
                        )}
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent={isMobile ? 'flex-start' : 'flex-end'}
                        xs={12}
                        md={8}
                    >
                        <Grid item>
                            <WalletSummaryText />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>Filter:</Grid>
                <Grid item>
                    <FormControl
                        variant='outlined'
                        size='small'
                        style={{ minWidth: '250px', maxWidth: '300px' }}
                    >
                        <Select
                            id='lease-status-filter'
                            variant='outlined'
                            multiple
                            value={statusFilter}
                            onChange={handleStatusFilterChange}
                            renderValue={(selected) => (selected as string[]).join(', ')}
                        >
                            {BillStatuses.map((status) => (
                                <MenuItem key={status.key} value={status.key}>
                                    <Checkbox
                                        checked={!!statusFilter.find((f) => f === status.key)}
                                    />
                                    <ListItemText primary={status.Text} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    {/* filtered Bills */}
                    {filteredBills?.length === 0 ? (
                        <Typography variant='subtitle1'>
                            {!!bills && bills?.length > 0
                                ? 'Could not find a lease bill that matches the filter'
                                : 'There are no bills available'}
                        </Typography>
                    ) : (
                        filteredBills?.map((bill) => (
                            <LeaseBillSnapshot key={bill.id} bill={bill} />
                        ))
                    )}
                </Grid>
            </Grid>
            <SendOrRequestPaymentModal
                open={openPaymentModal}
                handleCloseModal={handlePaymentModalClose}
                tenantName={tenant?.firstname}
                leaseAgreement={leaseAgreement}
                onPaymentRequested={handlePaymentRequested}
            />
        </>
    );
});

export const LeaseBillSnapshot: React.FC<{ bill: IdBill }> = observer(({ bill }) => {
    const classes = useStyles();

    const { uiStore, walletStore } = useContext(RootStoreContext);

    const isTenant = () => {
        // TODO: update this uiStore method to use a more efficient tenant resolver process
        return uiStore.isTenantMode();
    };

    const isDisabled = () => {
        return bill.status === BillStatus.PAID || bill.status === BillStatus.CANCELED;
    };

    const isCanceled = () => {
        return bill.status === BillStatus.CANCELED;
    };

    const billStatus = resolveBillStatus(bill);

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openInsufficientDialog, setOpenInsufficientDialog] = useState(false);

    const handleBillPayment = () => {
        uiStore.showLoading();
        walletStore
            .getWallet()
            .then((balance) => {
                if (!balance || balance.amount < bill.amount) {
                    setOpenInsufficientDialog(true);
                } else {
                    setOpenConfirmDialog(true);
                }
            })
            .finally(() => {
                uiStore.hideLoading();
            });
    };

    const handleIsPaidBillPayment = () => {
        setOpenConfirmDialog(true);
    };

    const closeBillPaymentDialog = () => {
        setOpenConfirmDialog(false);
        setOpenInsufficientDialog(false);
    };

    const handleConfirmBillPayment = async () => {
        closeBillPaymentDialog();
        if (!isTenant()) {
            uiStore.error('Unable to pay this bill!');
        }
        uiStore.showLoading();

        // pay bill
        walletStore
            .payBill(
                bill.leaseId,
                bill.id,
                bill.debtorId,
                bill.amount,
                `Bill payment of type '${bill.type}' from '${bill.debtorId}' to ${bill.creditorId}.`,
            )
            .then((state) => {
                if (!state) {
                    uiStore.error('Unable to process bill payment!');
                } else {
                    uiStore.success('Bill payment successfully processed!');
                    bill.status = BillStatus.PAID;
                }
            })
            .finally(() => {
                uiStore.hideLoading();
            });
    };

    const handleMarkBillAsPaid = () => {
        uiStore.showLoading();
        closeBillPaymentDialog();
        walletStore
            .updateBillStatusAsPaid(bill.leaseId, bill.id)
            .then(() => {
                bill.status = BillStatus.PAID;
                uiStore.success('Bill confirmed paid');
            })
            .catch((err) => {
                console.error(`Could not mark bill of amount ${bill.amount} as paid`);
                console.error(err);
            })
            .finally(() => {
                uiStore.hideLoading();
            });
    };

    const asCurrencyNumericFormat = (price: number) => {
        if (!IsValidNumber(price)) price = 0;
        return (
            <>
                <NumericFormat
                    value={price}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix='$'
                    decimalSeparator='.'
                    fixedDecimalScale
                />
            </>
        );
    };

    return (
        <Box
            className={classes.cardParent}
            style={{
                backgroundColor: 'transparent',
            }}
        >
            <Grid container direction='row' spacing={2}>
                <Grid item xs={12} md={3} className={classes.cardContent}>
                    <Typography
                        variant='body1'
                        className={isCanceled() ? classes.disabledColor : classes.successColor}
                    >
                        <b>${bill.amount}</b>
                    </Typography>
                    <Typography variant='subtitle1' color='textSecondary'>
                        {bill.type.replace('_', ' ')}
                    </Typography>
                </Grid>

                <Grid item xs={4} md={2} className={classes.cardContent}>
                    <Typography
                        variant='body1'
                        style={{ textTransform: 'uppercase' }}
                        className={isCanceled() ? classes.disabledColor : ''}
                    >
                        <b>{billStatus.replace('_', ' ')}</b>
                    </Typography>
                    <Typography variant='subtitle1' color='textSecondary'>
                        STATUS
                    </Typography>
                </Grid>

                <Grid item xs={8} md={4}>
                    <Grid container direction='row' justifyContent='center'>
                        <Grid item xs={6} className={classes.cardContent}>
                            <Typography
                                variant='body1'
                                style={{
                                    color:
                                        billStatus === 'overdue'
                                            ? 'red'
                                            : isCanceled()
                                            ? 'rgba(0, 0, 0, 0.26)'
                                            : '',
                                }}
                            >
                                <b>{format(bill.dueDate, 'PP')}</b>
                            </Typography>
                            <Typography variant='subtitle1' color='textSecondary'>
                                DUE DATE
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {isTenant() ? (
                    <Grid item xs={12} md={3}>
                        <Grid container justifyContent='center'>
                            <Button
                                variant='outlined'
                                color='primary'
                                fullWidth
                                className={classes.successColor}
                                onClick={handleBillPayment}
                                disabled={isDisabled() || uiStore.loading}
                            >
                                <PaymentIcon />
                                Pay
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item xs={12} md={3}>
                        <Grid container justifyContent='center'>
                            <Button
                                variant='outlined'
                                color='primary'
                                fullWidth
                                className={classes.successColor}
                                onClick={handleIsPaidBillPayment}
                                disabled={isDisabled() || uiStore.loading}
                            >
                                <PaymentIcon />
                                Paid
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>

            <Dialog open={openConfirmDialog} onClose={closeBillPaymentDialog} fullWidth={true}>
                {isTenant() ? (
                    // Tenant Bill payment confirmation Dialog
                    <>
                        <DialogTitle id='alert-dialog-title'>
                            <Typography variant='h6' align='center'>
                                Confirm Bill Payment
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Typography>
                                Are you sure you want to pay the selected bill? This action cannot
                                be undone.
                            </Typography>
                        </DialogContent>
                        <DialogActions style={{ padding: '16px 24px' }}>
                            <Button onClick={closeBillPaymentDialog} color='primary'>
                                Cancel
                            </Button>
                            <Button
                                onClick={handleConfirmBillPayment}
                                color='primary'
                                variant='contained'
                            >
                                Pay Bill
                            </Button>
                        </DialogActions>
                    </>
                ) : (
                    // Landlord Marks bill as paid confirmation dialog
                    <>
                        <DialogTitle id='alert-dialog-title'>
                            <Typography align='center' variant='h6'>
                                Mark {bill.type.replace('_', ' ').toLowerCase()} bill of{' '}
                                {asCurrencyNumericFormat(bill.amount)} as paid?
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Typography>
                                Marking this bill as paid may incur a service charge in accordance
                                with our payment, terms and services. <br />
                                <br /> Would you like to proceed with marking the{' '}
                                {bill.type.replace('_', ' ').toLowerCase()} bill of{' '}
                                {asCurrencyNumericFormat(bill.amount)} as paid?
                            </Typography>
                        </DialogContent>
                        <DialogActions style={{ padding: '16px 24px' }}>
                            <Button onClick={closeBillPaymentDialog} color='primary'>
                                Cancel
                            </Button>
                            <Button
                                onClick={handleMarkBillAsPaid}
                                color='primary'
                                variant='contained'
                            >
                                Confirm
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>

            <Dialog open={openInsufficientDialog} onClose={closeBillPaymentDialog} fullWidth={true}>
                <DialogTitle id='alert-dialog-title'>
                    <Typography variant='h6' align='center'>
                        Insufficient Funds
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        You do not have sufficient funds in your wallet to pay this bill. Please use
                        the <strong style={{ textTransform: 'uppercase' }}>Fund Wallet</strong>{' '}
                        action to add funds to your wallet then try again.
                    </Typography>
                </DialogContent>
                <DialogActions style={{ padding: '16px 24px' }}>
                    <Button onClick={closeBillPaymentDialog} color='primary'>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
});
