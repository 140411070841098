import { Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';

export const RequestWizardEnd: React.FC<{
    tenantName: string | undefined;
    reason: string;
    amount: number;
    notes: string;
}> = ({ tenantName, reason, amount, notes }) => {
    return (
        <>
            <Grid container spacing={4} style={{ marginTop: '1rem' }}>
                <Grid item xs={12} style={{ marginTop: '1rem' }}>
                    <Typography variant='h6'>
                        <big>
                            You are requesting ${amount} CAD from <br /> {tenantName}
                        </big>
                    </Typography>

                    <Typography variant='subtitle1'>
                        {tenantName} can either accept, decline, or suggest a different amount.
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>
                        <b>Reason</b>
                    </Typography>
                    <Typography variant='subtitle1'>{reason}</Typography>
                </Grid>
                <Grid xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>
                        <b>Amount</b>
                    </Typography>
                    <Typography variant='subtitle1'>{amount}</Typography>
                </Grid>
                <Grid xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>
                        <b>Notes</b>
                    </Typography>
                    <Typography variant='subtitle1'>{notes}</Typography>
                </Grid>
            </Grid>
        </>
    );
};
