import React from 'react';
import { observer } from 'mobx-react-lite';
import { Typography } from '@material-ui/core';

export const PrivacyPolicyComponent: React.FC = observer(() => {
    return (
        <>
            <div className='ordered-bullets' style={{ paddingBottom: '50px' }}>
                <Typography variant='body1' align='justify' gutterBottom>
                    Realhaus exists to build connections between people and make Canada more open
                    and inclusive - We are a community built on trust.
                </Typography>

                <Typography variant='body1' align='justify' gutterBottom>
                    When you use Realhaus Technologies Inc. and its affiliates (<b>“we”</b>,{' '}
                    <b>“us”</b>
                    or <b>“Realhaus”</b>) to find or rent your home, we know you are trusting us
                    with your data. We also know we are responsible for respecting your privacy, and
                    we work hard to do just that. This policy explains what information we collect,
                    why we use it, who we share it with, and how we protect it, along with the tools
                    you can use to manage your privacy.
                </Typography>

                <Typography variant='body1' align='justify' gutterBottom>
                    We know that everyone’s home journey is different and that deciding on the right
                    place to call home is an intimate, personal experience. Along with a wealth of
                    information about homes, neighborhoods, and rental market conditions, we also
                    use the information you give us to provide our services and help you find your
                    next home. We carefully evaluate how we use data to make sure that we’re using
                    your information to provide value for you. On this page, we aim to explain
                    everything you need to know about your privacy at Realhaus. If you have any
                    questions that we haven’t answered here, you can always contact us at{' '}
                    <b>privacy@realhaus.ca</b>
                </Typography>
                <ol>
                    <li>
                        <Typography variant='h6' gutterBottom display='inline'>
                            Definitions
                        </Typography>
                        <Typography variant='body1' align='justify' gutterBottom display='block'>
                            If you see an undefined term in this Privacy Policy (such as “Listing”
                            or “Realhaus Platform”), it has the same definition as in our Terms and
                            Conditions (“Terms”).
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='h6' gutterBottom display='inline'>
                            Information We Collect
                        </Typography>
                        <Typography variant='body1' align='justify' gutterBottom display='block'>
                            There are four general categories of information we collect.
                        </Typography>
                        <ol>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Information needed to use the Realhaus Platform
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    We ask for and collect personal information about you when you
                                    use the Realhaus Platform. This information is necessary for the
                                    adequate performance of the contract between you and us and to
                                    allow us to comply with our legal obligations. Without it, we
                                    may not be able to provide all services requested. This
                                    information includes:
                                </Typography>
                                <ol>
                                    <li>
                                        <Typography
                                            variant='subtitle1'
                                            gutterBottom
                                            display='inline'
                                        >
                                            <b>
                                                Contact Information, Account, Profile Information{' '}
                                            </b>
                                        </Typography>
                                        <Typography
                                            variant='body1'
                                            align='justify'
                                            gutterBottom
                                            display='block'
                                        >
                                            Such as your first name, last name, phone number, postal
                                            address, email address, date of birth, and profile
                                            photo, some of which will depend on the features you
                                            use.
                                            <br />
                                            <br />
                                            During the rental application process, we collect extra
                                            information like your employment history, rental
                                            history, and income. This information is necessary to
                                            assist the landlord make a more informed decision about
                                            your rental profile. We also pull your credit report and
                                            criminal background check to include in your
                                            application. We retain your reports so that we can
                                            easily share them with any landlord of your choice later
                                            on when you submit multiple applications.
                                            <br />
                                            We partner with Certn to generate those reports. We do
                                            not provide an avenue to download these reports,
                                            however, your landlord/ prospective landlord may
                                            capture/ screenshot copies of your reports, application,
                                            and any related documents you submit. Any copies
                                            retained by your landlord are subject to the privacy
                                            policies of your landlord.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant='body1'
                                            gutterBottom
                                            align='justify'
                                            display='inline'
                                        >
                                            <b>Identity Verification Information. </b>
                                        </Typography>
                                        <Typography
                                            variant='body1'
                                            align='justify'
                                            gutterBottom
                                            display='block'
                                        >
                                            Such as images of your government-issued ID (as
                                            permitted by applicable laws), your ID number, or other
                                            verification information. We may use third-party vendors
                                            to collect and verify your identity alongside the
                                            identity documents specified above. If a copy of your ID
                                            is provided to us, we may scan, use, and store
                                            information contained in your ID to verify your
                                            identity.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant='subtitle1'
                                            gutterBottom
                                            display='inline'
                                        >
                                            <b>Payment Information. </b>
                                        </Typography>
                                        <Typography
                                            variant='body1'
                                            align='justify'
                                            gutterBottom
                                            display='block'
                                        >
                                            Such as bank account or payment account information.
                                            When you connect a bank account for payments or payouts,
                                            we may use a trusted third party to collect and verify
                                            your banking information.
                                            <br />
                                            <br />
                                            When you pay your rent through Realhaus, we collect your
                                            transaction information, but we don’t store your payment
                                            account details. Instead, we use trusted partners to
                                            collect and store that data. If you use your bank
                                            account to pay your rent, our service provider Flinks
                                            collects personal and financial information from your
                                            bank or other financial institution. By using Realhaus
                                            to make your rent payments, you are granting Realhaus
                                            and Flinks the right, power, and authority to act on
                                            your behalf to access and transmit your personal and
                                            financial information from the relevant financial
                                            institution. Your information will be transferred,
                                            stored, and processed by Flinks in accordance with the
                                            Flinks Privacy Policy.
                                        </Typography>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Information you give to us
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    You can choose to provide us with additional personal
                                    information. This information may include:
                                </Typography>
                                <ol>
                                    <li>
                                        <Typography
                                            variant='subtitle1'
                                            gutterBottom
                                            display='inline'
                                        >
                                            <b>Additional Profile Information.</b>
                                        </Typography>
                                        <Typography
                                            variant='body1'
                                            align='justify'
                                            gutterBottom
                                            display='block'
                                        >
                                            Such as gender, preferred language(s), city, and
                                            personal description. Some of this information as
                                            indicated in your account settings is part of your
                                            public profile page and will be publicly visible.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant='subtitle1'
                                            gutterBottom
                                            display='inline'
                                        >
                                            <b>Information About Others. </b>
                                        </Typography>
                                        <Typography
                                            variant='body1'
                                            align='justify'
                                            gutterBottom
                                            display='block'
                                        >
                                            Such as a payment instrument belonging to another person
                                            or information about other occupants of a rental lease.
                                            By providing us with personal information about others,
                                            you certify that you have permission to provide that
                                            information to Realhaus for the purposes described in
                                            this Privacy Policy, have shared the Realhaus Privacy
                                            Policy with them, and that they have read and understood
                                            that it applies to them.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant='subtitle1'
                                            gutterBottom
                                            display='inline'
                                        >
                                            <b>Other Information.</b>
                                        </Typography>
                                        <Typography
                                            variant='body1'
                                            align='justify'
                                            gutterBottom
                                            display='block'
                                        >
                                            Such as when you fill in a form, add information to your
                                            account, respond to surveys, post to community forums,
                                            participate in promotions, communicate with our customer
                                            care team and other Members, or share your experience
                                            with us. This may include health information if you
                                            choose to share it with us.
                                        </Typography>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Information We Automatically Collect by Using the Realhaus
                                    Platform and our Payment Services
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    Along with the information you give us directly, we collect a
                                    variety of information automatically as you use Realhaus
                                    services.
                                </Typography>
                                <ol>
                                    <li>
                                        <Typography
                                            variant='subtitle1'
                                            gutterBottom
                                            display='inline'
                                        >
                                            <b>Geo-location Information. </b>
                                        </Typography>
                                        <Typography
                                            variant='body1'
                                            align='justify'
                                            gutterBottom
                                            display='block'
                                        >
                                            Such as precise or approximate location determined from
                                            your IP address or mobile device’s GPS depending on your
                                            device settings. Most devices allow you to control or
                                            disable the use of location services for applications in
                                            the device’s settings menu. We may also collect this
                                            information when you’re not using the app if you enable
                                            this through your settings or device permissions.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant='subtitle1'
                                            gutterBottom
                                            display='inline'
                                        >
                                            <b>Usage Information.</b>
                                        </Typography>
                                        <Typography
                                            variant='body1'
                                            align='justify'
                                            gutterBottom
                                            display='block'
                                        >
                                            Such as the pages or content you view, searches for
                                            homes, homes viewed, showings you have booked, rental
                                            applications submitted, amount of time spent looking at
                                            different parts of the platform, and other actions on
                                            the Realhaus Platform.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant='subtitle1'
                                            gutterBottom
                                            display='inline'
                                        >
                                            <b>Log Data and Device Information.</b>
                                        </Typography>
                                        <Typography
                                            variant='body1'
                                            align='justify'
                                            gutterBottom
                                            display='block'
                                        >
                                            Such as details about how you’ve used the Realhaus
                                            Platform (including if you clicked on links to
                                            third-party applications), IP address, access dates and
                                            times, hardware and software information, device
                                            information, device event information, unique
                                            identifiers, crash data, cookie data, and the pages
                                            you’ve viewed or engaged with before or after using the
                                            Realhaus Platform. We may collect this information even
                                            if you haven’t created a Realhaus account or logged in.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant='subtitle1'
                                            gutterBottom
                                            display='inline'
                                        >
                                            <b>Cookies and Similar Technologies</b>
                                        </Typography>
                                        <Typography
                                            variant='body1'
                                            align='justify'
                                            gutterBottom
                                            display='block'
                                        >
                                            We and our partners use various tools to collect data
                                            when you visit our sites and apps, including cookies,
                                            pixel tags, and other similar technologies. Cookies are
                                            bits of electronic data that can be transferred to your
                                            computer or other devices to identify your browser.
                                            <br />
                                            <br />
                                            When you use Realhaus, we and our partners may use
                                            cookies and other tools to gather information about how
                                            you view and use our services and content, and to
                                            connect your activity with other data we store about
                                            you. The use of cookies helps us serve you better by
                                            understanding what you’re interested in, tracking
                                            trends, measuring the effectiveness of ads, saving your
                                            preferences, and storing information you may want to
                                            retrieve on a regular basis, such as your favorite
                                            homes. We also allow specific, approved partners to
                                            collect data from your browser or device for advertising
                                            and measurement purposes using their own cookies or
                                            similar tools.
                                            <br />
                                            <br />
                                            At any time, you can use our cookie preference tools to
                                            manage what kinds of cookies and other tracking
                                            technologies you’re comfortable with. Check out our for
                                            information about how to access these tools. You can
                                            also disable cookies altogether by adjusting the
                                            settings on your browser. However, if you choose to
                                            disable some or all cookies, many parts of our services
                                            may no longer work.
                                            <br />
                                            <br />
                                            In addition, if you give your email address to us, we
                                            may use a scrambled, unreadable form (called a “hashed”
                                            version) of your email address to deliver tailored ads
                                            to you on Realhaus or on other websites, including via
                                            Facebook’s Custom Audience Feature or other similar
                                            tools.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant='subtitle1'
                                            gutterBottom
                                            display='inline'
                                        >
                                            <b>Payment Transaction Information.</b>
                                        </Typography>
                                        <Typography
                                            variant='body1'
                                            align='justify'
                                            gutterBottom
                                            display='block'
                                        >
                                            Such as payment instrument used, date and time, payment
                                            amount, payment instrument expiration date and billing
                                            postcode, PayPal email address, IBAN information, your
                                            address, and other related transaction details.
                                        </Typography>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Information We Collect from Third-Parties
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    We may collect information, including personal information, that
                                    others provide about you when they use the Realhaus Platform, or
                                    obtain information from other sources and combine that with
                                    information we collect through the Realhaus Platform. We do not
                                    control, supervise, or be responsible for how the third parties
                                    providing your information process your Personal Information.
                                    Any information request regarding the disclosure of your
                                    personal information to us should be directed to such third
                                    parties.
                                    <br />
                                    <br />
                                    The sources we collect personal information from include:
                                </Typography>
                                <ol>
                                    <li>
                                        <Typography
                                            variant='subtitle1'
                                            gutterBottom
                                            display='inline'
                                        >
                                            <b>Third-Party Services. </b>
                                        </Typography>
                                        <Typography
                                            variant='body1'
                                            align='justify'
                                            gutterBottom
                                            display='block'
                                        >
                                            If you link, connect, or log in to the Realhaus Platform
                                            with a third-party service (e.g. Google, Facebook), you
                                            direct the service to send us information such as your
                                            registration, friends list, and profile information as
                                            controlled by that service or as authorized by you via
                                            your privacy settings at that service.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant='subtitle1'
                                            gutterBottom
                                            display='inline'
                                        >
                                            <b>Background Information.</b>
                                        </Typography>
                                        <Typography
                                            variant='body1'
                                            align='justify'
                                            gutterBottom
                                            display='block'
                                        >
                                            We may obtain the local version of police, background,
                                            or registered sex offender checks. We may use your
                                            information, including your full name and date of birth,
                                            to obtain such reports.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant='subtitle1'
                                            gutterBottom
                                            display='inline'
                                        >
                                            <b>Referrals and co-occupants.</b>
                                        </Typography>
                                        <Typography
                                            variant='body1'
                                            align='justify'
                                            gutterBottom
                                            display='block'
                                        >
                                            If you are invited to the Realhaus Platform, such as a
                                            co-occupants on a lease agreement or landlord/ tenant
                                            invitation, the person who invited you can submit
                                            personal information about you such as your email
                                            address or other contact information.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant='subtitle1'
                                            gutterBottom
                                            display='inline'
                                        >
                                            <b>Other Sources.</b>
                                        </Typography>
                                        <Typography
                                            variant='body1'
                                            align='justify'
                                            gutterBottom
                                            display='block'
                                        >
                                            To the extent permitted by applicable law, we may
                                            receive additional information about you, such as
                                            references, demographic data, or information to help
                                            detect fraud and safety issues from third-party service
                                            providers and/or partners and combine it with
                                            information we have about you. For example, we may
                                            receive background check results or fraud warnings from
                                            identity verification service providers for use in our
                                            fraud prevention and risk assessment efforts. We may
                                            receive information about you and your activities on and
                                            off the Realhaus Platform, or about your experiences and
                                            interactions from our partners. We may receive health
                                            information, including but not limited to, health
                                            information related to contagious diseases.
                                        </Typography>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant='h6' gutterBottom display='inline'>
                            How We Use the Information We Collect
                        </Typography>
                        <Typography variant='body1' align='justify' gutterBottom display='block'>
                            We may use, store, and process personal information to (1) provide,
                            understand, improve, and develop the Realhaus Platform, (2) create and
                            maintain a trusted and safer environment (such as to comply with our
                            legal obligations and ensure compliance with Realhaus Policies) and (3)
                            provide, personalize, measure, and improve our advertising and
                            marketing.
                        </Typography>
                        <ol>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Provide, Improve and Develop the Realhaus Platform
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    <ul>
                                        <li>
                                            enable you to access the Realhaus Platform and make and
                                            receive payments,
                                        </li>
                                        <li>enable you to communicate with other Members,</li>
                                        <li>process your request,</li>
                                        <li>perform analytics, debug and conduct research,</li>
                                        <li>provide customer service, training, </li>
                                        <li>
                                            send you messages, updates, security alerts, and account
                                            notifications,
                                        </li>
                                        <li>
                                            if you provide us with your contacts’ information, such
                                            as your friends or co-occupants, we may process this
                                            information: (i) to facilitate your referral
                                            invitations, (ii) to share your trip details and
                                            facilitate trip planning, (iii) for fraud detection and
                                            prevention, and (iv) to facilitate your requests or for
                                            any other purpose you authorize,
                                        </li>
                                        <li>
                                            Personalize and customize your experience based on your
                                            interactions with the Realhaus platform, your search and
                                            booking history, your profile information and
                                            preferences, and other content you submit,
                                        </li>
                                    </ul>
                                    <br />
                                    We process this personal information for these purposes given
                                    our legitimate interest in improving the Realhaus Platform and
                                    our experience with it, and where it is necessary for the
                                    adequate performance of the contract with you.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Create and Maintain a Trusted and Safer environment
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    <ul>
                                        <li>
                                            detect and prevent fraud, spam, abuse, security and
                                            safety incidents, and other harmful activity,
                                        </li>
                                        <li>
                                            conduct security investigations and risk assessments,
                                        </li>
                                        <li>verify or authenticate information provided by you,</li>
                                        <li>
                                            conduct checks against databases and other information
                                            sources, including background or police checks, to the
                                            extent permitted by applicable laws and with your
                                            consent where required,
                                        </li>
                                        <li>
                                            comply with our legal obligations, protect the health
                                            and well-being of our Tenants, Landlords, Maintenance
                                            Personnel, and members of the public,
                                        </li>
                                        <li>
                                            resolve disputes with any of our Users, - enforce our
                                            agreements with third parties,
                                        </li>
                                        <li>
                                            enforce our Terms and other policies (e.g.
                                            Nondiscrimination Policy)
                                        </li>
                                    </ul>
                                </Typography>
                            </li>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Provide, Personalize, Measure, and Improve our Advertising and
                                    Marketing
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    Such as:
                                    <ul>
                                        <li>
                                            send you promotional messages, marketing, advertising,
                                            and other information based on your preferences and
                                            social media advertising through social media platforms,
                                        </li>
                                        <li>personalize, measure, and improve our advertising,</li>
                                        <li>
                                            administer referral programs, rewards, surveys,
                                            sweepstakes, contests, or other promotional activities
                                            or events sponsored or managed by Realhaus or its
                                            third-party partners,
                                        </li>
                                        <li>
                                            analyze characteristics and preferences to send you
                                            promotional messages, marketing, advertising, and other
                                            information that we think might be of interest to you,
                                            and,
                                        </li>
                                        <li>invite you to events and relevant opportunities.</li>
                                    </ul>
                                </Typography>
                            </li>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Provide Payment Services
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    Personal information is used to enable, or authorize third
                                    parties to use, payment services such as to:
                                    <ul>
                                        <li>
                                            detect and prevent money laundering, fraud, abuse, and
                                            security incidents,
                                        </li>
                                        <li>
                                            conduct security investigations and risk assessments,
                                        </li>
                                        <li>
                                            comply with legal obligations (such as anti-money
                                            laundering regulations),
                                        </li>
                                        <li>enforce Payment Terms and other payment policies,</li>
                                        <li>
                                            with your consent, send you promotional messages,
                                            marketing, advertising, and other information that may
                                            be of interest to you based on your preferences, and
                                        </li>
                                        <li>provide and improve Payment Services.</li>
                                    </ul>
                                </Typography>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant='h6' gutterBottom display='inline'>
                            Sharing & Disclosure
                        </Typography>
                        <ol>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Sharing with your Consent or at Your Direction
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    Where you provide consent, we share your information as
                                    described at the time of consent, such as when authorizing a
                                    third-party application or website to access your Realhaus
                                    account or participating in promotional activities by Realhaus
                                    partners or third parties.
                                    <br />
                                    <br />
                                    Where permissible under applicable law, we may use certain
                                    information about you such as your email address, de-identify
                                    it, and share it with social media platforms to generate leads,
                                    drive traffic to Realhaus, or otherwise promote our products and
                                    services.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Sharing Between Members
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    To help facilitate renting or other interactions between
                                    Members, we may need to share certain information such as:
                                    <ul>
                                        <li>
                                            When a showing is booked, when a rental application is
                                            submitted, when there is a co-occupant, or a dispute is
                                            submitted, certain information may be shared between
                                            Tenant(s) and Landlord(s), including profile, name,
                                            names of any additional occupants, cancellation history,
                                            review information, age of guest (unless prohibited by
                                            applicable law), dispute outcome (when applicable), and
                                            other information you choose to share and submit. When a
                                            rental application is submitted, additional information
                                            is shared to provide the landlord with all information
                                            needed to make an informed decision about the applicant,
                                            like rent history, credit reports, and occupation.
                                        </li>
                                        <li>
                                            When you as a Tenant invite additional Occupants to a
                                            rent agreement, your name, move-in /out dates, Landlord
                                            name, Listing details, address, and other related
                                            information will be shared with each additional
                                            Occupant.
                                        </li>
                                    </ul>
                                </Typography>
                            </li>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Profile, Listings & Other Public Information
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    You can make certain information publicly visible to others,
                                    such as:
                                    <ul>
                                        <li>
                                            Your public profile page; which includes your profile
                                            photo, first name (or initials where applicable),
                                            description, and city.
                                        </li>
                                        <li>
                                            Listing pages that include information such as the
                                            Accommodation or Experience’s approximate or precise
                                            location description, calendar availability, profile
                                            photo, aggregated demand information (like page views
                                            over a period of time), and additional information you
                                            choose to share.
                                        </li>
                                        <li>Reviews, ratings, and other public feedback.</li>
                                        <li>
                                            Content in a community or discussion forum, blog, or
                                            social media post.
                                        </li>
                                    </ul>
                                    We may display parts of your public profile and other Content
                                    you make available to the public like Listing details on
                                    third-party sites, platforms, and apps. This will be done in
                                    Realhaus’s legitimate interest to promote the Realhaus platform.
                                    <br />
                                    <br />
                                    Information you share publicly on the Realhaus Platform may be
                                    indexed through third-party search engines.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Compliance with Law, Responding to Legal Requests, Preventing
                                    Harm & Protecting our Rights
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    We may disclose your information to courts, law enforcement,
                                    governmental or public authorities, tax authorities, or
                                    authorized third parties, if and to the extent we are required
                                    or permitted to do so by law or where disclosure is reasonably
                                    necessary to: (i) comply with our legal obligations, (ii) comply
                                    with a valid legal request (such as a subpoena or court order)
                                    or to respond to claims asserted against Realhaus, (iii) respond
                                    to a valid legal request relating to a criminal investigation to
                                    address alleged or suspected illegal activity, or to respond to
                                    or address any other activity that may expose us, you, or any
                                    other of our users to legal or regulatory liability, (iv)
                                    enforce and administer our agreements with Members, including
                                    our Terms or Service, and Policies, or (v) protect the rights,
                                    property or personal safety of Realhaus, its employees, its
                                    Members, or members of the public.
                                    <br />
                                    <br />
                                    These disclosures may be necessary to comply with our legal
                                    obligations, for the protection of your or another person's
                                    vital interests or for the purposes of our or a third party’s
                                    legitimate interest in keeping the Realhaus Platform secure,
                                    preventing harm or crime, enforcing or defending legal rights,
                                    facilitating the collection of taxes and prevention of tax fraud
                                    or preventing damage.
                                    <br />
                                    <br />
                                    Where appropriate, we may notify Members about legal requests
                                    unless: (i) providing notice is prohibited by the legal process
                                    itself, by court order we receive, or by applicable law, or (ii)
                                    we believe that providing notice would be futile, ineffective,
                                    create a risk of injury or bodily harm to an individual or
                                    group, or create or increase a risk of fraud upon or harm to
                                    Realhaus, our Members, or expose Realhaus to a claim of
                                    obstruction of justice.
                                    <br />
                                    <br />
                                    Where legally required or permissible according to applicable
                                    law, we may disclose Landlords’ and/or Tenants’ information to
                                    tax authorities for the purpose of the tax authorities’
                                    determination of proper compliance with relevant tax
                                    obligations. Relevant tax obligations include Realhaus’s tax
                                    obligations on its service fees, its facilitation of taxes on
                                    accommodations and withholding taxes, and the Landlord’s
                                    individual tax obligations. Information that may be disclosed
                                    includes, but is not limited to, Landlord and Tenant names,
                                    listing/ property addresses, Landlord addresses, tax/business
                                    identification number(s), date of birth, and/or contact
                                    information, property parcel identification numbers, payout
                                    information, transaction dates and amounts, rental terms and
                                    tenure, gross and net rent and payout amounts, taxes collected
                                    by Realhaus on behalf of Landlord and Tenants, to the extent any
                                    of this information is known by Realhaus.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Landlord Service Providers
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    Landlords may use third-party services to help manage or deliver
                                    their services, such as cleaning services, maintenance, or lock
                                    providers. Landlords may use features on the Realhaus Platform
                                    to share information about the Tenant with such third-party
                                    service providers.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Realhaus Service Providers
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    Realhaus shares personal information with affiliated and
                                    unaffiliated service providers to help us run our business and
                                    for their compliance purposes, including service providers that
                                    help us: (i) verify your identity or authenticate your
                                    identification documents, (ii) check information against public
                                    databases, (iii) conduct background or police checks, fraud
                                    prevention and risk assessment, (iv) perform product
                                    development, maintenance and debugging, (v) allow the provision
                                    of the Realhaus Services through third-party platforms and
                                    software tools (e.g. through the integration with our APIs),
                                    (vi) provide customer service, advertising, or payments
                                    services, or (vii) process, handle or assess insurance claims or
                                    similar claims. These providers are contractually bound to
                                    protect your personal information and have access to your
                                    personal information to perform these tasks.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Business Transfer
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    If Realhaus undertakes or is involved in any merger,
                                    acquisition, reorganization, sale of assets, bankruptcy, or
                                    insolvency event, then we may sell, transfer, or share some or
                                    all of our assets, including your information in connection with
                                    such transaction or in contemplation of such transaction (e.g.,
                                    due diligence). In this event, we will notify you before your
                                    personal information is transferred and becomes subject to a
                                    different privacy policy.
                                </Typography>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant='h6' gutterBottom display='inline'>
                            Other Important Information
                        </Typography>
                        <ol>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Analyzing your Communications
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    We may review, scan, or analyze your communications on the
                                    Realhaus Platform for reasons outlined in the “How We Use the
                                    Information We Collect” section of this policy, including fraud
                                    prevention, risk assessment, regulatory compliance,
                                    investigation, product development, research, analytics,
                                    enforcing our Terms of Service, and customer support purposes.
                                    For example, as part of our fraud prevention efforts, we scan
                                    and analyze messages to mask contact information and references
                                    to other sites. In some cases, we may also scan, review, or
                                    analyze messages to debug, improve, and expand product
                                    offerings. We use automated methods where reasonably possible.
                                    Occasionally we may need to manually review communications, such
                                    as for fraud investigations and customer support, or to assess
                                    and improve the functionality of these automated tools. We will
                                    not review, scan, or analyze your messaging communications to
                                    send third-party marketing messages to you and we will not sell
                                    reviews or analyses of these communications.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Third-Party Partners and Integrations
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    Some Parts of the Realhaus platform may link to third-party
                                    services, not owned or controlled by Realhaus, such as Google
                                    Maps/Earth. Use of these services is subject to the privacy
                                    policies of those providers, such as Google Maps/Earth
                                    Additional Terms of Use, and Google Privacy Policy. Realhaus
                                    does not own or control these third parties and when you
                                    interact with them you are providing your information to them.
                                </Typography>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant='h6' gutterBottom display='inline'>
                            Your Rights
                        </Typography>
                        <Typography variant='body1' align='justify' gutterBottom display='block'>
                            You may exercise any of the rights described in this section at
                            help@realhaus.ca. Please note that we may ask you to verify your
                            identity and request before taking further action on your request.
                        </Typography>
                        <ol>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Managing your Information
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    You can access and update some of your personal information
                                    through your Account settings. If you connected your Realhaus
                                    Account to a third-party service, like Facebook or Google, you
                                    can change your settings and unlink from that service in your
                                    Account settings. You are responsible for keeping your personal
                                    information up to date.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Data Access and Portability
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    Applicable law may entitle you to request certain copies of your
                                    personal information or information about how we handle your
                                    personal information, request copies of personal information
                                    that you have provided to us in a structured, commonly used, and
                                    machine-readable format, and/or request that we transmit this
                                    information to another service provider (where technically
                                    feasible).
                                </Typography>
                            </li>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Data Retention & Erasure
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    You can request that your personal information be deleted. Do
                                    note however that if you request the deletion of your personal
                                    information, or if your account is suspended, terminated, or
                                    voluntarily closed:
                                    <ul>
                                        <li>
                                            We may retain your personal information as necessary for
                                            our legitimate business interests, such as the
                                            prevention of money laundering, fraud detection and
                                            prevention, and enhancing safety. For example, if we
                                            suspend a Realhaus Account for fraud or safety reasons,
                                            we may retain information from that Realhaus Account to
                                            prevent that Member from opening a new Realhaus Account
                                            in the future.
                                        </li>
                                        <li>
                                            We may retain and use your personal information to the
                                            extent necessary to comply with our legal obligations.
                                            For example, Realhaus may keep information for tax,
                                            legal reporting, and auditing obligations.
                                        </li>
                                        <li>
                                            Information you have shared with others (e.g Reviews,
                                            postings) will continue to be publicly visible on
                                            Realhaus, even after your Realhaus Account is canceled.
                                            However, attribution of such information to you will be
                                            removed. Some copies of your information (e.g., log
                                            records) will remain in our database, but are
                                            disassociated from personal identifiers.
                                        </li>
                                        <li>
                                            Because we take measures to protect data from accidental
                                            or malicious loss and destruction, residual copies of
                                            your personal information may not be removed from our
                                            backup systems for a limited period of time.
                                        </li>
                                    </ul>
                                </Typography>
                            </li>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Security
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    While no organization can guarantee perfect security, we are
                                    continuously implementing and updating administrative,
                                    technical, and physical security measures to help protect your
                                    information against unauthorized access, loss, destruction, or
                                    alteration.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Changes to this Policy
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    Realhaus reserves the right to modify this Privacy Policy at any
                                    time in accordance with this provision. If we make changes to
                                    this Privacy Policy, we will post the revised Privacy Policy on
                                    the Realhaus Platform and update the “Last Updated” date at the
                                    top of this Privacy Policy. We will also provide you with notice
                                    of the modification by email at least thirty (30) days before
                                    the date they become effective. If you disagree with the revised
                                    Privacy Policy, you may cancel your Account. If you do not
                                    cancel your Account before the date the revised Privacy Policy
                                    becomes effective, your continued access to or use of the
                                    Realhaus Platform will be subject to the revised Privacy Policy.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant='h6' gutterBottom display='inline'>
                                    Contacting Us
                                </Typography>
                                <Typography
                                    variant='body1'
                                    align='justify'
                                    gutterBottom
                                    display='block'
                                >
                                    If you have any questions or complaints about this Privacy
                                    Policy or Realhaus’s information handling practices, you may
                                    reach out to <b>hello@realhaus.ca</b>.
                                </Typography>
                            </li>
                        </ol>
                    </li>
                </ol>

                <Typography variant='body1' align='justify' gutterBottom>
                    BY AGREEING TO USE THE WEBSITE, YOU ACKNOWLEDGE THAT YOU HAVE READ THIS PRIVACY
                    STATEMENT, UNDERSTAND IT, AND AGREE TO ALL THE TERMS AND CONDITIONS IN THIS
                    PRIVACY STATEMENT.
                </Typography>
            </div>
        </>
    );
});
