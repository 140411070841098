import React from 'react';
import { observer } from 'mobx-react-lite';
import { Title } from './styles';
import { IListing } from 'realhaus-sdk';
import { Grid, InputLabel, TextField, Typography } from '@material-ui/core';

interface PropertyTitleProps {
    listing: IListing;
    updateListing: (prop: keyof IListing) => (event: React.ChangeEvent<{ value: unknown }>) => void;
}

export const PropertyTitle: React.FC<PropertyTitleProps> = observer(
    ({ listing, updateListing }) => {
        return (
            <>
                <Title>
                    <Typography variant='h5' gutterBottom>
                        Create a title and set price for your listing
                    </Typography>
                </Title>

                <form noValidate autoComplete='off'>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor='title'>Title</InputLabel>
                            <TextField
                                id='title'
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                margin='normal'
                                value={listing?.title ?? ''}
                                onChange={updateListing('title')}
                                required
                            />
                        </Grid>
                        {/* Rent will be set in Terms page */}
                        {/* <Grid item xs={12}>
                            <InputLabel htmlFor='cost'>Rent Cost</InputLabel>
                            <OutlinedInput
                                id='cost'
                                type='number'
                                fullWidth
                                value={listing.price}
                                onChange={updateListing('price')}
                                startAdornment={
                                    <InputAdornment position='start'>CAD</InputAdornment>
                                }
                                required
                            />
                        </Grid> */}
                    </Grid>
                </form>
            </>
        );
    },
);
