import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStyles } from '../styles';
import {
    Grid,
    Button,
    FormControl,
    TextField,
    InputAdornment,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { PropertyTypeEnum } from 'realhaus-sdk';
import StringUtils from '../../utils/string';

export const HomeTypeFilter: React.FC<{ value: any; onChange: (newValue: any) => void }> = observer(
    ({ value, onChange }) => {
        const classes = useStyles();

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange((event.target as HTMLInputElement).value);
        };

        return (
            <Grid container item className={classes.filterFormContainer}>
                <FormControl component='fieldset'>
                    <RadioGroup
                        aria-label='home-type'
                        name='home-type-group'
                        value={value}
                        onChange={handleChange}
                    >
                        <Grid container className={classes.filterFormContainer}>
                            <Grid item xs={6}>
                                <FormControlLabel value='any' control={<Radio />} label='any' />
                            </Grid>
                            {Object.keys(PropertyTypeEnum).map((k) => (
                                <Grid item xs={6} key={`key-${k}`}>
                                    <FormControlLabel
                                        value={k}
                                        control={<Radio />}
                                        label={StringUtils.enumToDisplayString(k.toLowerCase())}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </RadioGroup>
                </FormControl>
            </Grid>
        );
    },
);

export const PriceFilter: React.FC<{
    minValue: any;
    maxValue: any;
    onChange: (minValue: any, maxValue: any) => void;
}> = observer(({ minValue, maxValue, onChange }) => {
    const classes = useStyles();
    const [minPrice, setMinPrice] = useState<number>(parseInt(minValue));
    const [maxPrice, setMaxPrice] = useState<number>(parseInt(maxValue));

    const handleSave = () => {
        onChange(minPrice, maxPrice);
    };
    return (
        <Grid container item className={classes.filterFormContainer}>
            <FormControl className={classes.inputField} variant='outlined' component='fieldset'>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TextField
                            className={classes.inputField}
                            label='Min. Price'
                            variant='outlined'
                            type='number'
                            InputProps={{
                                name: 'minPrice',
                                id: 'search-min-price',
                                startAdornment: (
                                    <InputAdornment position='start'>CAD</InputAdornment>
                                ),
                            }}
                            value={minPrice}
                            onChange={(ev) => {
                                setMinPrice(parseInt(ev.target.value));
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            className={classes.inputField}
                            label='Max. Price'
                            variant='outlined'
                            type='number'
                            InputProps={{
                                name: 'maxPrice',
                                id: 'search-max-price',
                                startAdornment: (
                                    <InputAdornment position='start'>CAD</InputAdornment>
                                ),
                            }}
                            value={maxPrice}
                            onChange={(ev) => {
                                setMaxPrice(parseInt(ev.target.value));
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            size='small'
                            variant='outlined'
                            color='primary'
                            onClick={handleSave}
                            style={{ float: 'right' }}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </FormControl>
        </Grid>
    );
});

export const BedsBathsFilter: React.FC<{
    bedrooms: string;
    bathrooms: string;
    onChange: (beds: string[], baths: string[]) => void;
}> = observer(({ bedrooms, bathrooms, onChange }) => {
    const bedroomOptions = [
        { key: 'Any', value: 'any' },
        { key: 'STUDIO', value: '0' },
        { key: '1 BED', value: '1' },
        { key: '1+ DEN', value: '1.5' },
        { key: '2 BEDS', value: '2' },
        { key: '2+ DEN', value: '2.5' },
        { key: '3 BEDS', value: '3' },
        { key: '3+ BEDS', value: '3plus' },
    ];
    const bathroomOptions = [
        { key: 'Any', value: 'any' },
        { key: '1 BATH', value: '1' },
        { key: '1.5 BATH', value: '1.5' },
        { key: '2 BATHS', value: '2' },
        { key: '2.5 BATHS', value: '2.5' },
        { key: '3+ BATHS', value: '3plus' },
    ];

    const classes = useStyles();
    const [beds, setBeds] = useState<string[]>(
        bedrooms
            .split(',')
            .map((bd) => bedroomOptions.find((x) => x.value === bd.toLowerCase())?.key ?? 'any'),
    );

    const [baths, setBaths] = useState<string[]>(
        bathrooms
            .split(',')
            .map((bth) => bathroomOptions.find((x) => x.value === bth.toLowerCase())?.key ?? 'any'),
    );
    const handleSelectedRoomChange =
        (room: 'bedroom' | 'bathroom') => (_: React.MouseEvent<HTMLElement>, rooms: string[]) => {
            if (rooms.indexOf('Any') === rooms.length - 1) {
                rooms = ['Any'];
            }
            if (rooms.length > 1 && rooms.indexOf('Any') >= 0) {
                rooms = rooms.filter((x) => x !== 'Any');
            }
            if (room === 'bedroom') setBeds(rooms);
            else setBaths(rooms);
        };

    const handleSave = () => {
        const selectedBeds = beds.map((b) => bedroomOptions.find((x) => x.key === b)?.value ?? '');
        const selectedBaths = baths.map(
            (b) => bathroomOptions.find((x) => x.key === b)?.value ?? '',
        );
        onChange(selectedBeds, selectedBaths);
    };

    return (
        <Grid container item className={classes.filterFormContainer}>
            <FormControl className={classes.inputField} variant='outlined' component='fieldset'>
                <Grid item container xs={12}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Bedrooms</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleButtonGroup
                            value={beds}
                            onChange={handleSelectedRoomChange('bedroom')}
                            aria-label='bedrooms'
                            className={classes.fullWidth}
                        >
                            {bedroomOptions.map((bd) => (
                                <ToggleButton
                                    key={`key-${bd.key}`}
                                    value={bd.key}
                                    aria-label={bd.key}
                                >
                                    <Typography>{bd.key}</Typography>
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Bathrooms</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleButtonGroup
                            value={baths}
                            onChange={handleSelectedRoomChange('bathroom')}
                            aria-label='bathrooms'
                            className={classes.fullWidth}
                        >
                            {bathroomOptions.map((bath) => (
                                <ToggleButton
                                    value={bath.key}
                                    aria-label={bath.key}
                                    key={`key-bath-${bath.key}`}
                                >
                                    <Typography>{bath.key}</Typography>
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        size='small'
                        variant='outlined'
                        color='primary'
                        onClick={handleSave}
                        style={{ float: 'right' }}
                    >
                        Save
                    </Button>
                </Grid>
            </FormControl>
        </Grid>
    );
});
