import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Grid,
    Link,
    TextField,
    Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { RootStoreContext } from '../../../global/storeContext';
import { ISignupUser } from '../../../stores/userStore';
import { ContentBody, ContentFooter, ContentHeader, RootContent } from '../styles';
import { paths } from '../../../routes';
import { AuthErrors } from '../../../constants/authErrors';

export const Signup: React.FC = observer(() => {
    const { userStore, uiStore } = useContext(RootStoreContext);
    const [user, setUser] = React.useState<ISignupUser>({
        firstname: '',
        lastname: '',
        email: '',
        passwordOne: '',
        passwordTwo: '',
    });
    const [signupMsg, setsignupMsg] = React.useState<string | undefined>(undefined);
    const [signupError, setsignupError] = React.useState<any>(undefined);

    const handleChange =
        (prop: keyof ISignupUser) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setUser({ ...user, [prop]: event.target.value });
        };

    const handleSignup = async () => {
        uiStore.showLoading();
        setsignupMsg(undefined);
        setsignupError(undefined);

        userStore
            .create(user)
            .then((_authUser) => {
                uiStore.hideLoading();

                setsignupMsg(
                    'Your Account has been created. You will be redirected to the login page in a bit.',
                );
                setTimeout(() => {
                    // redirect to login
                    uiStore.goTo(paths.login);
                }, 5000);
            })
            .catch((err) => {
                uiStore.hideLoading();
                console.log(err);
                if (!!err?.code) {
                    const authErr = AuthErrors.find((au) => au.code === err.code);
                    if (!!authErr) {
                        setsignupError(authErr.message);
                    } else {
                        setsignupError(
                            'Uh-Oh! We were unable to create your account. Try again later',
                        );
                    }
                }
            });
    };

    return (
        <>
            <RootContent>
                <ContentHeader>
                    <Typography variant='h5' gutterBottom>
                        <b>Welcome to Realhaus</b>
                    </Typography>
                </ContentHeader>
                <ContentBody>
                    <Card elevation={0}>
                        <CardContent>
                            <Grid container>
                                {!!signupError ? (
                                    <Grid item xs={12}>
                                        <Box display='flex' alignItems='center'>
                                            <ErrorIcon fontSize='large' color='error' />
                                            &nbsp;&nbsp;
                                            <Typography color='textSecondary' variant='body1'>
                                                {signupError}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                ) : null}
                                {!!signupMsg ? (
                                    <Grid item xs={12}>
                                        <Box display='flex' alignItems='center'>
                                            <Typography color='primary' variant='body1'>
                                                {signupMsg}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                ) : null}
                            </Grid>

                            <form noValidate autoComplete='off'>
                                <Grid
                                    container
                                    spacing={1}
                                    alignItems='center'
                                    justifyContent='center'
                                >
                                    {/* First Name */}
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id='firstname'
                                            label='First Name'
                                            placeholder='First Name'
                                            fullWidth
                                            margin='dense'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={user.firstname}
                                            onChange={handleChange('firstname')}
                                            required
                                        />
                                    </Grid>

                                    {/* Last Name */}
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id='lastname'
                                            label='Last Name'
                                            placeholder='Last Name'
                                            fullWidth
                                            margin='dense'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={user.lastname}
                                            onChange={handleChange('lastname')}
                                            required
                                        />
                                    </Grid>

                                    {/* Email */}
                                    <Grid item xs={12}>
                                        <TextField
                                            id='email'
                                            label='Email'
                                            placeholder='your@email.com'
                                            type='email'
                                            fullWidth
                                            margin='dense'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={user.email}
                                            onChange={handleChange('email')}
                                            required
                                        />
                                    </Grid>

                                    {/* Password */}
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            id='passwordOne'
                                            label='Password'
                                            placeholder='Password'
                                            type='password'
                                            margin='dense'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={user.passwordOne}
                                            onChange={handleChange('passwordOne')}
                                            required
                                        />
                                    </Grid>

                                    {/* Password Two */}
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            id='passwordTwo'
                                            label='Confirm Password'
                                            placeholder='Password again'
                                            type='password'
                                            margin='dense'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={user.passwordTwo}
                                            onChange={handleChange('passwordTwo')}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <Typography variant='body2' gutterBottom>
                                            <br />
                                            By selecting <b>Agree and Create Account</b>, I agree to
                                            abide by Realhaus's{' '}
                                            <Link
                                                variant='body2'
                                                style={{ cursor: 'pointer' }}
                                                underline='always'
                                                href={paths.termsofservice}
                                            >
                                                Terms of Service
                                            </Link>{' '}
                                            and acknowledge the{' '}
                                            <Link
                                                variant='body2'
                                                underline='always'
                                                style={{ cursor: 'pointer' }}
                                                href={paths.privacypolicy}
                                            >
                                                Privacy Policy
                                            </Link>
                                            .
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>

                        <div>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={handleSignup}
                                disabled={
                                    !user.email ||
                                    !user.passwordOne ||
                                    user.passwordOne !== user.passwordTwo ||
                                    uiStore.loading
                                }
                                fullWidth
                            >
                                Agree and Create Account
                            </Button>
                        </div>
                    </Card>
                </ContentBody>
                <Divider variant='middle' />
                <ContentFooter>
                    <Typography variant='subtitle1' gutterBottom>
                        Already have an account?{' '}
                        <Button href='/login' color='primary'>
                            Sign in!
                        </Button>
                    </Typography>
                </ContentFooter>
            </RootContent>
        </>
    );
});
