import { action, makeAutoObservable, runInAction } from 'mobx';
import { Collections } from './dbStore';
import FirebaseStore from './firebaseStore';
import { UserStore } from './userStore';
import { Unsubscribe } from 'firebase/firestore';
import { IdMessage, IdConversation, IConversation, IMessage, MessageType } from 'realhaus-sdk';

export class MessageStore {
  messages: IdMessage[] = []
  conversations: IdConversation[] = []

  messageListener?: Unsubscribe = undefined;
  conversationListener?: Unsubscribe = undefined;

  constructor(private fireStore: FirebaseStore, private userStore: UserStore) {
    makeAutoObservable(this);
  }

  messagePath = (convoId: string) => `${Collections.conversations}/${convoId}/${Collections.messages}`;


  /**
   * Creates a conversation between current user and participants
   * @param participants members of this conversation group, excluding current user
   * @returns convoId - the conversation Id
   */
  createConversation = async (participants: string[]) => {
    const uid = this.userStore.userId;
    if (!uid) {
      return;
    }

    const convoMembers = Array.from(new Set([...participants.map(p => p.trim()).filter(x => x !== uid), uid]));

    // check groups for all participants
    // get conversations I belong to from UserGroups
    let groups = await this.userStore.getUserGroups();

    if (!groups) {
      throw Error("Cannot start conversation");
    }

    const conversations = await Promise.all(groups.conversations.map(async (convId) => {
      // get conversation
      const convo = await this.fireStore.getDocument(Collections.conversations, convId);
      return { id: convo.id, ...(convo.data() as IConversation) } as IdConversation;
    }));

    const exists = conversations.find(convo => convo.members.length === convoMembers.length && convoMembers.every(p => convo.members.includes(p)));
    let convoId = exists?.id;
    if (!convoId) {
      const convo = {
        members: convoMembers,
      } as IConversation;
      const convoRef = await this.fireStore.addDocument(Collections.conversations, convo);
      convoId = convoRef.id;
      convoMembers.forEach((member: string) => this.userStore.addConversationGroup(convoRef.id, member));
    }
    return convoId;
  }

  /**
   * Creates a conversation between current user and participants and sends a first message from the user
   * @param participants members of this conversation group, excluding current user
   * @param message first message of this conversation
   * @returns void
   */
  startConversation = async (participants: string[], message: string) => {
    const convoId = await this.createConversation(participants);
    if (!convoId) return;
    await this.sendMessage(convoId, message);
  }

  getConversation = async (convId: string): Promise<IConversation> => {

    // get conversation
    const convo = await this.fireStore.getDocument(Collections.conversations, convId);
    return { ...(convo.data() as IConversation) };
  }

  /**
   * Retrieve conversations this user belongs in
   * @returns an array of IdConversation
   */
  getConversations = async (): Promise<IdConversation[]> => {
    const uid = this.userStore.userId;
    if (!uid) {
      return [];
    }

    // get conversations I belong to from UserGroups
    const groups = await this.userStore.getUserGroups();

    if (!groups || !groups.conversations || groups.conversations.length === 0) {
      return [];
    }

    const conversations = await Promise.all(groups.conversations.map(async (convId) => {
      // get conversation
      const convo = await this.fireStore.getDocument(Collections.conversations, convId);
      return { id: convo.id, ...(convo.data() as IConversation) } as IdConversation;
    }));

    return conversations;
  }

  isMemberOfConversation = async (convoId: string) => {
    const uid = this.userStore.userId;
    if (!uid) {
      return [];
    }

    // get conversations I belong to from UserGroups
    const groups = await this.userStore.getUserGroups();
    if (!groups || !groups.conversations || groups.conversations.length === 0) {
      return false;
    }

    return !!groups.conversations.find(cId => cId === convoId)
  }

  /**
   * Retrieve messages in a conversation group
   * @param conversationId the id of the conversatino this message belongs in
   * @returns an array of IdMessage
   */
  getMessages = async (conversationId: string): Promise<IdMessage[]> => {
    const uid = this.userStore.userId;
    if (!uid) {
      return [];
    }

    const messagesRef = await this.fireStore.getDocuments(this.messagePath(conversationId));
    const messages = messagesRef.docs.map(doc => ({ id: doc.id, ...(doc.data() as IMessage) } as IdMessage))

    return messages;
  }

  /**
   * Writes a message to the conversation group
   * @param convoId the id of the conversation group
   * @param message the message to send to the group
   */
  sendMessage = async (convoId: string, message: string, messageType: MessageType = MessageType.MESSAGE): Promise<void> => {
    const uid = this.userStore.userId;
    if (!uid || !convoId) {
      throw Error('Message not sent')
    }

    // add a message to conversation
    const msg = {
      sender: uid,
      type: messageType,
      timestamp: Date.now(),
      message
    } as IMessage;

    await this.fireStore.addDocument(this.messagePath(convoId), msg);
  }

  @action
  subscribeMessages = async (convoId: string, onNewMessage: (msg: IdMessage) => void, onMessageDeleted: (msg: IdMessage) => void) => {
    const uid = this.userStore.userId;
    if (!uid) {
      return [];
    }

    const msgs = (await this.getMessages(convoId)).sort((a, b) => a.timestamp - b.timestamp);
    runInAction(() => {
      this.messages = msgs;
    });

    const addNewMessage = (msg: IdMessage) => {
      runInAction(() => {
        if (!this.messages.find((message) => message.id === msg.id)) this.messages.push(msg)
      });
    }

    const removeOldMessage = (msg: IdMessage) => {
      runInAction(() => {
        this.messages = this.messages.filter((message) => message.id !== msg.id)
      });
    };
    this.messageListener = this.fireStore.subscribeCollection(this.messagePath(convoId), (querySnapShot) => {
      querySnapShot.docChanges().forEach((change) => {
        if (change.type === "added") {
          addNewMessage({ id: change.doc.id, ...(change.doc.data() as IMessage) } as IdMessage);
        }
        if (change.type === "removed") {
          // onMessageDeleted({ id: change.doc.id, ...(change.doc.data() as IMessage) } as IdMessage);
          removeOldMessage({ id: change.doc.id, ...(change.doc.data() as IMessage) } as IdMessage);
        }
      })
    })
  }

  @action
  clearMessages = () => {
    this.messages.length = 0
  }

  @action
  subscribeConversations = async () => {
    const uid = this.userStore.userId;
    if (!uid) {
      return [];
    }
    const convos = (await this.getConversations()).sort((a, b) => b.lastMessageSentAt - a.lastMessageSentAt);
    runInAction(() => {
      this.conversations = convos;
    });

    const updateConversation = (conversation: IdConversation) => {
      runInAction(() => {
        this.conversations = this.conversations.map((convo) => {
          if (conversation.id === convo.id) {
            return conversation
          }
          return convo
        })
      });
    };

    const addNewConvo = (convo: IdConversation) => {
      runInAction(() => {
        if (!this.conversations.find((conversation) => conversation.id === convo.id)) this.conversations.push(convo)
      });
    }

    const removeOldConvo = (convo: IdConversation) => {
      runInAction(() => {
        this.conversations = this.conversations.filter((conversation) => conversation.id !== convo.id)
      });
    };

    this.conversationListener = this.fireStore.subscribeCollection(Collections.conversations, (querySnapShot) => {

      querySnapShot.docChanges().forEach((change) => {
        const convo = change.doc.data() as IConversation
        if (!this.isUserMember(convo.members)) return

        if (change.type === "modified") {
          updateConversation({ id: change.doc.id, ...convo } as IdConversation);
        }
        if (change.type === "added") {
          addNewConvo({ id: change.doc.id, ...convo } as IdConversation);
        }
        if (change.type === "removed") {
          removeOldConvo({ id: change.doc.id, ...convo } as IdConversation);
        }
      })
    })
  }

  isUserMember(members: string[]) {
    const uid = this.userStore.userId
    if (!uid) return
    return members.includes(uid)
  }

  componentWillUnmount() {
    this.conversationListener && this.conversationListener();
    this.messageListener && this.messageListener();
  }
}

