import React from 'react';
import { observer } from 'mobx-react-lite';
import { IOccupation, ITenantProfile } from 'realhaus-sdk';
import { TenantEmployment } from '../tenantProfile/tenantEmployment';
import { GridContent, GridMainContent, GridSideContent } from '../uiComponents/UIComponents';
import { Typography } from '@material-ui/core';

interface FinanceInfoProps {
    profile: ITenantProfile;
    updateProfile: (prop: keyof ITenantProfile) => (value: IOccupation) => void;
}

export const FinanceInfoComponent: React.FC<FinanceInfoProps> = observer(
    ({ profile, updateProfile }) => {
        return (
            <>
                <GridContent spacing={5}>
                    <GridMainContent>
                        <Typography variant='h5' gutterBottom>
                            What is your financial situation?
                        </Typography>
                    </GridMainContent>
                    <GridMainContent withSidebar>
                        <TenantEmployment
                            employment={profile.occupation}
                            updateEmployment={(prop: keyof IOccupation) => (ev) => {
                                updateProfile('occupation')({
                                    ...profile.occupation,
                                    [prop]: ev.target.value,
                                });
                            }}
                        />
                    </GridMainContent>
                    <GridSideContent></GridSideContent>
                </GridContent>
            </>
        );
    },
);
