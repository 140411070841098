import { Dialog, DialogContent, DialogActions, Box, Button } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import {
    ILeaseRatingReviewDetails,
    ILeaseRatingsReview,
    ILeaseReview,
    LeaseTerm,
    RatingsForLandlord,
    RatingsForTenant,
} from 'realhaus-sdk';
import { RootStoreContext } from '../../global/storeContext';

import { ReviewInfo } from './reviewInfo';
import { ReviewExperience } from './reviewExperience';
import { ReviewComment } from './reviewComment';

export enum RatingReview {
    START,
    RATE_EXPERIENCE,
    REVIEW,
}

export const UserRatingReview = [
    RatingReview.START,
    RatingReview.RATE_EXPERIENCE,
    RatingReview.REVIEW,
];

export const RatingReviewDialog: React.FC<{
    open: boolean;
    handleCloseModal: () => void;
    term: string;
    revieweeFirstName: string;
    review: ILeaseRatingReviewDetails;
    handleReviewCompleted: () => void;
}> = observer(
    ({ open, handleCloseModal, term, revieweeFirstName, review, handleReviewCompleted }) => {
        const { uiStore, ratingStore, userStore } = React.useContext(RootStoreContext);

        const isLandlord = uiStore.isLandlordMode();

        const defaultExpVal = isLandlord
            ? {
                  Communication: 0,
                  Cleanliness: 0,
                  Respectfulness: 0,
                  House_Rules: 0,
              }
            : {
                  Accuracy: 0,
                  Communication: 0,
                  Respectfulness: 0,
                  Difficulty: 0,
              };

        const [reviewNote, setReviewNote] = React.useState<string>('');
        const [expVal, setExpVal] = React.useState<RatingsForTenant | RatingsForLandlord>(
            defaultExpVal as RatingsForTenant | RatingsForLandlord,
        );
        const firstStep = UserRatingReview[0];
        const [activeStep, setActiveStep] = React.useState(firstStep);
        const lastStep = UserRatingReview[UserRatingReview.length - 1];
        const lastStepIndex = UserRatingReview.indexOf(lastStep);
        const activeStepIndex = UserRatingReview.indexOf(activeStep);

        const stepComponents: {
            [key: number]: { component: JSX.Element; validate: () => Promise<boolean> };
        } = {
            [RatingReview.START]: {
                component: (
                    <ReviewInfo
                        firstName={revieweeFirstName}
                        term={term}
                        landlordOrTenant={isLandlord ? 'tenants' : 'landlords'}
                        oppLandlordOrTenant={isLandlord ? 'landlords' : 'tenants'}
                    />
                ),
                validate: () => {
                    return Promise.resolve(true);
                },
            },
            [RatingReview.RATE_EXPERIENCE]: {
                component: (
                    <ReviewExperience
                        isLandlord={isLandlord}
                        expVal={expVal}
                        setExpVal={setExpVal}
                    />
                ),
                validate: () => {
                    return Promise.resolve(true);
                },
            },
            [RatingReview.REVIEW]: {
                component: (
                    <ReviewComment
                        revieweeName={revieweeFirstName}
                        comments={reviewNote}
                        setComments={setReviewNote}
                    />
                ),
                validate: () => {
                    const isValid = !!reviewNote;
                    if (!isValid) {
                        uiStore.error('Please write a review');
                    }
                    return Promise.resolve(isValid);
                },
            },
        };

        const handleReset = () => {
            setReviewNote('');
            setExpVal(defaultExpVal as RatingsForTenant | RatingsForLandlord);
            setActiveStep(firstStep);
        };

        const handleBack = () => {
            const stepIndex = activeStepIndex;
            if (stepIndex === 0) {
                return;
            }
            const step = UserRatingReview[stepIndex - 1];
            setActiveStep(step);
        };

        const handleNext = async () => {
            const stepIndex = activeStepIndex;
            if (!(await stepComponents[activeStep].validate())) {
                return;
            }
            if (stepIndex === lastStepIndex) {
                if (!userStore.userId) return;
                uiStore.showLoading();
                let data = {};
                if (term === LeaseTerm.midterm) {
                    data = {
                        middleOfTerm: {
                            ratings: expVal,
                            review: reviewNote,
                            timeStamp: Date.now(),
                        } as ILeaseReview,
                    } as ILeaseRatingsReview;
                    await ratingStore.upsertReview(
                        review.leaseAgreementId,
                        userStore.userId,
                        data as ILeaseRatingsReview,
                    );
                } else {
                    data = {
                        endOfTerm: {
                            ratings: expVal,
                            review: reviewNote,
                            timeStamp: Date.now(),
                        } as ILeaseReview,
                    };
                    await ratingStore.upsertReview(
                        review.leaseAgreementId,
                        userStore.userId,
                        data as ILeaseRatingsReview,
                    );
                }

                await ratingStore
                    .updateRatingDetails(
                        review.leaseAgreementId,
                        isLandlord
                            ? { ownerCompletedOn: Date.now() }
                            : { tenantCompletedOn: Date.now() },
                        term,
                    )
                    .then(() => {
                        uiStore.hideLoading();
                        handleCloseModal();
                        handleReset();
                        uiStore.success('Review submitted');
                        handleReviewCompleted();
                    });
                uiStore.hideLoading();
                return;
            }
            const step = UserRatingReview[stepIndex + 1];
            setActiveStep(step);
        };

        return (
            <>
                <Dialog
                    open={open}
                    onClose={handleCloseModal}
                    maxWidth='sm'
                    fullWidth
                    PaperProps={{ style: { padding: '1.5rem', paddingTop: 0, height: '400px' } }}
                >
                    <DialogContent
                        style={{
                            overflow: 'hidden',
                            marginLeft: '1.5rem',
                            marginRight: '1.5rem',
                        }}
                    >
                        {stepComponents[activeStep].component}
                    </DialogContent>
                    <DialogActions>
                        <Box
                            style={{
                                width: '100%',
                                padding: '10px',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            {activeStep === firstStep ? (
                                <Button
                                    onClick={() => {
                                        handleCloseModal();
                                        handleReset();
                                        handleBack();
                                    }}
                                    style={{ marginRight: '1rem' }}
                                >
                                    Cancel
                                </Button>
                            ) : (
                                <Button style={{ marginRight: '1rem' }} onClick={handleBack}>
                                    Back
                                </Button>
                            )}

                            {activeStep === lastStep ? (
                                <Button variant='contained' color='primary' onClick={handleNext}>
                                    submit
                                </Button>
                            ) : activeStep === firstStep ? (
                                <Button variant='contained' color='primary' onClick={handleNext}>
                                    Begin
                                </Button>
                            ) : (
                                <Button variant='contained' color='primary' onClick={handleNext}>
                                    Next
                                </Button>
                            )}
                        </Box>
                    </DialogActions>
                </Dialog>
            </>
        );
    },
);
