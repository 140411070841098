import { Grid, Typography, Divider } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';

export const SendWizardEnd: React.FC<{ amount: number; sendNotes: string }> = observer(
    ({ amount, sendNotes }) => {
        return (
            <>
                <Grid container spacing={4} style={{ marginTop: '1rem' }}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            <big>Send money</big>
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                        <Typography variant='subtitle1'>
                            <b>Amount</b>
                        </Typography>
                        <Typography variant='subtitle1'>${amount}CAD</Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                        <Typography variant='subtitle1'>
                            <b>Notes</b>
                        </Typography>
                        <Typography variant='subtitle1'>{sendNotes}</Typography>
                    </Grid>
                </Grid>
            </>
        );
    },
);
