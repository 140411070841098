import { createStyles, makeStyles, styled, Theme } from '@material-ui/core/styles';

export const FullWidth = styled('span')({
  width: '100%'
})

export const StepperButtons = styled('div')({
  padding: '10px',

})

export const Spacing = styled('div')({
  flex: '1 1 auto',
})

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    marginBottom: {
      marginBottom: theme.spacing(1),
    },

    root: {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '500px',
        height: '100%',
        maxHeight: '600px'
      },
    },

    verificationTypeContent: {
      flex: '1 1 auto',
      textAlign: 'center',
      minHeight: '100px'
    },
    verificationTypeRoot: {
      display: 'flex',
      height: '100%',
    }
  })
)