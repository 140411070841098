import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@material-ui/core';

import { observer } from 'mobx-react-lite';
import { getRootStyles } from '../styles';

interface ConfirmDialogProps {
    title: string;
    content: React.ReactNode;
    acceptText?: string;
    openDialog: boolean;
    closeDialog: (confirm: boolean) => () => void;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = observer(
    ({ title, content, acceptText = 'Yes', openDialog, closeDialog }) => {
        const classes = getRootStyles();
        return (
            <Dialog open={openDialog} onClose={closeDialog(false)} fullWidth={true}>
                <DialogTitle id='alert-dialog-title'>
                    <Typography align='center' variant='h6'>
                        {title}
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.modalContent}>
                    <Typography align='center' variant='body1'>
                        {content}
                    </Typography>
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button onClick={closeDialog(false)} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={closeDialog(true)} color='primary' variant='contained'>
                        {acceptText}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
);
