import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Chip,
    Grid,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    IconButton,
    ListItemSecondaryAction,
} from '@material-ui/core';
import { FileCopy, Delete } from '@material-ui/icons';
import { ILeaseAgreementDocument, IdLeaseAgreementDocument } from 'realhaus-sdk';
import { RootStoreContext } from '../../global/storeContext';
import { ConfirmDialog } from '../uiComponents/confirmationDialog';
import { DocumentUploadButton } from './documentUploadButton';

export const LeaseDocumentsComponent: React.FC<{
    readOnly: boolean;
    leaseId: string;
}> = observer(({ readOnly, leaseId }) => {
    return !readOnly ? (
        <LeaseDocumentsEditComponent leaseId={leaseId} />
    ) : (
        <LeaseDocumentsReadComponent leaseId={leaseId} />
    );
});

export const LeaseDocumentsEditComponent: React.FC<{ leaseId: string }> = observer(
    ({ leaseId }) => {
        const { leaseStore, uiStore } = useContext(RootStoreContext);
        const [documents, setDocuments] = React.useState<IdLeaseAgreementDocument[]>([]);
        const [docToDelete, setDocToDelete] = React.useState<IdLeaseAgreementDocument>();
        const [openConfirmDelete, setOpenConfirmDelete] = React.useState<boolean>(false);

        const getDocument = async () => {
            const documents = await leaseStore.getLeaseAgreementDocuments(leaseId);
            if (documents) {
                setDocuments(documents);
            }
        };

        useEffect(() => {
            setDocuments([]);
            const init = async () => {
                uiStore.showLoading();
                try {
                    await getDocument();
                } finally {
                    uiStore.hideLoading();
                }
            };

            init();
        }, [leaseId]);

        const handleDeleteDocument = async (doc: IdLeaseAgreementDocument) => {
            try {
                uiStore.showLoading();
                await leaseStore.deleteLeaseAgreementDocument(leaseId, doc.id, doc);
                setDocuments(documents.filter((d) => d.id !== doc.id));
            } catch (err) {
            } finally {
                uiStore.hideLoading();
            }
        };

        return (
            <>
                <Box display='flex' justifyContent='space-between' mb={2}>
                    <Typography variant='h6'>Lease Documents</Typography>
                    <DocumentUploadButton leaseId={leaseId} updateDocument={getDocument} />
                </Box>
                <Typography variant='body1' gutterBottom>
                    Upload all documents that pertains to this lease in this section
                </Typography>
                <Box mt={2}>
                    <Grid container spacing={3}>
                        <Grid item md={12}>
                            {documents &&
                                documents?.map((doc, index) => (
                                    <List key={index}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar sizes='small'>
                                                    <FileCopy />
                                                </Avatar>
                                            </ListItemAvatar>

                                            <ListItemText
                                                primary={
                                                    doc.url ? (
                                                        <a
                                                            href={doc.url}
                                                            target='_blank'
                                                            rel='noreferrer'
                                                        >
                                                            {doc.title}
                                                        </a>
                                                    ) : (
                                                        doc.title
                                                    )
                                                }
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    edge='end'
                                                    aria-label='delete'
                                                    onClick={() => {
                                                        setDocToDelete(doc);
                                                        setOpenConfirmDelete(true);
                                                    }}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                ))}
                        </Grid>
                    </Grid>
                </Box>

                <ConfirmDialog
                    title='Confirm Delete Document'
                    content={
                        <Typography>
                            Are you sure you want to delete "{docToDelete?.title}"? This action
                            cannot be undone.
                        </Typography>
                    }
                    openDialog={openConfirmDelete}
                    closeDialog={(confirm: boolean) => () => {
                        if (confirm && docToDelete) {
                            handleDeleteDocument(docToDelete);
                        }

                        setOpenConfirmDelete(false);
                    }}
                />
            </>
        );
    },
);

const LeaseDocumentsReadComponent: React.FC<{
    leaseId: string;
}> = observer(({ leaseId }) => {
    const { leaseStore } = useContext(RootStoreContext);
    const [documents, setDocuments] = React.useState<ILeaseAgreementDocument[]>([]);

    useEffect(() => {
        setDocuments([]);
        const init = async () => {
            const documents = await leaseStore.getLeaseAgreementDocuments(leaseId);
            if (documents) {
                setDocuments(documents);
            }
        };

        init();
    }, [leaseId]);

    return (
        <>
            <Typography variant='h6'>Lease Documents</Typography>
            <Box mt={3}>
                {documents &&
                    documents?.map((document, key) => (
                        <Chip
                            key={key}
                            label={
                                document.url ? (
                                    <a href={document.url} target='_blank' rel='noreferrer'>
                                        {document.title}
                                    </a>
                                ) : (
                                    document.title
                                )
                            }
                            style={{ marginBottom: 5, marginRight: 5 }}
                        />
                    ))}
            </Box>
        </>
    );
});
