import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { observer } from 'mobx-react-lite';
import { Typography } from '@material-ui/core';
type ValuesType = {
    label: string;
    value: any;
};
const RadioButtonsGroup: React.FC<{
    label?: string;
    value?: string | number | boolean;
    values?: ValuesType[];
    onChange: (value: any) => void;
    children: any;
    id: string;
}> = observer(({ value, onChange, label, id, children }) => {
    return (
        <FormControl component='fieldset'>
            {label && (
                <FormLabel focused={false} component='legend'>
                    <Typography variant='body1'>{label}</Typography>
                </FormLabel>
            )}
            <RadioGroup
                id={id}
                name={id}
                aria-label={id}
                value={value}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    onChange(event.target.value as unknown);
                }}
            >
                {children}
            </RadioGroup>
        </FormControl>
    );
});

export const RadioItem: React.FC<{
    id: string;
    color?: 'primary' | 'secondary' | 'default' | undefined;
    labelPlacement?: any;
    value: ValuesType;
}> = observer(({ id, color = 'primary', labelPlacement, value }) => {
    return (
        <FormControlLabel
            value={value.value}
            control={<Radio color={color} />}
            labelPlacement={labelPlacement}
            label={value.label}
            name={id}
        />
    );
});

export default RadioButtonsGroup;
