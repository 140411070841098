import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Dialog, DialogContent } from '@material-ui/core';
import { useStyles } from './styles';
import Persona from 'persona';
import { PersonaEnvironment, PersonaTemplateId } from '../../appConfig';
import { RootStoreContext } from '../../global/storeContext';

export const PersonaModal: React.FC<{
    openVerificationModal: boolean;
    closeVerificationModal: () => void;
    onVerificationComplete: () => void;
}> = observer(({ openVerificationModal, onVerificationComplete, closeVerificationModal }) => {
    const { idVerificationStore, uiStore } = useContext(RootStoreContext);
    const classes = useStyles();

    const closeModal = () => {
        onVerificationComplete();
    };

    return (
        <>
            <Dialog
                open={openVerificationModal}
                onClose={closeVerificationModal}
                maxWidth='sm'
                fullWidth={true}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
                className={classes.root}
            >
                <DialogContent>
                    <Persona.Inquiry
                        templateId={PersonaTemplateId}
                        environment={PersonaEnvironment}
                        onLoad={() => {
                            console.log('Loaded inline');
                        }}
                        onComplete={({ inquiryId, status, fields }) => {
                            // Inquiry completed. Optionally tell your server about it.
                            console.log(`ID verification complete. ${inquiryId}`);
                            if (status === 'completed') {
                                closeModal();
                                uiStore.hideLoading();
                            }
                        }}
                        onEvent={(name, meta) => {
                            switch (name as any) {
                                case 'start': {
                                    if (meta && meta.inquiryId) {
                                        idVerificationStore
                                            .updatePersonaIDVerification(meta.inquiryId as string)
                                            .then(() => {
                                                // setInquiryId(meta.inquiryId as string);
                                            });
                                    }
                                    break;
                                }
                            }
                        }}
                        onError={(error) => {
                            console.error(`User Identity Verification Error`, error);
                        }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
});
