import {
    Box,
    createStyles,
    FormControl,
    Grid,
    makeStyles,
    MenuItem,
    Popover,
    Select,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { BillStatus, IdBill, IdLeaseAgreement, LeaseAgreementStatus } from 'realhaus-sdk';
import { AdminPageLayout, PageTitle } from '../../../components/uiComponents/UIComponents';
import { RootStoreContext } from '../../../global/storeContext';
import { streetAddress } from '../../../utils/address';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
interface IEarningBills {
    sumTotal: number;
    bills: IdBill[];
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const Earnings: React.FC<{}> = observer(() => {
    const [propertyFilter, setPropertyFilter] = React.useState<string>('all');
    const [yearlyFilter, setYearlyFilter] = React.useState<string>(
        new Date().getFullYear().toString(),
    );
    const [leases, setLeases] = React.useState<IdLeaseAgreement[]>();
    const [expectedBills, setExpectedBills] = React.useState<IEarningBills>();
    const [paidBills, setPaidBills] = React.useState<IEarningBills>();
    const [filteredPaidBills, setFilteredPaidBills] = React.useState<IEarningBills>();
    const [filteredExpectedBills, setFilteredExpectedBills] = React.useState<IEarningBills>();
    const [signedLeaseAgreements, setSignedLeaseAgreements] = React.useState<IdLeaseAgreement[]>();
    const [filteredLeaseAgreements, setFilteredLeaseAgreements] =
        React.useState<IdLeaseAgreement[]>();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [yearIndex, setYearIndex] = React.useState(0);
    const [arrYears, setArrYears] = React.useState<number[]>([]);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const labels = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];
    const options = {
        responsive: true,
        plugins: {
            htmlLegend: {
                // ID of the container to put the legend in
                containerID: 'legend-container',
            },
            legend: {
                display: false,
                // position: 'top' as const,
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    const calculateSumBills = (bills: IdBill[]) => {
        let billsNum: number[] = [];

        bills.forEach((bills) => {
            billsNum.push(bills.amount);
        });

        return billsNum.reduce((acc, bills) => acc + Number(bills), 0);
    };

    //     generate datas for graph
    const generateData = (earningBills: IEarningBills | undefined) => {
        if (!earningBills) return [];
        let dataArr: number[] = [];
        for (let index = 0; index < labels.length; index++) {
            const bills = earningBills.bills.filter(
                (bill) => new Date(bill.dueDate).getMonth() === index,
            );
            if (bills.length > 0) {
                dataArr.push(calculateSumBills(bills));
            } else {
                dataArr.push(0);
            }
        }
        return dataArr;
    };

    //barChart data

    const data = {
        labels,
        datasets: [
            {
                label: 'Bills Paid',
                data: generateData(filteredPaidBills),
                backgroundColor: 'black',
            },
            {
                label: 'Expected',
                data: generateData(filteredExpectedBills),
                backgroundColor: 'gray',
            },
        ],
    };

    const { leaseStore } = React.useContext(RootStoreContext);

    const handlePropertyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const filter = event.target.value as string;
        setPropertyFilter(filter);
        filterLeasesBills(filter, yearlyFilter);
    };

    const handleYearChangeArrow = (direction: string) => {
        switch (direction) {
            case 'left': {
                if (yearIndex === 0) {
                    const filter = arrYears[arrYears.length - 1].toString();
                    setYearlyFilter(filter);
                    setYearIndex(arrYears.length - 1);
                    filterLeasesBills(propertyFilter, filter);
                    return;
                }
                const index = yearIndex - 1;
                const filter = arrYears[index].toString();
                setYearIndex(index);
                setYearlyFilter(filter);
                filterLeasesBills(propertyFilter, filter);
                break;
            }
            case 'right': {
                if (yearIndex + 1 >= arrYears.length) {
                    const filter = arrYears[0].toString();
                    setYearlyFilter(filter);
                    setYearIndex(0);
                    filterLeasesBills(propertyFilter, filter);
                    return;
                }
                const index = yearIndex + 1;
                const filter = arrYears[index].toString();
                setYearIndex(index);
                setYearlyFilter(filter);
                filterLeasesBills(propertyFilter, filter);
            }
        }
    };

    const filterLeasesBills = (propertyFilter: string, year: string) => {
        let findLeases = [...(signedLeaseAgreements ?? [])];
        let filteredPaidBills = [...(paidBills?.bills ?? [])];
        let filteredExpectedBills = [...(expectedBills?.bills ?? [])];
        let filteredPaidBillsObj = { ...paidBills } as IEarningBills;
        let filteredExpectedBillsObj = { ...expectedBills } as IEarningBills;

        if (!!filteredPaidBills && !!filteredExpectedBills && propertyFilter !== 'all') {
            const foundLease = findLeases.filter(
                (lease) => streetAddress(lease.listingInfo.address) === propertyFilter,
            );
            const filtPaidBill = foundLease.map((lease) => {
                return filteredPaidBills.filter(
                    (paidBill) => paidBill.leaseId === (lease.id as string),
                );
            });
            const filtExpecBill = foundLease.map((lease) => {
                return filteredExpectedBills.filter(
                    (expBill) => expBill.leaseId === (lease.id as string),
                );
            });
            findLeases = foundLease;
            filteredPaidBills = filtPaidBill.flat();
            filteredExpectedBills = filtExpecBill.flat();

            //   calculate sum of bills
            const sumPaidBills = calculateSumBills(filteredPaidBills);
            const sumExpectBills = calculateSumBills(filteredExpectedBills);
            filteredPaidBillsObj = {
                sumTotal: sumPaidBills,
                bills: filteredPaidBills,
            } as IEarningBills;
            filteredExpectedBillsObj = {
                sumTotal: sumExpectBills,
                bills: filteredExpectedBills,
            } as IEarningBills;
        }

        if (!!year) {
            filteredPaidBills = filteredPaidBills.filter(
                (bill) => new Date(bill.dueDate).getFullYear() === Number(year),
            );
            filteredExpectedBills = filteredExpectedBills.filter(
                (bill) => new Date(bill.dueDate).getFullYear() === Number(year),
            );

            findLeases = findLeases.filter((lease) => {
                return (
                    new Date(lease.moveinDate).getFullYear() === Number(year) ||
                    new Date(lease.moveoutDate).getFullYear() === Number(year)
                );
            });

            //   calculate sum
            const sumPaidBills = calculateSumBills(filteredPaidBills);
            const sumExpectBills = calculateSumBills(filteredExpectedBills);

            filteredPaidBillsObj = {
                sumTotal: sumPaidBills,
                bills: filteredPaidBills,
            } as IEarningBills;
            filteredExpectedBillsObj = {
                sumTotal: sumExpectBills,
                bills: filteredExpectedBills,
            } as IEarningBills;
        }

        setFilteredLeaseAgreements(findLeases);
        setFilteredPaidBills(filteredPaidBillsObj);

        setFilteredExpectedBills(filteredExpectedBillsObj);
    };

    const getAddresses = () => {
        const address: string[] = [];
        leases?.forEach((lease) => {
            const key = streetAddress(lease.listingInfo.address);
            if (!key) return;
            if (!address.find((addr) => addr === key)) {
                address.push(key);
            }
        });
        return address;
    };

    const getYears = (allLeaseBills: IdBill[]) => {
        const allLeaseBillsDates: number[] = [];
        let years = [];
        allLeaseBills?.forEach((lBill) => {
            allLeaseBillsDates.push(lBill.dueDate);
        });
        if (allLeaseBillsDates.length > 0) {
            const minDate = Math.min(...allLeaseBillsDates);
            const maxDate = Math.max(...allLeaseBillsDates);
            const maxYear = new Date(maxDate).getFullYear();
            const minYear = new Date(minDate).getFullYear();

            for (let i = maxYear; i >= minYear; i--) {
                years.push(i);
            }
        }
        return years;
    };

    const getOccupiedMonths = () => {
        let filteredLeases = [...(filteredLeaseAgreements ?? [])];
        let months: number[] = [];

        if (filteredLeases.length <= 0) {
            return 0;
        }
        if (!!leases && propertyFilter !== 'all') {
            filteredLeases.forEach((fL) => {
                months.push(
                    calculateYearlyOccupancyRate(
                        new Date(fL.moveinDate),
                        new Date(fL.moveoutDate),
                        yearlyFilter === 'all' ? new Date().getFullYear() : Number(yearlyFilter),
                    ),
                );
            });

            return months.reduce((acc, month) => acc + Number(month), 0).toFixed(2);
        } else if (!!leases && propertyFilter === 'all') {
            filteredLeases.forEach((fL) => {
                months.push(
                    calculateYearlyOccupancyRate(
                        new Date(fL.moveinDate),
                        new Date(fL.moveoutDate),
                        yearlyFilter === 'all' ? new Date().getFullYear() : Number(yearlyFilter),
                    ),
                );
            });

            return Math.max(...months);
        }

        return 0;
    };

    const calculateYearlyOccupancyRate = (
        moveInDate: Date,
        moveOutDate: Date,
        year: number,
    ): number => {
        const yearEnd = new Date(year, 11, 31);
        const totalMonths = yearEnd.getMonth();

        let occupiedMonths = 0;
        if (year === moveInDate.getFullYear() && year === moveOutDate.getFullYear()) {
            occupiedMonths = Math.ceil(moveOutDate.getMonth() - moveInDate.getMonth());
        } else if (year === moveInDate.getFullYear()) {
            const yearEndDate = new Date(year, 11, 31);
            occupiedMonths = Math.ceil(yearEndDate.getMonth() - moveInDate.getMonth());
        } else if (year === moveOutDate.getFullYear()) {
            const yearStartDate = new Date(year, 0, 1);
            occupiedMonths = Math.ceil(moveOutDate.getMonth() - yearStartDate.getMonth());
        } else {
            occupiedMonths = totalMonths;
        }

        return occupiedMonths + 1;
    };

    const formatToCurrency = (number: number | undefined) => {
        const curr = number?.toLocaleString('en-CA', { style: 'currency', currency: 'CAD' });
        return !!curr ? curr : '$0';
    };

    const getInitialReadings = async () => {
        let paidBills: IdBill[] = [];
        let filteredPaidBills: IdBill[] = [];
        let expectedBills: IdBill[] = [];
        let filteredExpectedBills: IdBill[] = [];
        let allLeasesBills: IdBill[] = [];
        const leaseAgreements = await leaseStore.getOwnerLeaseAgreements();
        const signedLeaseAgreements = leaseAgreements?.filter(
            (lease) => lease.status === LeaseAgreementStatus.SIGNED,
        );
        setSignedLeaseAgreements(signedLeaseAgreements);
        setFilteredLeaseAgreements(signedLeaseAgreements);
        setLeases(leaseAgreements);

        if (leaseAgreements) {
            for (const lease of leaseAgreements) {
                const leaseBills = await leaseStore.getAllLeaseBills(lease.id);
                if (!leaseBills) return;
                allLeasesBills.push(...leaseBills);
                const paidLeaseBills = leaseBills.filter(
                    (leaseBill) => leaseBill.status === BillStatus.PAID,
                );

                const yearFilterPaidBills = paidLeaseBills.filter(
                    (bill) => new Date(bill.dueDate).getFullYear() === new Date().getFullYear(),
                );
                const expectedLeaseBills = leaseBills.filter(
                    (leaseBill) => leaseBill.status === BillStatus.PENDING,
                );
                const yearFilterExpectBills = expectedLeaseBills.filter(
                    (bills) => new Date(bills.dueDate).getFullYear() === new Date().getFullYear(),
                );
                paidLeaseBills.forEach((paidBill) => paidBills.push(paidBill));
                yearFilterPaidBills.forEach((yPaidBills) => filteredPaidBills.push(yPaidBills));
                expectedLeaseBills.forEach((paidBill) => expectedBills.push(paidBill));
                yearFilterExpectBills.forEach((yExpPaidBill) =>
                    filteredExpectedBills.push(yExpPaidBill),
                );
            }
            const sumPaid = calculateSumBills(paidBills);
            const filteredSumPaid = calculateSumBills(filteredPaidBills);
            const paidObj = {
                sumTotal: sumPaid,
                bills: paidBills,
            } as IEarningBills;

            const filtPaidObj = {
                sumTotal: filteredSumPaid,
                bills: filteredPaidBills,
            } as IEarningBills;

            setPaidBills(paidObj);
            setFilteredPaidBills(filtPaidObj);

            const sumExpect = calculateSumBills(expectedBills);
            const filtSumExpect = calculateSumBills(filteredExpectedBills);

            const expectObj = {
                sumTotal: sumExpect,
                bills: expectedBills,
            } as IEarningBills;

            const filtExpectObj = {
                sumTotal: filtSumExpect,
                bills: filteredExpectedBills,
            };

            //   get and set all years used in the bills
            setArrYears(getYears(allLeasesBills));
            //   get year index of current year
            setYearIndex(getYears(allLeasesBills).indexOf(new Date().getFullYear()));

            setExpectedBills(expectObj);
            setFilteredExpectedBills(filtExpectObj);
        }
    };

    //     getInitialReadings();
    useEffect(() => {
        getInitialReadings();
    }, []);

    const OccupancyStats = () => {
        return (
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h5' align='center'>
                        {`${yearlyFilter === 'all' ? new Date().getFullYear() : yearlyFilter}`}{' '}
                        Stats
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container xs={12}>
                        <Grid item xs={6} style={{ display: 'flex' }}>
                            {propertyFilter === 'all' ? (
                                <Typography
                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                >
                                    Unoccupied months
                                </Typography>
                            ) : (
                                <Typography display='inline'>Unoccupied months</Typography>
                            )}
                        </Grid>
                        <Grid item xs={6} style={{ display: 'flex' }} justifyContent='flex-end'>
                            <Typography>{`${12 - Number(getOccupiedMonths())}`}/12</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container xs={12}>
                        <Grid item xs={6}>
                            <Typography>Occupancy rate</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ display: 'flex' }} justifyContent='flex-end'>
                            <Typography>{`${((Number(getOccupiedMonths()) / 12) * 100).toFixed(
                                2,
                            )}%`}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const CharNav = () => {
        return (
            <Grid container xs={12} spacing={1}>
                <Grid item xs={2} style={{ display: 'flex' }} justifyContent='center'>
                    <ChevronLeftIcon
                        onClick={() => handleYearChangeArrow('left')}
                        style={{ cursor: 'pointer' }}
                    />
                </Grid>
                <Grid item xs={8} style={{ display: 'flex' }} justifyContent='center'>
                    <Typography variant='subtitle1'>
                        <b>{arrYears[yearIndex]}</b>
                    </Typography>
                </Grid>
                <Grid item xs={2} style={{ display: 'flex' }} justifyContent='center'>
                    <ChevronRightIcon
                        onClick={() => handleYearChangeArrow('right')}
                        style={{ cursor: 'pointer' }}
                    />
                </Grid>
            </Grid>
        );
    };

    const ChartLegend = () => {
        return (
            <Grid
                container
                direction='row'
                spacing={2}
                justifyContent='flex-start'
                alignItems='center'
                style={{ marginTop: '2rem' }}
            >
                <Grid item>
                    <Typography variant='h6'>
                        {!!filteredPaidBills ? formatToCurrency(filteredPaidBills.sumTotal) : '$0'}
                    </Typography>
                    <Box display='flex'>
                        <Box
                            style={{
                                backgroundColor: 'black',
                                width: '20px',
                                height: '20px',
                                marginRight: '5px',
                            }}
                        ></Box>

                        <Typography>Bills paid</Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Typography variant='h6'>
                        {!!filteredExpectedBills
                            ? formatToCurrency(filteredExpectedBills.sumTotal)
                            : '$0'}
                    </Typography>
                    <Box display='flex'>
                        <Box
                            style={{
                                backgroundColor: 'gray',
                                width: '20px',
                                height: '20px',
                                marginRight: '5px',
                            }}
                        ></Box>

                        <Typography>Expected</Typography>
                    </Box>
                </Grid>
            </Grid>
        );
    };
    return (
        <>
            <Grid container spacing={2} direction='column'>
                <Grid item>
                    <Grid
                        container
                        direction='row'
                        spacing={2}
                        justifyContent='flex-start'
                        alignItems='center'
                    >
                        <Grid item>Filter:</Grid>
                        <Grid item>
                            <FormControl
                                variant='outlined'
                                size='small'
                                style={{ minWidth: '300px', maxWidth: '350px' }}
                            >
                                <Select
                                    id='propertyFilter'
                                    value={propertyFilter}
                                    onChange={handlePropertyChange}
                                    displayEmpty
                                    variant='outlined'
                                >
                                    <MenuItem value='all' key='all-properties'>
                                        All Properties
                                    </MenuItem>
                                    {getAddresses().map((addr) => (
                                        <MenuItem value={addr} key={addr}>
                                            {addr}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <ChartLegend />
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: '2rem' }} md={10} spacing={3}>
                <Grid item xs={12}>
                    <Bar options={options} data={data} />
                </Grid>
                <Grid item xs={12}>
                    <CharNav />
                </Grid>
                <Grid item xs={12}>
                    <OccupancyStats />
                </Grid>
            </Grid>

            <InfoHoverPopper
                open={open}
                anchorEl={anchorEl}
                handlePopoverClose={handlePopoverClose}
            />
        </>
    );
});

const InfoHoverPopper: React.FC<{
    open: boolean;
    anchorEl: HTMLElement | null;
    handlePopoverClose: () => void;
}> = observer(({ open, anchorEl, handlePopoverClose }) => {
    const useStyles = makeStyles(() =>
        createStyles({
            paper: {
                padding: '1rem',
            },
        }),
    );

    const classes = useStyles();
    return (
        <>
            <Popover
                id='mouse-over-popover'
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                style={{ pointerEvents: 'none' }}
                classes={{ paper: classes.paper }}
            >
                <Typography>Data shows listing with the most unoccupied months</Typography>
            </Popover>
        </>
    );
});

export const EarningsPage: React.FC<{}> = observer(() => {
    return (
        <AdminPageLayout
            header={<PageTitle>Earnings</PageTitle>}
            content={<Earnings />}
        ></AdminPageLayout>
    );
});
