import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Loader } from '../loader/loader';

export interface GatekeeperProps {
    initialize: () => Promise<any>;
    children?: React.ReactNode;
}

const Gatekeeper: React.FC<GatekeeperProps> = observer(({ children, initialize }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        initialize()
            .then(() => {
                setLoading(false);
            })
            .catch(setError);
    }, []);

    if (error) {
        return <span>Error...</span>;
    }

    if (loading) {
        return <Loader loading={loading} />;
    }

    return <>{children}</>;
});

export default Gatekeeper;
