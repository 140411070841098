import { TextField } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { InputVariant } from '../../enums/InputVariant';

export const InputField: React.FC<{
    id: string;
    value?: string | null;
    placeholder: string;
    fullWidth: boolean;
    variant?: InputVariant;
    disable?: boolean;
    [x: string]: any;
    onChange: (value: string | null) => void;
}> = observer(({ id, value, placeholder, fullWidth, variant, disable, onChange, ...props }) => {
    return (
        <TextField
            {...props}
            fullWidth={fullWidth}
            required
            id={id}
            placeholder={placeholder}
            margin='normal'
            variant={variant ?? 'outlined'}
            InputLabelProps={{
                shrink: true,
            }}
            value={value ?? ''}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                onChange(event.target.value as string);
            }}
            disabled={disable}
        />
    );
});
