import { Color } from '@material-ui/lab';
import { makeAutoObservable, observable } from 'mobx';
import { LeaseAgreementViewer } from 'realhaus-sdk';
import { paths } from '../routes';

export enum ToastrType {
  INFO,
  ERROR,
  WARN,
  SUCCESS,
}

export class UIStore {
  @observable toastrOpen = false;
  @observable toastrMsg: { severity: Color; message: string } | undefined;
  @observable loading = false;

  private loadingStack: boolean[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  success = (message: string) => {
    console.log(message);
    this.openToastr(ToastrType.SUCCESS, message);
  }

  error = (message: string) => {
    console.error(message);
    this.openToastr(ToastrType.ERROR, message);
  }

  openToastr = (type: ToastrType, message: string) => {
    this.toastrMsg = { severity: "success", message }
    switch (type) {
      case ToastrType.ERROR: {
        this.toastrMsg.severity = 'error';
        break;
      }
      case ToastrType.WARN: {
        this.toastrMsg.severity = 'warning';
        break;
      }
      case ToastrType.INFO: {
        this.toastrMsg.severity = 'info';
        break;
      }
      case ToastrType.SUCCESS: {
        this.toastrMsg.severity = 'success';
        break;
      }
    }
    this.toastrOpen = true;
  }

  closeToastr = () => {
    this.toastrOpen = false;
  }

  goTo = (path: string, redirect?: string) => {
    const url = redirect ? `${path}?redirectURL=${redirect}` : path
    this.clearLoading();
    if (`${window.location.origin}${url}` === window.location.href) return;
    window.location.href = url;
  }

  showLoading = () => {
    this.loadingStack.push(true);
    this.loading = true;
  }

  hideLoading = () => {
    this.loadingStack.pop();
    if (this.loadingStack.length === 0)
      this.loading = false;
  }

  isLandlordMode = () => {
    return window.location.pathname.startsWith('/m/');
  }

  isTenantMode = () => {
    return window.location.pathname.startsWith('/t/');
  }

  getLeaseViewerType = () => {
    if (this.isLandlordMode()) {
      return LeaseAgreementViewer.LANDLORD;
    }
    return LeaseAgreementViewer.TENANT;
  }

  private clearLoading = () => {
    this.loadingStack = [];
    this.hideLoading();
  }
}