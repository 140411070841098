import React from 'react';
import { observer } from 'mobx-react-lite';
import { Container, Grid } from '@material-ui/core';
import { MainHeader } from './header';
import { MainContent } from './styles';
import { MainFooter } from './footer';
import { NegativeBanner } from '../../App';

interface MainLayoutProps {
    fullWidth?: boolean;
    children?: React.ReactNode;
    // children?: React.ReactNode;
}
export const MainLayout: React.FC<MainLayoutProps> = observer(({ children, fullWidth }) => {
    return (
        <>
            <div>
                <MainHeader />
                {/* Body */}
                <MainContent style={{ minHeight: 'calc(100vh - 140px)' }}>
                    <NegativeBanner margin={true} />
                    <main>
                        <Container maxWidth={fullWidth ? 'xl' : 'lg'}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {children}
                                </Grid>
                            </Grid>
                        </Container>
                    </main>
                </MainContent>
                {/* Footer */}
                <MainFooter />
            </div>
        </>
    );
});
