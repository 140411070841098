import { Button } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { WithdrawFundsCheckout } from './withdrawFundsCheckout';

export interface WithdrawFundsButtonProps {
    variant?: 'outlined' | 'text' | 'contained' | undefined;
    color?: 'primary' | 'inherit' | 'secondary' | 'default' | undefined;
    size?: 'small' | 'medium' | 'large' | undefined;
    text?: string;
    updateWallet: () => Promise<void>;
}

export const WithdrawFundsButton: React.FC<WithdrawFundsButtonProps> = observer(
    ({ text = 'Withdraw', variant = 'outlined', color = 'primary', size = 'small',updateWallet }) => {
        const [openCheckout, setOpenCheckout] = React.useState(false);

        const handleOpenCheckout = () => {
            setOpenCheckout(true);
        };

        const handleCloseCheckout = () => {
            setOpenCheckout(false);
        };

        return (
            <>
                <Button
                    variant={variant}
                    color={color}
                    size={size}
                    aria-haspopup='true'
                    onClick={handleOpenCheckout}
                >
                    {text}
                </Button>
                <WithdrawFundsCheckout
                    open={openCheckout}
                    handleClose={handleCloseCheckout}
                    updateWallet={updateWallet}
                />
            </>
        );
    },
);
