import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Checkbox,
    FormControl,
    Grid,
    ListItemText,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core';
import { AdminPageLayout, PageTitle } from '../../../components/uiComponents/UIComponents';
import {
    ITenantInfo,
    IdLeaseAgreement,
    LeaseAgreementStatus,
    LeaseAgreementViewer,
    LeaseStatuses,
} from 'realhaus-sdk';
import { LeaseSnapshot } from '../../../components/gatekeeper/lease/leaseSnapshot';
import { RootStoreContext } from '../../../global/storeContext';
import { LeaseAgreementTenantComponent } from '../../../components/leaseAgreement/leaseAgreementTenant';
import { LeaseAgreementDetailComponent } from '../../../components/leaseAgreement/details/leaseAgreementDetail';
import { useSearchParams } from 'react-router-dom';
import { paths } from '../../../routes';

const TenantLeases: React.FC = observer(() => {
    const {
        leaseStore,
        userStore: { userId },
        uiStore,
    } = useContext(RootStoreContext);
    const [leaseAgreementModalOpen, setLeaseAgreementModalOpen] = React.useState<boolean>(false);
    const [leaseAgreementDetailOpen, setLeaseAgreementDetailOpen] = React.useState<boolean>(false);
    const [selectedLeaseId, setSelectedLeaseId] = React.useState<string | undefined>();
    const [tenants, setTenants] = React.useState<Map<string, ITenantInfo>>(
        new Map<string, ITenantInfo>(),
    );
    const [statusFilter, setStatusFilter] = React.useState<string[]>(
        LeaseStatuses.map((s) => s.key),
    );
    const [leases, setLeases] = React.useState<IdLeaseAgreement[]>();
    const [filteredLeases, setFilteredLeases] = React.useState<IdLeaseAgreement[]>();
    const [hasActiveLease, setHasActiveLease] = React.useState(false);
    const [params] = useSearchParams();

    useEffect(() => {
        const init = async () => {
            const leaseAgreements = await leaseStore.getLeaseAgreementsForTenant();
            setLeases(leaseAgreements);
            setFilteredLeases(leaseAgreements);

            const tenantIds: string[] = [];
            leaseAgreements?.forEach((l) =>
                tenantIds.push(...l.tenantIds.filter((id) => !tenantIds.find((x) => x === id))),
            );
            const hasActiveLeaseAgreement =
                leaseAgreements?.some((l) => LeaseAgreementStatus.SIGNED === l.status) || false;
            const tenants = await Promise.all(
                tenantIds.map(async (tenantId) => {
                    const tenant = await leaseStore.getTenantInfo(tenantId);
                    return Promise.resolve({ id: tenantId, tenant: tenant });
                }),
            );

            const tMap = new Map<string, ITenantInfo>();
            tenants.forEach((tenant) => {
                if (!tMap.has(tenant.id) && !!tenant.tenant) {
                    tMap.set(tenant.id, tenant.tenant);
                }
            });
            setTenants(tMap);
            setHasActiveLease(hasActiveLeaseAgreement);
            if (userId && !tenantIds.includes(userId)) {
                handleAddCurrentUserToLease();
            }
        };
        init();
    }, []);

    const handleAddCurrentUserToLease = async () => {
        const joinlease = params.get('joinlease');
        const leaseAgreementId = params.get('leaseId');
        if (joinlease && leaseAgreementId) {
            uiStore.showLoading();
            const res = await leaseStore.addTenantFromLeaseInviteToLease(leaseAgreementId);
            if (res) {
                uiStore.hideLoading();
                uiStore.goTo(paths.tenant.leases);
            } else {
                uiStore.hideLoading();
            }
        }
    };

    useEffect(() => {
        if (!!selectedLeaseId) {
            const selectedLease = [...(filteredLeases ?? [])].find(
                (lease) => selectedLeaseId === lease.id,
            );
            if (
                LeaseAgreementStatus.SIGNED === selectedLease?.status ||
                LeaseAgreementStatus.TERMINATED === selectedLease?.status
            ) {
                setLeaseAgreementDetailOpen(true);
            } else {
                setLeaseAgreementModalOpen(true);
            }
        } else {
            setLeaseAgreementModalOpen(false);
            setLeaseAgreementDetailOpen(false);
        }
    }, [selectedLeaseId]);

    const handleLeaseModalOpen = () => {
        if (!selectedLeaseId) return;
        setLeaseAgreementModalOpen(true);
    };

    const handleLeaseModalClose = () => {
        setLeaseAgreementModalOpen(false);
        setSelectedLeaseId(undefined);
    };

    const handleLeaseDetailOpen = () => {
        if (!selectedLeaseId) return;
        setLeaseAgreementDetailOpen(true);
    };

    const handleLeaseDetailClose = () => {
        setLeaseAgreementDetailOpen(false);
        setSelectedLeaseId(undefined);
    };

    const handleLeaseSnapshotSelected = (leaseId: string) => () => {
        setSelectedLeaseId(leaseId);
    };

    const handleStatusFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let filteredValues = event.target.value as string[];
        filteredValues =
            filteredValues.length === 0 ? LeaseStatuses.map((s) => s.key) : filteredValues;
        setStatusFilter(filteredValues);
        filterLeases(filteredValues);
    };

    const filterLeases = (filteredStatuses: string[]) => {
        let filtered = [...(leases ?? [])];
        if (filteredStatuses.length > 0) {
            filtered = filtered.filter((lease) =>
                filteredStatuses.find((f) => f === getStatusCategory(lease)),
            );
        }
        setFilteredLeases(filtered);
    };

    const getStatusCategory = (lease: IdLeaseAgreement) => {
        switch (lease.status) {
            case LeaseAgreementStatus.DRAFT:
            case LeaseAgreementStatus.PENDING_TENANT_SIGNATURE:
                return 'pending';
            case LeaseAgreementStatus.SIGNED: {
                return lease.moveoutDate < Date.now() ? 'past' : 'active';
            }
            case LeaseAgreementStatus.TERMINATED:
            case LeaseAgreementStatus.CANCELLED: {
                return 'past';
            }
        }
    };

    return (
        <>
            <Grid container spacing={2} direction='column'>
                <Grid item>
                    <Grid
                        container
                        spacing={2}
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                    >
                        <Grid item>Filter:</Grid>
                        <Grid item>
                            <FormControl
                                variant='outlined'
                                size='small'
                                style={{ minWidth: '250px', maxWidth: '300px' }}
                            >
                                <Select
                                    id='lease-status-filter'
                                    variant='outlined'
                                    multiple
                                    value={statusFilter}
                                    onChange={handleStatusFilterChange}
                                    renderValue={(selected) => (selected as string[]).join(', ')}
                                >
                                    {LeaseStatuses.map((status) => (
                                        <MenuItem key={status.key} value={status.key}>
                                            <Checkbox
                                                checked={
                                                    !!statusFilter.find((f) => f === status.key)
                                                }
                                            />
                                            <ListItemText primary={status.Text} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* We should pay rent through the Bills component. */}
                        {/* {hasActiveLease && (
                            <Grid
                                item
                                style={{
                                    display: 'flex',
                                    flexGrow: '2',
                                    flexDirection: 'row-reverse',
                                }}
                            >
                                <Grid item>
                                    <Button variant='outlined' color='primary' disabled>
                                        <PaymentIcon />
                                        Pay Rent
                                    </Button>
                                </Grid>
                            </Grid>
                        )} */}
                    </Grid>
                </Grid>
                <Grid item>
                    {/* filtered leases */}
                    {filteredLeases?.length === 0 ? (
                        <Typography variant='subtitle1'>
                            {!!leases && leases?.length > 0
                                ? 'Could not find a lease that matches the filter'
                                : 'There are no leases available'}
                        </Typography>
                    ) : (
                        filteredLeases?.map((lease) => (
                            <LeaseSnapshot
                                key={lease.id}
                                lease={lease}
                                tenants={lease.tenantIds
                                    .filter((tId) => tenants.has(tId))
                                    .map((tId) => tenants.get(tId) as ITenantInfo)}
                                isActive={selectedLeaseId === lease.id}
                                onSelected={handleLeaseSnapshotSelected(lease.id)}
                                viewer={LeaseAgreementViewer.TENANT}
                            />
                        ))
                    )}
                </Grid>
            </Grid>

            <LeaseAgreementTenantComponent
                leaseAgreementId={selectedLeaseId}
                isOpen={leaseAgreementModalOpen}
                onOpen={handleLeaseModalOpen}
                onClose={handleLeaseModalClose}
            />

            {/* TODO: allow tenant to view pending lease, and possible ask for lease update
            (if there is an update request update status of lease to draft)
            Also allow tenant view signed lease */}
            <LeaseAgreementDetailComponent
                leaseAgreementId={selectedLeaseId}
                isOpen={leaseAgreementDetailOpen}
                onOpen={handleLeaseDetailOpen}
                onClose={handleLeaseDetailClose}
                viewer={LeaseAgreementViewer.TENANT}
                setFilteredLeases={setFilteredLeases}
                setSelectedLeaseId={setSelectedLeaseId}
            />
        </>
    );
});

export const TenantLeasesPage: React.FC = observer(() => {
    return (
        <>
            <AdminPageLayout
                header={<PageTitle>Lease Agreements</PageTitle>}
                content={
                    // TODO:
                    // - Rent payment status
                    // - Pay due rent
                    // - Payment method configuration
                    <TenantLeases />
                }
            />
        </>
    );
});
