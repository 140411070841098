import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import DoneIcon from '@material-ui/icons/Done';
import { RootStoreContext } from '../../global/storeContext';
import { IAccountSetupSteps } from '../../stores/userStore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useNavigate } from 'react-router';

export const AccountSetup: React.FC = observer(() => {
    const { userStore } = React.useContext(RootStoreContext);
    const [userAccount, setUserAccount] = React.useState<IAccountSetupSteps>();
    const navigate = useNavigate();

    useEffect(() => {
        userStore.getAccountSetupSteps().then((data) => {
            setUserAccount(data);
        });
    }, []);
    return (
        <>
            <Card style={{ paddingTop: '10px' }}>
                <Typography align='center' variant='h6'>
                    <small>Set-up your account</small>
                </Typography>

                <CardContent
                    style={{ padding: '40px 60px', display: 'grid', placeContent: 'center' }}
                >
                    <Box>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Typography variant='subtitle1' color='primary'>
                                    <b>STEP 1</b>
                                </Typography>
                                <Box display='flex' justifyContent='space-between' width={'100%'}>
                                    <Typography variant='h6'>
                                        <small> Verify Your Email </small>
                                    </Typography>
                                    {userAccount?.EMAIL_VERIFIED ? (
                                        <DoneIcon color='primary' />
                                    ) : (
                                        <ChevronRightIcon
                                            color='primary'
                                            onClick={() => navigate('/account/personal-info')}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    )}
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography variant='subtitle1' color='primary'>
                                    <b>STEP 2</b>
                                </Typography>
                                <Box display='flex' justifyContent='space-between' width={'100%'}>
                                    <Typography variant='h6'>
                                        <small> Verify Your Identity </small>
                                    </Typography>
                                    {userAccount?.IDENTITY_VERIFIED ? (
                                        <DoneIcon color='primary' />
                                    ) : (
                                        <ChevronRightIcon
                                            color='primary'
                                            onClick={() => navigate('/account/personal-info')}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant='subtitle1' color='primary'>
                                    <b>STEP 3</b>
                                </Typography>
                                <Box display='flex' justifyContent='space-between' width={'100%'}>
                                    <Typography variant='h6'>
                                        <small> Setup Withdrawal Method </small>
                                    </Typography>
                                    {userAccount?.PAYOUT_ACCT_SETUP ? (
                                        <DoneIcon color='primary' />
                                    ) : (
                                        <ChevronRightIcon
                                            color='primary'
                                            onClick={() => navigate('/account/financials')}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
});
