import { BI_WEEKS_YEAR, IncomeType, MONTHS_YEAR, WEEKS_YEAR } from 'realhaus-sdk';

export const convertIncomeType = (income: number, fromType: IncomeType, toType: IncomeType) => {

  if (fromType === toType) return income;

  const incomeYearly = fromType === IncomeType.WEEKLY ? income * WEEKS_YEAR : fromType === IncomeType.BIWEEKLY ? income * BI_WEEKS_YEAR : fromType === IncomeType.MONTHLY ? income * MONTHS_YEAR : income;

  switch (toType) {
    case IncomeType.WEEKLY:
      return incomeYearly / WEEKS_YEAR;
    case IncomeType.BIWEEKLY:
      return incomeYearly / BI_WEEKS_YEAR;
    case IncomeType.MONTHLY:
      return incomeYearly / MONTHS_YEAR;
    case IncomeType.YEARLY:
      return incomeYearly;
  }
}