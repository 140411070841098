import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';
import { RootStoreContext } from '../../../global/storeContext';
import { IdLeaseAgreement } from 'realhaus-sdk';
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    createStyles,
    Grid,
    makeStyles,
    Paper,
    Theme,
    Typography,
} from '@material-ui/core';
import { paths } from '../../../routes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardRoot: {
            display: 'flex',
        },
        cardDetails: {
            display: 'flex',
            flexDirection: 'column',
            width: '500px',
            padding: '35px',
        },
        cardContent: {
            flex: '1 0 auto',
        },
        cardCover: {
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        cardControls: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        alignCenter: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '25px',
        },
    }),
);

const NoActiveLease: React.FC = () => {
    const classes = useStyles();

    const { uiStore } = useContext(RootStoreContext);

    const onSearchListings = () => {
        uiStore.goTo(paths.listings.search);
    };
    return (
        <>
            <Card variant='outlined' className={classes.cardRoot}>
                <div className={classes.cardDetails}>
                    <CardContent className={classes.cardContent}>
                        <Typography
                            variant='body1'
                            style={{ fontWeight: 'bold', fontSize: '16px' }}
                        >
                            You don't have an active lease ... yet!
                        </Typography>
                        {/* <Typography variant='subtitle1' color='textSecondary'>
                            Mac Miller
                        </Typography> */}
                    </CardContent>
                    <div className={classes.cardControls}>
                        <Button color='primary' variant='contained' onClick={onSearchListings}>
                            Find you next home
                        </Button>
                    </div>
                </div>

                <CardMedia
                    className={classes.cardCover}
                    image='/img/main3.jpg'
                    title='Somewhere peaceful'
                    style={{ backgroundPositionY: 'bottom' }}
                />
            </Card>
        </>
    );
};

const ActiveLease: React.FC<{ lease: IdLeaseAgreement }> = ({ lease }) => {
    const classes = useStyles();
    return (
        <>
            {/* <Paper variant='elevation'> */}
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} md={6}>
                    <Paper variant='elevation'>
                        <div className={classes.alignCenter}>
                            <Typography variant='subtitle2' color='textSecondary' gutterBottom>
                                RENT
                            </Typography>
                            <Typography
                                variant='h5'
                                gutterBottom
                                display='inline'
                                style={{ padding: '10px' }}
                            >
                                ${Number(lease.rentAmount ?? 0).toFixed(2)}{' '}
                                <Typography variant='caption' display='inline'>
                                    CAD
                                </Typography>
                            </Typography>
                            <Typography variant='subtitle2' color='textSecondary'>
                                DUE MONTHLY
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper variant='elevation'>
                        <div className={classes.alignCenter}>
                            <Typography variant='subtitle2' color='textSecondary' gutterBottom>
                                LEASE END
                            </Typography>
                            <Typography variant='h5' gutterBottom style={{ padding: '10px' }}>
                                {format(lease.moveoutDate, 'PPP')}
                            </Typography>
                            <Typography variant='subtitle2' color='textSecondary'>
                                {/* DUE MONTHLY */} &nbsp;
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            {/* </Paper> */}
        </>
    );
};

export const LeaseSummary: React.FC = observer(() => {
    const { leaseStore } = useContext(RootStoreContext);
    const [activeLease, setActiveLease] = useState<IdLeaseAgreement | undefined>();
    useEffect(() => {
        const init = async () => {
            // fetch lease aggrement
            const leases = (await leaseStore.getLeaseAgreementsForTenant())?.sort(
                (a, b) => a.moveoutDate - b.moveoutDate,
            );

            if (!!leases && leases?.length > 0 && leases[0].moveoutDate > Date.now()) {
                setActiveLease(leases[0]);
            }
        };

        init();
    }, []);
    return <>{!activeLease ? <NoActiveLease /> : <ActiveLease lease={activeLease} />}</>;
});
