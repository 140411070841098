import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    TextField,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { IDocumentType } from 'realhaus-sdk';
import { RootStoreContext } from '../../global/storeContext';
import { TransitionProps } from '@material-ui/core/transitions';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export const DocumentUploadDialog: React.FC<{
    leaseId: string;
    docSelectModalOpen: boolean;
    handleModalClose: () => void;
    updateDocument: () => void;
    additionalDocument?: boolean;
}> = observer(
    ({ leaseId, handleModalClose, updateDocument, docSelectModalOpen, additionalDocument }) => {
        const fileInput = React.useRef<HTMLInputElement>(null);
        const [doc, setDoc] = React.useState<IDocumentType | undefined>();
        const { uiStore, leaseStore } = React.useContext(RootStoreContext);

        const closeDocumentSelectModal = () => {
            setDoc(undefined);
            handleModalClose();
            if (fileInput.current) {
                fileInput.current.value = '';
                fileInput.current.files = null;
            }
        };

        const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!e.currentTarget.files) return;
            const file = e.currentTarget.files[0];
            const fileSize = Math.round(file.size / 1024);
            const maxFileSize = 3072;
            if (fileSize > maxFileSize) {
                // files too large
                const errorMsg = 'Invalid file. The file size must not be more than 3 mb';
                console.error(errorMsg);
                uiStore.error(errorMsg);
                return;
            }
            setDoc((prevDoc) => ({ ...prevDoc, file }));
        };

        const handleUploadDocument = async () => {
            // upload doc
            if (!doc || !doc.title || !doc.file) return;

            uiStore.showLoading();
            try {
                console.log(`Uploading Document`);

                const d = await leaseStore.uploadLeaseAgreementDocument(
                    leaseId,
                    doc.title,
                    doc.file,
                    additionalDocument,
                );

                if (!d) {
                    uiStore.error('Failed to upload document');
                    return;
                }

                updateDocument();
            } catch (err) {
                uiStore.error('Invalid file.The file size must not be more than 3 mb');
                console.log(err);
            } finally {
                closeDocumentSelectModal();
                uiStore.hideLoading();
            }
        };

        const handleTextTitle = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setDoc((prevDoc) => ({ ...prevDoc, title: e.target.value }));
        };
        return (
            <>
                <Dialog
                    open={docSelectModalOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={closeDocumentSelectModal}
                    aria-labelledby='alert-dialog-slide-title'
                    aria-describedby='alert-dialog-slide-description'
                >
                    <DialogTitle id='alert-dialog-slide-title'>
                        Upload a document for this lease
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id='docTitle'
                                    value={doc?.title}
                                    onChange={(e) => handleTextTitle(e)}
                                    label='Title'
                                    margin='normal'
                                    variant='outlined'
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <input
                                    accept='*'
                                    id='doc-file'
                                    type='file'
                                    ref={fileInput}
                                    value={fileInput.current?.value}
                                    onChange={(e) => handleFileSelected(e)}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDocumentSelectModal}>Cancel</Button>

                        <Button
                            disabled={!doc?.title || !doc?.file}
                            onClick={handleUploadDocument}
                            color='primary'
                        >
                            Upload
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    },
);
