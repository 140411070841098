import { observer } from 'mobx-react-lite';
import React from 'react';
import { Signup } from '../../components/auth/signup/signup';
import { AuthLayout } from '../../layouts/auth/auth';

export const SignupPage: React.FC = observer(() => {
    return (
        <>
            <AuthLayout>
                <Signup />
            </AuthLayout>
        </>
    );
});
