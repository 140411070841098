import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../global/storeContext';

import { FullWidth, Spacing, StepperButtons } from './styles';
import { Box, Button, Grid, LinearProgress } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { PropertyAddress } from '../../components/createProperty/propertyAddress';
import { PropertyType } from '../../components/createProperty/propertyType';
import { PropertyPhotos } from '../../components/createProperty/propertyPhotos';
import { PropertyTitle } from '../../components/createProperty/propertyTitle';
import { ToastrType } from '../../stores/uiStore';
import { PropertyDescription } from '../../components/createProperty/propertyDescription';
import { paths } from '../../routes';
import { PropertyTypeOfListing } from './propertyTypeOfListing';
import { IsValidBoolean, IsValidNumber, StringIsNullOrWhitespace } from '../../global/util';
import { GoogleMapsApiService } from '../../global/google-maps';
import { throttle } from 'lodash';
import Geohash from 'ngeohash';
import { isValidAddress } from '../../utils/address';
import {
    IdPropertyListing,
    IProperty,
    IListing,
    IAddress,
    GeocoderRequest,
    GeocoderResult,
    IAddressComponent,
    IListingTerm,
} from 'realhaus-sdk';

enum NewPropertySteps {
    TITLE,
    ADDRESS,
    TYPE,
    TYPEOFLISTING,
    DESCRIPTION,
    PHOTOS,
}

const editModeSteps = [
    NewPropertySteps.TITLE,
    NewPropertySteps.ADDRESS,
    NewPropertySteps.TYPE,
    NewPropertySteps.TYPEOFLISTING,
    NewPropertySteps.DESCRIPTION,
    NewPropertySteps.PHOTOS,
];

const newModeSteps = [
    NewPropertySteps.ADDRESS,
    NewPropertySteps.TYPE,
    NewPropertySteps.DESCRIPTION,
    NewPropertySteps.PHOTOS,
    NewPropertySteps.TITLE,
    NewPropertySteps.TYPEOFLISTING,
];
const progressOfIndex = (index: number): number => {
    const totalSteps = newModeSteps.length;
    return ((index + 1) / totalSteps) * 100;
};

interface UpsertPropertyProps {
    listingId?: string;
    propertyId?: string;
    isEditMode?: boolean;
}

export const UpsertProperty: React.FC<UpsertPropertyProps> = observer(
    ({ listingId, propertyId, isEditMode }) => {
        const { listingStore, uiStore, googleMapStore, analyticsStore } =
            useContext(RootStoreContext);
        const [listing, setListing] = React.useState<IdPropertyListing>({} as IdPropertyListing);

        const [propertyPhotos, setPropertyPhotos] = React.useState<File[]>([]);
        const [progress, setProgress] = React.useState(progressOfIndex(0));
        const firstStep = isEditMode ? editModeSteps[0] : newModeSteps[0];
        const lastStep = isEditMode
            ? editModeSteps[editModeSteps.length - 1]
            : newModeSteps[newModeSteps.length - 1];

        const [activeStep, setActiveStep] = React.useState(
            isEditMode || !propertyId ? firstStep : NewPropertySteps.TITLE,
        );

        const [photosToDelete, setPhotosToDelete] = useState<string[]>([]);

        useEffect(() => {
            if (!!listingId) {
                // get listing & property
                listingStore.getListing(listingId).then((listing) => {
                    if (!listing) return;
                    setListing(listing);
                });
            } else if (!!propertyId) {
                // get property
                listingStore.getProperty(propertyId).then((property) => {
                    setListing({ ...property, propertyId } as IdPropertyListing);
                });
            }
            setProgress(progressOfIndex(indexOfActiveStep()));
        }, [listingId, propertyId, listingStore]);

        const indexOfActiveStep = () =>
            isEditMode ? editModeSteps.indexOf(activeStep) : newModeSteps.indexOf(activeStep);

        const getNeighborhood = ({ results }: { results: GeocoderResult[] }) => {
            const addressComponent = results[0].address_components.find(
                (ac: IAddressComponent) => ac.types.indexOf('neighborhood') > -1,
            );
            return addressComponent ? addressComponent.short_name : '';
        };

        const fetchAddressGeocode = useMemo(
            () =>
                throttle(
                    (
                        request: GeocoderRequest,
                        callback?: (results?: GeocoderResult[], status?: any) => void,
                    ) => googleMapStore.apiService?.geocode(request, callback),
                    200,
                ),
            [],
        );

        const handleChange =
            (prop: keyof IProperty) => (event: React.ChangeEvent<{ value: unknown }>) => {
                setListing({ ...listing, [prop]: event.target.value });
            };

        const handleListingChange =
            (prop: keyof IListing) => (event: React.ChangeEvent<{ value: unknown }>) => {
                if (prop === 'isSharedProperty' && event.target.value === false) {
                    setListing({
                        ...listing,
                        [prop]: event.target.value,
                        bedroomForRent: 0,
                    });
                    return;
                }
                setListing({ ...(listing ?? {}), [prop]: event.target.value });
            };

        const handleAddressUpdate = (address: IAddress) => {
            setListing({ ...listing, ['address' as keyof IProperty]: address });
        };

        // get array of deleted photos
        const handleSetPhotoToDelete = (photoId: string) => {
            setPhotosToDelete([...photosToDelete, photoId]);
        };

        const handleUpsertProperty = async () => {
            uiStore.showLoading();
            try {
                const {
                    description,
                    address,
                    bathrooms,
                    bedrooms,
                    propertyType,
                    size,
                    yearBuilt,
                    title,
                    bathroomPrivacy,
                    isSharedProperty,
                    bedroomForRent,
                    photos,
                } = listing;

                if (!listingId) {
                    var propId: string | undefined = listing.propertyId;
                    // create property if new property
                    if (!propId) {
                        propId = await listingStore.createProperty(
                            {
                                address,
                                bathrooms,
                                bedrooms,
                                propertyType,
                                size,
                                yearBuilt,
                                description,
                                photos: [],
                                ownerId: '',
                            },
                            propertyPhotos,
                        );
                        if (!propId) {
                            uiStore.openToastr(
                                ToastrType.ERROR,
                                `Could not create property for listing ${listing.title}! Please try again`,
                            );
                            return;
                        }
                    }

                    const data = {
                        propertyId: propId,
                        title,
                        bathroomPrivacy,
                        isSharedProperty,
                    } as IListing;

                    if (bedroomForRent && bedroomForRent > 0) {
                        data.bedroomForRent = bedroomForRent;
                    }

                    const newListingId = await listingStore.createListing(propId, data);

                    if (!!newListingId) {
                        // create listing term object for new listing
                        const termData = {
                            listingId: newListingId,
                            dateAvailable: 0,
                            rentAmount: 0,
                            depositAmount: 0,
                            duration: 0,
                        } as IListingTerm;

                        const newListingTermId = await listingStore.upsertListingTerm(
                            newListingId,
                            termData,
                        );
                        if (!newListingTermId) {
                            uiStore.openToastr(
                                ToastrType.ERROR,
                                `Could not create listing term for "${listing.title}". Please try again!`,
                            );
                            return;
                        }
                    }

                    if (!newListingId) {
                        uiStore.openToastr(
                            ToastrType.ERROR,
                            `Could not create "${listing.title}". Please try again!`,
                        );
                        return;
                    }
                    uiStore.openToastr(ToastrType.SUCCESS, `${listing.title} has been created!`);
                    analyticsStore.trackCreateListing();
                    uiStore.goTo(
                        paths.landlord.editListing
                            .replace(':listingId', newListingId)
                            .replace(':pageId', 'terms'),
                    );
                } else {
                    let tempPhotos = photos;
                    if (photosToDelete.length > 0) {
                        for (let i = 0; i < photosToDelete.length; i++) {
                            const photo = photosToDelete[i];
                            const toDelete = photos.find((x) => x.id === photo);
                            if (toDelete) {
                                listingStore.deleteFileByUrl(toDelete.url).then(async () => {
                                    await listingStore.deletePropertyPhotos(
                                        listing.propertyId,
                                        toDelete,
                                    );
                                });
                                tempPhotos = tempPhotos.filter((t) => t.id !== photo);
                            }
                        }
                        setListing({ ...listing, photos: tempPhotos });
                    }

                    // update listing
                    await listingStore.updateProperty(
                        listing.propertyId,
                        {
                            description,
                            address,
                            bathrooms,
                            bedrooms,
                            propertyType,
                            size,
                            yearBuilt,
                            photos: tempPhotos?.length === 0 ? photos : tempPhotos ?? [],
                        },
                        propertyPhotos,
                    );

                    const data = {
                        propertyId: listing.propertyId,
                        title,
                        bathroomPrivacy,
                        isSharedProperty,
                        amenities: listing.amenities,
                    } as Partial<IListing>;
                    if (bedroomForRent && bedroomForRent > 0) {
                        data.bedroomForRent = bedroomForRent;
                    }

                    await listingStore.updateListing(listing.listingId, data);
                    uiStore.openToastr(ToastrType.SUCCESS, `${listing.title} has been updated!`);
                }
            } catch (err) {
                console.log(err);
            }
            uiStore.hideLoading();
        };

        const stepComponents: {
            [key: number]: { component: JSX.Element; validate: () => Promise<boolean> };
        } = {
            [NewPropertySteps.TITLE]: {
                component: <PropertyTitle updateListing={handleListingChange} listing={listing} />,
                validate: () => {
                    const isValid = !StringIsNullOrWhitespace(listing.title);

                    if (!isValid) {
                        uiStore.error('Provide a title for your listing');
                    }

                    return Promise.resolve(isValid);
                },
            },
            [NewPropertySteps.ADDRESS]: {
                component: (
                    <PropertyAddress
                        setAddress={handleAddressUpdate}
                        address={listing?.address ?? ({} as IAddress)}
                    />
                ),
                validate: async (): Promise<boolean> => {
                    const { streetAddress, city, province, postalCode, country } = listing.address;
                    const isValid = isValidAddress(listing.address);

                    if (!isValid) {
                        var msg = 'Please provide a valid address for your listing.';
                        if (!!listing.address) {
                            msg += '<br />Specify the address fields: <ul>';
                            msg += StringIsNullOrWhitespace(streetAddress) ? '<li>Street</li>' : '';
                            msg += StringIsNullOrWhitespace(city) ? ' <li>City</li>' : '';
                            msg += StringIsNullOrWhitespace(province) ? ' <li>Province</li>' : '';
                            msg += StringIsNullOrWhitespace(postalCode)
                                ? ' <li>Postal Code</li>'
                                : '';
                            msg += StringIsNullOrWhitespace(country) ? ' <li>Country</li>' : '';
                            msg += '</ul>';
                        }
                        uiStore.error(msg);
                    }

                    if (isValid) {
                        // find geometry
                        var codes = await fetchAddressGeocode({
                            address: `${streetAddress} ${city}, ${province}, ${postalCode}, ${country}`,
                        });

                        if (codes) {
                            if (codes.results?.length === 0) {
                                uiStore.error(
                                    `Uh-oh. We were not able to find that address.<br />Please verify you have specified the right address`,
                                );
                            } else {
                                const location = codes.results[0].geometry.location;
                                const lat = location.lat();
                                const lng = location.lng();
                                const geohash = Geohash.encode(lat, lng);
                                listing.address.geoloc = { lat, lng, geohash };

                                listing.address.neighborhood = getNeighborhood(codes);
                            }
                        }
                    }

                    return isValid;
                },
            },
            [NewPropertySteps.TYPE]: {
                component: <PropertyType updateProperty={handleChange} property={listing} />,
                validate: () => {
                    const { yearBuilt, size, propertyType, bedrooms, bathrooms } = listing;
                    const isValid =
                        IsValidNumber(yearBuilt) &&
                        IsValidNumber(size) &&
                        !StringIsNullOrWhitespace(propertyType) &&
                        IsValidNumber(bedrooms) &&
                        IsValidNumber(bathrooms);

                    if (!isValid) {
                        var msg = 'Please provide all information about this property';
                        msg += '<br />Specify the following fields: <ul>';
                        msg += !IsValidNumber(yearBuilt) ? '<li>Year property was built</li>' : '';
                        msg += !IsValidNumber(size) ? ' <li>Size of your property</li>' : '';
                        msg += StringIsNullOrWhitespace(propertyType)
                            ? ' <li>Property Type</li>'
                            : '';
                        msg += !IsValidNumber(bedrooms)
                            ? ' <li>Total number of bedrooms at the property</li>'
                            : '';
                        msg += IsValidNumber(bathrooms)
                            ? 'Total number of bathrooms at the property'
                            : '';
                        msg += '</ul>';
                        uiStore.error(msg);
                    }
                    return Promise.resolve(isValid);
                },
            },
            [NewPropertySteps.TYPEOFLISTING]: {
                component: (
                    <PropertyTypeOfListing updateListing={handleListingChange} listing={listing} />
                ),
                validate: (): Promise<boolean> => {
                    const { isSharedProperty, bedroomForRent, bathroomPrivacy } = listing;
                    const isValid =
                        IsValidBoolean(isSharedProperty) &&
                        !StringIsNullOrWhitespace(bathroomPrivacy) &&
                        (isSharedProperty === true ? IsValidNumber(bedroomForRent) : true);

                    if (!isValid) {
                        var msg = 'Provide some information about the living space';
                        msg += '<br />Specify the following fields: <ul>';
                        msg += !IsValidBoolean(isSharedProperty)
                            ? ' <li>Is this a shared property?</li>'
                            : '';
                        msg +=
                            isSharedProperty === true && !IsValidNumber(bedroomForRent)
                                ? ' <li>Which bedroom are you renting out</li>'
                                : '';
                        msg += StringIsNullOrWhitespace(bathroomPrivacy)
                            ? ' <li>Will the bathroom be shared?</li>'
                            : '';
                        msg += '</ul>';
                        uiStore.error(msg);
                    }

                    return Promise.resolve(isValid);
                },
            },
            [NewPropertySteps.DESCRIPTION]: {
                component: <PropertyDescription updateProperty={handleChange} property={listing} />,
                validate: (): Promise<boolean> => {
                    const isValid = !StringIsNullOrWhitespace(listing.description);

                    if (!isValid) {
                        uiStore.error('Provide a description of your property');
                    }
                    return Promise.resolve(isValid);
                },
            },
            [NewPropertySteps.PHOTOS]: {
                component: (
                    <PropertyPhotos
                        setPropertyPhotos={setPropertyPhotos}
                        propertyPhotos={propertyPhotos}
                        savedPhotos={listing.photos?.filter(
                            (x) => !photosToDelete.find((p) => x.id === p),
                        )}
                        setPhotoToDelete={handleSetPhotoToDelete}
                    />
                ),
                validate: (): Promise<boolean> => {
                    const isValid = propertyPhotos.length > 0 || listing.photos?.length > 0;

                    if (!isValid) {
                        uiStore.error('Please provide an image for this property');
                    }
                    return Promise.resolve(isValid);
                },
            },
        };

        const handleBack = () => {
            const stepIndex = indexOfActiveStep();
            if (stepIndex === 0) return;
            setActiveStep(isEditMode ? editModeSteps[stepIndex - 1] : newModeSteps[stepIndex - 1]);
        };

        const handleNext = async () => {
            const stepIndex = indexOfActiveStep();
            if (!(await stepComponents[activeStep].validate())) {
                return;
            }

            if (
                (isEditMode && stepIndex === editModeSteps.length - 1) ||
                (!isEditMode && stepIndex === newModeSteps.length - 1)
            ) {
                // End of wizard.
                // All listing fields are valid. Submit listing
                await handleUpsertProperty();
                return;
            }

            const step = isEditMode ? editModeSteps[stepIndex + 1] : newModeSteps[stepIndex + 1];

            setActiveStep(step);
            if (progressOfIndex(stepIndex + 1) > progress) {
                setProgress(progressOfIndex(stepIndex + 1));
            }
        };

        return (
            <>
                <FullWidth>
                    <LinearProgress variant='determinate' value={progress} />
                </FullWidth>
                <Box height='100%'>
                    <Grid>
                        <Grid container spacing={1}>
                            {/* Main */}
                            <Grid item xs={9}>
                                {stepComponents[activeStep].component}
                                <StepperButtons>
                                    <Button
                                        disabled={activeStep === firstStep}
                                        onClick={handleBack}
                                    >
                                        <KeyboardArrowLeft />
                                        Back
                                    </Button>
                                    <Spacing />
                                    {activeStep === lastStep ? (
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={handleNext}
                                        >
                                            {!listingId ? 'Create Listing' : 'Update Listing'}
                                        </Button>
                                    ) : (
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={handleNext}
                                        >
                                            Next <KeyboardArrowRight />
                                        </Button>
                                    )}
                                </StepperButtons>
                            </Grid>
                            {/* Side */}
                            <Grid item xs={3}></Grid>
                        </Grid>
                    </Grid>
                </Box>
            </>
        );
    },
);
