import React from 'react';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { ProspectiveTenants } from './prospective';
import { TenantInvites } from '../../../components/prospectiveTenants/tenantInvites';
import { TabComponent, TabSection } from '../../../components/uiComponents/tabs';

export const ProspectiveTabs: React.FC = observer(() => {
    const sections: TabSection[] = [
        {
            key: 'Applications',
            title: 'Applications',
            component: <ProspectiveTenants />,
        },
        {
            key: 'Invites',
            title: 'Invites',
            component: <TenantInvites />,
        },
    ];

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <TabComponent
                        sections={sections}
                        onSubmit={() => {}}
                        hideStepperButtons={true}
                        disableSave
                    />
                </Grid>
            </Grid>
        </>
    );
});
