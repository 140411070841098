import { DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';

export const ReviewComment: React.FC<{
    revieweeName?: string;
    comments: string;
    setComments: (event: string) => void;
}> = ({ revieweeName, comments, setComments }) => {
    const handleReviewNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComments(event.target.value);
    };
    return (
        <>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Typography align='center'>
                        <DialogTitle>Add a Review</DialogTitle>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography gutterBottom>
                        Write some review on your experience with {revieweeName}. Be sure to
                        highlight what went well and what didn't go well.
                    </Typography>
                    <TextField
                        value={comments}
                        variant='outlined'
                        multiline
                        fullWidth
                        minRows={5}
                        onChange={handleReviewNoteChange}
                    />
                </Grid>
            </Grid>
        </>
    );
};
