import { timeout } from './asyncUtils';

export const loadXml = async (xmlpath: string): Promise<XMLDocument | null> => {
  var xmlDoc: XMLDocument | null = null;
  var loadComplete = false;
  var xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = (ev) => {
    const t = ev.currentTarget as XMLHttpRequest;
    loadComplete = t.readyState === XMLHttpRequest.DONE;
    if (loadComplete && t.status === 200) {
      xmlDoc = t.responseXML;
    }
  };

  xhttp.open("GET", xmlpath, true);
  xhttp.send();

  while (!loadComplete) {
    await timeout(1000);
  }

  return xmlDoc;
}