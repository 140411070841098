import { makeAutoObservable } from 'mobx';
import { ICreditReport, ICreditReportSummary, creditScoreRanges, CreditScoreLevel } from 'realhaus-sdk';
import { Collections } from './dbStore';
import FirebaseStore from './firebaseStore';

export enum CreditScoreRange {
  EXCELLENT = 780,
  GOOD = 650,
  FAIR = 600,
  POOR = 500,
}

export class CreditReportStore {

  constructor(private fireStore: FirebaseStore) {
    makeAutoObservable(this)
  }

  getCreditReport = async (userId: string): Promise<ICreditReport | undefined> => {
    if (!userId) return;

    const ref = await this.fireStore.getDocument(Collections.creditReports, userId);

    if (!ref.exists()) {
      return;
    }

    const crData = await ref.data() as ICreditReport
    return crData;
  }

  getCreditReportSummary = async (userId: string): Promise<ICreditReportSummary | undefined> => {
    const creditReport = await this.getCreditReport(userId);

    if (!creditReport) return;

    const creditScore = creditReport.creditScore;

    const creditScoreRange = creditScoreRanges.find(({ upper, lower }) => creditScore < upper && creditScore > lower)
      || creditScoreRanges.find(({ level }) => CreditScoreLevel.POOR === level)

    const numberOfPublicRecords = creditReport.publicRecords ?
      (creditReport.publicRecords.bankruptcies ? creditReport.publicRecords.bankruptcies.length : 0)
      + (creditReport.publicRecords.collections ? creditReport.publicRecords.collections.length : 0)
      + (creditReport.publicRecords.judgement ? creditReport.publicRecords.judgement.length : 0)
      : 0;

    return {
      creditScore,
      creditScoreRange,
      criminalHistory: creditReport.publicRecords && creditReport.publicRecords.judgement ? creditReport.publicRecords.judgement.length : 0,
      evictions: creditReport.evictions ? creditReport.evictions.length : 0,
      employers: creditReport.personalInformation && creditReport.personalInformation.employment ? creditReport.personalInformation.employment.length : 0,
      collections: creditReport.publicRecords && creditReport.publicRecords.collections ? creditReport.publicRecords.collections.length : 0,
      publicRecords: numberOfPublicRecords,
    } as ICreditReportSummary
  }
}