import {
    Box,
    Grid,
    Theme,
    Typography,
    createStyles,
    useMediaQuery,
    withStyles,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import {
    ILeaseReview,
    LandlordGrades,
    RatingsForLandlord,
    RatingsForTenant,
    TenantGrades,
} from 'realhaus-sdk';
import StarIcon from '@material-ui/icons/Star';
import { LinearProgress, Rating, Stack } from '@mui/material';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';
import AccessibilityNewOutlinedIcon from '@material-ui/icons/AccessibilityNewOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';
import StringUtils from '../../utils/string';
import { RootStoreContext } from '../../global/storeContext';
import { useEffect } from 'react';
import React from 'react';
import { overallAverage } from '../../utils/ratings';
import { CalcAverage } from '../../utils/numbers';

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#1a90ff',
        },
    }),
)(LinearProgress);

export const StarRating: React.FC<{ rating: RatingsForLandlord | RatingsForTenant }> = observer(
    ({ rating }) => {
        const arrRatings = Object.entries(rating);
        const totalValue = arrRatings.reduce((accumulated, current) => {
            return (accumulated += current[1]) as number;
        }, 0);
        const avrValue = totalValue / arrRatings.length;

        return (
            <>
                <Rating value={avrValue} readOnly />
            </>
        );
    },
);

interface RatingsProps {
    ratings: (RatingsForTenant | RatingsForLandlord)[];
}

type TenantLandlordGrade = TenantGrades | LandlordGrades;

const gradeIcon = (grade: TenantLandlordGrade, fontSize: 'small' | 'medium' | 'large') => {
    switch (grade) {
        case TenantGrades.CLEANLINESS:
            return <DeleteOutlinedIcon fontSize={fontSize} />;
        case TenantGrades.COMMUNICATION:
            return <ForumOutlinedIcon fontSize={fontSize} />;
        case TenantGrades.HOUSE_RULES:
            return <GavelOutlinedIcon fontSize={fontSize} />;
        case TenantGrades.RESPECTFULNESS:
            return <AccessibilityNewOutlinedIcon fontSize={fontSize} />;
        case LandlordGrades.ACCURACY:
            return <CheckOutlinedIcon fontSize={fontSize} />;
        case LandlordGrades.DIFFICULTY:
            return <ExtensionOutlinedIcon fontSize={fontSize} />;
    }
};

const OverallRatingPoll: React.FC<RatingsProps> = observer(({ ratings }) => {
    const buckets: number[][] = [[], [], [], [], []];
    const isMobile = useMediaQuery('(max-width:959px)');

    ratings.forEach((r) => {
        const avg = CalcAverage(Object.values(r));
        // add to bucket
        buckets[Math.ceil(avg) - 1].push(avg);
    });

    const elements = [];
    for (let i = 4; i >= 0; i--) {
        const value = (CalcAverage(buckets[i]) / 5) * 100;
        elements.push(<BorderLinearProgress variant='determinate' value={value} />);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='subtitle1'>
                    <b>Overall Rating</b>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {elements.map((element, i) => {
                    return (
                        <Grid container>
                            <Grid item xs={2}>
                                <Typography align={isMobile ? 'center' : 'left'}>
                                    {5 - i}
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                {element}
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
});

const OverallRatingGrades: React.FC<RatingsProps> = observer(({ ratings }) => {
    const buckets: Map<TenantGrades | LandlordGrades, number[]> = new Map<
        TenantGrades | LandlordGrades,
        number[]
    >();

    ratings.forEach((r) => {
        Object.entries(r).forEach((e) => {
            const grade = e[0] as TenantLandlordGrade;
            const curr = buckets.get(grade) ?? [];
            buckets.set(grade, [...curr, e[1]]);
        });
    });

    return (
        <Grid container spacing={8}>
            {Array.from(buckets.entries())
                .sort((a, b) => a[0].localeCompare(b[0]))
                .map((entry, index) => {
                    return (
                        <Grid item xs={6} sm={3} key={index}>
                            <Box>
                                <Grid
                                    container
                                    alignContent='center'
                                    alignItems='center'
                                    spacing={2}
                                >
                                    <Grid item xs={12}>
                                        <Typography
                                            variant='subtitle1'
                                            align='center'
                                            style={{ whiteSpace: 'nowrap' }}
                                        >
                                            <b>{StringUtils.enumToDisplayString(entry[0])}</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='h5' align='center'>
                                            {CalcAverage(entry[1])}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        alignContent='center'
                                        alignItems='center'
                                        justifyContent='center'
                                    >
                                        <Typography align='center'>
                                            {gradeIcon(entry[0], 'large')}
                                        </Typography>
                                    </Grid>

                                    <br />
                                </Grid>
                            </Box>
                        </Grid>
                    );
                })}
        </Grid>
    );
});

export const AggRatingsCard: React.FC<RatingsProps> = observer(({ ratings }) => {
    const isMobile = useMediaQuery('(max-width:959px)');

    return (
        <>
            <Grid container spacing={3}>
                {/* Overall Average */}
                <Grid item xs={12}>
                    <Stack direction='row' alignItems='center' gap={1}>
                        <StarIcon />
                        <Typography variant='h5'>{overallAverage(ratings).toFixed(2)}</Typography>
                    </Stack>
                </Grid>
                {/* Poll, Overall Average for Grades */}
                <Grid item xs={12}>
                    <Grid container spacing={5}>
                        <Grid
                            item
                            xs={12}
                            md={3}
                            style={isMobile ? {} : { borderRight: '2px solid #e0e0e0' }}
                        >
                            <OverallRatingPoll ratings={ratings} />
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <OverallRatingGrades ratings={ratings} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
});

interface ReviewCardProps {
    title?: string;
    leaseReview: ILeaseReview;
}

export const ReviewCard: React.FC<ReviewCardProps> = observer(({ title, leaseReview }) => {
    return (
        <>
            {!!leaseReview ? (
                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12}>
                        <Typography gutterBottom variant='body1'>
                            <b>{title ?? StringUtils.getMonthAndYear(leaseReview.timeStamp)}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {!!leaseReview.ratings ? <StarRating rating={leaseReview.ratings} /> : null}
                    </Grid>

                    <Grid item xs={12}>
                        <Typography>{leaseReview.review ?? ''}</Typography>
                    </Grid>
                </Grid>
            ) : null}
        </>
    );
});

export const ReviewersName: React.FC<{ userId: string; leaseAgreementId?: string }> = observer(
    ({ userId, leaseAgreementId }) => {
        const { userStore, leaseStore } = React.useContext(RootStoreContext);
        const [firstName, setFirstName] = React.useState<string>('');
        const [lastName, setLastName] = React.useState<string>('');
        const [location, setLocation] = React.useState<string>('');

        const isRentApplicationReviews = !!leaseAgreementId;

        useEffect(() => {
            const init = async () => {
                await userStore.getUserProfile(userId).then((profile) => {
                    if (!!profile) {
                        setFirstName(profile.firstname);
                        setLastName(profile.lastname);
                    }
                });

                if (!!leaseAgreementId) {
                    await leaseStore
                        .getLeaseAgreementById(leaseAgreementId)
                        .then((leaseAgreement) => {
                            if (!!leaseAgreement) {
                                setLocation(
                                    `${leaseAgreement.listingInfo.address.city}, ${leaseAgreement.listingInfo.address.province}`,
                                );
                            }
                        });
                }
            };

            init();
        });

        return (
            <>
                {isRentApplicationReviews ? (
                    <>
                        <Typography variant='h6'>{firstName}</Typography>
                        <Typography variant='subtitle1'>{location}</Typography>
                    </>
                ) : (
                    <Typography variant='h6' gutterBottom>
                        {`Review from ${firstName} ${lastName}`}
                    </Typography>
                )}
            </>
        );
    },
);
