import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';


export const getRootStyles = makeStyles((theme: Theme): StyleRules<string, object> => createStyles({
  timeslotCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  cardActions: {
    padding: theme.spacing(1, 3),
  },
  cardFooter: {
    width: '100%',
  },
  alignCenter: {
    textAlign: 'center',
    alignContent: 'center'
  },
  timeslotSelected: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText
  }
}));