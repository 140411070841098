export const Collections = {
  users: 'users',
  userGroups: 'userGroups',
  properties: 'properties',
  listings: 'listings',
  listingTerms: 'listingTerms',
  leaseAgreements: 'leaseAgreements',
  showings: 'showings',
  appointments: 'appointments',
  tenantProfiles: 'tenantProfiles',
  leaseInvites: 'leaseInvites',
  rentApplications: 'rentApplications',
  conversations: 'conversations',
  messages: 'messages',
  creditReports: 'creditReports',
  bookmarks: 'bookmarks',
  ratingsAndReviews: 'ratingsAndReviews',
  bankAccounts: 'bankAccounts',
  idVerifications: 'idVerifications',
  wallets: 'wallets',
  prospectiveTenants: 'prospectiveTenants',
  invites: 'invites',
  leaseAgreementTenantInvites: 'tenantInvites',
  leaseAgreementDocuments: 'documents',
  leaseAgreementRequestDocuments: 'requests',
  reviews: 'reviews',
  backgroundCheckConsent: 'backgroundCheckConsent',
  bills: 'bills'
}

type ConstraintOp = '<' | '<=' | '==' | '!=' | '>=' | '>' | 'in' | 'array-contains';
export interface QueryOperation {
  type: 'where' | 'limit' | 'orderBy' | 'startAfter';
}
export class WhereQueryOperation implements QueryOperation {
  readonly type = 'where';
  constructor(public field: string, public op: ConstraintOp, public value: any) { }
}

export class StartAfterQueryOperation implements QueryOperation {
  readonly type = 'startAfter';
  constructor(public value: any) { }
}

export class LimitQueryOperation implements QueryOperation {
  readonly type = 'limit';
  constructor(public number: number) { }
}

export class OrderByQueryOperation implements QueryOperation {
  readonly type = 'orderBy';
  constructor(public field: string, public direction: 'desc' | 'asc') { }
}

export abstract class DBStore {
}