import { PaypalClientId, PaypalClientSecrete, PaypalTokenEndpoint, PaypalUserProfileEndpoint } from '../appConfig';


export const getAccessCode = async (code: string) => {
  const clientId = PaypalClientId;
  const clientSecrete = PaypalClientSecrete;
  const b64Basic = btoa(
    unescape(
      encodeURIComponent(clientId + ':' + clientSecrete),
    ),
  );
  const response = await fetch(PaypalTokenEndpoint ?? '', {
    method: 'POST',
    headers: {
      Authorization: `Basic ${b64Basic}`,
    },
    body: `grant_type=authorization_code&code=${code}`,
  });
  return response.json();
};

export const getPaypalUserProfile = async (token: string) => {
  const response = await fetch(PaypalUserProfileEndpoint ?? '', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return response.json();
};
