import React from 'react';
import { observer } from 'mobx-react-lite';
import { Title } from './styles';
import { IListing, IProperty } from 'realhaus-sdk';
import {
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@material-ui/core';

interface PropertyTypeOfListingProps {
    listing: IProperty & IListing;
    updateListing: (prop: keyof IListing) => (event: React.ChangeEvent<{ value: unknown }>) => void;
}

export const PropertyTypeOfListing: React.FC<PropertyTypeOfListingProps> = observer(
    ({ listing, updateListing }) => {
        const handleChange =
            (prop: keyof IListing) => (event: React.ChangeEvent<{ value: unknown }>) => {
                updateListing(prop)(event);
            };

        const bedRooms = () => {
            const rooms = [];
            for (var i = 1; i <= listing.bedrooms; i++) {
                const bdType = i === 1 ? 'Main Bedroom' : 'Standard Bedroom';
                rooms.push({ key: `${i}`, title: `Bedroom ${i} - ${bdType}` });
            }

            return rooms;
        };
        return (
            <>
                <Title>
                    <Typography variant='h5' gutterBottom>
                        Are you listing a shared space in a property?
                    </Typography>
                </Title>

                <form noValidate autoComplete='off'>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant='body1'>Is this a shared property?</Typography>
                            <FormControl component='fieldset'>
                                <RadioGroup
                                    row
                                    aria-label='position'
                                    name='isSharedProperty'
                                    value={`${listing.isSharedProperty}`}
                                    onChange={(_e, v) => {
                                        handleChange('isSharedProperty')({
                                            target: { value: /true/i.test(v) },
                                        } as React.ChangeEvent<{ value: boolean }>);
                                    }}
                                >
                                    <FormControlLabel
                                        value={'true'}
                                        control={<Radio color='primary' />}
                                        label='YES'
                                    />
                                    <FormControlLabel
                                        value={'false'}
                                        control={<Radio color='primary' />}
                                        label='NO'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel htmlFor='bedroomForRent'>
                                Which of the Bedrooms are you renting out?
                            </InputLabel>
                            <TextField
                                select
                                margin='normal'
                                id='bedroomForRent'
                                fullWidth
                                required
                                variant='outlined'
                                value={`${listing.bedroomForRent}`}
                                onChange={(v) => {
                                    handleChange('bedroomForRent')({
                                        target: { value: Number(v.target.value) },
                                    } as React.ChangeEvent<{ value: number }>);
                                }}
                                disabled={!listing.isSharedProperty}
                            >
                                {bedRooms().map((rm) => (
                                    <MenuItem key={rm.key} value={rm.key}>
                                        {rm.title}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel htmlFor='bathroom-shared'>Bathroom Privacy</InputLabel>
                            <TextField
                                select
                                margin='normal'
                                id='bathroom-shared'
                                fullWidth
                                required
                                variant='outlined'
                                value={listing.bathroomPrivacy}
                                onChange={handleChange('bathroomPrivacy')}
                            >
                                <MenuItem value={'private'}>Private Bathroom</MenuItem>
                                <MenuItem value={'shared'}>Shared Bathroom</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}></Grid>
                    </Grid>
                </form>
            </>
        );
    },
);
