import React from 'react';
import { observer } from 'mobx-react-lite';
import { ILeaseAgreement, ILeaseFees } from 'realhaus-sdk';
import { Box, Grid, Typography } from '@material-ui/core';
import { InputFieldMoney } from '../formComponents/inputfieldmoney';

export const LeaseRentDepositFeesComponent: React.FC<{
    leaseAgreement: ILeaseAgreement;
    setLeaseAgreement: (
        field: keyof ILeaseAgreement,
    ) => (value: Date | string | number | ILeaseFees | null) => void;
    readOnly?: boolean;
}> = observer(({ leaseAgreement, setLeaseAgreement, readOnly }) => {
    return !readOnly ? (
        <LeaseRentDepositFeesEditComponent
            leaseAgreement={leaseAgreement}
            setLeaseAgreement={setLeaseAgreement}
        />
    ) : (
        <LeaseRentDepositFeesReadComponent leaseAgreement={leaseAgreement} />
    );
});

const LeaseRentDepositFeesEditComponent: React.FC<{
    leaseAgreement: ILeaseAgreement;
    setLeaseAgreement: (
        field: keyof ILeaseAgreement,
    ) => (value: Date | string | number | ILeaseFees | null) => void;
}> = observer(({ leaseAgreement, setLeaseAgreement }) => {
    const handleFees = (prop: keyof ILeaseFees) => (value: string | number | null) => {
        setLeaseAgreement('fees')({
            ...leaseAgreement.fees,
            [prop]: value?.toString(),
        });
    };

    return (
        <>
            <Typography variant='h6'>Rent, Deposits &amp; Fees</Typography>
            <Typography variant='body1' gutterBottom>
                The information below has been automatically filled.<b>Double check</b> to make sure
                they are accurate
            </Typography>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Typography variant='subtitle1' gutterBottom>
                        Rent
                    </Typography>
                    <InputFieldMoney
                        fullWidth={false}
                        id='rentAmount'
                        value={leaseAgreement?.rentAmount}
                        onChange={setLeaseAgreement('rentAmount')}
                        placeholder={''}
                    ></InputFieldMoney>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='subtitle1' gutterBottom>
                        Security Deposit
                    </Typography>
                    <InputFieldMoney
                        id='securityDepositAmount'
                        value={leaseAgreement?.securityDepositAmount}
                        onChange={setLeaseAgreement('securityDepositAmount')}
                        placeholder={''}
                        fullWidth={false}
                    ></InputFieldMoney>
                </Grid>
            </Grid>

            <Box mt={2} mb={2}>
                <Typography variant='subtitle1'>
                    <b>Fees</b>
                </Typography>
            </Box>

            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Typography variant='subtitle1' gutterBottom>
                        Late Rent Fees
                    </Typography>
                    <InputFieldMoney
                        id='lateRentFee'
                        value={leaseAgreement?.fees?.lateRentFee}
                        onChange={handleFees('lateRentFee')}
                        placeholder={''}
                        fullWidth={false}
                    ></InputFieldMoney>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='subtitle1' gutterBottom>
                        Parking Fees
                    </Typography>
                    <InputFieldMoney
                        id='parkingFee'
                        value={leaseAgreement?.fees?.parkingFee}
                        onChange={handleFees('parkingFee')}
                        placeholder={''}
                        fullWidth={false}
                    ></InputFieldMoney>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='subtitle1' gutterBottom>
                        Move-in Fees
                    </Typography>
                    <InputFieldMoney
                        id='moveInFees'
                        value={leaseAgreement?.fees?.moveInFee}
                        onChange={handleFees('moveInFee')}
                        placeholder={''}
                        fullWidth={false}
                    ></InputFieldMoney>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='subtitle1' gutterBottom>
                        Move-out Fees
                    </Typography>
                    <InputFieldMoney
                        id='moveOutFees'
                        value={leaseAgreement?.fees?.moveOutFee}
                        onChange={handleFees('moveOutFee')}
                        placeholder={''}
                        fullWidth={false}
                    ></InputFieldMoney>
                </Grid>
            </Grid>
        </>
    );
});

const LeaseRentDepositFeesReadComponent: React.FC<{
    leaseAgreement: ILeaseAgreement;
}> = observer(({ leaseAgreement }) => {
    return (
        <>
            <Typography variant='h6'>Rent, Deposits &amp; Fees</Typography>
            <Grid style={{ marginTop: 2 }} container>
                <Grid item xs={6}>
                    <Typography variant='subtitle1' gutterBottom>
                        <b>Rent</b>
                    </Typography>
                    <Typography>$CAD {leaseAgreement?.rentAmount}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='subtitle1' gutterBottom>
                        <b>Security Deposit</b>
                    </Typography>
                    <Typography>$CAD {leaseAgreement?.securityDepositAmount}</Typography>
                </Grid>
            </Grid>

            <Box mt={2} mb={2}>
                <Typography variant='subtitle1'>
                    <b>Fees</b>
                </Typography>

                <Grid container>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography gutterBottom>Late Rent Fees:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    {leaseAgreement?.fees.lateRentFee
                                        ? `$CAD ${leaseAgreement?.fees.lateRentFee}`
                                        : 'N/A'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography gutterBottom>Parking Fees:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    {leaseAgreement?.fees.parkingFee
                                        ? `$CAD ${leaseAgreement?.fees.parkingFee}`
                                        : 'N/A'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography gutterBottom>Move-in Fees:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    {leaseAgreement?.fees.moveInFee
                                        ? `$CAD ${leaseAgreement?.fees.moveInFee}`
                                        : 'N/A'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography gutterBottom>Move-out Fees:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    {leaseAgreement?.fees.moveOutFee
                                        ? `$CAD ${leaseAgreement?.fees.moveOutFee}`
                                        : 'N/A'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
});
