import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { observer } from 'mobx-react-lite';

const CheckBoxFormGroup: React.FC<{
    children: any;
}> = observer(({ children }) => {
    return <FormGroup row>{children}</FormGroup>;
});

export const CheckboxItem: React.FC<{
    id: string;
    value: boolean;
    label: string;
    onChange?: (value: any) => void;
    readOnly?: boolean;
}> = observer(({ id, label, value, onChange, readOnly }) => {
    return (
        <FormControlLabel
            control={<Checkbox color='primary' checked={value} onChange={onChange} name={id} inputProps={{ readOnly }} disabled={readOnly} />}
            label={label}
        />
    );
});

export default CheckBoxFormGroup;
