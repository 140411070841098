import React from 'react';
import { observer } from 'mobx-react-lite';
import { ILeaseAgreement, ILeaseClause } from 'realhaus-sdk';
import { LibraryComponent } from '../uiComponents/library';

export const LeaseClausesComponent: React.FC<{
    leaseAgreement: ILeaseAgreement;
    updateProperty: (field: keyof ILeaseAgreement) => (value: ILeaseClause[] | []) => void;
    readOnly?: boolean;
}> = observer(({ leaseAgreement, updateProperty, readOnly }) => {
    const handleUpdateProperty = (values: ILeaseClause[] | []) => {
        updateProperty('clauses')(values);
    };
    return (
        <>
            <LibraryComponent
                name='Clauses'
                singular='Clause'
                description='This information has been automatically filled. Double check to make sure they are accurate.'
                items={leaseAgreement.clauses}
                updateProperty={handleUpdateProperty}
                readOnly={readOnly}
            />
        </>
    );
});
