import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { IAddress, ILeaseAgreement, LeaseEndAction, RentDueDay } from 'realhaus-sdk';
import { DatePicker } from '../datePicker/datepicker';
import { streetAddress } from '../../utils/address';
import RadioButtonsGroup, { RadioItem } from '../formComponents/radioField';
import { RootStoreContext } from '../../global/storeContext';
import { format } from 'date-fns';

export const LeaseTermsComponent: React.FC<{
    readOnly: boolean;
    leaseAgreement: ILeaseAgreement;
    setLeaseAgreement: (
        field: keyof ILeaseAgreement,
    ) => (value: Date | string | number | null) => void;
}> = observer(({ readOnly, leaseAgreement, setLeaseAgreement }) => {
    return !readOnly ? (
        <LeaseTermsEditComponent
            leaseAgreement={leaseAgreement}
            setLeaseAgreement={setLeaseAgreement}
        />
    ) : (
        <LeaseTermsReadComponent leaseAgreement={leaseAgreement} />
    );
});

export const LeaseTermsEditComponent: React.FC<{
    leaseAgreement: ILeaseAgreement;
    setLeaseAgreement: (
        field: keyof ILeaseAgreement,
    ) => (value: Date | string | number | null) => void;
}> = observer(({ leaseAgreement, setLeaseAgreement }) => {
    const isSelect =
        leaseAgreement.dueRentDay !== RentDueDay.FIRST_OF_MONTH &&
        leaseAgreement.dueRentDay !== RentDueDay.END_OF_MONTH;

    const { uiStore } = useContext(RootStoreContext);
    const [rentDueDayType, setRentDueDayType] = React.useState<string>(
        isSelect ? 'SELECT' : leaseAgreement.dueRentDay.toString(),
    );

    const getCityProv = (address: IAddress | undefined) => {
        if (!address) return null;
        return `${address.city} , ${address.province}`;
    };

    const handleDueRentDay = (value: string) => {
        if (/\d/.test(value)) {
            setLeaseAgreement('dueRentDay')(Number(value));
            return;
        }
        setLeaseAgreement('dueRentDay')(value);
        setRentDueDayType(value);
    };

    const handleSelectDay = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleDueRentDay(e.target.value);
    };

    const leaseEndActionValues = [
        {
            label: 'Renew on a month-to-month basis automatically',
            value: LeaseEndAction.RENEW_MONTHLY,
        },
        {
            label: 'Terminate automatically on the end date above',
            value: LeaseEndAction.TERMINATE,
        },
    ];

    const rentDueValues = [
        {
            label: 'First of month',
            value: RentDueDay.FIRST_OF_MONTH,
        },
        {
            label: 'End of month',
            value: RentDueDay.END_OF_MONTH,
        },
        {
            label: 'Select Day',
            value: 'SELECT',
        },
    ];

    const menuDates = [];
    for (let i = 2; i < 29; i++) {
        menuDates.push(
            <MenuItem key={i} value={i}>
                {i}
            </MenuItem>,
        );
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item>
                    <Typography variant='h6'>Lease Term</Typography>
                    <Typography variant='body1' gutterBottom>
                        The information below has been automatically filled.<b>Double check</b> to
                        make sure they are accurate
                    </Typography>
                </Grid>
                <Grid item>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <HomeIcon />
                        <Box ml={1} component='span' mb={0}>
                            <b>Property Address</b>
                        </Box>
                    </Box>
                    <Box component='p' mt={1} fontSize={18}>
                        {streetAddress(leaseAgreement.listingInfo.address)}
                        <br />
                        {getCityProv(leaseAgreement.listingInfo.address)}
                    </Box>
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item md={6}>
                        <Box component='span' fontWeight='fontWeightRegular' fontSize={12} mb={0}>
                            <b>START DATE</b>
                        </Box>
                        <DatePicker
                            id='moveinDate'
                            selectedDate={
                                leaseAgreement.moveinDate
                                    ? new Date(leaseAgreement.moveinDate)
                                    : null
                            }
                            onChange={(d) => {
                                if (!d) {
                                    uiStore.error('Invalid Movein Date');
                                    throw new Error('Invalid Movein Date');
                                }
                                setLeaseAgreement('moveinDate')(d.getTime());
                            }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Box component='span' fontWeight='fontWeightRegular' fontSize={12} mb={0}>
                            <b>END DATE</b>
                        </Box>
                        <DatePicker
                            id='moveoutDate'
                            selectedDate={
                                leaseAgreement.moveoutDate
                                    ? new Date(leaseAgreement.moveoutDate)
                                    : null
                            }
                            onChange={(d) => {
                                if (!d) {
                                    uiStore.error('Invalid Moveout Date');
                                    throw new Error('Invalid Moveout Date');
                                }
                                setLeaseAgreement('moveoutDate')(d.getTime());
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid item container spacing={1}>
                    <Grid item xs={12}>
                        <b>Rent due day</b>
                    </Grid>

                    <Grid item xs={12}>
                        <RadioButtonsGroup
                            onChange={handleDueRentDay}
                            id='dueRentDay'
                            value={rentDueDayType}
                        >
                            {rentDueValues.map((val, i) => (
                                <RadioItem value={val} key={i} id='dueRentDay' />
                            ))}
                        </RadioButtonsGroup>
                    </Grid>
                    <Grid item>
                        {isSelect && (
                            <TextField
                                select
                                margin='normal'
                                id='dueRentDay'
                                required
                                variant='outlined'
                                value={leaseAgreement.dueRentDay}
                                onChange={handleSelectDay}
                            >
                                {menuDates}
                            </TextField>
                        )}
                    </Grid>
                </Grid>

                <Grid item>
                    <RadioButtonsGroup
                        onChange={setLeaseAgreement('leaseEndAction')}
                        id='leaseEndAction'
                        value={leaseAgreement.leaseEndAction}
                        label='When the lease ends on the date above, which of these should occur?'
                    >
                        {leaseEndActionValues.map((value, i) => (
                            <RadioItem value={value} key={i} id='leaseEndAction' />
                        ))}
                    </RadioButtonsGroup>
                </Grid>
            </Grid>
        </>
    );
});

const LeaseTermsReadComponent: React.FC<{
    leaseAgreement: ILeaseAgreement;
}> = observer(({ leaseAgreement }) => {
    const getCityProv = (address: IAddress | undefined) => {
        if (!address) return null;
        return `${address.city}, ${address.province}`;
    };
    const leaseEndActionValues = [
        {
            label: 'Renew on a month-to-month basis automatically',
            value: LeaseEndAction.RENEW_MONTHLY,
        },
        {
            label: 'Terminate automatically on the end date above',
            value: LeaseEndAction.TERMINATE,
        },
    ];
    const leaseEndActionValue = leaseEndActionValues.find(
        ({ value }) => `${value}` === leaseAgreement.leaseEndAction,
    );

    const RentDueText = () => {
        switch (leaseAgreement.dueRentDay) {
            case RentDueDay.FIRST_OF_MONTH:
                return 'First of month';
            case RentDueDay.END_OF_MONTH:
                return 'End of month';
            default:
                return `${leaseAgreement.dueRentDay} of month`;
        }
    };

    return (
        <>
            <Typography variant='h6'>Lease Term</Typography>
            <Box mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <HomeIcon />
                <Box ml={1} component='span' mb={0}>
                    <b>Property Address</b>
                </Box>
            </Box>
            <Box component='p' mt={1} mb={4} fontSize={14}>
                {streetAddress(leaseAgreement.listingInfo.address)}
                <br />
                {getCityProv(leaseAgreement.listingInfo.address)}
            </Box>
            <Grid container spacing={3}>
                <Grid item md={6}>
                    <Box component='span' fontWeight='fontWeightRegular' fontSize={12} mb={0}>
                        <b>START DATE</b>
                    </Box>
                    <Typography>{format(leaseAgreement.moveinDate, 'MMMM dd, yyyy')}</Typography>
                </Grid>
                <Grid item md={6}>
                    <Box component='span' fontWeight='fontWeightRegular' fontSize={12} mb={0}>
                        <b>END DATE</b>
                    </Box>
                    <Typography>{format(leaseAgreement.moveoutDate, 'MMMM dd, yyyy')}</Typography>
                </Grid>
            </Grid>

            <Box mt={6}>
                <Box ml={1} component='span' mb={0} display='block'>
                    <b>Rent due day</b>
                </Box>
                <Typography>
                    <b>{RentDueText()}</b>
                </Typography>
            </Box>

            <Box mt={4}>
                <Box component='span' fontWeight='fontWeightRegular' fontSize={12} mb={0}>
                    When the lease ends on the date above, the lease will:
                </Box>
                <Typography>
                    <b>{leaseEndActionValue && leaseEndActionValue.label}</b>
                </Typography>
            </Box>
        </>
    );
});
