import React from 'react';
import { observer } from 'mobx-react-lite';
import MuiPhoneNumber from 'material-ui-phone-number';
import { InputVariant } from '../../enums/InputVariant';

export const InputFieldPhone: React.FC<{
    id: string;
    value?: string;
    fullWidth: boolean;
    variant?: InputVariant;
    onChange: (value: any) => void;
    disable?: boolean;
}> = observer(({ id, value, fullWidth, onChange, disable, variant }) => {
    return (
        <MuiPhoneNumber
            fullWidth={fullWidth}
            error={validatePhoneNumber(value)}
            required
            id={id}
            margin='normal'
            variant={variant ?? 'outlined'}
            value={value ?? ''}
            onChange={onChange}
            data-cy='user-phone'
            defaultCountry={'ca'}
            onlyCountries={['ca']}
            disabled={disable}
            helperText='Please input a valid number'
        />
    );
});

export const validatePhoneNumber = (phoneNumber: string | undefined) => {
    if (!!phoneNumber) {
        var pattern = /^\+1 \(\d{3}\) \d{3}-\d{4}$/; // Matches a 10-digit number

        return !pattern.test(phoneNumber);
    }
    return true;
};
