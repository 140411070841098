import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStyles } from './styles';
import {
    FormControl,
    Grid,
    Typography,
    InputLabel,
    MenuItem,
    Select,
    Button,
} from '@material-ui/core';
import { SelectableShowingTimeslotCard } from '../../components/showings/showingTimeslotCards';
import { format } from 'date-fns';
import { RootStoreContext } from '../../global/storeContext';
import { IdShowingTimeslot } from 'realhaus-sdk';

interface SelectShowingTimeslotProps {
    listingId: string;
    propertyId: string;
    onShowingScheduled: (timeslot: IdShowingTimeslot) => void;
}

export const SelectShowingTimeslot: React.FC<SelectShowingTimeslotProps> = observer(
    ({ listingId, propertyId, onShowingScheduled }) => {
        const { listingStore, showingStore, uiStore } = useContext(RootStoreContext);
        const [timeslots, setTimeslots] = React.useState<Map<number, IdShowingTimeslot[]>>(
            new Map<number, IdShowingTimeslot[]>(),
        );

        const [timeslotDays, setTimeslotDays] = React.useState<number[]>([]);
        const [showingDay, setShowingDay] = React.useState<number | string>('');
        const [showingDayTimeslots, setShowingDayTimeslots] = React.useState<IdShowingTimeslot[]>(
            [],
        );
        const [selectedTimeslot, setSelectedTimeslot] = React.useState<IdShowingTimeslot>();

        const classes = useStyles();

        useEffect(() => {
            uiStore.showLoading();

            // get listing
            listingStore
                .getPropertyShowingTimeslots(propertyId)
                .then((slots) => {
                    const sortedSlots = slots.filter((s) => !s.reservedBy); // sortTimeslots(slots);

                    // set timeslot days
                    const grouped = new Map<number, IdShowingTimeslot[]>();
                    sortedSlots.forEach((value) => {
                        const dt = new Date(value.timestamp);
                        dt.setHours(0);
                        dt.setMinutes(0);
                        dt.setSeconds(0);

                        const dayTS = dt.getTime();
                        const data = grouped.get(dayTS) || [];
                        data.push(value);
                        grouped.set(dayTS, data);
                    });

                    // set timeslots
                    setTimeslots(grouped);
                    setTimeslotDays(Array.from(grouped.keys()));

                    if (grouped.size > 0) {
                        const firstKey = Array.from(grouped.keys())[0];
                        setShowingDay(firstKey);
                        setShowingDayTimeslots(grouped.get(firstKey) ?? []);
                    }
                })
                .finally(() => {
                    uiStore.hideLoading();
                });
        }, [propertyId, listingStore]);

        // const sortTimeslots = (slots: IdShowingTimeslot[] | undefined) =>
        //     slots?.sort((a, b) => a.timestamp - b.timestamp) ?? [];

        const handleShowingDayChange = (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: unknown;
            }>,
        ) => {
            setDay(event.target.value as number);
        };

        const setDay = (day: number) => {
            setShowingDay(day);
            setShowingDayTimeslots(timeslots?.get(day) ?? []);
        };

        const handleTimeslotSelected = (timeslot: IdShowingTimeslot) => () => {
            setSelectedTimeslot(timeslot);
        };

        const handleScheduleShowing = () => {
            if (!selectedTimeslot || !!selectedTimeslot.reservedBy) {
                return;
            }

            showingStore
                .reserveTimeslot(listingId, propertyId, selectedTimeslot.id)
                .then((appt) => {
                    if (!!appt) {
                        onShowingScheduled(selectedTimeslot);
                    }
                });
        };

        return (
            <>
                <Grid container spacing={1} style={{ marginBottom: 15 }}>
                    <Grid container justifyContent='space-between' className={classes.titleBar}>
                        <Grid item style={{ alignSelf: 'center' }}>
                            <Typography variant='subtitle1' gutterBottom>
                                Available Timeslots
                            </Typography>
                            {/* <Typography variant='h6' gutterBottom>
                            Available Timeslots
                        </Typography> */}
                        </Grid>
                        <Grid item>
                            <FormControl variant='outlined' style={{ minWidth: 180 }}>
                                <InputLabel id='demo-simple-select-outlined-label'>
                                    Showing Date
                                </InputLabel>
                                <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    value={showingDay}
                                    onChange={handleShowingDayChange}
                                    label='Select a date'
                                >
                                    {timeslotDays.map((day) => (
                                        <MenuItem key={day} value={day}>
                                            {format(day, 'PP')}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid>
                        <br />
                    </Grid>
                    <Grid container spacing={1} style={{ minHeight: 350, maxHeight: 350 }}>
                        {showingDayTimeslots.map((ts) => (
                            <Grid item xs={3} key={ts.id}>
                                <SelectableShowingTimeslotCard
                                    timeslot={ts}
                                    selected={selectedTimeslot?.id === ts.id}
                                    onClicked={handleTimeslotSelected}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container direction='row' justifyContent='center' alignItems='center'>
                        {/* <Grid item xs> */}
                        <Typography variant='button' display='block' gutterBottom>
                            {/* button text */}
                            {!!selectedTimeslot
                                ? format(selectedTimeslot?.timestamp, 'PPp')
                                : 'Select a timeslot'}
                        </Typography>
                        {/* </Grid> */}
                    </Grid>
                    <Grid container direction='row' justifyContent='center' alignItems='center'>
                        <Button variant='outlined' color='primary' onClick={handleScheduleShowing}>
                            Schedule Showing
                        </Button>
                    </Grid>
                </Grid>
            </>
        );
    },
);
