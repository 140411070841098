import './global/styles.css';

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import theme from './theme';

import { RootStoreContext } from './global/storeContext';
import { rootStore } from './stores/rootStore';
import Gatekeeper from './components/gatekeeper/gatekeeper';
import { LaunchDarklySDKClientID } from './appConfig';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

const container = document.getElementById('root');
const root = createRoot(container as Element);

(async () => {
    const LDProvider = await asyncWithLDProvider({
        clientSideID: LaunchDarklySDKClientID!,
    });

    root.render(
        // <React.StrictMode>
        <RootStoreContext.Provider value={rootStore}>
            <Gatekeeper
                initialize={async () => {
                    while (!rootStore.userStore.isAuthReady) {
                        await new Promise((resolve) => setTimeout(resolve, 2000));
                    }
                    return Promise.resolve();
                }}
            >
                <ThemeProvider theme={theme}>
                    <BrowserRouter basename=''>
                        <LDProvider>
                            <App />
                        </LDProvider>
                    </BrowserRouter>
                </ThemeProvider>
            </Gatekeeper>
        </RootStoreContext.Provider>,
        // </React.StrictMode>,
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
})();
