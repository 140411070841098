import React from 'react';
import { observer } from 'mobx-react-lite';
import { Typography, Box, Grid } from '@material-ui/core';
import RadioButtonsGroup, { RadioItem } from '../formComponents/radioField';
import { InputFieldMoney } from '../formComponents/inputfieldmoney';
import {
    ILeaseAgreement,
    ILeasePolicy,
    ILeaseFees,
    FeePaymentFrequency,
    FeePaymentFrequencies,
} from 'realhaus-sdk';
import { SelectField } from '../formComponents/selectfield';
import { CheckboxItem } from '../formComponents/checkboxfield';

const petPoliciesValues = [
    {
        label: 'Pets are allowed',
        value: true,
    },
    {
        label: 'Pets are not allowed',
        value: false,
    },
];

const smokingPoliciesValues = [
    {
        label: 'Smoking is allowed',
        value: true,
    },
    {
        label: 'Smoking is not allowed',
        value: false,
    },
];

const rentInsuranceProofPoliciesValues = [
    {
        label: 'Tenant must provide proof of tenant insurance before move-in',
        value: true,
    },
    {
        label: 'Tenant does not have to provide proof of tenant insurance before move-in',
        value: false,
    },
];

const automaticPaymentSetupPoliciesValues = [
    {
        label: 'Tenant must have automatic direct deposit rent payments setup',
        value: true,
    },
    {
        label: 'Tenant does not need to have automatic direct deposit rent payments setup',
        value: false,
    },
];

export const LeasePSIPolicyComponent: React.FC<{
    leaseAgreement: ILeaseAgreement;
    setLeaseAgreement: (field: keyof ILeaseAgreement) => (value: unknown) => void;
    readOnly?: boolean;
}> = observer(({ leaseAgreement, setLeaseAgreement, readOnly }) => {
    return !readOnly ? (
        <LeasePoliciesEditComponent
            leaseAgreement={leaseAgreement}
            setLeaseAgreement={setLeaseAgreement}
        />
    ) : (
        <LeasePoliciesReadComponent leaseAgreement={leaseAgreement} />
    );
});

const LeasePoliciesEditComponent: React.FC<{
    leaseAgreement: ILeaseAgreement;
    setLeaseAgreement: (field: keyof ILeaseAgreement) => (value: unknown) => void;
}> = observer(({ leaseAgreement, setLeaseAgreement }) => {
    const handleRadioChange = (prop: keyof ILeasePolicy) => (value: unknown) => {
        setLeaseAgreement('policies')({
            ...leaseAgreement.policies,
            [prop]: value === 'true' ? true : false,
        });
    };

    const handleCheckBoxChange =
        (prop: keyof ILeasePolicy) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setLeaseAgreement('policies')({
                ...leaseAgreement.policies,
                [prop]: event.target.checked,
            });
        };

    const handleFees = (prop: keyof ILeaseFees) => (value: string | number | null) => {
        setLeaseAgreement('fees')({
            ...leaseAgreement.fees,
            [prop]: value?.toString(),
        });
    };

    return (
        <>
            <Typography variant='h6'>Pets, Smoking &amp; Insurance</Typography>
            <Typography variant='body1' gutterBottom>
                The information below has been automatically filled.<b>Double check</b> to make sure
                they are accurate
            </Typography>
            {/* Peet Fees */}
            <Box mt={2}>
                <RadioButtonsGroup
                    onChange={handleRadioChange('petsAllowed')}
                    id='petsAllowed'
                    value={leaseAgreement?.policies?.petsAllowed}
                    label='Pet Fees & Policy'
                >
                    <Grid container>
                        {petPoliciesValues.map((value, i) => (
                            <Grid item key={i} xs={12}>
                                <RadioItem id='petsAllowed' value={value} />
                            </Grid>
                        ))}
                    </Grid>
                </RadioButtonsGroup>
            </Box>
            <Box mt={2}>
                <Typography>Fee</Typography>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <InputFieldMoney
                            disabled={!leaseAgreement.policies.petsAllowed}
                            fullWidth
                            id='petFee'
                            value={
                                !leaseAgreement.policies.petsAllowed
                                    ? 0
                                    : leaseAgreement?.fees?.petFee
                            }
                            onChange={handleFees('petFee')}
                            placeholder={''}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <SelectField
                            id='petFeeFrequency'
                            onChange={handleFees('petFeeFrequency')}
                            value={leaseAgreement?.fees?.petFeeFrequency}
                            values={FeePaymentFrequencies}
                        />
                    </Grid>
                </Grid>
            </Box>
            {/* Smoking Fees */}
            <Box mt={2}>
                <RadioButtonsGroup
                    onChange={handleRadioChange('smokingAllowed')}
                    id='smokingAllowed'
                    value={leaseAgreement?.policies?.smokingAllowed}
                    label='Smoking Fees & Policy'
                >
                    <Grid container>
                        {smokingPoliciesValues.map((value, i) => (
                            <Grid item key={i} xs={12}>
                                <RadioItem id='smokingAllowed' value={value} />
                            </Grid>
                        ))}
                    </Grid>
                </RadioButtonsGroup>
            </Box>
            <Box mt={2}>
                <Typography>Fee</Typography>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <InputFieldMoney
                            disabled={!leaseAgreement.policies.smokingAllowed}
                            fullWidth
                            id='smokingFee'
                            value={
                                !leaseAgreement.policies.smokingAllowed
                                    ? 0
                                    : leaseAgreement?.fees?.smokingFee
                            }
                            onChange={handleFees('smokingFee')}
                            placeholder={''}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <SelectField
                            id='smokingFeeFrequency'
                            onChange={handleFees('smokingFeeFrequency')}
                            value={leaseAgreement?.fees?.smokingFeeFrequency}
                            values={FeePaymentFrequencies}
                        />
                    </Grid>
                </Grid>
            </Box>

            {/* Renters Insurance */}
            <Box mt={2}>
                <Typography>
                    <b>Renters Insurance Policy</b>
                </Typography>
                <CheckboxItem
                    id='rentInsuranceProof'
                    onChange={handleCheckBoxChange('rentInsuranceProof')}
                    label='Tenant must provide proof of tenant insurance before move-in'
                    value={leaseAgreement?.policies?.rentInsuranceProof}
                />
            </Box>

            {/* Automatic Payment */}
            <Box mt={2}>
                <Typography>
                    <b>Require Automatic Payment Setup</b>
                </Typography>
                <CheckboxItem
                    id='autoPaymentSetup'
                    onChange={handleCheckBoxChange('autoPaymentSetup')}
                    label='Tenant must have automatic direct deposit rent payments setup'
                    value={leaseAgreement?.policies?.autoPaymentSetup}
                />
            </Box>
        </>
    );
});

const LeasePoliciesReadComponent: React.FC<{
    leaseAgreement: ILeaseAgreement;
}> = observer(({ leaseAgreement }) => {
    const getFeeFrequencyLabel = (val: FeePaymentFrequency) => {
        return (FeePaymentFrequencies.find((f) => f.val === val) || { label: 'N/A' }).label;
    };

    const petPolicy = petPoliciesValues.find(
        ({ value }) => value === leaseAgreement.policies.petsAllowed,
    );
    const smokingPolicy = smokingPoliciesValues.find(
        ({ value }) => value === leaseAgreement.policies.smokingAllowed,
    );
    const rentInsuranceProofPolicy = rentInsuranceProofPoliciesValues.find(
        ({ value }) => value === leaseAgreement.policies.rentInsuranceProof,
    );
    const automaticSetupPolicy = automaticPaymentSetupPoliciesValues.find(
        ({ value }) => value === leaseAgreement.policies.autoPaymentSetup,
    );

    return (
        <>
            <Typography variant='h6'>Pets, Smoking &amp; Insurance</Typography>
            {/* Peet Fees */}
            <Box mt={2}>
                <Typography variant='subtitle1'>{'Pet Fees & Policy'}</Typography>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography gutterBottom>
                            Policy <b>{petPolicy?.label}</b>
                        </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={6}>
                            <Typography gutterBottom>
                                Fee:{' '}
                                {leaseAgreement.fees.petFee
                                    ? `$CAD ${leaseAgreement?.fees.petFee}`
                                    : 'N/A'}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                Frequency:{' '}
                                {getFeeFrequencyLabel(leaseAgreement.fees.petFeeFrequency)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {/* Smoking Fees */}
            <Box mt={2}>
                <Typography variant='subtitle1'>{'Smoking Fees & Policy'}</Typography>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography gutterBottom>
                            Policy <b>{smokingPolicy?.label}</b>
                        </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={6}>
                            <Typography gutterBottom>
                                Fee:{' '}
                                {leaseAgreement.fees.smokingFee
                                    ? `$CAD ${leaseAgreement?.fees.smokingFee}`
                                    : 'N/A'}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                Frequency:{' '}
                                {getFeeFrequencyLabel(leaseAgreement.fees.smokingFeeFrequency)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {/* Renters Insurance */}
            <Box mt={2}>
                <Typography>Renters Insurance Policy</Typography>
                <Typography>
                    <b>{rentInsuranceProofPolicy?.label}</b>
                </Typography>
            </Box>

            {/* Automatic Payment */}
            <Box mt={2}>
                <Typography>Require Automatic Payment Setup</Typography>
                <Typography>
                    <b>{automaticSetupPolicy?.label}</b>
                </Typography>
            </Box>
        </>
    );
});
