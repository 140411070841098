import { InputAdornment, TextField } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { observer } from 'mobx-react-lite';
import React from 'react';

export const InputFieldMoney: React.FC<{
    id: string;
    value?: number;
    placeholder: string;
    fullWidth: boolean;
    onChange: (value: string | null) => void;
    readOnly?: boolean;
    disabled?: boolean;
    style?: CSSProperties;
}> = observer(({ id, value, placeholder, fullWidth, onChange, readOnly, style, disabled }) => {
    return (
        <TextField
            fullWidth={fullWidth}
            disabled={disabled ?? false}
            required
            id={id}
            placeholder={placeholder}
            margin='normal'
            variant='outlined'
            InputLabelProps={{
                shrink: true,
            }}
            InputProps={{
                startAdornment: <InputAdornment position='start'>$CAD</InputAdornment>,
                readOnly,
            }}
            value={value ?? ''}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                onChange(event.target.value as string);
            }}
            style={style}
        />
    );
});
