import { DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { InputFieldMoney } from '../../../../../formComponents/inputfieldmoney';

export const SendMoney: React.FC<{
    sendAmount: number;
    setSendMoneyAmount: (value: number) => void;
    setSendMoneyNote: (event: string) => void;
    sendMoneyNote: string;
}> = observer(({ sendAmount, setSendMoneyAmount, setSendMoneyNote, sendMoneyNote }) => {
    const handleSendMoneyNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSendMoneyNote(event.target.value);
    };

    const handleSendAmountChange = (value: string | number | null) => {
        const updatedAmount = Number(value);
        if (isNaN(updatedAmount) || updatedAmount < 0) return;
        setSendMoneyAmount(updatedAmount);
        setSendMoneyAmount(updatedAmount);
    };
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DialogTitle style={{ textAlign: 'center' }}>Send Money</DialogTitle>
                </Grid>

                <Grid item xs={12}>
                    <Grid>
                        <Typography variant='subtitle1'>
                            <b>How much will you like to send?</b>
                        </Typography>
                    </Grid>
                    <Grid>
                        <InputFieldMoney
                            id='money'
                            placeholder='Amount(CAD)'
                            fullWidth
                            value={sendAmount}
                            onChange={handleSendAmountChange}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid>
                        <Typography variant='subtitle1'>
                            <b>Notes</b>
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography variant='subtitle1'>
                            <small>Let them know why you're sending money</small>
                        </Typography>
                    </Grid>
                    <Grid>
                        <TextField
                            value={sendMoneyNote}
                            variant='outlined'
                            multiline
                            fullWidth
                            minRows={5}
                            onChange={handleSendMoneyNoteChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
});
