import React, { useContext } from 'react';
import { Avatar, Button, Typography } from '@material-ui/core';

import { GooglePlacesApiKey } from '../../appConfig';
import HomeIcon from '@material-ui/icons/Home';

import StringUtils from '../../utils/string';
import { observer } from 'mobx-react-lite';
import GoogleMapReact from 'google-map-react';
import { LatLngLiteral, IdPricedListingProperty, IRatings } from 'realhaus-sdk';
import { ListingDetailSectionMap } from './styles';
import { RootStoreContext } from '../../global/storeContext';
import { MapDropDetails } from './mapDropCard';

interface IListingMarker {
    id: string;
    name?: string;
    price: number;
    lat?: number;
    lng?: number;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
}
interface IHomeMarker {
    id: string;
    name?: string;
    lat?: number;
    lng?: number;
}

const ListingMarker = ({ id, price, onClick }: IListingMarker) => {
    return (
        <>
            <Button
                variant='contained'
                size='small'
                style={{ borderRadius: '16px', backgroundColor: 'white' }}
                aria-label={id}
                onClick={onClick}
            >
                {StringUtils.asCurrency(price)}
            </Button>
        </>
    );
};

const HomeMarker = ({ id }: IHomeMarker) => (
    <Avatar style={{ backgroundColor: 'red', cursor: 'pointer' }} alt={id}>
        <HomeIcon style={{ color: 'white' }} />
    </Avatar>
);

export const ListingMap = observer(
    (props: { centerCoordinate: LatLngLiteral; listings: IdPricedListingProperty[] }) => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const [listing, setListing] = React.useState<IdPricedListingProperty>();
        const [rating, setRating] = React.useState<IRatings | undefined>();

        const { ratingStore } = useContext(RootStoreContext);

        const handleClick =
            (l: IdPricedListingProperty) => async (event: React.MouseEvent<HTMLElement>) => {
                setListing(l);
                setAnchorEl(event.currentTarget);
                await ratingStore.calculateListingRating(l.listingId).then((rate) => {
                    setRating(rate);
                });
            };
        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const popperId = open ? 'simple-popper' : undefined;
        return (
            <>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: `${GooglePlacesApiKey}` }}
                    center={props.centerCoordinate}
                    zoom={11}
                >
                    {props.listings.map((l) => {
                        return (
                            <ListingMarker
                                lat={
                                    l.address.geoloc
                                        ? l.address.geoloc.lat
                                        : props.centerCoordinate.lat
                                }
                                lng={
                                    l.address.geoloc
                                        ? l.address.geoloc.lng
                                        : props.centerCoordinate.lng
                                }
                                id={l.listingId}
                                price={l.rentAmount}
                                onClick={handleClick(l)}
                                key={`listing-key-${l.listingId}`}
                            />
                        );
                    })}
                    {listing ? (
                        <MapDropDetails
                            popperId={popperId}
                            open={open}
                            anchorE1={anchorEl}
                            listing={listing}
                            rating={rating}
                            handleClose={handleClose}
                            maxWidth='300px'
                        />
                    ) : null}
                </GoogleMapReact>
            </>
        );
    },
);

export const HomeMap = observer((props: { centerCordinate: LatLngLiteral }) => {
    return (
        <>
            <Typography variant='h5' style={{ fontWeight: 'bold' }} gutterBottom>
                Location
            </Typography>
            <ListingDetailSectionMap>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: `${GooglePlacesApiKey}` }}
                    center={props.centerCordinate}
                    zoom={11}
                >
                    <HomeMarker
                        lat={props.centerCordinate.lat}
                        lng={props.centerCordinate.lng}
                        id={props.centerCordinate.lat.toString()}
                    />
                </GoogleMapReact>
            </ListingDetailSectionMap>
        </>
    );
});
