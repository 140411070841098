import { createStyles, makeStyles, styled, Theme } from '@material-ui/core';


export const StepperButtons = styled('div')({
  padding: '10px',

})

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      display: 'flex',
    },
    root: {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '500px',
        height: '50%',
        maxHeight: '600px'
      },
    },
    container: {
      width: '100%',
      overflow: "auto",
      height: 'calc(100vh - 380px)'
    },
    cardDetails: {
      display: 'flex',
      flexDirection: 'column',
      width: '500px',
      padding: '35px',
    },
    cardContent: {
      flex: '1 0 auto',
    },
    cardCover: {
      width: '100%',
    },
    cardControls: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    alignCenter: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '25px',
    },
    table: {
      minWidth: 650,
    },
  }),
);