import React from 'react';
import { observer } from 'mobx-react-lite';

import { TenantProfileForm } from '../../../components/tenantProfile/profileForm';
import { PageTitle, AdminPageLayout } from '../../../components/uiComponents/UIComponents';

export const TenantProfilePage: React.FC = observer(() => {
    return (
        <AdminPageLayout
            header={<PageTitle>Your Profile</PageTitle>}
            content={<TenantProfileForm />}
        />
    );
});
