import React from 'react';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { IOccupation } from 'realhaus-sdk';
import StringUtils from '../../utils/string';

interface FinancialsProps {
    occupation?: IOccupation;
}

export const FinancialsComponent: React.FC<FinancialsProps> = observer(({ occupation }) => {
    const classes = useStyles();
    const { startDate, businessType, income, incomeType, employer, title, manager } =
        occupation as IOccupation;

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography
                        variant='h6'
                        component='h6'
                        gutterBottom
                        style={{ textTransform: 'uppercase' }}
                    >
                        Employment History
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card variant='outlined' className={classes.cardRoot}>
                                <CardContent>
                                    <Grid container direction='row'>
                                        <Grid item xs={4} className={classes.gutterBottom}>
                                            <Typography variant='body1'>
                                                <b>
                                                    {format(startDate || new Date(), 'MMM yyyy')} -
                                                    Present
                                                </b>
                                            </Typography>
                                            <Typography variant='subtitle1' color='textSecondary'>
                                                Employed
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.gutterBottom}>
                                            <Typography variant='body1'>
                                                <b>{businessType}</b>
                                            </Typography>
                                            <Typography variant='subtitle1' color='textSecondary'>
                                                Business Type
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.gutterBottom}>
                                            <Typography variant='body1'>
                                                <b>{StringUtils.printIncome(income, incomeType)}</b>
                                            </Typography>
                                            <Typography variant='subtitle1' color='textSecondary'>
                                                Income
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant='body1'>
                                                <b>{employer}</b>
                                            </Typography>
                                            <Typography variant='subtitle1' color='textSecondary'>
                                                Employer
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant='body1'>
                                                <b>{title}</b>
                                            </Typography>
                                            <Typography variant='subtitle1' color='textSecondary'>
                                                Job Title
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant='body1'>
                                                <b>{manager ?? 'N/A'}</b>
                                            </Typography>
                                            <Typography variant='subtitle1' color='textSecondary'>
                                                Supervisor/Manager
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            {/* TODO: Next occupation goes here */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
});
