import React from 'react';
import { observer } from 'mobx-react-lite';
import { MainLayout } from '../../../layouts/main/main';
import { UpsertProperty } from '../../../components/createProperty/upsertProperty';
import {
    AdminPageContent,
    GridContent,
    GridMainContent,
    PageHead,
    PageTitle,
} from '../../../components/uiComponents/UIComponents';
import { useLocation } from 'react-router';

const useQuery = () => new URLSearchParams(useLocation().search);

export const NewPropertyPage: React.FC = observer(() => {
    const queryParams = useQuery();

    return (
        <>
            <MainLayout>
                <AdminPageContent>
                    <PageHead>
                        <div>
                            <PageTitle>New Property</PageTitle>
                        </div>
                    </PageHead>
                    <GridContent>
                        <GridMainContent>
                            <UpsertProperty
                                isEditMode={false}
                                propertyId={queryParams.get('c') ?? undefined}
                            />
                        </GridMainContent>
                    </GridContent>
                </AdminPageContent>
            </MainLayout>
        </>
    );
});
