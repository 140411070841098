import React from 'react';

import LoginIcon from "@material-ui/icons/LockOpen";
import RegisterIcon from "@material-ui/icons/GroupAdd";

import { LoginPage } from './pages/login/login';
import { SignupPage } from './pages/signup/signup';
import { DashboardPage } from './pages/landlord/dashboard/dashboard';
import { AccountPage } from './pages/account/account';
import { HomePage } from './pages/home/home';
import { PersonalInfoPage } from './pages/account/personalInfo';
import { ListingsPage } from './pages/landlord/listings/listingsList';
import { NewPropertyPage } from './pages/landlord/listings/createProperty';
import { ListingDetailPage } from './pages/properties/property';
import { ManageListingPage } from './pages/landlord/listings/editProperty';
import { TenantDashboardPage } from './pages/tenant/dashboard/dashboard';
import { InboxPage } from './pages/inbox';
import { TenantLeasesPage } from './pages/tenant/leases/leases';
import { TenantProfilePage } from './pages/tenant/profile/profile';
import { LandlordLeasesPage } from './pages/landlord/leases/leases';
import { PropertiesListPage } from './pages/properties/propertiesSearch';
import { ProspectiveTenantsPage } from './pages/landlord/leases/prospective';
import { LandlordBookmarkPage } from './pages/landlord/liked/landLordBookmark';
import { TenantBookmarkPage } from './pages/tenant/bookmark/bookmark';
import { LoginSecurityPage } from './pages/account/loginSecurity';
import { FinancialsPage } from './pages/account/financials';
import { PrivacyPolicyPage } from './pages/help/privacyPolicy';
import { TermsOfServicePage } from './pages/help/termsOfService';
import { EarningsPage } from './pages/landlord/earnings/earnings';

export interface IRoute {
  path: string;
  name: string;
  icon: any;
  component: React.FC;
  authGuard: boolean;
  noAuthGuard: boolean;
}

export const paths = {
  home: '/',
  login: '/login',
  signup: '/signup',
  inbox: '/inbox',
  privacypolicy: '/privacy-policy',
  termsofservice: '/terms-of-service',
  landlord: {
    dashboard: '/m/dashboard',
    listings: '/m/listings',
    editListing: '/m/listings/:listingId/:pageId',
    newListing: '/m/new-listing',
    listing: '/m/listing/:listingId',
    tenants: '/m/tenants',
    lease: '/m/leases/:leaseId',
    prospectiveTenant: '/m/prospective-tenants',
    financials: '/m/financials',
    bookmark: '/m/bookmark',
    earnings: '/m/earnings',
  },
  tenant: {
    dashboard: '/t/dashboard',
    profile: '/t/profile',
    leases: '/t/leases',
    lease: '/t/leases/:leaseId',
    financials: '/t/financials',
    bookmark: '/t/bookmark'
  },
  account: {
    view: '/account',
    personalInfo: '/account/personal-info',
    loginSecurity: '/account/login-security',
    financials: '/account/financials'
  },
  listings: {
    search: '/search',
    detail: '/l/:listingId'
  },

}

export const pageRoutes: IRoute[] = [
  {
    path: paths.home,
    name: "Home Page",
    icon: RegisterIcon,
    component: HomePage,
    authGuard: false,
    noAuthGuard: false,
  },
  {
    path: paths.login,
    name: "Login Page",
    icon: LoginIcon,
    component: LoginPage,
    authGuard: false,
    noAuthGuard: true,
  },
  {
    path: paths.signup,
    name: "Register Page",
    icon: RegisterIcon,
    component: SignupPage,
    authGuard: false,
    noAuthGuard: true,
  },
  // Listings
  {
    path: paths.listings.search,
    name: "Search Properties Page",
    icon: RegisterIcon,
    component: PropertiesListPage,
    authGuard: true,
    noAuthGuard: false,
  },
  {
    path: paths.listings.detail,
    name: "Listing Detail Page",
    icon: RegisterIcon,
    component: ListingDetailPage,
    authGuard: true,
    noAuthGuard: false,
  },
  // Inbox
  {
    path: paths.inbox,
    name: "Inbox Page",
    icon: RegisterIcon,
    component: InboxPage,
    authGuard: true,
    noAuthGuard: false,
  },
  // Privacy Policy
  {
    path: paths.privacypolicy,
    name: "Privacy Policy",
    icon: RegisterIcon,
    component: PrivacyPolicyPage,
    authGuard: false,
    noAuthGuard: false,
  },
  // Terms Of Service
  {
    path: paths.termsofservice,
    name: "Terms of Service",
    icon: RegisterIcon,
    component: TermsOfServicePage,
    authGuard: false,
    noAuthGuard: false,
  },
  // Landlord
  {
    path: paths.landlord.dashboard,
    name: "Dashboard Page",
    icon: RegisterIcon,
    component: DashboardPage,
    authGuard: true,
    noAuthGuard: false,
  },
  {
    path: paths.landlord.tenants,
    name: "Lease Agreements Page",
    icon: RegisterIcon,
    component: LandlordLeasesPage,
    authGuard: true,
    noAuthGuard: false,
  },
  {
    path: paths.landlord.prospectiveTenant,
    name: "Potential Tenants Page",
    icon: RegisterIcon,
    component: ProspectiveTenantsPage,
    authGuard: true,
    noAuthGuard: false,
  },
  {
    path: paths.landlord.listings,
    name: "My Listings Page",
    icon: RegisterIcon,
    component: ListingsPage,
    authGuard: true,
    noAuthGuard: false,
  },
  {
    path: paths.landlord.bookmark,
    name: "Bookmark Page",
    icon: RegisterIcon,
    component: LandlordBookmarkPage,
    authGuard: true,
    noAuthGuard: false,
  },
  {
    path: paths.landlord.earnings,
    name: 'Earnings Page',
    icon: RegisterIcon,
    component: EarningsPage,
    authGuard: true,
    noAuthGuard: false
  },
  {
    path: paths.landlord.editListing,
    name: "Edit Listing Page",
    icon: RegisterIcon,
    component: ManageListingPage,
    authGuard: true,
    noAuthGuard: false,
  },
  {
    path: paths.landlord.newListing,
    name: "New Listing Page",
    icon: RegisterIcon,
    component: NewPropertyPage,
    authGuard: true,
    noAuthGuard: false,
  },
  // Tenant
  {
    path: paths.tenant.dashboard,
    name: "Dashboard Page",
    icon: RegisterIcon,
    component: TenantDashboardPage,
    authGuard: true,
    noAuthGuard: false,
  },
  {
    path: paths.tenant.leases,
    name: "Leases Page",
    icon: RegisterIcon,
    component: TenantLeasesPage,
    authGuard: true,
    noAuthGuard: false,
  },
  {
    path: paths.tenant.bookmark,
    name: "Bookmark Page",
    icon: RegisterIcon,
    component: TenantBookmarkPage,
    authGuard: true,
    noAuthGuard: false,
  },
  {
    path: paths.tenant.profile,
    name: "Tenant Profile Page",
    icon: RegisterIcon,
    component: TenantProfilePage,
    authGuard: true,
    noAuthGuard: false,
  },

  // Account
  {
    path: paths.account.view,
    name: "Account Page",
    icon: RegisterIcon,
    component: AccountPage,
    authGuard: true,
    noAuthGuard: false,
  },
  {
    path: paths.account.personalInfo,
    name: "Personal Info Page",
    icon: RegisterIcon,
    component: PersonalInfoPage,
    authGuard: true,
    noAuthGuard: false,
  },
  {
    path: paths.account.loginSecurity,
    name: "Login & Security",
    icon: RegisterIcon,
    component: LoginSecurityPage,
    authGuard: true,
    noAuthGuard: false,
  },
  {
    path: paths.account.financials,
    name: "Financials",
    icon: RegisterIcon,
    component: FinancialsPage,
    authGuard: true,
    noAuthGuard: false,
  }
]

export default pageRoutes;