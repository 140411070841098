import { createStyles, makeStyles, Theme, styled } from '@material-ui/core';

export const Spacing = styled('div')({
  flex: '1 1 auto',
})

export const getRootStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  timeslotCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  cardActions: {
    padding: theme.spacing(1, 3),
  },
  cardFooter: {
    width: '100%',
  },

  alignLeft: {
    textAlign: 'left',
    alignContent: 'left'
  },

  alignCenter: {
    textAlign: 'center',
    alignContent: 'center'
  },
  alignRight: {
    textAlign: 'right',
    alignContent: 'right'
  },
  floatRight: {
    float: 'right'
  },
  fullWidth: {
    width: '100%'
  },
  toolbarSecondary: {
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1, 1.5),
    flexShrink: 0,
  },
  activeLink: {
    backgroundColor: '#f9f8f7',
    boxShadow: '0 1px 2px 0 rgba(0,0,0,.07)',
  },
  titleBar: {
    display: 'flex',
  },
  newTimeslotModalContent: {
    display: 'flex',
    // minWidth: '500px',
    minHeight: '170px',
  },
  verticalCenter: {
    justifyContent: 'center',
    alignContent: 'center',
  },
  modalContent: {
    minHeight: '50px',
    padding: '16px 24px',
  },
  modalFooter: {
    paddingBottom: '16px',
    paddingRight: '24px'
  },
}));

export const HeroContainer = styled('section')({
  height: '55vh',
  backgroundImage: `url(${"img/main8.jpg"})`,
  backgroundSize: 'cover',
  backgroundPosition: '50%',
  backgroundRepeat: 'no-repeat'
})
export const HeroContainerContent = styled('div')({
  paddingTop: 'calc(55vh/3)',
  color: 'white'
})
export const HeroContainerOverlay = styled('div')({
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  position: 'relative',
  zIndex: 1
})
export const HomeSectionContainer = styled('section')({
  padding: '48px 0px',
  minHeight: '240px',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  flexDirection: 'column',
})