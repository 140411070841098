import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container,
    createStyles,
    IconButton,
    Menu,
    MenuItem,
    Tab,
    Tabs,
    Theme,
    Toolbar,
    useScrollTrigger,
    withStyles,
} from '@material-ui/core';
import { Spacing, Logo, HeaderNav, useStyles, SearchBar } from './styles';
import { RootStoreContext } from '../../global/storeContext';
import { Link, useLocation } from 'react-router-dom';
import { paths } from '../../routes';
import { RealhausLogo } from './logo';
import { SearchLocationAutocomplete } from '../../components/search/autocomplete';
import { AutocompletePrediction } from 'realhaus-sdk';
import { locationQuery } from '../../utils/address';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import { createTheme } from '@mui/material';
import { NegativeBanner } from '../../App';

interface StyledTabsProps {
    children?: React.ReactNode;
    value: any;
    onChange: (event: React.ChangeEvent<{}>, newValue: any) => void;
    centered?: boolean;
    vertical: boolean;
}

interface StyledTabProps {
    label: string;
    component: any;
    to: string;
}

const theme = createTheme();

const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            maxWidth: 20,
            width: '100%',
            backgroundColor: '#635ee7',
        },
        [theme.breakpoints.down(938)]: {
            display: 'none',
        },
    },
})((props: StyledTabsProps) => (
    <Tabs
        {...props}
        orientation={props.vertical ? 'vertical' : 'horizontal'}
        TabIndicatorProps={{ children: <span /> }}
    />
));

const StyledTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
            fontWeight: 'normal',
            fontSize: theme.typography.pxToRem(15),
            marginRight: theme.spacing(1),
            minWidth: 10,
            '&:focus': {
                opacity: 1,
            },
        },
    }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

const ElevationScroll = (props: {
    window?: () => Window;
    children: React.ReactElement;
    elevateOnScroll: boolean;
}) => {
    const { children, elevateOnScroll } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 5,
    });

    return React.cloneElement(children, {
        elevation: elevateOnScroll && trigger ? 4 : 0,
    });
};

interface HeaderProps {
    colorWhite?: boolean;
    paddingTop?: string;
    elevateOnScroll?: boolean;
}

export const MainHeader: React.FC<HeaderProps> = observer(
    ({ colorWhite, paddingTop, elevateOnScroll = true }) => {
        const classes = useStyles();
        const path = useLocation();

        const { uiStore, userStore } = useContext(RootStoreContext);

        const [userPhoto, setUserPhoto] = useState<string>('');

        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const [mobileAnchorEl, setMobileAnchorEl] = React.useState<null | HTMLElement>(null);

        const isMenuOpen = Boolean(anchorEl);
        const isMobileMenuOpen = Boolean(mobileAnchorEl);
        const menuId = 'primary-search-account-menu';
        const mobileMenuId = 'primary-search-account-menu-mobile';

        useEffect(() => {
            const userData = userStore.userInfo;
            if (!!userData) {
                setUserPhoto(userData.photoURL);
            }
        }, []);
        const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        };
        const handleMenuClose = () => {
            setAnchorEl(null);
            // handleMobileMenuClose();
        };

        const handleMobileMenuClose = () => {
            setMobileAnchorEl(null);
        };

        const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
            setMobileAnchorEl(event.currentTarget);
        };

        const handleLogout = () => {
            userStore.logout().then(() => {
                goTo(paths.login);
            });
        };

        const navToAccount = () => {
            goTo(paths.account.view);
        };

        const navToInbox = () => {
            goTo(paths.inbox);
        };

        const navToLandlord = () => {
            goTo(paths.landlord.dashboard);
        };

        const navToTenant = () => {
            goTo(paths.tenant.dashboard);
        };

        const goTo = (url: string) => {
            uiStore.goTo(url);
        };

        const RenderSearchNavs = () => {
            const search = new URLSearchParams(path.search);
            const [place, setPlace] = useState<AutocompletePrediction | null>(null);
            const initialValue = search
                .get('q')
                ?.replace('--canada', '')
                .split('--')
                .map((word) => {
                    return word
                        .split('-')
                        .map((w) => w[0].toUpperCase() + w.substring(1))
                        .join(' ');
                })
                .join(', ');

            const locationSelected = (location: AutocompletePrediction | null) => {
                if (location) {
                    setPlace(location);
                }
            };

            const searchProperties = () => {
                if (!place) return;
                const query = `q=${locationQuery(place)}`;
                goTo(`/search?${query}`);
            };

            return (
                <SearchBar>
                    {path.pathname.includes('search') && (
                        <Box
                            width={{ lg: 400, md: 400 }}
                            sx={{ display: 'flex', alignItems: 'center' }}
                        >
                            <SearchLocationAutocomplete
                                onLocationSelected={locationSelected}
                                placeholder={initialValue ?? 'City, Province'}
                            />
                            <IconButton
                                type='submit'
                                aria-label='search'
                                color='default'
                                onClick={searchProperties}
                            >
                                <SearchIcon fontSize='large' />
                            </IconButton>
                        </Box>
                    )}
                </SearchBar>
            );
        };

        const isLandlordMode = uiStore.isLandlordMode();
        const isTenantMode = uiStore.isTenantMode();
        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={menuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                {!isLandlordMode && !isTenantMode ? (
                    <MenuItem onClick={navToLandlord}>Manage properties</MenuItem>
                ) : null}
                {!isLandlordMode && !isTenantMode ? (
                    <MenuItem onClick={navToTenant}>Manage tenancy</MenuItem>
                ) : null}
                <MenuItem onClick={navToInbox}>Messages</MenuItem>
                <MenuItem onClick={navToAccount}>My account</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        );

        const renderMobileMenu = (
            <Menu
                anchorEl={mobileAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={mobileMenuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={handleMobileMenuClose}
            >
                {isLandlordMode ? (
                    <RenderLandloardNavs vertical={true} />
                ) : isTenantMode ? (
                    <RenderTenantNavs vertical={true} />
                ) : (
                    <RenderSearchNavs />
                )}
                {isTenantMode ? (
                    <Button href={paths.landlord.dashboard}>Switch to Landlord</Button>
                ) : isLandlordMode ? (
                    <Button href={paths.tenant.dashboard}>Switch to Tenant</Button>
                ) : null}
            </Menu>
        );

        const scrollTrigger = useScrollTrigger({
            disableHysteresis: true,
            threshold: 0,
        });
        return (
            <>
                <ElevationScroll elevateOnScroll={elevateOnScroll}>
                    <AppBar
                        className={classes.appBar}
                        style={{
                            paddingTop: paddingTop ?? '0px',
                            backgroundColor:
                                elevateOnScroll && scrollTrigger ? 'white' : 'transparent',
                        }}
                        position={elevateOnScroll ? 'fixed' : 'relative'}
                    >
                        <Container maxWidth={'lg'}>
                            <Toolbar className={classes.toolBar}>
                                <div>
                                    <Logo href='/'>
                                        <RealhausLogo colorWhite={colorWhite} />
                                    </Logo>
                                </div>
                                <div className={classes.sectionDesktop}>
                                    <HeaderNav>
                                        {isLandlordMode ? (
                                            <RenderLandloardNavs vertical={false} />
                                        ) : isTenantMode ? (
                                            <RenderTenantNavs vertical={false} />
                                        ) : (
                                            <RenderSearchNavs />
                                        )}{' '}
                                    </HeaderNav>
                                </div>
                                <Spacing />

                                <div>
                                    <HeaderNav>
                                        <div className={classes.sectionDesktop}>
                                            {isTenantMode ? (
                                                <Button href={paths.landlord.dashboard}>
                                                    Switch to Landlord
                                                </Button>
                                            ) : isLandlordMode ? (
                                                <Button href={paths.tenant.dashboard}>
                                                    Switch to Tenant
                                                </Button>
                                            ) : null}
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div className={classes.sectionMobile}>
                                                {!path.pathname.endsWith('/') && (
                                                    <IconButton
                                                        aria-label='show more'
                                                        aria-controls={mobileMenuId}
                                                        aria-haspopup='true'
                                                        onClick={handleMobileMenuOpen}
                                                        color='inherit'
                                                    >
                                                        <MoreIcon />
                                                    </IconButton>
                                                )}
                                            </div>
                                            {userStore.isAuthenticated ? (
                                                <IconButton
                                                    edge='end'
                                                    aria-label='account of current user'
                                                    aria-controls={menuId}
                                                    aria-haspopup='true'
                                                    onClick={handleProfileMenuOpen}
                                                    color='inherit'
                                                >
                                                    <Avatar alt='user' src={userPhoto} />
                                                </IconButton>
                                            ) : (
                                                <Button
                                                    href={paths.login}
                                                    className={
                                                        colorWhite
                                                            ? classes.colorWhite
                                                            : classes.colorBlack
                                                    }
                                                >
                                                    Login
                                                </Button>
                                            )}
                                        </div>
                                    </HeaderNav>
                                </div>
                            </Toolbar>
                            {renderMenu}
                            {renderMobileMenu}
                        </Container>
                    </AppBar>
                </ElevationScroll>
            </>
        );
    },
);

export const RenderLandloardNavs: React.FC<{ vertical: boolean }> = observer(({ vertical }) => {
    const landlordNavs = [
        {
            label: 'Dashboard',
            path: paths.landlord.dashboard,
        },
        {
            label: 'Listings',
            path: paths.landlord.listings,
        },
        { label: 'Applications', path: paths.landlord.prospectiveTenant },
        { label: 'Tenants', path: paths.landlord.tenants },
        {
            label: 'Bookmark',
            path: paths.landlord.bookmark,
        },
        {
            label: 'Earnings',
            path: paths.landlord.earnings,
        },
    ];
    const currentTab = landlordNavs.findIndex((nav) => nav.path === window.location.pathname);

    return (
        <>
            <StyledTabs
                value={currentTab >= 0 ? currentTab : false}
                onChange={(_, value) => {
                    // goTo(landlordNavs[value].path)}
                }}
                centered
                vertical={vertical}
            >
                {landlordNavs.map(({ label, path }) => (
                    <StyledTab
                        key={label}
                        label={label}
                        // className={classes.tabLink}
                        component={Link}
                        to={path}
                    />
                ))}
            </StyledTabs>
        </>
    );
});

export const RenderTenantNavs: React.FC<{ vertical: boolean }> = observer(({ vertical }) => {
    const tenantNavs = [
        {
            label: 'Dashboard',
            path: paths.tenant.dashboard,
        },
        {
            label: 'Leases',
            path: paths.tenant.leases,
        },
        {
            label: 'Profile',
            path: paths.tenant.profile,
        },
        {
            label: 'Bookmark',
            path: paths.tenant.bookmark,
        },
    ];
    const currentTab = tenantNavs.findIndex((nav) => nav.path === window.location.pathname);

    return (
        <>
            <StyledTabs
                value={currentTab >= 0 ? currentTab : false}
                onChange={(_, value) => {
                    // goTo(tenantNavs[value].path);
                }}
                centered
                vertical={vertical}
            >
                {tenantNavs.map(({ label, path }) => (
                    <StyledTab
                        key={label}
                        label={label}
                        // className={classes.tabLink}
                        component={Link}
                        to={path}
                    />
                ))}
            </StyledTabs>
        </>
    );
});
