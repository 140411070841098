import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Card, Container, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { MainLayout } from '../../layouts/main/main';
import { GridContent, GridMainContent, PageHead, PageTitle } from '../uiComponents/UIComponents';
import { useStyles } from './style';
import { InboxConversationDetails } from './inboxConversationDetails';
import { InboxConversations } from './inboxConversations';
import { RootStoreContext } from '../../global/storeContext';

export const InboxComponent: React.FC = observer(() => {
    const classes = useStyles();

    const isMobile = useMediaQuery('(max-width:959px)');

    const { messageStore } = React.useContext(RootStoreContext);

    const [convoId, setConvoId] = useState<string>('');

    const [toggleConversationOpen, setToggleConversationOpen] = React.useState(false);

    const onConvoSelected = (convo: string, mobile: boolean) => {
        if (convoId !== convo) {
            messageStore.clearMessages();
            setConvoId(convo);
        }
        if (mobile) setToggleConversationOpen(true);
    };

    const messageBack = () => {
        setToggleConversationOpen(false);
    };

    return (
        <>
            <Card variant='outlined' className={classes.root}>
                <Grid container className={classes.grid}>
                    <Grid
                        item
                        md={4}
                        xs={12}
                        className={`${classes.boxBorderRight} ${classes.grid}`}
                        style={
                            isMobile
                                ? !toggleConversationOpen
                                    ? { display: 'block' }
                                    : { display: 'none' }
                                : {}
                        }
                    >
                        <Box className={classes.box}>
                            <Typography variant='h6' className={classes.contentPadding}>
                                All Conversations
                            </Typography>
                            <InboxConversations
                                onConvoSelected={onConvoSelected}
                                mobile={isMobile}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={8}
                        xs={12}
                        className={classes.grid}
                        style={
                            isMobile
                                ? toggleConversationOpen
                                    ? { display: 'block' }
                                    : { display: 'none' }
                                : {}
                        }
                    >
                        {convoId && (
                            <>
                                <InboxConversationDetails
                                    conversationId={convoId}
                                    isMobile={isMobile}
                                    messageBack={messageBack}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
            </Card>
        </>
    );
});

export const Inbox: React.FC = observer(() => {
    return (
        <>
            <MainLayout>
                <Container>
                    <PageHead>
                        <Grid container justifyContent='space-between'>
                            <PageTitle>Inbox</PageTitle>
                        </Grid>
                    </PageHead>
                    <GridContent>
                        <GridMainContent>
                            <InboxComponent />
                        </GridMainContent>
                    </GridContent>
                </Container>
            </MainLayout>
        </>
    );
});
