import { createStyles, makeStyles, styled, Theme } from '@material-ui/core/styles';

export const CardContentLink = styled('a')({
  display: 'block',
  width: '100%',
  textDecoration: 'none',
  color: 'inherit',
  margin: '-16px',
  padding: '24px'
})

export const CardContentLinkItem = styled('div')({
  display: 'flex',
  height: '100%',
})

export const CardLinkIcon = styled('div')({
  marginBottom: '16px'
})

export const CardLinkTitle = styled('div')({
  marginBottom: '8px',
  height: 'auto',
})

export const CardLinkFooter = styled('div')({
})

export const ListingPrice = styled('span')({
  color: '#519fff',
  fontWeight: 600,
  fontSize: '18px',
  display: 'block',
  marginBottom: '15px'
})

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%'
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      minHeight: '170px'
    },
    content: {
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 0
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    cover: {
      height: 160,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    sidebar: {
      width: '100%',
      maxWidth: 300,
      // backgroundColor: theme.palette.background.paper,
    },
    padLeft: {
      paddingLeft: 10
    },
    modalContent: {
      minHeight: '200px',
      padding: '40px !important'
    }
  }),
);