import { makeAutoObservable } from 'mobx';
import { GoogleMapsApiService } from '../global/google-maps';

export class GoogleMapsApiStore {
  private mapService: { current?: GoogleMapsApiService | null } = {};
  constructor() {
    this.mapService.current = new GoogleMapsApiService();
    makeAutoObservable(this);
  }

  get apiService() {
    return this.mapService.current;
  }
}