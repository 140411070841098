import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { ProgressButton } from './styles';

export interface LoaderProps {
    loading: boolean;
}

export const Loader: React.FC<LoaderProps> = ({ loading }) => {
    return (
        <>
            {!!loading && (
                <ProgressButton>
                    <CircularProgress size={24} />
                </ProgressButton>
            )}
        </>
    );
};
