import { createStyles, styled, makeStyles, Theme } from '@material-ui/core/styles';

import { green } from '@material-ui/core/colors';

export const PropertyDetail = styled('div')({
  whiteSpace: 'pre-wrap',
  marginTop: '2rem'
});

export const PropertyAddress = styled('span')({
  display: 'flex',
  alignItems: 'center'
});

export const ListingDetailSectionMap = styled('div')({
  width: '100%',
  height: '450px'
})

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%'
    },
    cardRoot: {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '1200px',
        height: '100%',
        maxHeight: '650px',
        overflow: 'hidden'
      },
    },
    popupPaper: {
      background: 'white',
      width: '100%',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '100%',
      overflow: 'hidden',
    },
    listingImage: {
      height: 0,
      paddingTop: '56.25%'
    },
    gridRoot: {
      display: 'flex',
      position: 'relative',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexDirection: 'column',
      width: '100%',
      height: 350,
      overflow: 'hidden',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    imageGridNavButton: {
      display: 'block',
      position: 'absolute',
      top: '152.5px',
      cursor: 'pointer',
      opacity: .4,
      zindex: 1000,
      backgroundColor: 'whitesmoke',
      color: 'inherit'
    },
    gutterBottom: {
      marginBottom: 15
    },
    listingBreadcrumb: {
      display: 'inline-flex',
      alignItems: 'center',
      alignContent: 'center',
      paddingRight: '5px'
    },
    alignCenter: {
      display: 'flex',
      alignItems: 'center'
    },
    propertySpec: {
      padding: 10,
      marginBottom: 10
    },
    listingTermContent: {
      marginBottom: 15,
      textAlign: 'center'
    },
    listingTerm: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    titleBar: {
      display: 'flex',
      width: '100%',
      padding: '10px 0px'
    },
    formContainer: {
      width: '100%',
    },
    amenityContainer: {
      display: "flex",
      alignItems: "center",
    },
    amenityIcon: {
      marginRight: theme.spacing(1),
      color: green[500],
    }
  }),
);