import {
    Box,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { SendRequestChoice } from './sendOrRequestModal';

export const SendOrRequest: React.FC<{
    sendRequestChoice: string;
    setSendRequestChoice: (value: SendRequestChoice) => void;
}> = observer(({ sendRequestChoice, setSendRequestChoice }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSendRequestChoice((event.target as HTMLInputElement).value as SendRequestChoice);
    };
    return (
        <>
            <Box width={'100%'} height={'100%'} display='flex' flexDirection='column'>
                <Box flexGrow={0}>
                    <Typography variant='h6' align='center' gutterBottom>
                        Send or Request Money
                    </Typography>
                </Box>

                <Box flexGrow={1} mt='2rem' mb='1rem'>
                    <Box>
                        <Typography variant='subtitle1'>
                            <b>What would you like to do? </b>
                        </Typography>
                    </Box>
                    <Box>
                        <FormControl component='fieldset'>
                            <RadioGroup
                                aria-label='gender'
                                name='payment'
                                value={sendRequestChoice}
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    value={SendRequestChoice.SEND}
                                    control={<Radio color='primary' />}
                                    label='Send money'
                                />
                                <FormControlLabel
                                    value={SendRequestChoice.REQUEST}
                                    control={<Radio color='primary' />}
                                    label='Request money'
                                />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box>
            </Box>
        </>
    );
});
