import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      marginBottom: theme.spacing(2),
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
    applicationDrawer: {
      '& .MuiDrawer-paper': {
        [theme.breakpoints.up('md')]: {
          width: '60%',
        },
        [theme.breakpoints.down('md')]: {
          width: '90%',
        },
      },
    },
    root: {
      display: 'flex',
    },
    flexContent: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    summaryAdditionalInfo: {
      display: 'flex',
      '& > *': {
        marginRight: theme.spacing(3),
      },
    },
    headerAvatar: {
      width: '80px',
      height: '80px',
    },
    space: {
      marginBottom: '20px',
    },
    actionButtons: {
      '& > *': {
        margin: theme.spacing(1),
      },
      '& > button': {
        borderRadius: '0'
      }
    },
    progressBarColor: {
      color: '#009688',
    },
    greyTextColor: {
      color: "grey"
    },
    cardRoot: {
      padding: '0px',
      paddingRight: '0px !important',
      backgroundColor: 'transparent'
    },
    gutterBottom: {
      marginBottom: '0.5rem',
    },
    gutterSubtitle: {
      marginTop: '0px',
    },
    ratingContainer: {
      [theme.breakpoints.up("sm")]: {
        textAlign: "center"
      },
    },
    modalContent: {
      minHeight: '50px',
      padding: '16px 24px',
    },
    modalFooter: {
      padding: '16px 24px',
    },
    successColor: {
      color: '#4caf50',
    },
    errorColor: {
      color: '#f44336',
    },
    warningColor: {
      color: '#ffeb3b',
    },
  })
);
