import { GooglePlacesApiKey } from '../appConfig';
import { LoadWindowScript } from './util';
import { retry } from '../utils/retry';
import { AutocompletePrediction, AutocompleteResponse, AutocompletionRequest, FindPlaceFromQueryRequest, GeocoderRequest, GeocoderResponse, GeocoderResult, PlaceResult } from 'realhaus-sdk';

export class GoogleMapsApiService {
  private services = { autocomplete: { current: null }, places: { current: null }, geocoder: { current: null } };

  constructor(private libraryScriptLoaded: boolean = false) {
    this.loadPlacesLibrariesScript();
  }
  init() {
    this.loadPlacesLibrariesScript();
  }
  private loadPlacesLibrariesScript = () => {
    if (typeof window !== 'undefined' && !this.libraryScriptLoaded) {
      if (!document.querySelector('#google-maps') || !document.querySelector('_googleMapsServiceId')) {

        console.log('>> Loading gmapsapi script');
        LoadWindowScript(
          `https://maps.googleapis.com/maps/api/js?key=${GooglePlacesApiKey}&libraries=places`,
          document.querySelector('head'),
          'google-maps',
        );
      }

      this.libraryScriptLoaded = true;
    }
  }

  private get mapElement() {
    const elementId = 'google-maps-element';
    if (typeof window !== 'undefined' && !document.querySelector(`#${elementId}`)) {
      const position = document.querySelector('body');

      const element = document.createElement('div');
      element.setAttribute('id', elementId);
      element.setAttribute('hidden', '');

      position?.append(element);
    }

    return document.getElementById(elementId);
  }

  private initAutocompleteService = () => {
    this.loadPlacesLibrariesScript();
    if (!this.services.autocomplete.current && (window as any).google) {
      this.services.autocomplete.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }

    if (!this.services.autocomplete.current) {
      throw Error('Could not initialize Google Autocomplete service')
    }
  }

  private initPlacesService = () => {
    this.loadPlacesLibrariesScript();
    if (!this.services.places.current && (window as any).google) {
      const map = new (
        window as any
      ).google.maps.Map(this.mapElement)
      this.services.places.current = new (
        window as any
      ).google.maps.places.PlacesService(map);
    }

    if (!this.services.places.current) {
      throw Error('Could not initialize Google Places service')
    }
  }

  private initGeocoderService = () => {
    this.loadPlacesLibrariesScript();
    if (!this.services.geocoder.current && (window as any).google) {
      this.services.geocoder.current = new (
        window as any
      ).google.maps.Geocoder();
    }
    if (!this.services.geocoder.current) {
      throw Error('Could not initialize Google Geocode service');
    }
  }

  getPlacePredictions = (request: AutocompletionRequest, callback?: (results?: AutocompletePrediction[], status?: any) => void): Promise<AutocompleteResponse> => {
    return retry(() => {
      this.initAutocompleteService();
      return (this.services.autocomplete.current as any).getPlacePredictions(request, callback);
    });
  }

  findPlaceFromQuery = (request: FindPlaceFromQueryRequest, callback?: (results?: PlaceResult[], status?: any) => void) => {
    return retry(() => {
      this.initPlacesService();
      return (this.services.places.current as any).findPlaceFromQuery(request, callback);
    });
  }

  geocode = (request: GeocoderRequest, callback?: (results?: GeocoderResult[], status?: any) => void): Promise<GeocoderResponse> => {
    return retry(() => {
      this.initGeocoderService();
      return (this.services.geocoder.current as any).geocode(request, callback);
    });
  }
}