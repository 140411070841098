import React from 'react';
import { observer } from 'mobx-react-lite';
import { Grid } from '@material-ui/core';
import { DueRentCardComponent } from './actionCardsComponents/dueRentCardComponent';
import { ExpiringLeasesCardComponent } from './actionCardsComponents/expiringLeasesCardComponent';
import { ProspectiveTenantsCardComponent } from './actionCardsComponents/prospectiveTenantsCardComponent';
import { ReservedShowings } from './actionCardsComponents/reservedShowings';
import { AccountSetup } from '../../../layouts/auth/accountSetup';
import { ActivityCard } from '../../../components/uiComponents/activityCard';

export const ActionCards: React.FC = observer(() => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid container spacing={1} item md={8} sm={12}>
                    <Grid item xs={12}>
                        <AccountSetup />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <DueRentCardComponent />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <ExpiringLeasesCardComponent />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <ProspectiveTenantsCardComponent />
                    </Grid>
                </Grid>
                <Grid item md={4} sm={12}>
                    <ActivityCard />
                </Grid>
                <Grid item xs={12}>
                    <br />
                    <br />
                </Grid>

                <ReservedShowings />
            </Grid>
        </>
    );
});
