import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Container, Grid, Link, List, ListItem, Typography } from '@material-ui/core';
import {
    Footer,
    FooterContent,
    FooterCopyright,
    FooterDescriptionContent,
    FooterLogo,
} from './styles';
import { RealhausLogo } from './logo';
import { paths } from '../../routes';

const ListLinkItem = (props: { children?: React.ReactNode; link?: string }) => (
    <>
        <ListItem disableGutters={true}>
            <UndecoratedLink link={props.link}>{props.children}</UndecoratedLink>
        </ListItem>
    </>
);

const UndecoratedLink = (props: { children?: React.ReactNode; link?: string }) => {
    return (
        <Link href={props.link ?? '#'} underline='hover' color='textSecondary'>
            {props.children}
        </Link>
    );
};

export const MainFooter: React.FC = observer(() => {
    return (
        <>
            <Footer>
                <Container maxWidth='lg'>
                    <Box pt={3} pb={3} mb={2} mt={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} alignContent='center' justifyContent='center'>
                                <FooterContent>
                                    <FooterLogo>
                                        <RealhausLogo />
                                    </FooterLogo>
                                    <FooterDescriptionContent>
                                        <Typography variant='body2' gutterBottom align='justify'>
                                            We believe owners and tenants have a lot of useful and
                                            helpful information to share. Information about the
                                            resident property, interactions and experiences with
                                            each other.
                                            <br />
                                            <br />
                                            Realhaus is redefining rental experiences between owners
                                            and tenants by providing credible avenues to mitigate
                                            risks and rent comfortably.
                                        </Typography>
                                    </FooterDescriptionContent>
                                    <Typography variant='body2'>
                                        Contact us: hello@realhaus.ca
                                    </Typography>
                                </FooterContent>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Typography variant='subtitle2' gutterBottom>
                                    ABOUT
                                </Typography>
                                <Typography variant='body2' component='div'>
                                    <List disablePadding={true}>
                                        <ListLinkItem>How Realhaus Works</ListLinkItem>
                                        <ListLinkItem>For Owners</ListLinkItem>
                                    </List>
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Typography variant='subtitle2' gutterBottom>
                                    EXPLORE
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Typography variant='subtitle2' gutterBottom>
                                    Legal Terms
                                </Typography>
                                <Typography variant='body2' component='div'>
                                    <List disablePadding={true}>
                                        <ListLinkItem link={paths.privacypolicy}>
                                            Privacy Policy
                                        </ListLinkItem>
                                        <ListLinkItem link={paths.termsofservice}>
                                            Terms of Service
                                        </ListLinkItem>
                                    </List>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <FooterCopyright>
                        <Typography variant='subtitle1' component='div'>
                            &copy; {new Date().getFullYear()} Realhaus Technologies Inc.{' '}
                            {/* <b>&middot; </b>
                            <UndecoratedLink link={paths.privacypolicy}>
                                Privacy
                            </UndecoratedLink>{' '}
                            <b>&middot; </b> <UndecoratedLink>Terms</UndecoratedLink> */}
                        </Typography>
                    </FooterCopyright>
                </Container>
            </Footer>
        </>
    );
});
