import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Typography } from '@material-ui/core';
import { RootStoreContext } from '../../../global/storeContext';
import { GridColumns } from '@material-ui/data-grid';

import { format } from 'date-fns';
import { formatAddress } from '../../../utils/address';
import { paths } from '../../../routes';
import { DataTable } from '../../../components/dataTable/dataTable';
import { IdRentApplication, IdPropertyListing, RentApplicationStatus } from 'realhaus-sdk';

export const MyRentalApplications: React.FC = observer(() => {
    const { tenantStore, listingStore, uiStore } = useContext(RootStoreContext);
    const [rentApplications, setRentApplications] = useState<IdRentApplication[]>([]);
    const [listings, setListings] = useState<Map<string, IdPropertyListing>>(
        new Map<string, IdPropertyListing>(),
    );
    const [loading, setLoading] = useState<boolean>(false);

    const cols: GridColumns = [
        {
            field: 'dateSubmitted',
            headerName: 'Date Submitted',
            valueGetter: (params) => format(params.value as number, 'PP'),
            align: 'center',
            flex: 0.5,
            minWidth: 180,
        },
        {
            field: 'listingId',
            headerName: 'Property',
            valueGetter: (params) => {
                const property = listings.get(params.value as string);
                if (!property) {
                    console.log('Property is not available.');
                    return null;
                }

                return formatAddress(property.address);
            },
            align: 'center',
            flex: 1.5,
            minWidth: 180,
        },
        {
            field: 'status',
            headerName: 'Status',
            valueGetter: (params) => {
                switch (params.value) {
                    case RentApplicationStatus.PENDING:
                        return 'Pending Review';
                    case RentApplicationStatus.REJECTED:
                        return 'Rejected';
                    case RentApplicationStatus.APPROVED:
                        return 'Approved';
                    case RentApplicationStatus.WITHDRAWN:
                        return 'Withdrawn';
                    default:
                        return 'UNKNOWN';
                }
            },
            align: 'center',
            flex: 0.5,
            minWidth: 180,
        },
        {
            field: 'id',
            headerName: 'Action',
            renderCell: (param) => {
                const app = rentApplications.find((app) => app.id === (param.id as string));
                return (
                    <>
                        <Button
                            variant='outlined'
                            size='small'
                            onClick={viewListing(app?.listingId ?? '')}
                        >
                            View Listing
                        </Button>
                        &nbsp;
                        {app?.status === RentApplicationStatus.PENDING ? (
                            <Button
                                variant='outlined'
                                color='secondary'
                                size='small'
                                onClick={withdrawApplication(param.value as string)}
                            >
                                Withdraw
                            </Button>
                        ) : null}
                    </>
                );
            },
            align: 'center',
            sortable: false,
            minWidth: 180,
        },
    ];

    useEffect(() => {
        setLoading(true);

        if (!rentApplications || rentApplications.length === 0) {
            // get user appointments
            tenantStore
                .getMySubmittedRentApplications()
                .then(async (apps) => {
                    if (!!apps) {
                        const ls = listings;
                        const arrApp = await Promise.all(
                            apps.map(async (app) => {
                                const listing = await listingStore.getListing(app.listingId);
                                if (!ls.has(app.listingId) && !!listing) {
                                    const property = await listingStore.getProperty(
                                        listing.propertyId,
                                    );
                                    if (!!property) {
                                        ls.set(app.listingId, listing);
                                        return app;
                                    }
                                }

                                return null;
                            }),
                        );

                        setListings(ls);
                        const filtApp = arrApp.filter((app) => app !== null);
                        setRentApplications(filtApp as IdRentApplication[]);
                    }
                    console.log(`Got ${listings?.size} Properties`);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log('Error getting Submitted application', err);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, []);

    const withdrawApplication = (appId: string) => async () => {
        await tenantStore.withdrawRentApplication(appId);
    };
    const viewListing = (listingId: string) => () => {
        const propertyUrl = paths.listings.detail.replace(':listingId', listingId);
        uiStore.goTo(propertyUrl);
    };

    return (
        <>
            {!rentApplications || rentApplications.length === 0 ? null : (
                <>
                    <Typography variant='h6' gutterBottom>
                        Rental Applications
                    </Typography>
                    <DataTable
                        width='100%'
                        height='400px'
                        loading={loading}
                        rows={rentApplications}
                        pageSize={5}
                        cols={cols}
                    />
                </>
            )}
        </>
    );
});
