import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DocumentUploadDialog } from './documentUploadDialog';

export const DocumentUploadButton: React.FC<{
    leaseId: string;
    updateDocument: () => Promise<void>;
    additionalDocument?: boolean;
    isTerminated?: boolean;
}> = observer(({ leaseId, updateDocument, additionalDocument, isTerminated = false }) => {
    const [docSelectModalOpen, setDocSelectModalOpen] = React.useState<boolean>(false);

    const handleModalClose = () => {
        setDocSelectModalOpen(false);
    };

    return (
        <>
            <Button
                variant='outlined'
                color='primary'
                onClick={() => setDocSelectModalOpen(true)}
                size='small'
                disabled={isTerminated}
            >
                Add a document
            </Button>
            <DocumentUploadDialog
                leaseId={leaseId}
                docSelectModalOpen={docSelectModalOpen}
                handleModalClose={handleModalClose}
                updateDocument={updateDocument}
                additionalDocument={additionalDocument}
            />
        </>
    );
});
