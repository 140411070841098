import React from 'react';
import { createStyles, Tab, Tabs, Theme, withStyles } from '@material-ui/core';

interface StyledTabsProps {
    children?: React.ReactNode;
    value: any;
    onChange: (event: React.ChangeEvent<{}>, newValue: any) => void;
    scrollButtons: boolean;
    centered?: boolean;
}

interface StyledTabProps {
    label: string;
    component?: any;
    id: string;
}

export const StyledTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            maxWidth: 30,
            width: '100%',
            backgroundColor: '#635ee7',
        },
    },
})((props: StyledTabsProps) => {
    const { scrollButtons, ...otherProps } = props;
    return (
        <Tabs
            {...otherProps}
            variant='scrollable'
            scrollButtons={scrollButtons ? 'on' : 'auto'}
            TabIndicatorProps={{ children: <span /> }}
        />
    );
});

export const StyledTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'uppercase',
            fontWeight: 'normal',
            fontSize: theme.typography.pxToRem(13),
            [theme.breakpoints.up('sm')]: {
                fontSize: theme.typography.pxToRem(15), // Adjust font size for small screens and up
            },
            marginRight: theme.spacing(1),
            minWidth: 10,
            '&:focus': {
                opacity: 1,
            },
        },
    }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

export const StyledVerticalTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            height: 30,
            width: '100%',
            backgroundColor: '#635ee7',
        },
    },
})((props: StyledTabsProps) => {
    const { scrollButtons, ...otherProps } = props;
    return (
        <Tabs
            {...otherProps}
            orientation='vertical'
            variant='scrollable'
            scrollButtons={scrollButtons ? 'on' : 'auto'}
            aria-label='vertical-tabs'
            TabIndicatorProps={{ children: <span /> }}
        />
    );
});

export const StyledVerticalTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'uppercase',
            fontWeight: 'normal',
            fontSize: theme.typography.pxToRem(15),
            marginRight: theme.spacing(1),
            minWidth: 10,
            '&:focus': {
                opacity: 1,
            },
        },
        wrapper: {
            alignItems: 'flex-start',
        },
    }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);
