import { styled } from '@material-ui/core/styles';

export const ProgressButton = styled('div')({
  color: 'green[500]',
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
  zIndex: 99999
});