import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Grid, IconButton, Menu, MenuItem, styled } from '@material-ui/core';
import { StyledTab, StyledTabs, StyledVerticalTab, StyledVerticalTabs } from './styledTabs';
import { GridContent, useIsMobible } from './UIComponents';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

export const StepperButtons = styled('div')({
    display: 'flex',
    marginTop: '30px',
    padding: '8px',
    justifyContent: 'flex-end',
});

interface TabComponentProps {
    sections: TabSection[];
    direction?: 'horizontal' | 'vertical';
    saveLabel?: string;
    disableSave?: boolean;
    onSubmit: () => void;
    scrollButtons?: boolean;
    hideStepperButtons?: boolean;
}

export interface TabSection {
    key: string;
    title: string;
    subTitle?: string;
    component: JSX.Element;
    hide?: boolean;
}

export const TabComponent: React.FC<TabComponentProps> = observer(
    ({
        sections,
        direction = 'horizontal',
        onSubmit,
        saveLabel = 'SAVE',
        disableSave,
        scrollButtons = false,
        hideStepperButtons = false,
    }) => {
        const [value, setValue] = React.useState(0);

        const handleChange = (newValue: number) => {
            setValue(newValue);
        };

        return (
            <>
                {direction === 'horizontal' ? (
                    <HorizontalTab
                        sections={sections}
                        value={value}
                        onTabChanged={handleChange}
                        onSubmit={onSubmit}
                        saveLabel={saveLabel}
                        disableSave={disableSave}
                        scrollButtons={scrollButtons}
                        hideStepperButtons={hideStepperButtons}
                    />
                ) : (
                    <VerticalTab
                        sections={sections}
                        value={value}
                        onTabChanged={handleChange}
                        onSubmit={onSubmit}
                        saveLabel={saveLabel}
                        disableSave={disableSave}
                        scrollButtons={scrollButtons}
                        hideStepperButtons={hideStepperButtons}
                    />
                )}
            </>
        );
    },
);

interface DirectionTabsPros {
    sections: TabSection[];
    value: number;
    saveLabel?: string;
    disableSave?: boolean;
    onTabChanged: (newValue: number) => void;
    onSubmit: () => void;
    scrollButtons: boolean;
    hideStepperButtons?: boolean;
}

const HorizontalTab: React.FC<DirectionTabsPros> = observer(
    ({
        sections,
        value,
        onTabChanged,
        onSubmit,
        saveLabel = 'SAVE',
        disableSave,
        scrollButtons,
        hideStepperButtons = false,
    }) => {
        const visibleSections = () => sections.filter((s) => !s.hide);

        const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
            onTabChanged(newValue);
        };

        const handleBack = () => {
            if (value > 0) onTabChanged(value - 1);
        };

        const handleNext = () => {
            if (value < visibleSections().length - 1) {
                onTabChanged(value + 1);
            } else if (value === visibleSections().length - 1) {
                onSubmit();
            }
        };

        const hideSave = disableSave && value === visibleSections().length - 1;

        return (
            <>
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    centered
                    scrollButtons={scrollButtons}
                >
                    {visibleSections().map((section, index) => (
                        <StyledTab
                            label={section.title}
                            key={`tab-${index}`}
                            id={`tab-${index}`}
                            aria-controls={`tab-${index}`}
                        />
                    ))}
                </StyledTabs>
                <Box marginRight='10px' mt={2}>
                    {visibleSections().map((section, index) => (
                        <TabPanelComponent
                            key={`tab-panel-${index}`}
                            id={`tab-panel-${index}`}
                            aria-controls={`tab-panel-${index}`}
                            isActive={value === index}
                            component={section.component}
                        />
                    ))}
                </Box>

                {!hideStepperButtons && (
                    <StepperButtons>
                        {value !== 0 ? (
                            <>
                                <Button
                                    disabled={value === 0}
                                    onClick={handleBack}
                                    style={{
                                        marginRight: '5px',
                                    }}
                                >
                                    <KeyboardArrowLeft />
                                    BACK
                                </Button>
                                {/* <Spacing /> */}
                            </>
                        ) : null}
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleNext}
                            style={
                                !hideSave
                                    ? { marginLeft: '5px' }
                                    : { marginLeft: '5px', display: 'none' }
                            }
                            disabled={hideSave}
                        >
                            {value === visibleSections().length - 1 ? (
                                <>{saveLabel}</>
                            ) : (
                                <>
                                    NEXT <KeyboardArrowRight />
                                </>
                            )}
                        </Button>
                    </StepperButtons>
                )}
            </>
        );
    },
);

const VerticalTab: React.FC<DirectionTabsPros> = observer(
    ({
        sections,
        value,
        onTabChanged,
        onSubmit,
        saveLabel,
        disableSave,
        scrollButtons,
        hideStepperButtons = false,
    }) => {
        const [openMenu, setOpenMenu] = React.useState<null | HTMLElement>(null);

        const visibleSections = () => sections.filter((s) => !s.hide);
        const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
            onTabChanged(newValue);
        };

        const handleBack = () => {
            if (value > 0) onTabChanged(value - 1);
        };

        const handleNext = () => {
            if (value < visibleSections().length - 1) {
                onTabChanged(value + 1);
            } else if (value === visibleSections().length - 1) {
                onSubmit();
            }
        };
        const handleChangeMenuTab = (index: number) => {
            onTabChanged(index);
        };

        const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
            setOpenMenu(event.currentTarget);
        };

        const handleCloseMenu = () => {
            setOpenMenu(null);
        };

        const hideSave = disableSave && value === visibleSections().length - 1;

        const isMobile = useIsMobible();

        return (
            <>
                <GridContent spacing={0}>
                    {!isMobile ? (
                        <>
                            <Grid item xs={12} md={4}>
                                <StyledVerticalTabs
                                    value={value}
                                    onChange={handleChange}
                                    scrollButtons={scrollButtons}
                                >
                                    {visibleSections().map((section, index) => (
                                        <StyledVerticalTab
                                            label={section.title}
                                            key={`tab-vertical-${index}`}
                                            id={`tab-vertical-${index}`}
                                            aria-controls={`tab-vertical-${index}`}
                                        />
                                    ))}
                                </StyledVerticalTabs>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid container item justifyContent='flex-end' xs={12}>
                                <IconButton aria-label='section-menu' onClick={handleOpenMenu}>
                                    <MoreHorizIcon />
                                </IconButton>
                                <Menu
                                    id='simple-menu'
                                    anchorEl={openMenu}
                                    keepMounted
                                    open={Boolean(openMenu)}
                                    onClose={handleCloseMenu}
                                >
                                    {visibleSections().map((section, index) => (
                                        <MenuItem onClick={() => handleChangeMenuTab(index)}>
                                            {section.title}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Grid>
                        </>
                    )}

                    <Grid
                        item
                        xs={12}
                        md={8}
                        style={{
                            height: '100%',
                            border: `${isMobile ? '' : '1px solid #e8e8e8'}`,
                            padding: '10px',
                        }}
                    >
                        <Box>
                            {visibleSections().map((section, index) => (
                                <TabPanelComponent
                                    key={`tab-vertical-panel-${index}`}
                                    id={`tab-vertical-panel-${index}`}
                                    aria-controls={`tab-vertical-panel-${index}`}
                                    isActive={value === index}
                                    component={section.component}
                                />
                            ))}
                        </Box>

                        {!hideStepperButtons && (
                            <StepperButtons>
                                {value !== 0 ? (
                                    <>
                                        <Button
                                            disabled={value === 0}
                                            onClick={handleBack}
                                            style={{
                                                marginRight: '5px',
                                            }}
                                        >
                                            <KeyboardArrowLeft />
                                            BACK
                                        </Button>
                                        {/* <Spacing /> */}
                                    </>
                                ) : null}
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={handleNext}
                                    style={
                                        !hideSave
                                            ? { marginLeft: '5px' }
                                            : { marginLeft: '5px', display: 'none' }
                                    }
                                    disabled={hideSave}
                                >
                                    {value === visibleSections().length - 1 ? (
                                        <>{saveLabel}</>
                                    ) : (
                                        <>
                                            NEXT <KeyboardArrowRight />
                                        </>
                                    )}
                                </Button>
                            </StepperButtons>
                        )}
                    </Grid>
                </GridContent>
            </>
        );
    },
);
const TabPanelComponent: React.FC<{
    id: string;
    isActive: boolean;
    component: JSX.Element;
}> = observer(({ id, isActive, component }) => {
    return (
        <div role='tabpanel' hidden={!isActive} id={id} aria-labelledby={id}>
            {isActive && <Box p={0}>{component}</Box>}
        </div>
    );
});
