import React from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, Typography } from '@material-ui/core';
import { IUserBio } from 'realhaus-sdk';
import { format } from 'date-fns';
import { useStyles } from './styles';

interface TenantContactProps {
    profile: IUserBio;
}

export const TenantContact: React.FC<TenantContactProps> = observer(({ profile }) => {
    const classes = useStyles();
    return (
        <>
            <Grid container spacing={2} alignItems='center' justifyContent='center'>
                {/* First Name */}
                <Grid item xs={6}>
                    <Typography className={classes.heading}>First Name</Typography>
                    <Typography>{profile.firstname ?? ''}</Typography>
                </Grid>
                {/* Last Name */}
                <Grid item xs={6}>
                    <Typography className={classes.heading}>Last Name</Typography>
                    <Typography>{profile.lastname ?? ''}</Typography>
                </Grid>
                {/* Date Of Birth */}
                <Grid item xs={6}>
                    <Typography className={classes.heading}>Date Of Birth</Typography>
                    <Typography>
                        {format(profile?.dateOfBirth ?? Date.now(), 'MMMM dd, yyyy')}
                    </Typography>
                </Grid>
                {/* Gender */}
                <Grid item xs={6}>
                    <Typography className={classes.heading}>Gender</Typography>
                    <Typography>{`${profile.gender ?? ''}`.toUpperCase()}</Typography>
                </Grid>
            </Grid>
        </>
    );
});
