import React from 'react';
import { observer } from 'mobx-react-lite';
import { ILeaseAgreement, ILeaseRule } from 'realhaus-sdk';
import { LibraryComponent } from '../uiComponents/library';

export const LeaseRulesComponent: React.FC<{
    leaseAgreement: ILeaseAgreement;
    updateProperty: (field: keyof ILeaseAgreement) => (value: ILeaseRule[] | []) => void;
    readOnly?: boolean;
}> = observer(({ leaseAgreement, updateProperty, readOnly }) => {
    const handleUpdateProperty = (values: ILeaseRule[] | []) => {
        updateProperty('rules')(values);
    };
    return (
        <>
            <LibraryComponent
                name='House Rules'
                singular='Rule'
                description='In this section, specify some house keeping rules for your tenants. e.g. noise levels, furniture moving, barbecuing etc.'
                items={leaseAgreement.rules}
                updateProperty={handleUpdateProperty}
                readOnly={readOnly}
            />
        </>
    );
});
