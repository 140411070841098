import { Card, CardContent, Typography, CardActions, Button } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { IdRentApplication, RentApplicationStatus } from 'realhaus-sdk';
import { RootStoreContext } from '../../../../global/storeContext';
import { paths } from '../../../../routes';
import { useStyles } from './style';

export const ProspectiveTenantsCardComponent: React.FC = observer(() => {
    const [pendingApplications, setPendingApplications] = useState<IdRentApplication[]>([]);
    const classes = useStyles();
    const { listingStore, uiStore } = useContext(RootStoreContext);

    const fetchPendingRentApplications = async () => {
        const listings = await listingStore.getUserListings();
        if (!listings) return;

        const pApps = [];
        for (const listing of listings) {
            const apps = await listingStore.getApplications(listing.listingId);
            pApps.push(...apps.filter((app) => app.status === RentApplicationStatus.PENDING));
        }

        setPendingApplications(pApps);
        uiStore.hideLoading();
    };

    useEffect(() => {
        uiStore.showLoading();
        fetchPendingRentApplications();
    }, [listingStore]);

    const viewRentApplicants = () => {
        uiStore.goTo(paths.landlord.prospectiveTenant);
    };
    return (
        <>
            <Card variant='elevation' className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <Typography variant='subtitle1' color='textSecondary'>
                        Prospective Tenants
                    </Typography>
                    <Typography variant='h2' component='h2'>
                        {pendingApplications.length}
                    </Typography>
                </CardContent>
                <CardActions className={classes.cardActions}>
                    <Button
                        size='small'
                        color='primary'
                        variant='outlined'
                        disabled={pendingApplications.length === 0}
                        onClick={viewRentApplicants}
                    >
                        Review
                    </Button>
                </CardActions>
            </Card>
        </>
    );
});
