import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%'
    },
    imgNav: {
      justifyContent: 'flex-start',
      alignItems: 'center',
      alignContent: 'center',
      height: '80vh',
      display: 'flex'
    },
    appBar: {
      position: 'relative',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      color: 'inherit',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
);