import { Dialog, DialogContent, DialogActions, Button, Divider, Box } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { IdLeaseAgreement } from 'realhaus-sdk';
import { RootStoreContext } from '../../../../../../global/storeContext';
import { RequestMoney } from './requestMoney';
import { RequestWizardEnd } from './requestWizardEnd';
import { SendMoney } from './sendMoney';
import { SendOrRequest } from './sendOrRequest';
import { SendWizardEnd } from './sendWizardEnd';

export enum RequestWizard {
    START,
    REQUEST,
    REQUESTEND,
}

export enum SendWizard {
    START,
    SEND,
    SENDEND,
}

export enum SendRequestChoice {
    SEND = 'send',
    REQUEST = 'request',
}

interface ISendRequestPayment {
    open: boolean;
    handleCloseModal: () => void;
    onPaymentRequested: () => void;
    tenantName: string | undefined;
    leaseAgreement: IdLeaseAgreement;
}

export const requestPage = [RequestWizard.START, RequestWizard.REQUEST, RequestWizard.REQUESTEND];

export const sendPage = [SendWizard.START, SendWizard.SEND, SendWizard.SENDEND];

export const SendOrRequestPaymentModal: React.FC<ISendRequestPayment> = observer(
    ({ open, handleCloseModal, tenantName, leaseAgreement, onPaymentRequested }) => {
        const [sendRequestChoice, setSendRequestChoice] = React.useState(SendRequestChoice.REQUEST);
        const [service, setService] = React.useState('Extra Services');
        const [amount, setAmount] = React.useState(0);
        const [sendAmount, setSendAmount] = React.useState(0);
        const [sendMoneyNote, setSendMoneyNote] = React.useState<string>('');
        const [paymentDate, setPaymentDate] = React.useState<Date>(new Date(Date.now()));
        const [attachments, setAttachments] = React.useState<File[]>([]);
        const [requestNote, setRequestNote] = React.useState<string>('');
        const [files, setFiles] = React.useState<
            (File & {
                preview: string;
            })[]
        >([]);
        const firstStep =
            sendRequestChoice === SendRequestChoice.REQUEST ? requestPage[0] : sendPage[0];
        const [activeStep, setActiveStep] = React.useState(firstStep);

        const lastStep =
            sendRequestChoice === SendRequestChoice.REQUEST
                ? requestPage[requestPage.length - 1]
                : sendPage[sendPage.length - 1];

        const lastStepIndex =
            sendRequestChoice === SendRequestChoice.REQUEST
                ? requestPage.indexOf(requestPage[requestPage.length - 1])
                : sendPage.indexOf(sendPage[sendPage.length - 1]);
        const indexOfActiveStep = () =>
            sendRequestChoice === SendRequestChoice.REQUEST
                ? requestPage.indexOf(activeStep as RequestWizard)
                : sendPage.indexOf(activeStep as SendWizard);

        const { uiStore, leaseStore } = React.useContext(RootStoreContext);

        const handleReset = () => {
            setSendRequestChoice(SendRequestChoice.REQUEST);
            setService('Extra Services');
            setAmount(0);
            setSendAmount(0);
            setSendMoneyNote('');
            setPaymentDate(new Date(Date.now()));
            setAttachments([]);
            setRequestNote('');
            setFiles([]);
            setActiveStep(firstStep);
        };

        const choiceStepComponents = () => {
            if (sendRequestChoice === SendRequestChoice.REQUEST) {
                return {
                    [RequestWizard.REQUEST]: {
                        component: (
                            <RequestMoney
                                service={service}
                                setService={setService}
                                amount={amount}
                                setAmount={setAmount}
                                paymentDate={paymentDate}
                                setPaymentDate={setPaymentDate}
                                attachments={attachments}
                                setAttachments={setAttachments}
                                setRequestNote={setRequestNote}
                                requestNote={requestNote}
                                files={files}
                                setFiles={setFiles}
                            />
                        ),
                        validate: () => {
                            const isValid = !!service && !!amount && !!paymentDate && !!requestNote;

                            if (!isValid) {
                                uiStore.error('Please fill all inputs');
                            }
                            return Promise.resolve(isValid);
                        },
                    },
                    [RequestWizard.REQUESTEND]: {
                        component: (
                            <RequestWizardEnd
                                tenantName={tenantName}
                                reason={service}
                                amount={amount}
                                notes={requestNote}
                            />
                        ),
                        validate: () => {
                            return Promise.resolve(true);
                        },
                    },
                };
            } else
                return {
                    [SendWizard.SEND]: {
                        component: (
                            <SendMoney
                                sendAmount={sendAmount}
                                setSendMoneyAmount={setSendAmount}
                                setSendMoneyNote={setSendMoneyNote}
                                sendMoneyNote={sendMoneyNote}
                            />
                        ),
                        validate: () => {
                            const isValid = !!sendAmount && !!sendMoneyNote;

                            if (!isValid) {
                                uiStore.error('Please fill all inputs');
                            }
                            return Promise.resolve(isValid);
                        },
                    },
                    [SendWizard.SENDEND]: {
                        component: <SendWizardEnd amount={sendAmount} sendNotes={sendMoneyNote} />,
                        validate: () => {
                            return Promise.resolve(true);
                        },
                    },
                };
        };

        //     stepComponents
        const stepComponents: {
            [key: number]: { component: JSX.Element; validate: () => Promise<boolean> };
        } = {
            [RequestWizard.START]: {
                component: (
                    <SendOrRequest
                        sendRequestChoice={sendRequestChoice}
                        setSendRequestChoice={setSendRequestChoice}
                    />
                ),
                validate: () => {
                    const isValid = !!sendRequestChoice;
                    if (!isValid) {
                        uiStore.error('Please make a choice');
                    }
                    return Promise.resolve(isValid);
                },
            },
            ...choiceStepComponents(),
        };

        const handleNext = async () => {
            const stepIndex = indexOfActiveStep();
            if (!(await stepComponents[activeStep].validate())) {
                return;
            }

            if (stepIndex === lastStepIndex) {
                // End of wizard.
                uiStore.showLoading();
                if (sendRequestChoice === SendRequestChoice.REQUEST) {
                    await leaseStore
                        .requestPayment(
                            leaseAgreement.id,
                            leaseAgreement.listingId,
                            leaseAgreement.tenantIds[0],
                            paymentDate.getTime(),
                            amount,
                            service,
                            requestNote,
                            attachments,
                        )
                        .then((state) => {
                            if (!state) {
                                uiStore.error('Unable to request payment');
                            } else {
                                handleCloseModal();
                                handleReset();
                                uiStore.success('Payment Request made');
                                onPaymentRequested();
                            }
                            uiStore.hideLoading();
                        });

                    return;
                } else {
                    await leaseStore
                        .sendMoney(
                            leaseAgreement.listingId,
                            leaseAgreement.tenantIds[0],
                            sendAmount,
                            sendMoneyNote,
                        )
                        .then(() => {
                            handleCloseModal();
                            handleReset();
                            uiStore.hideLoading();
                            uiStore.success('money sent successfully');
                        });
                }
                uiStore.hideLoading();

                return;
            }

            const step = requestPage[stepIndex + 1];

            setActiveStep(step);
        };

        const handleBack = () => {
            const stepIndex = indexOfActiveStep();
            //         var lastStepIndex = requestPage.indexOf(lastStep);

            if (stepIndex === 0) {
                return;
            }
            const step = requestPage[stepIndex - 1];

            setActiveStep(step);
        };

        return (
            <>
                <Dialog
                    open={open}
                    onClose={handleCloseModal}
                    maxWidth='sm'
                    fullWidth
                    PaperProps={{ style: { padding: '1.5rem', paddingTop: 0 } }}
                >
                    <DialogContent
                        style={{
                            overflow: 'visible',
                        }}
                    >
                        {stepComponents[activeStep].component}
                    </DialogContent>
                    <Divider style={{ margin: '0 1rem', marginTop: '1rem' }} />
                    <DialogActions>
                        <Box
                            style={{
                                width: '100%',
                                padding: '10px',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            {activeStep === firstStep ? (
                                <Button
                                    onClick={() => {
                                        handleCloseModal();
                                        handleReset();
                                    }}
                                    style={{ marginRight: '1rem' }}
                                >
                                    <ChevronLeft /> Back
                                </Button>
                            ) : (
                                <Button onClick={handleBack} style={{ marginRight: '1rem' }}>
                                    <ChevronLeft /> Back
                                </Button>
                            )}

                            {activeStep === lastStep ? (
                                <Button variant='contained' color='primary' onClick={handleNext}>
                                    {sendRequestChoice === SendRequestChoice.REQUEST
                                        ? 'Confirm and request'
                                        : 'Confirm and pay'}
                                </Button>
                            ) : (
                                <Button variant='contained' color='primary' onClick={handleNext}>
                                    Next
                                </Button>
                            )}
                        </Box>
                    </DialogActions>
                </Dialog>
            </>
        );
    },
);
