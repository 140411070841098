import { observer } from 'mobx-react-lite';
import React from 'react';
import { ILeaseAgreement } from 'realhaus-sdk';
import StringUtils from '../../utils/string';
import { format } from 'date-fns';
import { Box, Typography } from '@material-ui/core';

export const RequestedChanges: React.FC<{ leaseAgreement: ILeaseAgreement }> = observer(
    ({ leaseAgreement }) => {
        return (
            <>
                <Box mb={2}>
                    <Typography variant='h6'>Lease Agreement Changes Requested</Typography>
                </Box>
                <Box>
                    {leaseAgreement.changeRequests
                        .slice()
                        .sort((a, b) => b.timestamp - a.timestamp)
                        .map((request) => (
                            <>
                                <Box mb={2}>
                                    <Typography variant='subtitle2'>
                                        {StringUtils.getMonthDay(request.timestamp)} at{' '}
                                        {format(request.timestamp, 'p')}
                                    </Typography>
                                    <Box
                                        py={1}
                                        px={2}
                                        display='flex'
                                        alignItems='center'
                                        bgcolor='secondary.light'
                                        borderRadius='5px'
                                    >
                                        <Typography variant='body1'>{request.message}</Typography>
                                    </Box>
                                </Box>
                            </>
                        ))}
                </Box>
            </>
        );
    },
);
