import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../global/storeContext';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Typography,
    Box,
} from '@material-ui/core';
import { useStyles } from './style';
import StringUtils from '../../utils/string';
import { format } from 'date-fns';
import { parseMessage } from '../../utils/message';

interface InboxConversationProps {
    onConvoSelected: (convoId: string, mobile: boolean) => void;
    mobile?: boolean;
}

export const InboxConversations: React.FC<InboxConversationProps> = observer(
    ({ onConvoSelected, mobile = false }) => {
        const classes = useStyles();
        const { messageStore, userStore } = useContext(RootStoreContext);

        const [members, setMembers] = useState<Map<string, { name: string; avatar: string }>>(
            new Map<string, { name: string; avatar: string }>(),
        );

        useEffect(() => {
            const init = async () => {
                const convos = await messageStore.getConversations();

                // get a unique list of members in convo
                let convoMembers: string[] = [];
                convos?.forEach((convo) => {
                    if (convo.members?.length > 0) convoMembers.push(...convo.members);
                });
                convoMembers = Array.from(new Set(convoMembers));

                // fetch member profiles
                const memberProfiles = await Promise.all(
                    convoMembers.map(async (member) => {
                        const profile = await userStore.getUserProfile(member);
                        return Promise.resolve({
                            id: member,
                            name: profile?.firstname || '',
                            avatar: profile?.photoURL || '',
                        });
                    }),
                );

                const m = new Map<string, { name: string; avatar: string }>();
                memberProfiles.forEach((p) => {
                    m.set(p.id, { name: p.name, avatar: p.avatar });
                });

                setMembers(m);
                messageStore.subscribeConversations();
            };
            init();
        }, []);

        const isLastConvo = (key: number) =>
            messageStore.conversations.length > 1 && key === messageStore.conversations.length - 1;

        return (
            <List>
                {messageStore.conversations
                    .slice()
                    .sort((a, b) => b.lastMessageSentAt - a.lastMessageSentAt)
                    .map((convo, key) => {
                        const uid = userStore.userId;
                        const otherMember = convo.members.filter((m) => m !== uid);
                        const profile = members.get(otherMember[0]);
                        return (
                            <Box
                                key={key}
                                className={`${isLastConvo(key) ? '' : classes.boxBorderBottom} ${
                                    classes.cursorPointer
                                }`}
                                onClick={() => onConvoSelected(convo.id, mobile)}
                                role='link'
                            >
                                <Typography
                                    component='p'
                                    color='textSecondary'
                                    variant='body2'
                                    className={classes.date}
                                >
                                    {(convo.lastMessageSentAt &&
                                        format(convo.lastMessageSentAt, 'PP')) ??
                                        ''}
                                </Typography>

                                <ListItem alignItems='flex-start'>
                                    <ListItemAvatar>
                                        <Avatar alt='Remy Sharp' src={profile?.avatar} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={profile?.name}
                                        secondary={
                                            <React.Fragment>
                                                <Typography component='span' variant='body2'>
                                                    {StringUtils.truncateString(
                                                        parseMessage(
                                                            convo.lastMessage?.message ?? '',
                                                        ),
                                                    )}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            </Box>
                        );
                    })}
            </List>
        );
    },
);
