import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Typography,
    Checkbox,
    FormGroup,
    FormControlLabel,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { RootStoreContext } from '../../../global/storeContext';

export const BackgroundCheckConsentDialog: React.FC<{
    openBackgroundCheckConsentDialog: boolean;
    handleCloseBackgroundCheckConsentDialog: () => void;
}> = observer(({ openBackgroundCheckConsentDialog, handleCloseBackgroundCheckConsentDialog }) => {
    const { uiStore, userStore } = React.useContext(RootStoreContext);
    const [checked, setChecked] = React.useState<boolean>(false);

    const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleSignConsent = async () => {
        await userStore
            .registerbackgroundCheckConsent()
            .then(() => uiStore.success('Signed successfully'));
    };
    return (
        <>
            <Dialog
                open={openBackgroundCheckConsentDialog}
                onClose={handleCloseBackgroundCheckConsentDialog}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                fullWidth
                PaperProps={{ style: { maxWidth: '800px' } }}
            >
                <DialogTitle id='alert-dialog-title'>
                    <Typography align='center' variant='h6'>
                        Background Check Authorization
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        <b>Realhaus Technologies Inc</b> has partnered with{' '}
                        <a href='https://www.certn.co/' style={{ textDecoration: 'none' }}>
                            Certn
                        </a>{' '}
                        to provide background screening services including credit checks in
                        connection with your application. This partnership provides landlords with a
                        convenient way to perform a more informed decision about a tenant. <br />
                        <br />
                        By submitting the application, you authorize{' '}
                        <b>Realhaus Technologies Inc</b>, in conjunction with <b>Certn</b>, to
                        perform a background screening including a credit check and obtain a
                        consumer credit report about you from a credit reporting agency. You
                        understand that this report will be made available to landlords alongside
                        your rent application. This report could be used to make a decision
                        regarding your rental application. <br /> <br />
                        You understand that your credit report will contain information about your
                        credit activity and current credit situation, including but not limited to
                        loans, credit accounts, amounts owed, bankruptcies, public records, payment
                        histories and a credit score. <br /> <br />
                        You understand that this check may have a small impact on your credit score.
                        <br /> <br />
                        You understand that you have the right to revoke this consent in writing at
                        any time. <br /> <br />
                        By checking the box below, you are granting permission for <b>Certn</b>, on
                        behalf of <b> Realhaus Technologies Inc</b> to access this information for
                        the sole purpose indicated above. This authorization will remain in effect
                        for the next 6 months or until you provide written revocation. To revoke,
                        send an email to{' '}
                        <a href='mailto:hello@realhaus.ca' style={{ textDecoration: 'none' }}>
                            hello@realhaus.ca
                        </a>
                    </DialogContentText>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={handleCheckChange}
                                    color='primary'
                                />
                            }
                            label='I consent'
                        />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseBackgroundCheckConsentDialog} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={handleSignConsent} color='primary' disabled={!checked}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});
