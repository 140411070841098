import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import {
    Breadcrumbs,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    TextField,
    Typography,
} from '@material-ui/core';

import { RootStoreContext } from '../../global/storeContext';
import { IUserInfo } from 'realhaus-sdk';
import { useStyles } from './styles';
import { AuthErrors } from '../../constants/authErrors';
import { AdminPageLayout } from '../../components/uiComponents/UIComponents';

const LoginSecurity: React.FC = observer(() => {
    const { userStore } = useContext(RootStoreContext);

    const [profile, setProfile] = useState<IUserInfo>({} as IUserInfo);

    const [openPasswordModal, setOpenPasswordModal] = useState(false);

    const [openEmailModal, setOpenEmailModal] = useState(false);

    const handlePasswordModalClose = () => {
        setOpenPasswordModal(false);
    };
    const handlePasswordModalOpen = () => {
        setOpenPasswordModal(true);
    };

    const handleEmailModalOpen = () => {
        setOpenEmailModal(true);
    };

    const handleEmailModalClose = () => {
        setOpenEmailModal(false);
    };

    const classes = useStyles();

    useEffect(() => {
        if (!!userStore.userInfo) {
            setProfile(userStore.userInfo);
        }
    }, []);

    return (
        <>
            <List className={classes.container}>
                {/* Email */}
                <ListItem>
                    <ListItemText
                        primary={
                            <Typography variant='subtitle2' gutterBottom>
                                Email:
                            </Typography>
                        }
                        secondary={
                            <Typography variant='body2' gutterBottom>
                                {profile.email ?? ''}
                            </Typography>
                        }
                    />
                    <ListItemSecondaryAction>
                        <Button variant='contained' onClick={handleEmailModalOpen}>
                            Edit
                        </Button>
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider className={classes.divider} />
                {/* Password */}
                <ListItem>
                    <ListItemText
                        primary={
                            <Typography variant='subtitle2' gutterBottom>
                                Password:
                            </Typography>
                        }
                        secondary={
                            <Typography variant='body2' gutterBottom>
                                ***************
                            </Typography>
                        }
                    />
                    <ListItemSecondaryAction>
                        <Button variant='contained' onClick={handlePasswordModalOpen}>
                            Edit
                        </Button>
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider className={classes.divider} />
                {/* Two-step Verification */}
                <ListItem>
                    <ListItemText
                        primary={
                            <Typography variant='subtitle2' gutterBottom>
                                Two-Step Verification:
                            </Typography>
                        }
                        secondary={
                            <Typography variant='body2' gutterBottom>
                                Configure your Two Step Verification Authenticators
                            </Typography>
                        }
                    />
                    <ListItemSecondaryAction>
                        <Button variant='contained' disabled>
                            Edit
                        </Button>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
            <PasswordModal
                openPasswordModal={openPasswordModal}
                handlePasswordModalClose={handlePasswordModalClose}
            />
            <EmailModal
                openEmailModal={openEmailModal}
                handleEmailModalClose={handleEmailModalClose}
            />
        </>
    );
});

const PasswordModal: React.FC<{
    openPasswordModal: boolean;
    handlePasswordModalClose: () => void;
}> = observer(({ openPasswordModal, handlePasswordModalClose }) => {
    const { uiStore, userStore } = useContext(RootStoreContext);

    const [curPassword, setCurPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [changesMade, setChangesMade] = useState(false);

    const handleCurPassword = (e: React.ChangeEvent<{ value: unknown }>) => {
        setCurPassword(e.target.value as string);
        setChangesMade(true);
    };
    const handleNewPassword = (e: React.ChangeEvent<{ value: unknown }>) => {
        setNewPassword(e.target.value as string);
        setChangesMade(true);
    };
    const handleConfirmPassword = (e: React.ChangeEvent<{ value: unknown }>) => {
        setConfirmPassword(e.target.value as string);
        setChangesMade(true);
    };

    const handleSave = async () => {
        uiStore.showLoading();
        if (newPassword === confirmPassword && !!newPassword && !!confirmPassword) {
            if (curPassword) {
                userStore
                    .updatePassword(curPassword, newPassword)
                    .then(() => {
                        uiStore.success('Your password has been successfully updated');
                        handlePasswordModalClose();
                    })
                    .catch((err) => {
                        if (!!err?.code) {
                            const authErr = AuthErrors.find((au) => au.code === err.code);
                            if (!!authErr) {
                                uiStore.error(authErr.message);
                            } else {
                                uiStore.error(
                                    'Uh-Oh! We were unable to update your password.Try again later',
                                );
                            }
                        }
                    })
                    .finally(() => {
                        uiStore.hideLoading();
                    });
            } else {
                uiStore.error("current password field can't be empty");
                uiStore.hideLoading();
            }
        } else {
            uiStore.error(' Your new passwords do not match');
            uiStore.hideLoading();
        }
    };

    return (
        <>
            <Dialog
                open={openPasswordModal}
                onClose={handlePasswordModalClose}
                maxWidth='sm'
                fullWidth={true}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
            >
                <DialogTitle>Change Your Password</DialogTitle>
                <DialogContent>
                    <DialogContentText gutterBottom>
                        Use the form below to change the password for your account
                    </DialogContentText>
                    <form noValidate autoComplete='off'>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='subtitle2'>Current Password</Typography>
                                <TextField
                                    variant='outlined'
                                    type='password'
                                    required
                                    fullWidth
                                    size='small'
                                    value={curPassword}
                                    onChange={handleCurPassword}
                                ></TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='subtitle2'>New Password</Typography>
                                <TextField
                                    variant='outlined'
                                    type='password'
                                    required
                                    fullWidth
                                    size='small'
                                    value={newPassword}
                                    onChange={handleNewPassword}
                                ></TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='subtitle2'>Reenter new Password</Typography>
                                <TextField
                                    variant='outlined'
                                    type='password'
                                    required
                                    fullWidth
                                    size='small'
                                    value={confirmPassword}
                                    onChange={handleConfirmPassword}
                                ></TextField>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePasswordModalClose} color='primary'>
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        variant='contained'
                        onClick={handleSave}
                        disabled={!changesMade || uiStore.loading}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

// Change email modal
const EmailModal: React.FC<{ openEmailModal: boolean; handleEmailModalClose: () => void }> =
    observer(({ openEmailModal, handleEmailModalClose }) => {
        const { uiStore, userStore } = useContext(RootStoreContext);
        const [changesMade, setChangesMade] = useState(false);
        const [newEmail, setNewEmail] = useState('');
        const [confirmEmail, setConfirmEmail] = useState('');

        const [profile, setProfile] = useState<IUserInfo>({} as IUserInfo);

        const handleNewEmailInput = (e: React.ChangeEvent<{ value: unknown }>) => {
            setNewEmail(e.target.value as string);
            setChangesMade(true);
        };

        const handleConfirmInput = (e: React.ChangeEvent<{ value: unknown }>) => {
            setConfirmEmail(e.target.value as string);
            setChangesMade(true);
        };

        const handleSaveEmail = async () => {
            if (newEmail === confirmEmail && !!newEmail && !!confirmEmail) {
                uiStore.showLoading();

                try {
                    userStore
                        .emailUpdate(newEmail)
                        .then(() => {
                            uiStore.success('Email update successful');
                            uiStore.hideLoading();
                            handleEmailModalClose();
                        })
                        .catch((err) => {
                            if (!!err?.code) {
                                const authError = AuthErrors.find((au) => au.code === err.code);
                                if (!!authError) {
                                    uiStore.error(authError.message);
                                } else {
                                    uiStore.error(
                                        'Uh-Oh we were unable to update your email,Try again later',
                                    );
                                }
                            }
                        });
                } catch (err) {
                } finally {
                    uiStore.hideLoading();
                }
            } else {
                uiStore.error('Your new emails do not match');
                uiStore.hideLoading();
            }
        };

        useEffect(() => {
            if (!!userStore.userInfo) {
                setProfile(userStore.userInfo);
            }
        }, []);
        return (
            <>
                <Dialog
                    open={openEmailModal}
                    onClose={handleEmailModalClose}
                    maxWidth='sm'
                    fullWidth={true}
                    aria-labelledby='simple-modal-title'
                    aria-describedby='simple-modal-description'
                >
                    <DialogTitle>Change your email address</DialogTitle>
                    <DialogContent>
                        <DialogContentText gutterBottom>
                            Enter the email address you would like to associate with your account
                            below. <br />
                            Please note updating your email address will sign you out. <br /> <br />
                            <b>Current Email Address:</b> {profile.email}
                        </DialogContentText>
                        <DialogContentText gutterBottom></DialogContentText>
                        <form noValidate autoComplete='off'>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle2'>New Email Address</Typography>
                                    <TextField
                                        variant='outlined'
                                        type='email'
                                        required
                                        fullWidth
                                        size='small'
                                        value={newEmail}
                                        onChange={handleNewEmailInput}
                                    ></TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle2'>
                                        Reenter new Email Address
                                    </Typography>
                                    <TextField
                                        variant='outlined'
                                        type='email'
                                        required
                                        fullWidth
                                        size='small'
                                        value={confirmEmail}
                                        onChange={handleConfirmInput}
                                    ></TextField>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleEmailModalClose} color='primary'>
                            Cancel
                        </Button>
                        <Button
                            color='primary'
                            variant='contained'
                            onClick={handleSaveEmail}
                            disabled={!changesMade || uiStore.loading}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    });

export const LoginSecurityPage: React.FC = observer(() => {
    return (
        <>
            <AdminPageLayout
                header={
                    <div>
                        <Breadcrumbs separator='›' aria-label='breadcrumb'>
                            <Link color='inherit' href='/account'>
                                Account
                            </Link>
                            <Typography color='textPrimary'>Login &amp; Security</Typography>
                        </Breadcrumbs>
                        <Typography variant='h4' gutterBottom>
                            Login &amp; Security
                        </Typography>
                    </div>
                }
                content={<LoginSecurity />}
                maxWidth='md'
            />
        </>
    );
});
