import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { WizardStep } from '../../interfaces/wizard';
import {
    Button,
    Grid,
    Step,
    StepLabel,
    Stepper,
    Paper,
    Collapse,
    IconButton,
    Divider,
    Typography,
} from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { createStyles, makeStyles, styled, Theme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

export const FullWidth = styled('span')({
    width: '100%',
});

export const Spacing = styled('div')({
    flex: '1 1 auto',
});

export const StepperButtons = styled('div')({
    display: 'flex',
    marginTop: '30px',
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(4, 4),
            height: '80vh',
        },
        paper: {
            padding: theme.spacing(2, 4),
            height: '100%',
            margin: 'auto',
        },
    }),
);

export interface WizardComponentProps {
    title: JSX.Element | string;
    steps: WizardStep[];
    finishLabel?: string;
    onSubmit: () => void;
    onCancel: () => void;
}

export const WizardComponent: React.FC<WizardComponentProps> = observer(
    ({ title, steps, finishLabel = 'FINISH', onSubmit, onCancel }) => {
        const [activeStep, setActiveStep] = useState(steps[0].step);
        const [openError, setOpenError] = useState(false);
        const classes = useStyles();

        const showError = () => {
            setOpenError(true);
            setTimeout(() => {
                setOpenError(false);
            }, 5000);
        };

        const handleNext = () => {
            if (!steps.find((x) => x.step === activeStep)?.validate()) {
                showError();
                return;
            }

            if (activeStep === steps[steps.length - 1].step) {
                // finish
                onSubmit();
            } else {
                // let newSkipped = skipped;
                // if (isStepSkipped(activeStep)) {
                //     newSkipped = new Set(newSkipped.values());
                //     newSkipped.delete(activeStep);
                // }

                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                // setSkipped(newSkipped);
            }
        };

        const handleBack = () => {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        };

        return (
            <Grid
                container
                spacing={3}
                justifyContent='center'
                alignItems='center'
                className={classes.root}
            >
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant='h5' gutterBottom>
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton onClick={onCancel} style={{ float: 'right' }}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
                <Grid item xs={10}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((value) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: { optional?: React.ReactNode } = {};

                            return (
                                <Step key={value.label} {...stepProps}>
                                    <StepLabel {...labelProps}>{value.label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Grid>
                <Grid item xs={11}>
                    <Collapse in={openError}>
                        <Alert variant='outlined' severity='error'>
                            Incomplete fields. Check that you have completed all fields before
                            proceeding
                        </Alert>
                    </Collapse>
                </Grid>
                <Grid item xs={11} style={{ minHeight: '100%' }}>
                    <Paper
                        variant='outlined'
                        square
                        className={classes.paper}
                        style={{ height: '100%' }}
                    >
                        <Grid
                            container
                            spacing={2}
                            justifyContent='center'
                            style={{ height: 'calc(100% - 60px)' }}
                        >
                            <Grid item xs={12}>
                                {steps.find((x) => x.step === activeStep)?.component}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center'>
                            <Grid item>
                                <StepperButtons>
                                    {activeStep !== steps[0].step ? (
                                        <>
                                            <Button
                                                disabled={activeStep === steps[0].step}
                                                onClick={handleBack}
                                                style={{ marginRight: '5px' }}
                                            >
                                                <KeyboardArrowLeft />
                                                BACK
                                            </Button>
                                            <Spacing />
                                        </>
                                    ) : null}
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={handleNext}
                                        style={{ marginLeft: '5px' }}
                                    >
                                        {activeStep === steps[steps.length - 1].step ? (
                                            <>{finishLabel}</>
                                        ) : (
                                            <>
                                                NEXT <KeyboardArrowRight />
                                            </>
                                        )}
                                    </Button>
                                </StepperButtons>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    },
);
