import React, { useEffect, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { IdLeaseAgreement, LeaseAgreementStatus, IAddress, ITenantInfo } from 'realhaus-sdk';
import { RightDrawer } from '../uiComponents/rightDrawer';
import { RootStoreContext } from '../../global/storeContext';
import { streetAddress } from '../../utils/address';
import { GridContent } from '../uiComponents/UIComponents';
import { LeaseAgreementReadView } from './leaseAgreementReadView';
import { TenantRequestLeaseChangesDialog, TenantSignLeaseAgreementDialog } from './dialog';

interface LeaseAgreementProps {
    leaseAgreementId?: string;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
}

export const LeaseAgreementTenantComponent: React.FC<LeaseAgreementProps> = observer(
    ({ leaseAgreementId, isOpen, onOpen, onClose }) => {
        const { leaseStore, uiStore } = useContext(RootStoreContext);
        const [leaseAgreement, setLeaseAgreement] = useState<IdLeaseAgreement>();
        const [tenant, setTenant] = useState<ITenantInfo>();

        const handleClose = () => {
            onClose();
        };

        useEffect(() => {
            const init = () => {
                if (!leaseAgreementId) {
                    setLeaseAgreement(undefined);
                    handleClose();
                    return;
                }

                // get lease agreement
                leaseStore.getLeaseAgreementById(leaseAgreementId).then(async (l) => {
                    setLeaseAgreement(l);

                    if (!l || !l.tenantIds || l.tenantIds.length === 0) return;
                    // get tenant
                    const tenantId = l.tenantIds[0];

                    const tenantInfo = await leaseStore.getTenantInfo(tenantId);
                    setTenant(tenantInfo);
                });
            };

            init();
        }, [leaseAgreementId]);

        const signLeaseAgreement = () => {
            if (
                !leaseAgreement?.tenantIds ||
                leaseAgreement?.tenantIds.length === 0 ||
                !leaseAgreementId
            ) {
                uiStore.error('No tenant(s) attached to this Lease Agreement!');
                return;
            }
            uiStore.showLoading();
            leaseStore
                .signLeaseAgreement(leaseAgreementId, leaseAgreement)
                .then(async (signedLeaseAgreement) => {
                    setLeaseAgreement(signedLeaseAgreement);
                    uiStore.success('Lease agreement has been signed');
                    uiStore.hideLoading();
                })
                .catch((err) => {
                    console.error(err);
                    uiStore.hideLoading();
                    uiStore.error('Failed to sign the lease agreement!');
                })
                .finally(() => {
                    handleClose();
                });
        };

        const requestLeasAgreementChanges = (change: string) => {
            if (
                !leaseAgreement?.tenantIds ||
                leaseAgreement?.tenantIds.length === 0 ||
                !leaseAgreementId
            ) {
                uiStore.error('No tenant(s) attached to this Lease Agreement!');
                return;
            }
            uiStore.showLoading();
            leaseStore
                .requestChange(leaseAgreementId, leaseAgreement, change)
                .then(async (updatedLeaseAgreement) => {
                    setLeaseAgreement(updatedLeaseAgreement);
                    uiStore.success('Lease Change Request has been sent');
                    uiStore.hideLoading();
                })
                .catch((err) => {
                    console.error(err);
                    uiStore.hideLoading();
                    uiStore.error('Failed to send the Change Request!');
                })
                .finally(() => {
                    handleClose();
                });
        };

        return (
            <>
                <RightDrawer isOpen={isOpen} onOpen={onOpen} onClose={handleClose}>
                    <Grid container direction='column' spacing={3}>
                        <Grid item>
                            {!!tenant && !!leaseAgreement && (
                                <>
                                    <Box mb={2}>
                                        <LeaseAgreementHeader
                                            tenant={{
                                                firstname: tenant.firstname,
                                                lastname: tenant.lastname,
                                            }}
                                            listingAddress={leaseAgreement.listingInfo.address}
                                            status={leaseAgreement.status}
                                        />
                                    </Box>

                                    <LeasePreviewComponent
                                        leaseAgreement={leaseAgreement}
                                        handleSignLeaseAgreement={signLeaseAgreement}
                                        handleRequestChanges={requestLeasAgreementChanges}
                                    />
                                </>
                            )}
                        </Grid>
                        <Grid item></Grid>
                    </Grid>
                </RightDrawer>
            </>
        );
    },
);

const LeaseAgreementHeader: React.FC<{
    tenant: { firstname: string; lastname: string };
    listingAddress: IAddress;
    status: LeaseAgreementStatus;
}> = observer(({ tenant, listingAddress, status }) => {
    return (
        <>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant='body1'>Lease agreement for</Typography>
                    <Typography variant='h5'>
                        {tenant.firstname} {tenant.lastname}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={6}
                    direction='column'
                    container
                    justifyContent='center'
                    alignItems='flex-end'
                >
                    <Box>
                        <Typography variant='h6' align='right'>
                            {streetAddress(listingAddress)}
                            <br />
                            {listingAddress.city}, {listingAddress.province}
                        </Typography>
                    </Box>

                    <Box>
                        {status === LeaseAgreementStatus.CANCELLED && (
                            <Typography variant='subtitle1' color={'secondary'} gutterBottom>
                                This lease agreement is cancelled
                            </Typography>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
});

interface ILeasePreviewComponentProps {
    leaseAgreement: IdLeaseAgreement;
    handleSignLeaseAgreement: () => void;
    handleRequestChanges: (data: string) => void;
}
const LeasePreviewComponent: React.FC<ILeasePreviewComponentProps> = observer(
    ({ leaseAgreement, handleSignLeaseAgreement, handleRequestChanges }) => {
        const [signatureRequestDialogOpen, setSignatureRequestDialogOpen] = React.useState(false);
        const [requestChangesDialogOpen, setRequestChangesDialogOpen] = React.useState(false);

        const openRequestChangesDialog = () => {
            setRequestChangesDialogOpen(true);
        };

        const closeRequestChangesDialog = () => {
            setRequestChangesDialogOpen(false);
        };

        const openSignatureRequestDialog = () => {
            setSignatureRequestDialogOpen(true);
        };

        const closeSignatureRequestDialog = () => {
            setSignatureRequestDialogOpen(false);
        };

        const agreementStatusMessage = () => {
            switch (leaseAgreement.status) {
                case LeaseAgreementStatus.DRAFT: {
                    return 'This lease agreement is in progress.';
                }
                case LeaseAgreementStatus.PENDING_TENANT_SIGNATURE: {
                    return 'Waiting for tenant to sign.';
                }
                case LeaseAgreementStatus.SIGNED: {
                    return 'This lease agreement has been signed.';
                }
                default: {
                    return '';
                }
            }
        };

        return (
            <>
                <GridContent spacing={2}>
                    <Grid
                        container
                        item
                        justifyContent='flex-end'
                        style={{ width: '100%', display: 'flex' }}
                    >
                        {leaseAgreement.status === LeaseAgreementStatus.PENDING_TENANT_SIGNATURE ? (
                            <Grid
                                container
                                item
                                spacing={0}
                                justifyContent='space-between'
                                style={{ width: '100%', display: 'flex' }}
                            >
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        style={{ borderRadius: '0' }}
                                        onClick={openRequestChangesDialog}
                                    >
                                        Request Changes
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        style={{ borderRadius: '0' }}
                                        onClick={openSignatureRequestDialog}
                                    >
                                        Sign Lease
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            <Typography variant='subtitle1' color='primary'>
                                {agreementStatusMessage()}
                            </Typography>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                            height: '80vh',
                            width: '100%',
                            overflow: 'auto',
                            border: '1px solid #e8e8e8',
                        }}
                    >
                        <LeaseAgreementReadView leaseAgreement={leaseAgreement} />
                    </Grid>
                    {/* TODO: add preview of lease documents here if they exist */}
                </GridContent>

                {/* Tenant confirms and signs lease agreement */}
                <TenantSignLeaseAgreementDialog
                    dialogOpen={signatureRequestDialogOpen}
                    closeDialog={closeSignatureRequestDialog}
                    onAccept={() => {
                        closeSignatureRequestDialog();
                        handleSignLeaseAgreement();
                    }}
                />

                {/* Tenant required some changes in the lease agreement from the landlord */}
                <TenantRequestLeaseChangesDialog
                    dialogOpen={requestChangesDialogOpen}
                    closeDialog={closeRequestChangesDialog}
                    onAccept={(data) => {
                        closeRequestChangesDialog();
                        handleRequestChanges(data);
                    }}
                />
            </>
        );
    },
);
