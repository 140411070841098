import React from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, InputAdornment, MenuItem, TextField, Typography } from '@material-ui/core';
import { IOccupation } from 'realhaus-sdk';

interface TenantEmploymentProps {
    employment: IOccupation;
    updateEmployment: (
        prop: keyof IOccupation,
    ) => (event: React.ChangeEvent<{ value: unknown }>) => void;
}

export const TenantEmployment: React.FC<TenantEmploymentProps> = observer(
    ({ employment, updateEmployment }) => {
        return (
            <>
                <Grid container spacing={2} alignItems='center' justifyContent='center'>
                    {/* Gender */}
                    <Grid item xs={12}>
                        <Typography variant='subtitle2' gutterBottom>
                            Employment Status
                        </Typography>
                        <TextField
                            select
                            margin='normal'
                            id='status'
                            value={employment?.status ?? ''}
                            onChange={updateEmployment('status')}
                            fullWidth
                        >
                            <MenuItem value='employed'>Employed</MenuItem>
                            <MenuItem value='self-employed'>Self-Employed</MenuItem>
                            <MenuItem value='retired'>Retired</MenuItem>
                            <MenuItem value='unemployed'>Unemployed</MenuItem>
                            <MenuItem value='student'>Student</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='subtitle2' gutterBottom>
                            Employer
                        </Typography>
                        <TextField
                            id='employer'
                            placeholder='Employer'
                            fullWidth
                            margin='dense'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={employment?.employer ?? ''}
                            onChange={updateEmployment('employer')}
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='subtitle2' gutterBottom>
                            Manager
                        </Typography>
                        <TextField
                            id='manager'
                            placeholder='Supervisor/Manager Name'
                            fullWidth
                            margin='dense'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={employment?.manager ?? ''}
                            onChange={updateEmployment('manager')}
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='subtitle2' gutterBottom>
                            Type of Business
                        </Typography>
                        <TextField
                            id='businessType'
                            placeholder='Type of Business'
                            fullWidth
                            margin='dense'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={employment?.businessType ?? ''}
                            onChange={updateEmployment('businessType')}
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='subtitle2' gutterBottom>
                            Job Title
                        </Typography>
                        <TextField
                            id='title'
                            placeholder='Job Title'
                            fullWidth
                            margin='dense'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={employment?.title ?? ''}
                            onChange={updateEmployment('title')}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='subtitle2' gutterBottom>
                            Employment Income
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle2' gutterBottom>
                            Income
                        </Typography>
                        <TextField
                            id='income'
                            placeholder='e.g. 1400'
                            fullWidth
                            margin='dense'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                            }}
                            value={employment?.income ?? ''}
                            onChange={updateEmployment('income')}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle2' gutterBottom>
                            Income Type
                        </Typography>
                        <TextField
                            select
                            margin='normal'
                            id='incomeType'
                            value={employment?.incomeType ?? ''}
                            onChange={updateEmployment('incomeType')}
                            fullWidth
                        >
                            <MenuItem value='weekly'>Weekly</MenuItem>
                            <MenuItem value='bi-weekly'>Bi Weekly</MenuItem>
                            <MenuItem value='monthly'>Monthly</MenuItem>
                            <MenuItem value='yearly'>Yearly</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
            </>
        );
    },
);
