import { makeAutoObservable } from 'mobx';
import { ITenantProfile, IRentApplication, IdRentApplication, IRentApplicationForm, RentApplicationStatus, IUserBio, LeaseAgreementStatus } from 'realhaus-sdk';
import { Collections, WhereQueryOperation } from './dbStore';
import { IProspectiveTenant, IdProspectiveTenant } from 'realhaus-sdk'
import FirebaseStore from './firebaseStore';

export class TenantStore {

  constructor(private fireStore: FirebaseStore) {
    makeAutoObservable(this);
  }

  getProfile = async (tenantId: string) => {
    return (await this.fireStore.getDocument(Collections.tenantProfiles, tenantId)).data() as ITenantProfile;
  }

  getUserBio = async (tenantId: string) => {
    return (await this.fireStore.getDocument(Collections.users, tenantId)).data() as IUserBio;
  }

  getTenantProfile = async () => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    try {
      const profile = (await this.fireStore.getDocument(Collections.tenantProfiles, uid)).data() as ITenantProfile;
      return profile;
    } catch (err) {
      console.log('>> Error getting tenant profile: ', err);
    }
  }

  updateProfile = async (profile: ITenantProfile) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    await this.fireStore.updateDocument(Collections.tenantProfiles, uid, profile);
  }

  createProspectiveTenantInvite = async (prospectiveTenant: IProspectiveTenant) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return
    await this.fireStore.addDocument(`${Collections.prospectiveTenants}/${uid}/${Collections.invites}`, prospectiveTenant)
  }

  getProspectiveTenantsInvites = async () => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return
    const inviteRef = await this.fireStore.getDocuments(`${Collections.prospectiveTenants}/${uid}/${Collections.invites}`)
    const invites = inviteRef.docs.map(doc => ({ id: doc.id, ...(doc.data() as IProspectiveTenant) } as IdProspectiveTenant))
    return invites;
  }

  deleteProspectiveTenantInvite = async (inviteId: string) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid || !inviteId) return

    return this.fireStore.deleteDocument(`${Collections.prospectiveTenants}/${uid}/${Collections.invites}`, inviteId);
  }

  getRentApplication = async (listingId: string) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return;
    var query = [
      new WhereQueryOperation('tenantId', '==', uid),
      new WhereQueryOperation('listingId', '==', listingId),
    ];

    return (await this.fireStore.findDocuments(Collections.rentApplications, query)).docs.map(doc => ({ id: doc.id, ...doc.data() as IRentApplication } as IdRentApplication));
  }

  getMySubmittedRentApplications = async () => {
    const uid = await this.fireStore.authService.currentUser?.uid;

    if (!uid) return;
    var query = [
      new WhereQueryOperation('tenantId', '==', uid),
    ];

    return (await this.fireStore.findDocuments(Collections.rentApplications, query)).docs.map(doc => ({ id: doc.id, ...doc.data() as IRentApplication } as IdRentApplication));
  }

  submitRentApplication = async (listingId: string, rentApplicationForm: IRentApplicationForm) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    const existing = (await this.getRentApplication(listingId))?.some(app => app.status === RentApplicationStatus.PENDING);

    if (existing) {
      throw new Error('You have already submitted an application for this listing.');
    }

    const alreadyLeased = (await this.fireStore.findDocuments(Collections.leaseAgreements, [
      new WhereQueryOperation('listingId', '==', listingId),
      new WhereQueryOperation('status', '==', LeaseAgreementStatus.SIGNED)
    ])).size > 0;

    if (alreadyLeased) {
      throw new Error('This listing has already been leased.');
    }

    const application = {
      listingId,
      tenantId: uid,
      application: rentApplicationForm,
      dateSubmitted: Date.now(),
      status: RentApplicationStatus.PENDING
    } as IRentApplication;

    await this.fireStore.addDocument(Collections.rentApplications, application);
    return application;

  }

  withdrawRentApplication = async (applicationId: string) => {
    if (!applicationId) return;
    await this.fireStore.updateDocument(Collections.rentApplications, applicationId, { status: RentApplicationStatus.WITHDRAWN, statusDate: Date.now() } as Partial<IRentApplication>)
  }
}