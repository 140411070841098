import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useContext, useState } from 'react';
import { PaypalClientId, StripePaymentLink } from '../../appConfig';
import { RootStoreContext } from '../../global/storeContext';
import { InputFieldMoney } from '../formComponents/inputfieldmoney';
import { FundSourceTypes } from '../../enums/FundSource';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { PaypalButton } from './paypal/paypalButton';
import {
    LargeInteracIcon,
    SmallInteracIcon,
    SmallPayPalIcon,
    SmallStripeIcon,
} from '../icon/vendorIcons';
import { RightDrawer } from '../uiComponents/rightDrawer';
import { NumericFormat } from 'react-number-format';
import { useFlags } from 'launchdarkly-react-client-sdk';

const paypalInitialOptions = {
    'client-id': PaypalClientId ?? '',
    currency: 'CAD',
    intent: 'capture',
};

export const FundWalletCheckout: React.FC<{
    open: boolean;
    handleClose: () => void;
    handleComplete: () => void;
    handleFailure: () => void;
}> = observer(({ open, handleClose, handleComplete, handleFailure }) => {
    const { uiStore, userStore, walletStore, analyticsStore } = useContext(RootStoreContext);
    const [amount, setAmount] = useState(0);
    const [disableFunding, setDisableFunding] = useState(true);
    const [openPaypalModal, setOpenPaypalModal] = useState(false);
    const [openInteracModal, setOpenInteracModal] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState<FundSourceTypes>();
    const [feeBreakdown, setFeeBreakdown] =
        useState<{ [key in FundSourceTypes]: { fee: number; netAmount: number } }>();
    const {
        paymentOptionPaypalDisabled,
        paymentOptionStripeDisabled,
        paymentOptionInteracDisabled,
    } = useFlags();

    const netAmount =
        (feeBreakdown && selectedVendor && feeBreakdown[selectedVendor].netAmount) ?? 0;

    const handleAmountChange = (value: string | number | null) => {
        const updatedAmount = Number(value);
        if (isNaN(updatedAmount) || updatedAmount < 0) return;
        setAmount(updatedAmount);
        const vendorFeeBreakdown = walletStore.getFundWalletFeeBreakdown(updatedAmount);
        setFeeBreakdown({ ...vendorFeeBreakdown });
        if (selectedVendor && disableFunding) {
            setDisableFunding(false);
        }
    };

    const handleVendorChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
        if (FundSourceTypes.INTERAC === value) {
            setSelectedVendor(FundSourceTypes.INTERAC);
        } else if (FundSourceTypes.STRIPE === value) {
            setSelectedVendor(FundSourceTypes.STRIPE);
        } else if (FundSourceTypes.PAYPAL === value) {
            setSelectedVendor(FundSourceTypes.PAYPAL);
        }
        if (amount > 0 && disableFunding) {
            setDisableFunding(false);
        }
    };

    const handleResetCheckout = () => {
        handleClose();

        setAmount(0);
        setDisableFunding(true);
        setFeeBreakdown(undefined);
        setSelectedVendor(FundSourceTypes.INTERAC);
    };

    const handleOpenStripe = () => {
        window.open(
            `${StripePaymentLink}?client_reference_id=${userStore.userInfo?.uid}&prefilled_email=${userStore.userInfo?.email}`,
            '_blank',
        );
        handleResetCheckout();
    };

    const handleOpenPaypal = () => {
        // handleClose(); // NOTE: Leave Checkout page open for now
        setOpenPaypalModal(true);
    };

    const handleClosePaypal = () => {
        setOpenPaypalModal(false);
    };

    const handleOpenInterac = () => {
        setOpenInteracModal(true);
        uiStore.showLoading();
        walletStore
            .fundWalletViaInterac(netAmount)
            .then((data) => {
                if (data) {
                    // send user to payment url
                    // An email containing the payment url will be sent to the user's email as well
                    window.open(data.paymentUrl, '_blank');
                    handleResetCheckout();
                    analyticsStore.trackFundWallet(netAmount, 'interac');
                } else {
                    uiStore.error(
                        'Unable to fund wallet via Interac at this time. Please try again',
                    );
                }
            })
            .catch((err) => {
                uiStore.error('We were unable to fund your wallet at this time. Please try again');
                console.error(err);
            })
            .finally(() => {
                setOpenInteracModal(false);
                uiStore.hideLoading();
            });
    };

    const handleFundWallet = () => {
        if (!feeBreakdown || !selectedVendor || amount < 0) {
            return;
        }

        const { fee, netAmount } = feeBreakdown[selectedVendor];
        console.debug(
            `Charging fee of ${fee} for amount ${amount} for a net amount of ${netAmount}`,
        );

        switch (selectedVendor) {
            case FundSourceTypes.STRIPE:
                handleOpenStripe();
                break;
            case FundSourceTypes.PAYPAL:
                handleOpenPaypal();
                break;
            case FundSourceTypes.INTERAC:
                handleOpenInterac();
                break;
        }
    };

    return (
        <>
            <RightDrawer isOpen={open} onOpen={() => {}} onClose={handleResetCheckout}>
                <div style={{ height: 'calc(100vh - 140px)' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={1}></Grid>
                        <Grid container item xs={10} spacing={3}>
                            <Grid item>
                                <Typography variant='h5'>Fund Your Wallet</Typography>
                            </Grid>
                            <Grid container item spacing={4}>
                                <Grid item xs={12}>
                                    <Typography variant='body1' gutterBottom>
                                        Amount
                                    </Typography>
                                    <InputFieldMoney
                                        id='amount'
                                        value={amount}
                                        placeholder='Amount to Fund'
                                        fullWidth
                                        onChange={handleAmountChange}
                                        style={{ marginTop: '0', paddingTop: '0' }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body1' gutterBottom>
                                        Select Payment Method
                                    </Typography>
                                    <RadioGroup
                                        aria-label='fee-options'
                                        name='fee-options'
                                        value={selectedVendor}
                                        onChange={handleVendorChange}
                                    >
                                        {!paymentOptionInteracDisabled && (
                                            <Paper
                                                elevation={0}
                                                variant='outlined'
                                                style={{
                                                    padding: '0.5em',
                                                    paddingLeft: '1em',
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={FundSourceTypes.INTERAC}
                                                    control={
                                                        <Grid style={{ paddingRight: '1em' }}>
                                                            <Radio
                                                                value={FundSourceTypes.INTERAC}
                                                            />
                                                            <SmallInteracIcon />
                                                        </Grid>
                                                    }
                                                    label={
                                                        <>
                                                            <i>Interac</i> e-Transfer&reg;
                                                        </>
                                                    }
                                                />
                                            </Paper>
                                        )}
                                        {!paymentOptionStripeDisabled && (
                                            <Paper
                                                elevation={0}
                                                variant='outlined'
                                                style={{
                                                    padding: '0.5em',
                                                    paddingLeft: '1em',
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={FundSourceTypes.STRIPE}
                                                    control={
                                                        <Grid style={{ paddingRight: '1em' }}>
                                                            <Radio value={FundSourceTypes.STRIPE} />
                                                            <SmallStripeIcon />
                                                        </Grid>
                                                    }
                                                    label='Credit Card (Stripe)'
                                                />
                                            </Paper>
                                        )}
                                        {!paymentOptionPaypalDisabled && (
                                            <Paper
                                                elevation={0}
                                                variant='outlined'
                                                style={{
                                                    padding: '0.5em',
                                                    paddingLeft: '1em',
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={FundSourceTypes.PAYPAL}
                                                    control={
                                                        <Grid style={{ paddingRight: '1em' }}>
                                                            <Radio value={FundSourceTypes.PAYPAL} />
                                                            <SmallPayPalIcon />
                                                        </Grid>
                                                    }
                                                    label='Paypal'
                                                />
                                            </Paper>
                                        )}
                                    </RadioGroup>
                                    {paymentOptionInteracDisabled &&
                                        paymentOptionPaypalDisabled &&
                                        paymentOptionStripeDisabled && (
                                            <Paper
                                                elevation={0}
                                                variant='outlined'
                                                style={{
                                                    padding: '0.5em',
                                                    paddingLeft: '1em',
                                                }}
                                            >
                                                <Typography
                                                    variant='body1'
                                                    align='center'
                                                    gutterBottom
                                                >
                                                    <b>No payment method available at this time.</b>
                                                </Typography>
                                            </Paper>
                                        )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4}></Grid>
                                        <Grid item container xs={8}>
                                            <Grid container item spacing={2}>
                                                <Grid item container xs={12}>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant='body1'
                                                            gutterBottom
                                                            align='left'
                                                        >
                                                            Amount
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant='body1'
                                                            gutterBottom
                                                            align='right'
                                                        >
                                                            <NumericFormat
                                                                value={amount}
                                                                prefix='$ '
                                                                suffix=' CAD'
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                decimalSeparator='.'
                                                                fixedDecimalScale
                                                                decimalScale={2}
                                                            />
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={12}>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant='body1'
                                                            gutterBottom
                                                            align='left'
                                                        >
                                                            Transaction Fee
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant='body1'
                                                            gutterBottom
                                                            align='right'
                                                        >
                                                            <NumericFormat
                                                                value={
                                                                    feeBreakdown && selectedVendor
                                                                        ? feeBreakdown[
                                                                              selectedVendor
                                                                          ].fee
                                                                        : 0
                                                                }
                                                                prefix='$ '
                                                                suffix=' CAD'
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                decimalSeparator='.'
                                                                fixedDecimalScale
                                                                decimalScale={2}
                                                            />
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider
                                                    variant='fullWidth'
                                                    style={{ width: '100%' }}
                                                />
                                                <Grid item container xs={12}>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant='body1'
                                                            gutterBottom
                                                            align='left'
                                                        >
                                                            <b>Total</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant='body1'
                                                            gutterBottom
                                                            align='right'
                                                        >
                                                            <b>
                                                                <NumericFormat
                                                                    value={netAmount}
                                                                    prefix='$ '
                                                                    suffix=' CAD'
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    decimalSeparator='.'
                                                                    fixedDecimalScale
                                                                    decimalScale={2}
                                                                />
                                                            </b>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        fullWidth
                                                        onClick={handleFundWallet}
                                                        color='primary'
                                                        variant='contained'
                                                        style={{ marginRight: '1rem' }}
                                                        disabled={!(amount > 0 && !!selectedVendor)}
                                                    >
                                                        Pay
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </div>
                <Grid container spacing={3}>
                    <Paper
                        role='dialog'
                        aria-modal='false'
                        aria-label='Cookie banner'
                        square
                        variant='outlined'
                        style={{
                            borderTopWidth: '1px',
                            borderWidth: '0',
                            width: '100%',
                        }}
                    >
                        <Grid container alignContent='center' style={{ padding: '8px 34px' }}>
                            <Typography variant='body2' align='center' style={{ width: '100%' }}>
                                <i>Interac</i> e-Transfer is a registered trade-mark and the Interac
                                logo is a trade-mark of Interac Corp. Used under license.
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>
            </RightDrawer>

            <Dialog
                open={openPaypalModal}
                onClose={handleClosePaypal}
                maxWidth='sm'
                fullWidth={true}
                aria-labelledby='fund-wallet-via-paypal-checkout'
                aria-describedby='fund-wallet-via-paypal-checkout-modal'
            >
                <DialogContent>
                    <PayPalScriptProvider options={paypalInitialOptions}>
                        {/* NOTE: Paypal charges the customer the processing fee directly so we don't include it here. */}
                        <PaypalButton amount={amount.toFixed(2)} onComplete={handleComplete} />
                    </PayPalScriptProvider>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClosePaypal}
                        color='primary'
                        style={{ marginRight: '1rem' }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openInteracModal} onClose={handleClose} maxWidth='sm' fullWidth>
                <DialogContent>
                    <div
                        style={{
                            minHeight: '250px',
                            paddingTop: '10px',
                            padding: '10px',
                        }}
                    >
                        <Grid container>
                            <Grid item style={{ width: '100%' }}>
                                <div style={{ float: 'right', marginBottom: '15px' }}>
                                    <LargeInteracIcon />
                                </div>
                            </Grid>
                            <Grid item>
                                <div>
                                    <Typography variant='h5' align='center' gutterBottom>
                                        Creating <i>Interac</i> e-Transfer® payment request for the
                                        amount{' '}
                                        <b>
                                            <NumericFormat
                                                value={netAmount}
                                                prefix='$ '
                                                suffix=' CAD'
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                decimalSeparator='.'
                                                fixedDecimalScale
                                                decimalScale={2}
                                            />
                                        </b>
                                        .
                                    </Typography>
                                    <br />
                                    <Typography variant='h5' align='center' gutterBottom>
                                        You will be redirected shortly. Please Wait...
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Paper
                        role='dialog'
                        aria-modal='false'
                        aria-label='Cookie banner'
                        square
                        variant='outlined'
                        style={{
                            borderTopWidth: '1px',
                            borderWidth: '0',
                        }}
                    >
                        <Grid container style={{ padding: '8px 34px' }}>
                            <Typography variant='body2'>
                                <i>Interac</i> e-Transfer is a registered trade-mark and the Interac
                                logo is a trade-mark of Interac Corp. Used under license.
                            </Typography>
                        </Grid>
                    </Paper>
                </DialogActions>
            </Dialog>
        </>
    );
});
