import React from 'react';
import { observer } from 'mobx-react-lite';
import { Card, CardContent, Container, Grid, Typography } from '@material-ui/core';
import { MainLayout } from '../../layouts/main/main';
import {
    CardContentLink,
    CardContentLinkItem,
    PageContent,
    CardLinkIcon,
    CardLinkTitle,
    CardLinkFooter,
    PageHead,
    useStyles,
} from './styles';

export const AccountPage: React.FC = observer(() => {
    const classes = useStyles();

    const AccountCard: React.FC<{ title: string; link: string; text: string }> = observer(
        ({ title, link, text }) => {
            return (
                <>
                    <Grid item xs={12} md={4}>
                        <Card variant='outlined' className={classes.root}>
                            <CardContent className={classes.content}>
                                <CardContentLinkItem>
                                    <CardContentLink href={link}>
                                        <CardLinkIcon></CardLinkIcon>
                                        <CardLinkTitle>
                                            <Typography variant='h5' component='h5'>
                                                {title}
                                            </Typography>
                                        </CardLinkTitle>
                                        <CardLinkFooter>
                                            {/* <Hidden smDown> */}
                                            {/*TODO: Uncomment after upgrade to MUI 5 */}
                                            <Typography color='textSecondary'>{text}</Typography>
                                            {/* </Hidden> */}
                                        </CardLinkFooter>
                                    </CardContentLink>
                                </CardContentLinkItem>
                            </CardContent>
                        </Card>
                    </Grid>
                </>
            );
        },
    );

    return (
        <>
            <MainLayout>
                <PageContent>
                    <Container maxWidth='md'>
                        <PageHead>
                            <Typography variant='h4' gutterBottom>
                                Account
                            </Typography>
                        </PageHead>
                        <Grid>
                            <Grid container spacing={1}>
                                <AccountCard
                                    title='Personal Info'
                                    link='/account/personal-info'
                                    text='Provide personal details and how we can reach you'
                                />
                                <AccountCard
                                    title='Login & Security'
                                    link='/account/login-security'
                                    text='Update your login information and secure your account'
                                />
                                <AccountCard
                                    title='Financials'
                                    link='/account/financials'
                                    text='Review your payment, payout methods and
                                settings'
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </PageContent>
            </MainLayout>
        </>
    );
});
