import { Button, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { ILeaseRatingReviewDetails, LeaseTerm } from 'realhaus-sdk';
import { RootStoreContext } from '../../global/storeContext';
import StringUtils from '../../utils/string';
import { RatingReviewDialog } from './ratingReviewDialog';

export const PendingReviewsCard: React.FC<{}> = observer(() => {
    const { ratingStore, uiStore } = React.useContext(RootStoreContext);

    const fetchPendingReviews = () => {
        ratingStore.getPendingReviews(uiStore.isLandlordMode());
    };

    useEffect(() => {
        fetchPendingReviews();
    }, []);

    const handleRatingReviewCompleted = () => {
        fetchPendingReviews();
    };

    return (
        <>
            {ratingStore.PendingReviews.map((l: ILeaseRatingReviewDetails) => {
                return (
                    <Grid container key={l.leaseAgreementId} spacing={4}>
                        <PendingReviewCard
                            review={l}
                            handleReviewCompleted={handleRatingReviewCompleted}
                        />
                    </Grid>
                );
            })}
        </>
    );
});

export const PendingReviewCard: React.FC<{
    review: ILeaseRatingReviewDetails;
    handleReviewCompleted: () => void;
}> = observer(({ review, handleReviewCompleted }) => {
    const [openRatingDailog, setOpenRatingDailog] = React.useState(false);
    const [revieweeFirstName, setRevieweeFirstname] = React.useState<string>('');
    const [address, setAddress] = React.useState<string>('');
    const { userStore, uiStore, leaseStore } = React.useContext(RootStoreContext);

    const isLandlord = uiStore.isLandlordMode();

    const handleOpen = () => {
        setOpenRatingDailog(true);
    };
    const handleClose = () => {
        setOpenRatingDailog(false);
    };

    const termToReview = (
        isLandlord
            ? !review.midterm.ownerCompletedOn && review.midterm.dateDue > Date.now()
            : !review.midterm.tenantCompletedOn && review.midterm.dateDue > Date.now()
    )
        ? LeaseTerm.midterm
        : LeaseTerm.endterm;

    useEffect(() => {
        if (!!review) {
            // get leaseAgreement
            leaseStore.getLeaseAgreementById(review.leaseAgreementId).then((lease) => {
                if (!!lease) {
                    // set listing address
                    setAddress(lease.listingInfo.address.streetAddress);

                    // get reviewee info
                    userStore
                        .getUserProfile(isLandlord ? lease.tenantIds[0] : lease.ownerId)
                        .then((user) => {
                            if (!!user) {
                                setRevieweeFirstname(user.firstname);
                            }
                        });
                }
            });
        }
    }, []);

    const userReviewTitle = `${revieweeFirstName} at ${address}`;

    return (
        <>
            <Grid container item spacing={2}>
                <Grid item xs={9}>
                    <Typography variant='subtitle1'>{` ${StringUtils.truncateString(
                        userReviewTitle,
                        27,
                    )}`}</Typography>
                    <Typography variant='body1'>
                        due{' '}
                        {termToReview === LeaseTerm.midterm
                            ? StringUtils.getDateTimeFormat(review.midterm.dateDue)
                            : StringUtils.getDateTimeFormat(review.endterm.dateDue)}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        onClick={() => {
                            handleOpen();
                        }}
                        color='primary'
                        variant='outlined'
                    >
                        Review
                    </Button>
                </Grid>
            </Grid>
            <RatingReviewDialog
                open={openRatingDailog}
                handleCloseModal={handleClose}
                revieweeFirstName={revieweeFirstName}
                review={review}
                term={termToReview}
                handleReviewCompleted={handleReviewCompleted}
            />
        </>
    );
});
