import { observer } from 'mobx-react-lite';
import { Breadcrumbs, Typography, Link } from '@material-ui/core';
import React, { useEffect } from 'react';
import { AdminPageLayout } from '../../components/uiComponents/UIComponents';
import { FinancialsComponent } from '../../components/financials/index';
import { RootStoreContext } from '../../global/storeContext';
import { BankAccountPurpose, Vendors, IPaypalToken } from 'realhaus-sdk';
import { getAccessCode, getPaypalUserProfile } from '../../utils/paypal';

interface PaypalUserProfile {
    user_id: string;
    name: string;
    payer_id: string;
    address: {
        street_address: string;
        locality: string;
        region: string;
        postal_code: string;
        country: string;
    };
    verified_account: boolean;
    emails: [
        {
            value: string;
            primary: boolean;
        },
    ];
}

export const FinancialsPage: React.FC = observer(() => {
    const { bankAccountStore, uiStore } = React.useContext(RootStoreContext);

    useEffect(() => {
        let params = new URL(document.location.href).searchParams;
        let code = params.has('code') ? params.get('code') : null;
        if (!!code) {
            getAccessCode(code).then((data: IPaypalToken) => {
                if (!data || !data.access_token) {
                    console.log('== could not retrieve paypal access token ==');
                    throw new Error('== could not retrieve paypal access token ==');
                }
                getPaypalUserProfile(data.access_token).then((data: PaypalUserProfile) => {
                    if (!data) {
                        throw new Error('== could not retrieve paypal user profile');
                    }
                    const payer_id = data.payer_id;
                    const email = data.emails.filter((email) => email.primary === true)[0].value;
                    if (data.verified_account) {
                        bankAccountStore.connectBankAccount(BankAccountPurpose.PAYOUTS, {
                            vendor: Vendors.PAYPAL,
                            context: { payer_id, email },
                            timestamp: new Date().getTime(),
                        });
                    } else {
                        const msg = 'Attempt to connect your paypal account failed.';
                        uiStore.error(msg);
                        console.error(msg);
                        return;
                    }
                });
            });
            params.delete('code');
            uiStore.goTo(params.toString());
        }
        return console.log('paypal code not provided');
    }, []);
    return (
        <AdminPageLayout
            header={
                <div>
                    <Breadcrumbs separator='›' aria-label='breadcrumb'>
                        <Link color='inherit' href='/account'>
                            Account
                        </Link>
                        <Typography color='textPrimary'>Financials</Typography>
                    </Breadcrumbs>
                    <Typography variant='h4' gutterBottom>
                        Financials
                    </Typography>
                </div>
            }
            content={<FinancialsComponent />}
            maxWidth='md'
        />
    );
});
