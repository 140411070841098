import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import { ITenantLeaseInvite } from 'realhaus-sdk';

interface LeaseTenantInformationComponentProps {
    tenantLeaseInvite: ITenantLeaseInvite;
    updateTenantLeaseInvite: (
        prop: keyof ITenantLeaseInvite,
    ) => (event: React.ChangeEvent<{ value: unknown }>) => void;
    readOnly?: boolean;
}

export const LeaseTenantInformationComponent: React.FC<LeaseTenantInformationComponentProps> =
    observer(({ tenantLeaseInvite, updateTenantLeaseInvite, readOnly }) => {
        return !readOnly ? (
            <LeaseTenantInformationEditComponent
                tenantLeaseInvite={tenantLeaseInvite}
                updateTenantLeaseInvite={updateTenantLeaseInvite}
            />
        ) : (
            <LeaseTenantInformationReadComponent tenantLeaseInvite={tenantLeaseInvite} />
        );
    });

const LeaseTenantInformationEditComponent: React.FC<LeaseTenantInformationComponentProps> =
    observer(({ tenantLeaseInvite, updateTenantLeaseInvite }) => {
        return (
            <>
                <Typography variant='h6'>Tenant Information</Typography>
                <Box mt={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Typography variant='subtitle1' gutterBottom>
                                First Name
                            </Typography>
                            <TextField
                                id='firstName'
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                margin='normal'
                                value={tenantLeaseInvite.firstname ?? ''}
                                onChange={updateTenantLeaseInvite('firstname')}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='subtitle1' gutterBottom>
                                Last Name
                            </Typography>
                            <TextField
                                id='lastName'
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                margin='normal'
                                value={tenantLeaseInvite.lastname ?? ''}
                                onChange={updateTenantLeaseInvite('lastname')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1' gutterBottom>
                                Email Address
                            </Typography>
                            <TextField
                                id='email'
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                margin='normal'
                                value={tenantLeaseInvite.email ?? ''}
                                onChange={updateTenantLeaseInvite('email')}
                                required
                            />
                        </Grid>
                    </Grid>
                </Box>
            </>
        );
    });

const LeaseTenantInformationReadComponent: React.FC<{
    tenantLeaseInvite: ITenantLeaseInvite;
}> = observer(({ tenantLeaseInvite }) => {
    return (
        <>
            <Typography variant='h6'>Tenant Information</Typography>
            <Grid style={{ marginTop: 2 }} container>
                <Grid item xs={6}>
                    <Typography variant='subtitle1' gutterBottom>
                        <b>First Name</b>
                    </Typography>
                    <Typography>{tenantLeaseInvite?.firstname}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='subtitle1' gutterBottom>
                        <b>Last Name</b>
                    </Typography>
                    <Typography>{tenantLeaseInvite?.lastname}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle1' gutterBottom>
                        <b>Email Address</b>
                    </Typography>
                    <Typography>$CAD {tenantLeaseInvite?.email}</Typography>
                </Grid>
            </Grid>
        </>
    );
});
