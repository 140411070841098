import { Card, CardContent, Typography, CardActions, Button } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { IdLeaseAgreement, LeaseAgreementStatus } from 'realhaus-sdk';
import { RootStoreContext } from '../../../../global/storeContext';
import { paths } from '../../../../routes';
import { useStyles } from './style';

export const ExpiringLeasesCardComponent: React.FC = observer(() => {
    const [expiringLeases, setExpiringLeases] = useState<IdLeaseAgreement[]>([]);
    const { leaseStore, uiStore } = useContext(RootStoreContext);

    const classes = useStyles();

    const fetchExpiringLeases = async () => {
        const leaseAgreements = await leaseStore.getOwnerLeaseAgreements();
        if (!leaseAgreements) {
            return console.log('>> Landlord has no leaseAgreement');
        }
        const expLeases = [];

        const date = new Date();
        date.setDate(date.getDay() + 60);
        expLeases.push(
            ...leaseAgreements.filter(
                (lease) =>
                    lease.status === LeaseAgreementStatus.SIGNED &&
                    Number(date) >= lease.moveoutDate,
            ),
        );
        setExpiringLeases(expLeases);
        uiStore.hideLoading();
    };

    useEffect(() => {
        uiStore.showLoading();
        fetchExpiringLeases();
    }, []);

    const viewLeaseDetails = () => {
        uiStore.goTo(paths.landlord.tenants);
    };
    return (
        <>
            <Card variant='elevation' className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <Typography variant='subtitle1' color='textSecondary'>
                        Expiring Leases
                    </Typography>
                    <Typography variant='h2' component='h2'>
                        {expiringLeases.length}
                    </Typography>
                </CardContent>
                <CardActions className={classes.cardActions}>
                    <Button
                        size='small'
                        color='primary'
                        variant='outlined'
                        disabled={expiringLeases.length === 0}
                        onClick={viewLeaseDetails}
                    >
                        View
                    </Button>
                </CardActions>
            </Card>
        </>
    );
});
