import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { IAddress, IResidenceInfo } from 'realhaus-sdk';

export const streetAddress = (address: IAddress) => (
    <>
        {!address
            ? null
            : address.unit
            ? `${address.unit} - ${address.streetAddress}`
            : address.streetAddress}
    </>
);

export type AddressViewerAddress = IResidenceInfo & {
    leaseId?: string;
};

export const AddressCardReadonly: React.FC<{
    residence: AddressViewerAddress;
    canDelete?: boolean;
    onDelete?: () => void;
}> = observer(({ residence, canDelete, onDelete }) => {
    return (
        <>
            <Card variant='outlined'>
                <CardContent>
                    <Typography variant='body1' gutterBottom>
                        {streetAddress(residence.address)}
                        <br />
                        {residence.address.city}, {residence.address.province}{' '}
                        {residence.address.postalCode}
                        <br />
                        {residence.address.country}
                    </Typography>

                    <Typography variant='subtitle2'>
                        {format(residence.moveinDate, 'PPP')} -{' '}
                        {!!residence.moveoutDate ? format(residence?.moveoutDate, 'PPP') : null}
                    </Typography>
                </CardContent>
                {!canDelete || !onDelete ? null : (
                    <CardActions>
                        <Button size='small' onClick={onDelete}>
                            Delete
                        </Button>
                    </CardActions>
                )}
            </Card>
        </>
    );
});
